import { useEffect, useRef } from "react";

export default function useInterval(callback, delay) {
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (delay === null || delay === undefined) return;

    let handle = setInterval(() => savedCallback.current(), delay);

    return () => {
      clearInterval(handle);
    };
  }, [delay]);
}
