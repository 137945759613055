import React, {Fragment, useState} from 'react';
import {Form, InputGroup, Alert} from 'react-bootstrap';
import {ScrollView, View} from 'react-native';
import {observer} from 'mobx-react-lite';
import InstallationStore from '../../stores/InstallationStore';
import AssetStore from '../../stores/AssetStore';
import GatewaysStore from '../../stores/GatewaysStore';
import SensorsStore from '../../stores/SensorsStore';

import {sensorType} from '../../utils/Constants';
import {
  getEsn,
  findShortMacDuplicates,
  checkTpmsSensorsCount,
  checkGatewayCount,
} from '../../utils/Utils';
import FilledButton from '../../shared/components/common/MediumButton/FilledButton';
import {useScale} from '../../shared/theme/scale';
import NavigationIcon from '../../shared/components/icons/NavigationIcon';
import {useStore} from '../../shared/stores/useStore';
import InstallationTopAppBar from '../../components/InstallationTopAppBar';
import {SafeAreaView} from 'react-native-safe-area-context';
import Container from '../../shared/components/common/Container';
import {ResponsiveStyle, useStyles} from '../../shared/theme/styles';
import {StyleSheet} from 'react-native';
import Footer from '../../shared/components/navigation/Footer';
import Label from '../../shared/components/common/Label';
import {usePalette} from '../../shared/theme/palette';
import locales from '../../shared/locales/en';
import {useNavigate} from '../../shared/utils/routing';

function InstallationWorkOrder() {
  const {apiClient} = useStore();

  const [error, setError] = useState('');
  const navigate = useNavigate();

  const styles = useStyles(createStyles);

  const palette = usePalette();

  const {moderateScale} = useScale();

  const [isLoading, setIsLoading] = useState(false);

  const gatewayCount = GatewaysStore.gateways.filter(g => g.id).length;

  async function confirm() {
    try {
      setError('');
      setIsLoading(true);

      const shortMacError = findShortMacDuplicates(
        SensorsStore.sensors.filter(s => s.type !== sensorType.smarthub),
        [
          ...AssetStore.getSensorsPositionsList(sensorType.tpms),
          ...AssetStore.getSensorsPositionsList(sensorType.linePressure),
        ],
      );

      if (shortMacError?.value) {
        setError(shortMacError.text);
        return;
      }

      const sensorsCountWarning = checkTpmsSensorsCount(
        GatewaysStore.gateways.filter(g => g.id).length,
        SensorsStore.sensors.filter(s => s.type !== sensorType.smarthub).length,
      );

      if (sensorsCountWarning) {
        setError(sensorsCountWarning);
        return;
      }

      const gatewayCountWarning = checkGatewayCount(
        GatewaysStore.gateways.filter(g => g.id).length,
        SensorsStore.sensors.length,
      );

      if (gatewayCountWarning) {
        setError(gatewayCountWarning);
        return;
      }

      if (!gatewayCount && AssetStore.hasOdometer) {
        await AssetStore.resetOdometer(apiClient.asset);
      }

      await apiClient.installation.setComment(
        InstallationStore.installation.id,
        InstallationStore.installation.comment,
      );
      InstallationStore.clear();
      navigate('/');
    } catch (e) {
      setError((e as any).message);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Fragment>
      <View style={styles.screen}>
        <InstallationTopAppBar />

        <SafeAreaView
          mode="padding"
          edges={['left', 'right']}
          style={styles.bodyContainer}>
          <ScrollView
            keyboardShouldPersistTaps="handled"
            contentContainerStyle={{
              flexGrow: 1,
              padding: moderateScale(16),
            }}>
            <Container
              style={{
                flexGrow: 1,
                flexShrink: 1,
              }}>
              <Label
                variant="H3"
                color={palette.text[1000]}
                style={{textAlign: 'center', marginBottom: 24}}>
                {AssetStore.assetTypeName.toUpperCase()} INSTALLATION WORK ORDER
              </Label>

              <InstallationInfo />

              {!gatewayCount && AssetStore.hasOdometer && (
                <Alert className="mt-3" variant="warning">
                  Odometer offset will not be saved – requires gateway
                </Alert>
              )}

              {GatewaysStore.validationErrorResults.map(validationResult => (
                <Alert
                  key={validationResult.message}
                  className="mt-2 mb-2"
                  variant={
                    validationResult.status === 'warning' ? 'warning' : 'danger'
                  }>
                  <h6 className="alert-heading mb-1 text-left font-weight-bold">
                    {validationResult.gatewayMacs.join(', ')}
                  </h6>

                  <p className="mb-0 text-left">{validationResult.message}</p>
                </Alert>
              ))}

              {error && (
                <Alert className="mt-3" variant="danger">
                  {error}
                </Alert>
              )}

              <View
                style={{
                  flexDirection: 'row',
                }}>
                <FilledButton
                  text={locales.goBack}
                  isDisabled={isLoading}
                  onPress={() => {
                    navigate('/installation-photos');
                  }}
                  leftNode={() => <NavigationIcon direction="back" />}
                  style={{
                    flexGrow: 1,
                    flexShrink: 1,
                  }}
                />

                <View style={{flexBasis: moderateScale(8)}} />

                <FilledButton
                  text={locales.confirm}
                  isDisabled={isLoading || GatewaysStore.hasValidationError}
                  onPress={confirm}
                  rightNode={() => <NavigationIcon direction="forward" />}
                  style={{
                    flexGrow: 1,
                    flexShrink: 1,
                  }}
                />
              </View>
            </Container>
          </ScrollView>
        </SafeAreaView>

        <Footer />
      </View>
    </Fragment>
  );
}

const InstallationInfo = observer(() => {
  const {userStore} = useStore();

  const palette = usePalette();

  return (
    <>
      <Form.Group style={{textAlign: 'center'}}>
        <Form.Label style={{color: palette.text[900]}}>ASSET VIN</Form.Label>
        <InputGroup>
          <Form.Control
            className="text-center"
            value={AssetStore.asset.vin}
            readOnly
          />
          <InputGroup.Append>
            <InputGroup.Text>
              <i
                className={`fas fa-image ${
                  InstallationStore.installation.assetVins3key
                    ? 'text-success'
                    : 'text-danger'
                }`}></i>
            </InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
      </Form.Group>

      <Form.Group style={{textAlign: 'center'}}>
        <Form.Label style={{color: palette.text[900]}}>ASSET NAME</Form.Label>
        <Form.Control
          className="text-center"
          value={AssetStore.asset.name}
          readOnly
        />
      </Form.Group>

      {userStore.isRivataUser === false && (
        <Form.Group style={{textAlign: 'center'}}>
          <Form.Label style={{color: palette.text[900]}}>
            CASE NUMBER
          </Form.Label>
          <Form.Control
            className="text-center"
            value={InstallationStore.installation.caseNumber}
            readOnly
          />
        </Form.Group>
      )}

      <GatewaysInfo />

      <SensorsInfo type={sensorType.smarthub} typeName="SmartHub" />
      <SensorsInfo type={sensorType.tpms} typeName="TPMS" />

      <PneumaticsInfo />

      <Form.Group style={{textAlign: 'center'}} className="mb-0">
        <Form.Label style={{color: palette.text[900]}}>
          INSTALL COMMENTS <span className="text-muted">(Optional)</span>
        </Form.Label>
        <Form.Control
          as="textarea"
          value={InstallationStore.installation.comment || ''}
          onChange={e => {
            InstallationStore.installation.comment = e.target.value;
          }}
          rows={3}
          placeholder="Include any comments/concerns on installation process."
        />
      </Form.Group>
    </>
  );
});

const GatewaysInfo = observer(() => {
  const gateways = GatewaysStore.gateways.filter(g => g.id);

  const palette = usePalette();

  if (gateways.length <= 0) return null;

  return (
    <>
      <Label
        variant="H5"
        color={palette.text[1000]}
        style={{textAlign: 'center', marginTop: 24}}>
        GATEWAYS
      </Label>

      {gateways.map(g => (
        <Form.Group style={{textAlign: 'center'}} key={g.id}>
          <Form.Label style={{color: palette.text[900]}}>
            GATEWAY ESN
          </Form.Label>
          <InputGroup>
            <Form.Control
              className="text-center"
              value={getEsn(g.esn)}
              readOnly
            />
            <InputGroup.Append>
              <InputGroup.Text>
                <i
                  className={`fas fa-image ${
                    g.s3key && g.powers3key ? 'text-success' : 'text-danger'
                  }`}></i>
              </InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Form.Group>
      ))}
    </>
  );
});

const SensorsInfo = observer(({type, typeName}: {type: any; typeName: any}) => {
  const sensors = SensorsStore.sensors.filter(s => s.type === type && s.id);

  const palette = usePalette();

  if (sensors.length <= 0) return null;

  return (
    <>
      <Label
        variant="H5"
        color={palette.text[1000]}
        style={{textAlign: 'center', marginTop: 24}}>
        {typeName}
      </Label>

      {AssetStore.getSensorsPositionsList(type).map(p => {
        const sensor = sensors.find(s => s.code === p.code);

        if (!sensor) return null;

        return (
          <Form.Group style={{textAlign: 'center'}} key={sensor.code}>
            <Form.Label style={{color: palette.text[900]}}>
              {sensor.code}
            </Form.Label>
            <InputGroup>
              <Form.Control
                className="text-center"
                value={sensor.esn}
                readOnly
              />
              <InputGroup.Append>
                <InputGroup.Text>
                  <i
                    className={`fas fa-image ${
                      sensor.s3key ? 'text-success' : 'text-danger'
                    }`}></i>
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
        );
      })}
    </>
  );
});

const PneumaticsInfo = observer(() => {
  const palette = usePalette();

  const axleLoad = SensorsStore.sensors.find(
    s => s.type === sensorType.axleLoad,
  );

  const linePressure = SensorsStore.sensors.find(
    s => s.type === sensorType.linePressure,
  );
  return (
    <>
      {axleLoad && (
        <>
          <Label
            variant="H5"
            color={palette.text[1000]}
            style={{textAlign: 'center', marginTop: 24}}>
            AXLE LOAD
          </Label>

          <Form.Group style={{textAlign: 'center'}} key={axleLoad.id}>
            <Form.Label style={{color: palette.text[900]}}>
              AXLE LOAD ID
            </Form.Label>
            <InputGroup>
              <Form.Control
                className="text-center"
                value={axleLoad.esn}
                readOnly
              />
              <InputGroup.Append>
                <InputGroup.Text>
                  <i
                    className={`fas fa-image ${
                      axleLoad.s3key ? 'text-success' : 'text-danger'
                    }`}></i>
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
        </>
      )}

      {linePressure && (
        <>
          <Label
            variant="H5"
            color={palette.text[1000]}
            style={{textAlign: 'center', marginTop: 24}}>
            LINE PRESSURE
          </Label>

          <Form.Group style={{textAlign: 'center'}} key={linePressure.id}>
            <Form.Label style={{color: palette.text[900]}}>
              LINE PRESSURE ID
            </Form.Label>
            <InputGroup>
              <Form.Control
                className="text-center"
                value={linePressure.esn}
                readOnly
              />
              <InputGroup.Append>
                <InputGroup.Text>
                  <i
                    className={`fas fa-image ${
                      linePressure.s3key ? 'text-success' : 'text-danger'
                    }`}></i>
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
        </>
      )}
    </>
  );
});

const createStyles = ({palette}: ResponsiveStyle) =>
  StyleSheet.create({
    screen: {
      flexGrow: 1,
      flexShrink: 1,
      backgroundColor: palette.surface[50],
    },
    headerDivider: {
      borderBottomWidth: 1,
      borderBottomColor: palette.surface[200],
    },
    bodyContainer: {
      flexGrow: 1,
      flexShrink: 1,
    },
  });

export default observer(InstallationWorkOrder);
