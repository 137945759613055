import React from 'react';
import {ToggleButton, ToggleButtonGroup} from 'react-bootstrap';
import {atiInstalled} from '../utils/Constants';
import {usePalette} from '../shared/theme/palette';

const AssetAtiSelection = ({isDisabled, value, onChange}) => {
  const palette = usePalette();

  return (
    <ToggleButtonGroup
      type="radio"
      name="options"
      disabled={isDisabled}
      defaultValue={atiInstalled.noInfoProvided}
      vertical
      onChange={onChange}>
      <ToggleButton
        id="tbg-radio-1"
        size="lg"
        disabled={isDisabled}
        value={atiInstalled.yes}
        variant={value === atiInstalled.yes ? 'success' : 'outline-primary'}
        style={{
          color: value === atiInstalled.yes ? undefined : palette.text[1000],
        }}>
        ATI Installed
      </ToggleButton>

      <ToggleButton
        id="tbg-radio-2"
        size="lg"
        disabled={isDisabled}
        value={atiInstalled.no}
        variant={value === atiInstalled.no ? 'success' : 'outline-primary'}
        style={{
          color: value === atiInstalled.no ? undefined : palette.text[1000],
        }}>
        ATI Not Installed
      </ToggleButton>

      <ToggleButton
        id="tbg-radio-3"
        size="lg"
        disabled={isDisabled}
        value={atiInstalled.notSure}
        variant={value === atiInstalled.notSure ? 'success' : 'outline-primary'}
        style={{
          color:
            value === atiInstalled.notSure ? undefined : palette.text[1000],
        }}>
        Not Sure
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default AssetAtiSelection;
