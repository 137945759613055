import React from 'react';
import {CheckSquareSvg} from '../iconsExporter';
import {useScale} from '../../theme/scale';

type Props = {
  size?: number;
};

const CheckSquareIcon: React.FC<Props> = props => {
  const {size = 24} = props;

  const {moderateScale} = useScale();

  return (
    <CheckSquareSvg width={moderateScale(size)} height={moderateScale(size)} />
  );
};

export default CheckSquareIcon;
