import { useMemo } from "react";
import { useState } from "react";
import { useEffect, useRef } from "react";
import { provisionStatus } from "../utils/Constants";
import useInterval from "./useInterval";
import { useStore } from "../shared/stores/useStore";

const provisionStatusIntervalDelay = 5000;
const maxProvisionStatusRetryCount = 28;

export default function useInstallationProvisionStatus(installationId) {
  const {apiClient} = useStore();

  const [provisionStatusInfo, setProvisionStatusInfo] = useState(null);
  const [provisionStatusRetryCount, setProvisionStatusRetryCount] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);

  const intervalCanRun = useMemo(() => {
    return (
      installationId &&
      provisionStatusRetryCount < maxProvisionStatusRetryCount &&
      (!provisionStatusInfo || provisionStatusInfo.status === provisionStatus.inProgress)
    );
  }, [provisionStatusRetryCount, installationId, provisionStatusInfo]);

  async function getProvisionStatus() {
    const data = await apiClient.installation.getStatus(installationId);

    if (data) setProvisionStatusInfo(data);
  }

  async function updateProvisionStatus(id) {
    if (!id) return;

    try {
      setIsLoading(true);

      await getProvisionStatus();
      if ((!provisionStatusInfo || provisionStatusInfo.status === provisionStatus.inProgress) && !intervalCanRun) {
        await setFailedProvisionStatus();
      }
    } finally {
      setIsLoading(false);
    }
  }

  async function setFailedProvisionStatus() {
    await apiClient.installation.setStatus(installationId, provisionStatus.failed);
    await getProvisionStatus();
  }

  function reset() {
    setProvisionStatusInfo(null);
    setProvisionStatusRetryCount(-1);
  }

  useInterval(
    () => {
      setProvisionStatusRetryCount((prev) => prev + 1);
    },
    intervalCanRun ? provisionStatusIntervalDelay : null
  );

  useEffect(() => {
    if (provisionStatusRetryCount >= 0) updateProvisionStatus(installationId);
  }, [provisionStatusRetryCount]);

  useEffect(() => {
    reset();
    updateProvisionStatus(installationId);
  }, [installationId]);

  return {
    provisionStatusInfo,
    isProvisionInProgress: intervalCanRun || isLoading,
  };
}
