import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";

function ConfirmationModal(_, ref) {
  const [isShown, setIsShown] = useState(false);

  ref.current.show = (data) => {
    ref.current.title = data.title;
    ref.current.text = data.text;
    ref.current.onConfirm = data.onConfirm;
    setIsShown(true);
  };

  ref.current.hide = () => {
    setIsShown(false);
  };

  return (
    <Modal show={isShown} onHide={ref.current.hide} backdrop="static">
      <Modal.Header className="h3" closeButton>
        {ref.current.title}
      </Modal.Header>
      <Modal.Body className="h5">{ref.current.text}</Modal.Body>
      <Modal.Footer>
        <Button
          onClick={async () => {
            await ref.current.onConfirm();
            ref.current.hide();
          }}
        >
          Confirm
        </Button>
        <Button variant="outline-primary" onClick={ref.current.hide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default React.forwardRef(ConfirmationModal);
