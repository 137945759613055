import React, {Fragment, useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useStore} from '../../shared/stores/useStore';
import {ScrollView, StyleSheet, View} from 'react-native';
import InstallationTopAppBar from '../../components/InstallationTopAppBar';
import {ResponsiveStyle, useStyles} from '../../shared/theme/styles';
import {SafeAreaView} from 'react-native-safe-area-context';
import {useScale} from '../../shared/theme/scale';
import FilledButton from '../../shared/components/common/MediumButton/FilledButton';
import OutlineButton from '../../shared/components/common/MediumButton/OutlineButton';
import Label from '../../shared/components/common/Label';
import {usePalette} from '../../shared/theme/palette';
import ConMetLogo from '../../shared/components/icons/ConMetLogo';
import Container from '../../shared/components/common/Container';
import Alert from '../../shared/components/common/Alert';
import locales from '../../shared/locales/en';
import Footer from '../../shared/components/navigation/Footer';
import Logging from '../../shared/services/logging';

function SignIn() {
  const {authStore, userStore} = useStore();

  const styles = useStyles(createStyles);

  const palette = usePalette();

  const {moderateScale, moderateScaleV} = useScale();

  const [loginUrl, setLoginUrl] = useState<string | null>(null);

  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if (redirect && loginUrl) {
      window.location.href = loginUrl;
      setLoginUrl(null);
    }
  }, [redirect, loginUrl]);

  async function setSignInPageUrl(isRivataUser: boolean) {
    try {
      const timeout = new Promise<void>((resolve, reject) =>
        setTimeout(() => reject('Timeout'), 10 * 1000),
      );

      userStore.setIsRivataUser(isRivataUser);

      const url = await Promise.race([authStore.getSignInUrl(), timeout]);

      setRedirect(true);
      setLoginUrl(url || '');
    } catch (e: unknown) {
      const error = Logging.parseUnknownError(e);
      Logging.recordError(error);

      authStore.errorStore.setError(error.message);
    }
  }

  return (
    <Fragment>
      <View style={styles.screen}>
        <InstallationTopAppBar />

        <SafeAreaView
          mode="padding"
          edges={['left', 'right']}
          style={styles.bodyContainer}>
          <ScrollView
            keyboardShouldPersistTaps="handled"
            contentContainerStyle={{
              flexGrow: 1,
              padding: moderateScale(16),
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Container
              style={{
                flexGrow: 1,
                flexShrink: 1,
              }}>
              <Label
                variant="H3"
                color={palette.text[1000]}
                style={{textAlign: 'center'}}>
                {locales.welcomeTo}
              </Label>

              <View
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: moderateScale(32),
                  marginBottom: moderateScaleV(64),
                }}>
                <ConMetLogo
                  size="big"
                  variant={
                    palette.mode === 'light' ? 'primaryBlack' : 'primaryWhite'
                  }
                />
              </View>

              {!!authStore.errorStore.error && (
                <Alert
                  variant="centerAligned"
                  styleVariant="error"
                  label={authStore.errorStore.error}
                  style={{marginBottom: moderateScaleV(24)}}
                />
              )}

              <Container maxWidth={358}>
                <FilledButton
                  text={locales.loginAsFleet}
                  isDisabled={authStore.isLoading}
                  onPress={() => setSignInPageUrl(true)}
                  style={{marginBottom: moderateScaleV(16)}}
                />

                <OutlineButton
                  text={locales.loginAsContractor}
                  isDisabled={authStore.isLoading}
                  onPress={() => setSignInPageUrl(false)}
                />
              </Container>
            </Container>
          </ScrollView>
        </SafeAreaView>

        <Footer />
      </View>
    </Fragment>
  );
}

const createStyles = ({palette}: ResponsiveStyle) =>
  StyleSheet.create({
    screen: {
      flexGrow: 1,
      flexShrink: 1,
      backgroundColor: palette.surface[50],
    },
    headerDivider: {
      borderBottomWidth: 1,
      borderBottomColor: palette.surface[200],
    },
    bodyContainer: {
      flexGrow: 1,
      flexShrink: 1,
    },
  });

export default observer(SignIn);
