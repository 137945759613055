import React from 'react';
import {Text, TextProps, TextStyle} from 'react-native';
import {
  TypographyVariant,
  typographyVariants,
  useTypographyFamily,
  useTypographySize,
} from '../../theme/typography';

type TextInheritProps = Pick<
  TextProps,
  | 'children'
  | 'style'
  | 'numberOfLines'
  | 'ellipsizeMode'
  | 'onPress'
  | 'lineBreakMode'
>;

type LabelProps = TextInheritProps & {
  variant?: TypographyVariant;
  color?: string;
};

const Label = (props: LabelProps) => {
  const {
    variant,
    color,
    ellipsizeMode,
    numberOfLines,
    lineBreakMode,
    children,
    style,
    onPress,
  } = props;

  const styleProps: Pick<
    TextStyle,
    | 'fontSize'
    | 'fontWeight'
    | 'fontStyle'
    | 'textTransform'
    | 'letterSpacing'
    | 'fontFamily'
    | 'textTransform'
    | 'letterSpacing'
  > = variant ? typographyVariants[variant] : {};

  const familyStyle = useTypographyFamily(
    styleProps.fontFamily,
    styleProps.fontStyle,
    styleProps.fontWeight,
  );

  const sizeStyle = useTypographySize(styleProps.fontSize, true);

  return (
    <Text
      allowFontScaling={false}
      ellipsizeMode={ellipsizeMode}
      lineBreakMode={lineBreakMode}
      numberOfLines={numberOfLines}
      onPress={onPress}
      style={[
        familyStyle,
        sizeStyle,
        {
          letterSpacing: styleProps.letterSpacing,
          textTransform: styleProps.textTransform,
          color,
        },
        style,
      ]}>
      {children}
    </Text>
  );
};

export default Label;
