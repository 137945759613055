import * as React from "react";

const MailIcon = ({ width, height, color, rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width={width} height={height} fill={color} xmlSpace="preserve" {...rest}>
    <path d="M30.9 33.7 0 13v36c0 2.2 1.8 4 4 4h56c2.2 0 4-1.8 4-4V13L33.1 33.7c-.7.4-1.5.4-2.2 0z" />
    <path d="M60.4 10.6H3.6l27.3 18.3c.7.5 1.6.5 2.2 0l27.3-18.3z" />
  </svg>
);

export default MailIcon;
