import {Platform, StyleProp, TextStyle} from 'react-native';
import {useScale} from './scale';

type FontFamily = 'Roboto' | 'Noto Sans';

type FontStyle = Exclude<TextStyle['fontStyle'], undefined>;

type FontWeight = Exclude<TextStyle['fontWeight'], undefined>;

export type TypographyVariant = keyof typeof typographyVariants;

function createTypographyVariants<T extends Record<string, TextStyle>>(
  typographyVariants: T,
): T {
  return typographyVariants;
}

function createTypographyFontFamilies<
  T extends Record<FontFamily, Record<FontStyle, Record<FontWeight, string>>>,
>(typographyFontFamilies: T): T {
  return typographyFontFamilies;
}

export const typographyVariants = createTypographyVariants({
  H1: {
    fontSize: 28,
    fontWeight: '700',
    fontStyle: 'normal',
    textTransform: 'none',
    letterSpacing: 0,
  },
  H2: {
    fontSize: 24,
    fontWeight: '600',
    fontStyle: 'normal',
    textTransform: 'none',
    letterSpacing: 0,
  },
  H3: {
    fontSize: 22,
    fontWeight: '600',
    fontStyle: 'normal',
    textTransform: 'none',
    letterSpacing: 0.02,
  },
  H4: {
    fontSize: 20,
    fontWeight: '600',
    fontStyle: 'normal',
    textTransform: 'none',
    letterSpacing: 0.02,
  },
  H5: {
    fontSize: 18,
    fontWeight: '600',
    fontStyle: 'normal',
    textTransform: 'none',
    letterSpacing: 0.02,
  },
  H6: {
    fontSize: 16,
    fontWeight: '600',
    fontStyle: 'normal',
    textTransform: 'none',
    letterSpacing: 0.02,
  },
  L1: {
    fontSize: 16,
    fontWeight: '500',
    fontStyle: 'normal',
    textTransform: 'none',
    letterSpacing: 0.04,
  },
  L2: {
    fontSize: 14,
    fontWeight: '500',
    fontStyle: 'normal',
    textTransform: 'uppercase',
    letterSpacing: 0.04,
  },
  L3: {
    fontSize: 14,
    fontWeight: '500',
    fontStyle: 'normal',
    textTransform: 'none',
    letterSpacing: 0.02,
  },
  L4: {
    fontSize: 12,
    fontWeight: '500',
    fontStyle: 'normal',
    textTransform: 'none',
    letterSpacing: 0.03,
  },
  L5: {
    fontSize: 11,
    fontWeight: '500',
    fontStyle: 'normal',
    textTransform: 'none',
    letterSpacing: 0.02,
  },
  B1: {
    fontSize: 20,
    fontWeight: '600',
    fontStyle: 'normal',
    textTransform: 'none',
    letterSpacing: 0.02,
  },
  B2: {
    fontSize: 16,
    fontWeight: '500',
    fontStyle: 'normal',
    textTransform: 'none',
    letterSpacing: 0.02,
  },
  B3: {
    fontSize: 14,
    fontWeight: '500',
    fontStyle: 'normal',
    textTransform: 'none',
    letterSpacing: 0.03,
  },
  B4: {
    fontSize: 12,
    fontWeight: '500',
    fontStyle: 'normal',
    textTransform: 'none',
    letterSpacing: 0.03,
  },
  P1: {
    fontSize: 16,
    fontWeight: '400',
    fontStyle: 'normal',
    textTransform: 'none',
    letterSpacing: 0,
  },
  P1_bold: {
    fontSize: 16,
    fontWeight: '600',
    fontStyle: 'normal',
    textTransform: 'none',
    letterSpacing: 0,
  },
  P1_italic: {
    fontSize: 16,
    fontWeight: '400',
    fontStyle: 'italic',
    textTransform: 'none',
    letterSpacing: 0.01,
  },
  P2: {
    fontSize: 14,
    fontWeight: '400',
    fontStyle: 'normal',
    textTransform: 'none',
    letterSpacing: 0.01,
  },
  P2_bold: {
    fontSize: 14,
    fontWeight: '600',
    fontStyle: 'normal',
    textTransform: 'none',
    letterSpacing: 0.01,
  },
  P2_italic: {
    fontSize: 14,
    fontWeight: '400',
    fontStyle: 'italic',
    textTransform: 'none',
    letterSpacing: 0.01,
  },
  P3: {
    fontSize: 12,
    fontWeight: '400',
    fontStyle: 'normal',
    textTransform: 'none',
    letterSpacing: 0.02,
  },
  P3_bold: {
    fontSize: 12,
    fontWeight: '600',
    fontStyle: 'normal',
    textTransform: 'none',
    letterSpacing: 0.02,
  },
  P3_italic: {
    fontSize: 12,
    fontWeight: '400',
    fontStyle: 'italic',
    textTransform: 'none',
    letterSpacing: 0.02,
  },
});

export const defaultLineHeightMultiplier = 1.375;

const fontFamilies = createTypographyFontFamilies({
  Roboto: {
    normal: {
      '100': 'Roboto',
      '200': 'Roboto',
      '300': 'Roboto',
      '400': 'Roboto',
      normal: 'Roboto',
      '500': 'Roboto',
      '600': 'Roboto',
      '700': 'Roboto',
      bold: 'Roboto',
      '800': 'Roboto',
      '900': 'Roboto',
    },
    italic: {
      '100': 'Roboto',
      '200': 'Roboto',
      '300': 'Roboto',
      '400': 'Roboto',
      normal: 'Roboto',
      '500': 'Roboto',
      '600': 'Roboto',
      '700': 'Roboto',
      bold: 'Roboto',
      '800': 'Roboto',
      '900': 'Roboto',
    },
  },
  'Noto Sans': {
    normal: {
      '100': 'NotoSans-Regular',
      '200': 'NotoSans-Regular',
      '300': 'NotoSans-Regular',
      '400': 'NotoSans-Regular',
      normal: 'NotoSans-Medium',
      '500': 'NotoSans-Medium',
      '600': 'NotoSans-SemiBold',
      '700': 'NotoSans-Bold',
      bold: 'NotoSans-Bold',
      '800': 'NotoSans-Bold',
      '900': 'NotoSans-Bold',
    },
    italic: {
      '100': 'NotoSans-Italic',
      '200': 'NotoSans-Italic',
      '300': 'NotoSans-Italic',
      '400': 'NotoSans-Italic',
      normal: 'NotoSans-Italic',
      '500': 'NotoSans-MediumItalic',
      '600': 'NotoSans-SemiBoldItalic',
      '700': 'NotoSans-BoldItalic',
      bold: 'NotoSans-BoldItalic',
      '800': 'NotoSans-BoldItalic',
      '900': 'NotoSans-BoldItalic',
    },
  },
});

export function useTypographyFamily(
  fontFamily: TextStyle['fontFamily'] = 'Noto Sans',
  fontStyle: TextStyle['fontStyle'] = 'normal',
  fontWeight: TextStyle['fontWeight'] = '400',
): StyleProp<TextStyle> {
  return Platform.select({
    native: {
      fontFamily:
        fontFamily in fontFamilies
          ? fontFamilies[fontFamily as FontFamily][fontStyle][fontWeight]
          : fontFamily,
    },
    web: {
      fontFamily: `${fontFamily}, myriad-pro-condensed, myriad-pro, sans-serif`,
      fontStyle,
      fontWeight,
    },
  });
}

export function useTypographySize(
  fontSize: TextStyle['fontSize'] = 14,
  scaled: boolean,
  lineHeightMultiplier: number = defaultLineHeightMultiplier,
): StyleProp<TextStyle> {
  const {moderateScaleV} = useScale();

  const scaledFontSize = scaled ? moderateScaleV(fontSize) : fontSize;

  return {
    fontSize: scaledFontSize,
    lineHeight: scaledFontSize * lineHeightMultiplier,
  };
}

export function useTypographyVariant(
  fontFamily: FontFamily,
  variant: TypographyVariant,
): StyleProp<TextStyle> {
  const variantProps = typographyVariants[variant];

  const familyStyle = useTypographyFamily(
    fontFamily,
    variantProps.fontStyle,
    variantProps.fontWeight,
  );

  const sizeStyle = useTypographySize(variantProps.fontSize, true);

  return [
    familyStyle,
    sizeStyle,
    {
      letterSpacing: variantProps.letterSpacing,
      textTransform: variantProps.textTransform,
    },
  ];
}
