import React, {ReactNode} from 'react';
import {StyleSheet, View} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';
import {useScale} from '../../theme/scale';

type Props = {
  variant: 'centerAligned';
} & CenterAlignedProps;

type CenterAlignedProps = {
  leftNode?: ReactNode;
  centerNode?: ReactNode;
  rightNode?: ReactNode;
};

const TopAppBar: React.FC<Props> = props => {
  const {variant, ...rest} = props;

  if (variant === 'centerAligned') {
    return <CenterAligned {...rest} />;
  }

  return null;
};

const CenterAligned: React.FC<CenterAlignedProps> = props => {
  const {leftNode, centerNode, rightNode} = props;

  const {moderateScale, moderateScaleV} = useScale();

  return (
    <SafeAreaView mode="padding" edges={['top', 'left', 'right']}>
      <View
        style={{
          height: moderateScaleV(64),
          paddingHorizontal: moderateScale(16),
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        {leftNode ? (
          <View style={{marginRight: moderateScale(24), zIndex: 1}}>
            {leftNode}
          </View>
        ) : (
          <View />
        )}

        <View
          style={[
            StyleSheet.absoluteFill,
            {
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 0,
            },
          ]}>
          {centerNode}
        </View>

        {rightNode ? (
          <View style={{marginLeft: moderateScale(24), zIndex: 1}}>
            {rightNode}
          </View>
        ) : (
          <View />
        )}
      </View>
    </SafeAreaView>
  );
};

export default TopAppBar;
