import { action, computed, makeObservable, observable } from "mobx";

export default class HardwareData {
  code;
  mac;
  type;
  isUploaded;
  reportedIn4Hours;
  reportedBetween4and24hours;
  notReportedIn24HoursOrLonger;
  belongsToAnotherAsset;
  latestPackets;

  constructor(data) {
    this.code = data.code;
    this.mac = data.mac;
    this.type = data.type;
    this.isUploaded = data.isUploaded;
    this.reportedIn4Hours = data.reportedIn4Hours;
    this.reportedBetween4and24hours = data.reportedBetween4and24hours;
    this.notReportedIn24HoursOrLonger = data.notReportedIn24HoursOrLonger;
    this.belongsToAnotherAsset = data.belongsToAnotherAsset;
    this.latestPackets = [];

    makeObservable(this, {
      code: observable,
      mac: observable,
      type: observable,
      isUploaded: observable,
      reportedIn4Hours: observable,
      reportedBetween4and24hours: observable,
      notReportedIn24HoursOrLonger: observable,
      belongsToAnotherAsset: observable,
      latestPackets: observable,

      latestPacketUpdateDate: computed,
      reportColorVariant: computed,

      addLatestPacket: action,
      clearLatestPackets: action,
    });
  }

  get latestPacketUpdateDate() {
    let lastEpoch = null;
    this.latestPackets.forEach((d) => {
      if (lastEpoch === null) {
        lastEpoch = d.timestamp;
        return;
      }

      if (lastEpoch < d.timestamp) {
        lastEpoch = d.timestamp;
      }
    });

    return lastEpoch;
  }

  get reportColorVariant() {
    if (!this.isUploaded) return "danger";
    if (this.reportedIn4Hours) return "success";
    if (this.reportedBetween4and24hours) return "warning";
    if (this.notReportedIn24HoursOrLonger) return "dark";
    return "danger";
  }

  addLatestPacket(data) {
    this.latestPackets.push(data);
  }

  clearLatestPackets() {
    this.latestPackets = [];
  }
}
