import FetchStore from '../stores/fetch/FetchStore';

export default class AssetAPI {
  constructor(private readonly fetchStore: FetchStore) {}

  async getAsset(vin: string, upToDate: any) {
    return await this.fetchStore.get(`asset?vin=${vin}&upToDate=${!!upToDate}`);
  }

  async getInstallation(id: any, getForcedNew: any) {
    let url = `asset/${id}/installation`;

    if (getForcedNew) {
      url += `?getForcedNew=${getForcedNew}`;
    }

    return await this.fetchStore.get(url);
  }

  async setType(id: any, type: any) {
    return await this.fetchStore.put(`asset/${id}/type`, {type: type});
  }

  async setS3key(id: any, key: any) {
    return await this.fetchStore.put(`asset/${id}/s3key`, {s3key: key});
  }

  async setOdometer(id: any, data: any) {
    return await this.fetchStore.put(`asset/${id}/odometer`, data);
  }

  async setAti(assetId: any, atiInstalled: any) {
    return await this.fetchStore.put(`asset/${assetId}/ati`, {
      atiInstalled: atiInstalled,
    });
  }

  async search(data: any) {
    return await this.fetchStore.post(`asset/search`, data);
  }

  async create(data: any) {
    return await this.fetchStore.post(`asset/create`, data);
  }

  async getFromFleet(vin: string) {
    return await this.fetchStore.get(`asset/fleet?vin=${vin}`);
  }

  async getFromNHTSA(vin: string): Promise<NhtsaAssetDto> {
    return await this.fetchStore.get(`asset/nhtsa?vin=${vin}`);
  }
}
