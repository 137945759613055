import {
  AssetSubtypeTractor,
  ManufacturerTrailerTypeMapping,
} from '../types/enums';

export function listToTree<
  T extends {
    id: number;
    name: string;
    parent_id: number | null;
  },
>(list: Array<T>) {
  const map: Record<number, number> = {};

  const listCopy: Array<T & {children: Array<T>}> = [];

  const roots: Array<T & {children: Array<T>}> = [];

  for (let i = 0; i < list.length; i += 1) {
    listCopy[i] = {...list[i], children: []};
    map[listCopy[i].id] = i;
  }

  for (let i = 0; i < listCopy.length; i += 1) {
    const node = listCopy[i];

    if (node.parent_id && map[node.parent_id]) {
      listCopy[map[node.parent_id]].children.push(node);
    } else {
      roots.push(node);
    }
  }

  roots.forEach(
    root =>
      (root.children = root.children.sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
      )),
  );

  return roots.sort((a, b) =>
    a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
  );
}

export const removeSpecialCharacters = (str: string) =>
  str.replace(/[^a-zA-Z0-9]/g, '');

export const parseSerial = (rawSerial: string) => {
  const dividers = ['$S', '@S'];

  dividers.forEach(d => {
    const indexOfDivider = rawSerial.indexOf(d);

    if (indexOfDivider !== -1) {
      rawSerial = rawSerial.substring(indexOfDivider + d.length);
    }
  });

  let serial = rawSerial;

  if (rawSerial.indexOf('-') === -1) {
    serial = rawSerial.replace(/(.{2})/g, '$1-');
    serial = serial.replace(/-$/, '');
  }

  return serial;
};

export const validateGatewayEsn = (serial: string) => {
  const hexCount = getHexCount(serial);
  return hexCount === 10 || hexCount === 12;
};

export const getHexCount = (string: string) =>
  string.split('').reduce((total, symbol) => {
    if (symbol !== '-' && hex.includes(symbol)) {
      total++;
    }

    return total;
  }, 0);

export const hex = '0123456789ABCDEF';

export const getGatewayEsn = (esn: string) => {
  if (!esn?.length) return '';

  esn = esn.startsWith('00') ? esn.substring(2) : esn;
  esn = removeSpecialCharacters(esn);
  esn = insertSeparatorBetweenChars(esn, '-', 2);

  return esn;
};

export function insertSeparatorBetweenChars(
  text: string,
  separator: string,
  charGroupLength: number,
) {
  const regex = new RegExp(`(.{${charGroupLength}})`, 'g');
  const separetedText = text.replace(regex, '$1' + separator);

  return text.length % charGroupLength === 0
    ? separetedText.slice(0, -separator.length)
    : separetedText;
}

export function inEnum<T, E extends Record<string, string | number>>(
  value: T,
  enumType: E,
): value is T {
  if (typeof value !== 'string' && typeof value !== 'number') return false;

  return Object.values(enumType).includes(value as string | number);
}

export function getTrailerSubtypeFromVin(vin: string) {
  const manufacturerIdentifier = vin.substring(0, 3);

  if (
    !ManufacturerTrailerTypeMapping.knownManufacturers.includes(
      manufacturerIdentifier,
    )
  ) {
    return null;
  }

  const manufacturerData =
    ManufacturerTrailerTypeMapping.manufacturersData[
      manufacturerIdentifier as keyof typeof ManufacturerTrailerTypeMapping.manufacturersData
    ];

  const trailerSubtypeSymbol = vin[manufacturerData.indexOfTrailerType];

  if (!manufacturerData.validTypes.includes(trailerSubtypeSymbol)) {
    return null;
  }

  const trailerSubtype =
    manufacturerData.mapping[
      trailerSubtypeSymbol as keyof typeof manufacturerData.mapping
    ];

  return trailerSubtype;
}

export function getTractorSubtypeFromTrim(nhtsaTrim: string) {
  const tractorSubType = Object.values(AssetSubtypeTractor).find(element =>
    nhtsaTrim.toLowerCase().replace(/\s/g, '_').includes(element),
  );

  return tractorSubType || null;
}

export function toCapitalCase(value: string, separator: string = ' '): string {
  return value.replace(new RegExp(`^.|(${separator})(.)`, 'g'), char =>
    char.toUpperCase(),
  );
}
