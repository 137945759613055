import React, {useState, useEffect, useMemo, Fragment} from 'react';
import {observer} from 'mobx-react-lite';
import {Modal, Button} from 'react-bootstrap';
import {useForm, Controller} from 'react-hook-form';
import AssetStore from '../../stores/AssetStore';
import InstallationStore from '../../stores/InstallationStore';
import ButtonWithSpinner from '../../components/ButtonWithSpinner';
import useNavigationState from '../../hooks/useNavigationState';
import {validateYearToInt} from '../../utils/Utils';
import GatewaysStore from '../../stores/GatewaysStore';
import {useStore} from '../../shared/stores/useStore';
import Logging from '../../shared/services/logging';
import {ScrollView, View} from 'react-native';
import AssetNameController from '../../shared/modules/AssetCreate/AssetNameController';
import {useScale} from '../../shared/theme/scale';
import AssetMakeController from '../../shared/modules/AssetCreate/AssetMakeController';
import AssetModelController from '../../shared/modules/AssetCreate/AssetModelController';
import AssetYearController from '../../shared/modules/AssetCreate/AssetYearController';
import AssetTypeController from '../../shared/modules/AssetCreate/AssetTypeController';
import AssetAtiController from '../../shared/modules/AssetCreate/AssetAtiController';
import Label from '../../shared/components/common/Label';
import LabelInput from '../../shared/components/common/LabelInput';
import {
  getTractorSubtypeFromTrim,
  getTrailerSubtypeFromVin,
  inEnum,
  toCapitalCase,
} from '../../shared/utils';
import {
  AssetSubtypeTractor,
  AssetSubtypeTrailer,
  AssetType,
  NthsaTrailerSubtypeMap,
  OverlayModalKey,
} from '../../shared/types/enums';
import AssetSubTypeController, {
  SubTypeOptions,
} from '../../shared/modules/AssetCreate/AssetSubTypeController';
import CustomerSelect from '../../shared/modules/AssetCreate/CustomerSelect';
import AssetCreateView from '../../shared/modules/AssetCreate/AssetCreateView';
import OverlayModal from '../../shared/components/common/OverlayModal';
import DialogView from '../../shared/components/common/DialogView';
import AssetAxleCountController from '../../shared/modules/AssetCreate/AssetAxleCountController';
import {usePalette} from '../../shared/theme/palette';
import Alert from '../../shared/components/common/Alert';
import locales from '../../shared/locales/en';
import {useNavigate} from '../../shared/utils/routing';

const CreateAssetModal = ({
  showModal,
  closeModal,
  setFilter,
  setNothingFound,
}: {
  showModal: any;
  closeModal: any;
  setFilter: any;
  setNothingFound: any;
}) => {
  const {userStore, apiClient} = useStore();

  const [isLoading, setIsLoading] = useState(false);
  const [nhtsaInfo, setNhtsaInfo] = useState<NhtsaAssetDto | null>(null);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [proceedWithInvalidVin, setProceedWithInvalidVin] = useState(false);
  const [asset, setAsset] = useState<{id: any; vin: any} | null>(null);
  const [error, setError] = useState('');

  const navigate = useNavigate();

  const {getScreen} = useNavigationState();

  const {moderateScale} = useScale();

  const palette = usePalette();

  const {
    reset,
    watch,
    handleSubmit,
    setValue: setFormValue,
    control,
    trigger,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
  });

  const watchAssetType = watch('type', null);

  const [subTypeOptions, setSubTypeOptions] = useState<SubTypeOptions>([]);

  function setupSubTypeOptions(assetType: AssetType | null) {
    if (assetType === AssetType.tractor) {
      setSubTypeOptions(
        [...Object.values(AssetSubtypeTractor)].map(c => {
          return {
            value: c,
            label: toCapitalCase(c.replace(/\_/g, ' ').trim()),
          };
        }),
      );
    } else if (assetType === AssetType.trailer) {
      setSubTypeOptions(
        [...Object.values(AssetSubtypeTrailer)].map(c => {
          return {
            value: c,
            label: toCapitalCase(c.replace(/\_/g, ' ').trim()),
          };
        }),
      );
    } else {
      setSubTypeOptions([]);
    }
  }

  const [isSubTypePreselected, setIsSubTypePreselected] = useState(false);

  const onSubmit = async (formData: any) => {
    setIsSubTypePreselected(false);
    setError('');
    setIsLoading(true);

    try {
      const values: {
        type: AssetType | null;
        subType: any | null;
      } = {
        type: null,
        subType: null,
      };

      const vin = formData.vin.trim().toUpperCase();

      const trailerSubtype = getTrailerSubtypeFromVin(vin);

      if (trailerSubtype) {
        values.type = AssetType.trailer;
        values.subType = trailerSubtype;
        setIsSubTypePreselected(true);
      }

      const fleetAsset = await apiClient.asset.getFromFleet(vin);

      if (fleetAsset.exists) {
        let existsMessage = 'Asset already exists';

        if (!fleetAsset.haveAccess)
          existsMessage += ", but you don't have access";

        setError(existsMessage);
        setIsLoading(false);
      } else {
        const nhtsa: NhtsaAssetDto | undefined =
          await apiClient.asset.getFromNHTSA(vin);

        if (nhtsa) {
          setNhtsaInfo(nhtsa);

          setFormValue('make', nhtsa.make);
          setFormValue('model', nhtsa.model);
          setFormValue(
            'modelyear',
            isNaN(parseInt(nhtsa.modelyear, 10))
              ? undefined
              : parseInt(nhtsa.modelyear, 10).toString(),
          );
          values.type = nhtsa.type || null;
          setFormValue('numberOfAxles', nhtsa.numberOfAxles);

          if (!values.subType) {
            if (nhtsa.type === AssetType.trailer && nhtsa?.trailerSubType) {
              const found_asset_subtype =
                NthsaTrailerSubtypeMap[
                  nhtsa.trailerSubType as keyof typeof NthsaTrailerSubtypeMap
                ];

              if (found_asset_subtype) {
                values.subType = found_asset_subtype;
                setIsSubTypePreselected(true);
              }
            } else if (nhtsa.type === AssetType.tractor && nhtsa?.trim) {
              const tractorSubType = getTractorSubtypeFromTrim(nhtsa.trim);

              if (tractorSubType) {
                values.subType = tractorSubType;
                setIsSubTypePreselected(true);
              }
            }
          }
        }
      }

      setFormValue('type', values.type);
      setupSubTypeOptions(values.type);
      setFormValue('subType', values.subType);
    } catch (e) {
      const error = Logging.parseUnknownError(e);
      Logging.recordError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onCreate = async (formData: any) => {
    setIsLoading(true);

    try {
      const parsedNumberOfAxles = parseInt(formData.numberOfAxles);

      const data = {
        vin: formData.vin.trim().toUpperCase(),
        make: formData.make.trim(),
        model: formData.model.trim(),
        modelyear: validateYearToInt(formData.modelyear, null),
        name: formData.name.trim(),
        type: formData.type,
        subType: formData.subType,
        odometer: null,
        isOdometerInMiles: true,
        isInvalid: nhtsaInfo?.isInvalid,
        rawNHTSA: nhtsaInfo?.rawNHTSA,
        customerId: formData.customerId,
        atiInstalled: formData.atiInstalled,
        numberOfAxles: isNaN(parsedNumberOfAxles) ? null : parsedNumberOfAxles,
      };

      const result = await apiClient.asset.create(data);

      if (result && result.id) setAsset(result);
    } catch (e) {
      const error = Logging.parseUnknownError(e);
      Logging.recordError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const startInstallation = async () => {
    setIsLoading(true);

    try {
      const installation = await apiClient.asset.getInstallation(
        asset?.id,
        undefined,
      );

      InstallationStore.apply(installation);
      await GatewaysStore.validate(installation.asset.vin, apiClient.gateway);
    } catch (e) {
      const error = Logging.parseUnknownError(e);
      Logging.recordError(error);

      return;
    } finally {
      setIsLoading(false);
    }

    AssetStore.set({...asset, isNew: true});

    navigate(getScreen('assetCreate.startInstallation'));
  };

  const vinEditDisabled = useMemo(() => {
    if (isLoading) return true;

    if (nhtsaInfo && nhtsaInfo.isInvalid) return proceedWithInvalidVin;

    return !!nhtsaInfo;
  }, [isLoading, nhtsaInfo, proceedWithInvalidVin]);

  const continueButtonText = useMemo(() => {
    if (!nhtsaInfo) return 'Continue';

    if (nhtsaInfo.isInvalid && !proceedWithInvalidVin)
      return 'Proceed without valid VIN';

    return 'Create';
  }, [nhtsaInfo, proceedWithInvalidVin]);

  useEffect(() => {
    if (!nhtsaInfo) return;

    if (!nhtsaInfo.isInvalid || proceedWithInvalidVin) setShowCreateForm(true);
  }, [nhtsaInfo, proceedWithInvalidVin]);

  const resetAndClose = () => {
    reset();
    setError('');
    setNhtsaInfo(null);
    setShowCreateForm(false);
    setProceedWithInvalidVin(false);
    setIsLoading(false);
    setAsset(null);
    closeModal();
  };

  if (asset) {
    return (
      <Modal
        show={showModal}
        onHide={() => {
          AssetStore.set({...asset, isNew: true});
          setFilter({value: asset.vin, withoutUpdate: false});
          setNothingFound(false);
          resetAndClose();
        }}
        backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title style={{color: palette.text[1000]}}>
            Create Asset
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          Asset successfully created, would you like to provision this asset
          now?
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setFilter({value: '', withoutUpdate: false});
              resetAndClose();
            }}
            style={{width: '80px'}}
            disabled={isLoading}>
            No
          </Button>

          <ButtonWithSpinner
            variant="primary"
            type="submit"
            isLoading={isLoading}
            onClick={startInstallation}
            style={{minWidth: '80px'}}
            className={undefined}
            disabled={undefined}
            block={undefined}>
            Yes
          </ButtonWithSpinner>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <OverlayModal modalKey={OverlayModalKey.ASSET_CREATE} visible={showModal}>
      <DialogView fullWidth={true}>
        <ScrollView style={{flexGrow: 0, flexShrink: 1}}>
          <AssetCreateView
            disabled={isLoading}
            continueButtonText={continueButtonText}
            resetAndClose={resetAndClose}
            onContinue={() => {
              if (!nhtsaInfo) return handleSubmit(onSubmit)();

              if (!nhtsaInfo.isInvalid) return handleSubmit(onCreate)();

              if (proceedWithInvalidVin) {
                handleSubmit(onCreate)();
              } else {
                setProceedWithInvalidVin(true);
              }
            }}>
            <Controller
              name="vin"
              control={control}
              defaultValue=""
              rules={{
                required: 'This field is required',
                minLength: 1,
                maxLength: {
                  value: 64,
                  message: 'This field must be at most 64 characters',
                },
              }}
              render={({field: {onChange, value}, fieldState}) => (
                <Fragment>
                  <Label
                    variant="L1"
                    color={palette.text[900]}
                    style={{marginBottom: moderateScale(4)}}>
                    VIN
                  </Label>

                  <LabelInput
                    isDisabled={vinEditDisabled}
                    value={value}
                    onChangeText={text => {
                      if (error) setError('');

                      onChange(text);

                      setNhtsaInfo(null);
                      setProceedWithInvalidVin(false);
                    }}
                  />

                  {!!fieldState.error && (
                    <Label
                      variant="L4"
                      color={palette.error[300]}
                      style={{marginTop: moderateScale(4)}}>
                      {fieldState.error?.message?.toString()}
                    </Label>
                  )}
                </Fragment>
              )}
            />

            {nhtsaInfo && nhtsaInfo.isInvalid && !proceedWithInvalidVin && (
              <Alert
                variant="centerAligned"
                styleVariant="warning"
                label={
                  nhtsaInfo.isNhtsaEmpty
                    ? locales.nhtsaAlerts.vinNotRecognized
                    : locales.nhtsaAlerts.vinPartiallyRecognized
                }
                style={{marginTop: 24}}
              />
            )}

            {!!nhtsaInfo && showCreateForm && (
              <>
                <View style={{marginTop: moderateScale(20)}} />

                <AssetNameController control={control} disabled={isLoading} />

                <View style={{marginTop: moderateScale(20)}} />

                <AssetMakeController
                  control={control}
                  disabled={
                    isLoading || (!!nhtsaInfo.make && !nhtsaInfo.isInvalid)
                  }
                />

                <View style={{marginTop: moderateScale(20)}} />

                <AssetModelController
                  control={control}
                  disabled={
                    isLoading || (!!nhtsaInfo.model && !nhtsaInfo.isInvalid)
                  }
                />

                <View style={{marginTop: moderateScale(20)}} />

                <AssetYearController
                  control={control}
                  disabled={
                    isLoading || (!!nhtsaInfo.modelyear && !nhtsaInfo.isInvalid)
                  }
                />

                <View style={{marginTop: moderateScale(20)}} />

                <AssetTypeController
                  control={control}
                  disabled={
                    isLoading || (!!nhtsaInfo.type && !nhtsaInfo.isInvalid)
                  }
                  setupSubTypeOptions={setupSubTypeOptions}
                />

                <View style={{marginTop: moderateScale(20)}} />

                <AssetSubTypeController
                  control={control}
                  disabled={
                    isLoading ||
                    !inEnum(watchAssetType, AssetType) ||
                    (isSubTypePreselected && !nhtsaInfo.isInvalid)
                  }
                  data={subTypeOptions}
                />

                {!!userStore.customers.length && (
                  <Fragment>
                    <View style={{marginTop: moderateScale(20)}} />

                    <Controller
                      name="customerId"
                      control={control}
                      rules={{
                        required: 'This field is required',
                      }}
                      defaultValue={
                        userStore.customers.length > 1
                          ? null
                          : userStore.customers[0].id
                      }
                      render={({field: {onChange, value}, fieldState}) => (
                        <CustomerSelect
                          customers={userStore.customers}
                          label="Customer"
                          alignLabel="left"
                          value={value}
                          onChange={onChange}
                          disabled={
                            isLoading || userStore.customers.length <= 1
                          }
                          error={fieldState?.error?.message?.toString()}
                        />
                      )}
                    />
                  </Fragment>
                )}

                <View style={{marginTop: moderateScale(20)}} />

                <AssetAxleCountController
                  control={control}
                  disabled={
                    isLoading ||
                    (nhtsaInfo.numberOfAxles !== null && !nhtsaInfo.isInvalid)
                  }
                  assetType={watchAssetType}
                  watch={watch}
                  trigger={trigger}
                />

                <View style={{marginTop: moderateScale(20)}} />

                <AssetAtiController control={control} disabled={isLoading} />
              </>
            )}

            {!!error && (
              <div className="invalid-feedback" style={{display: 'block'}}>
                {error}
              </div>
            )}
          </AssetCreateView>
        </ScrollView>
      </DialogView>
    </OverlayModal>
  );
};

export default observer(CreateAssetModal);
