// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// prettier-ignore
import { Navigate as _Navigate, useNavigate as _useNavigate, useSearchParams as _useSearchParams, useLocation as _useLocation, useParams as _useParams } from 'react-router-dom';

import {Routing} from './types';

export const {
  useNavigate,
  useSearchParams,
  useLocation,
  useParams,
  Navigate,
}: Routing = {
  useNavigate: _useNavigate,
  useSearchParams: _useSearchParams,
  useLocation: _useLocation,
  useParams: _useParams,
  Navigate: _Navigate,
};
