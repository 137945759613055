import {StyleProp, ViewStyle, Platform} from 'react-native';

type Shadow = {
  [K in 'sm' | 'md' | 'lg' | 'xl']: StyleProp<ViewStyle>;
};

/** @prop {string} shadowColor - [Android API >= 28, IOS, Web] */
/** @prop {number} elevation - [Android] */

const web: Shadow = {
  sm: {
    boxShadow:
      '0px 0px 1px 0px rgba(0, 0, 0, 0.04), 0px 0px 2px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.04)',
  },
  md: {
    boxShadow:
      '0px 0px 1px 0px rgba(0, 0, 0, 0.04), 0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 10px 20px 0px rgba(0, 0, 0, 0.04)',
  },
  lg: {
    boxShadow:
      '0px 0px 1px 0px rgba(0, 0, 0, 0.04), 0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 16px 24px 0px rgba(0, 0, 0, 0.06)',
  },
  xl: {
    boxShadow:
      '0px 0px 1px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 16px 24px 0px rgba(0, 0, 0, 0.05), 0px 24px 32px 0px rgba(0, 0, 0, 0.05)',
  },
};

const native: Shadow = {
  sm: {
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.15,
    shadowRadius: 1.0,
    elevation: 1,
  },
  md: {
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.16,
    shadowRadius: 1.51,
    elevation: 2,
  },
  lg: {
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.17,
    shadowRadius: 3.05,
    elevation: 4,
  },
  xl: {
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 6,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5.62,
    elevation: 8,
  },
};

const shadow: Shadow = Platform.OS === 'web' ? web : native;

export type Elevation = keyof typeof shadow;

export default shadow;
