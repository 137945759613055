import React from 'react';
import {Pressable} from 'react-native';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';
import {usePalette} from '../../theme/palette';
import {useScale} from '../../theme/scale';
import CheckSquareIcon from '../icons/CheckSquareIcon';

type Props = {
  disabled?: boolean;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
};

type StyleByState = {
  bgColor: string;
  borderColor: string;
};

const SIZE = 24;

const BOUNCE_IN = 0.9;

const CheckBox: React.FC<Props> = props => {
  const {disabled, checked, onChange} = props;

  const palette = usePalette();

  const {moderateScale} = useScale();

  const stateStyles: Record<
    'notChecked' | 'checked' | 'disabled',
    StyleByState
  > = {
    notChecked: {
      bgColor: palette.surface[50],
      borderColor: palette.surface[900],
    },
    checked: {
      bgColor: palette.surface[0],
      borderColor: palette.surface[1000],
    },
    disabled: {
      bgColor: palette.surface[100],
      borderColor: palette.surface[300],
    },
  };

  const stateStyle = disabled
    ? stateStyles.disabled
    : checked
    ? stateStyles.checked
    : stateStyles.notChecked;

  const animationBounceValue = useSharedValue(1);

  function handleOnPress() {
    if (onChange) onChange(!checked);
  }

  function handlePressIn() {
    animationBounceValue.value = withSpring(BOUNCE_IN, {
      mass: 1,
      damping: 20,
      stiffness: 1000,
    });
  }

  function handlePressOut() {
    animationBounceValue.value = withSpring(1, {
      mass: 1,
      damping: 12,
      stiffness: 1000,
    });
  }

  const animatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{scale: animationBounceValue.value}],
      opacity: animationBounceValue.value,
    };
  }, [animationBounceValue]);

  return (
    <Pressable
      disabled={disabled}
      onPress={handleOnPress}
      onPressIn={handlePressIn}
      onPressOut={handlePressOut}>
      <Animated.View
        style={[
          {
            width: moderateScale(SIZE),
            height: moderateScale(SIZE),
            justifyContent: 'center',
            alignItems: 'center',

            borderWidth: 1,
            borderRadius: 4,
            borderColor: stateStyle.borderColor,
            backgroundColor: stateStyle.bgColor,
          },
          animatedStyle,
        ]}>
        {checked ? <CheckSquareIcon size={SIZE} /> : null}
      </Animated.View>
    </Pressable>
  );
};

export default CheckBox;
