import {makeObservable} from 'mobx';
import FetchConfig from './FetchConfig';

export default class FetchStore {
  runLocally = false;

  constructor(private readonly fetchConfig: FetchConfig) {
    makeObservable(this, {});
  }

  getUrl(url: string, path: string) {
    if (this.runLocally) {
      const pathWithoutQueryParams = path.split('?')[0];
      const lambdaFunctionName = pathWithoutQueryParams.split('/')[0];

      return this.fetchConfig.localEndpoints[lambdaFunctionName] + path;
    }

    return url + path;
  }

  async get(path: string) {
    const res = await fetch(this.getUrl(this.fetchConfig.apiUrl, path), {
      method: 'GET',
      headers: this.fetchConfig.headers,
    });

    return this.parseJson(res);
  }

  async post(path: string, data: SerializedData = {}) {
    const res = await fetch(this.getUrl(this.fetchConfig.apiUrl, path), {
      method: 'POST',
      headers: this.fetchConfig.headers,
      body: JSON.stringify(data),
    });

    return this.parseJson(res);
  }

  async put(path: string, data: SerializedData = {}) {
    const res = await fetch(this.getUrl(this.fetchConfig.apiUrl, path), {
      method: 'PUT',
      headers: this.fetchConfig.headers,
      body: JSON.stringify(data),
    });

    return this.parseJson(res);
  }

  async delete(path: string, data: SerializedData = {}) {
    const res = await fetch(this.getUrl(this.fetchConfig.apiUrl, path), {
      method: 'DELETE',
      headers: this.fetchConfig.headers,
      body: JSON.stringify(data),
    });

    return this.parseJson(res);
  }

  async parseJson(res: Response) {
    const json = await res.json().catch(() => {
      return null;
    });

    if (json?.error) {
      if (res.status === 401) {
        this.fetchConfig.onUnauthorizedError(json.error || 'UnauthorizedError');
      }

      throw new Error(json.error);
    }

    return json;
  }
}
