import React, {useRef, useEffect, useState} from 'react';
import {Alert, Button, Spinner} from 'react-bootstrap';
import {observer} from 'mobx-react-lite';
import InstallationPhotoStore from '../../stores/InstallationPhotoStore';

import {dataURItoFile} from '../../utils/Utils';
import {useStore} from '../../shared/stores/useStore';
import {useNavigate} from '../../shared/utils/routing';

const confirmationModalData = {
  title: 'Warning',
  text: 'Are you sure you want to delete this installation photo?',
};

function SensorPhotoUploader({
  className,
  name,
  fileName,
  s3key,
  onSucessfullUpload,
  onSucessfullDelete,
  confirmationModalRef,
}) {
  const {apiClient} = useStore();

  const [isLoading, setIsLoading] = useState(false);

  const fileInputRef = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (
      !InstallationPhotoStore.photo.file ||
      InstallationPhotoStore.photo.fileName !== fileName
    )
      return;

    handleCameraRead();
  }, []);

  const uploadPhoto = async (file, name) => {
    setIsLoading(true);

    try {
      const params = {
        key: `public/${name}`,
        contentType: file.type,
      };

      const data = await apiClient.photo.getSignedUrl(params);

      if (!data.uploadUrl) {
        return;
      }

      await fetch(data.uploadUrl, {
        method: 'PUT',
        body: file,
      }).then(async res => {
        if (res.ok) {
          await onSucessfullUpload(name);
        }
      });
    } catch (err) {
      console.error('Error uploading file: ', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeletePhotoWarning = async () => {
    confirmationModalRef.current.show({
      onConfirm: async () => {
        confirmationModalRef.current.hide();
        await deletePhoto();

        if (fileInputRef && fileInputRef.current)
          fileInputRef.current.value = '';
      },
      ...confirmationModalData,
    });
  };

  const deletePhoto = async () => {
    if (!s3key) return;

    setIsLoading(true);

    try {
      await apiClient.photo.delete({key: `public/${s3key}`}).then(async () => {
        await onSucessfullDelete();
      });
    } catch (err) {
      console.error('Error deleting file: ', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCameraRead = async () => {
    await uploadPhoto(
      dataURItoFile(InstallationPhotoStore.photo.file, 'temp.png'),
      `${fileName}.png`,
    );

    InstallationPhotoStore.clear();
  };

  const handleFileRead = async e => {
    const file = e.target.files[0];

    const lastDot = file.name.lastIndexOf('.');
    const extension = file.name.substring(lastDot + 1);

    await uploadPhoto(file, `${fileName}.${extension}`);
  };

  const handleCameraClick = () => {
    InstallationPhotoStore.clear();

    InstallationPhotoStore.photo.fileName = fileName;

    navigate('/installation-photo-camera');
  };

  const handleFileUploadClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <div className={className || ''}>
      <div className="d-flex justify-content-between">
        <Alert
          className="mb-0 flex-fill d-flex justify-content-center align-items-center"
          variant={s3key ? 'success' : 'danger'}>
          {name}{' '}
          {isLoading && (
            <Spinner className="ml-2" animation="border" size="sm" />
          )}
        </Alert>

        <div className="d-flex">
          {!s3key && (
            <Button
              disabled={isLoading}
              onClick={handleCameraClick}
              style={{opacity: isLoading ? 0.38 : undefined}}
              className="ml-3 flex-fill">
              <i className="fas fa-camera" />
            </Button>
          )}

          <input
            ref={fileInputRef}
            className="d-none"
            type="file"
            onChange={handleFileRead}
          />

          {!s3key && (
            <Button
              disabled={isLoading}
              onClick={handleFileUploadClick}
              style={{opacity: isLoading ? 0.38 : undefined}}
              className="ml-3 flex-fill">
              <i className="fas fa-image" />
            </Button>
          )}

          {s3key && (
            <Button
              disabled={isLoading}
              variant="danger"
              onClick={handleDeletePhotoWarning}
              style={{opacity: isLoading ? 0.38 : undefined}}
              className="ml-3 flex-fill">
              <i className="fas fa-trash-alt" />
            </Button>
          )}
        </div>
      </div>

      {s3key && (
        <div className="text-left">
          <span className="font-weight-bold">File Name:</span>{' '}
          <span>{s3key.replace('public/', '')}</span>
        </div>
      )}
    </div>
  );
}

export default observer(SensorPhotoUploader);
