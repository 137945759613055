import {computed, makeObservable} from 'mobx';
import {RootStore} from '../useStore';
import deviceInfoModule from 'react-native-device-info';
import {Platform} from 'react-native';

export default class FetchConfig {
  constructor(private readonly rootStore: RootStore) {
    makeObservable(this, {
      headers: computed,
      apiUrl: computed,
      localEndpoints: computed,
    });
  }

  get headers(): Record<string, string> {
    const headerRecords: Record<string, string> = {
      'Content-Type': 'application/json',
      authorization: `Bearer ${this.rootStore.authStore?.accessToken}`,
      'id-token': this.rootStore.authStore?.idToken,
      'is-rivata-user': this.rootStore.userStore?.isRivataUser.toString(),
      source: Platform.OS,
    };

    if (Platform.OS !== 'web') {
      headerRecords['build-number'] = deviceInfoModule.getBuildNumber();
    }

    return headerRecords;
  }

  get apiUrl() {
    return this.rootStore.appStore.config.apiUrl;
  }

  get localEndpoints(): Record<string, string> {
    const domain = Platform.OS === 'web' ? 'localhost' : '10.0.2.2';

    return {
      auth: `http://${domain}:4000/api/private/`,
      asset: `http://${domain}:4001/api/private/`,
      gateway: `http://${domain}:4002/api/private/`,
      installation: `http://${domain}:4003/api/private/`,
      sensor: `http://${domain}:4004/api/private/`,
      photo: `http://${domain}:4005/api/private/`,
      report: `http://${domain}:4006/api/private/`,
      user: `http://${domain}:4007/api/private/`,
      document: `http://${domain}:4008/api/private/`,
    };
  }

  async onUnauthorizedError(error: string) {
    this.rootStore.authStore.dispose();
    this.rootStore.userStore.dispose();
    this.rootStore.dispose();

    this.rootStore.userStore?.errorStore.setError(error);
  }
}
