import {observer} from 'mobx-react-lite';
import React, {Fragment} from 'react';
import {ListGroup} from 'react-bootstrap';
import MailIcon from '../components/icons/MailIcon';
import PhoneIcon from '../components/icons/PhoneIcon';
import NavigationBar from '../components/navigation/NavigationBar';
import SupportListGroupItem from '../components/supportListGroupItem/SupportListGroupItem';
import {supportEmail, supportPhone} from '../utils/Constants';
import {ResponsiveStyle, useStyles} from '../shared/theme/styles';
import {ScrollView, StyleSheet, View} from 'react-native';
import {useScale} from '../shared/theme/scale';
import InstallationTopAppBar from '../components/InstallationTopAppBar';
import {SafeAreaView} from 'react-native-safe-area-context';
import Container from '../shared/components/common/Container';
import Footer from '../shared/components/navigation/Footer';
import Label from '../shared/components/common/Label';
import {usePalette} from '../shared/theme/palette';
import {useLocation} from '../shared/utils/routing';

function Support() {
  const location = useLocation();

  const styles = useStyles(createStyles);

  const palette = usePalette();

  const {moderateScale} = useScale();

  return (
    <Fragment>
      <View style={styles.screen}>
        <InstallationTopAppBar />

        <SafeAreaView
          mode="padding"
          edges={['left', 'right']}
          style={styles.bodyContainer}>
          <ScrollView
            keyboardShouldPersistTaps="handled"
            contentContainerStyle={{
              flexGrow: 1,
              padding: moderateScale(16),
            }}>
            <Container
              style={{
                flexGrow: 1,
                flexShrink: 1,
              }}>
              <Label
                variant="H3"
                color={palette.text[1000]}
                style={{marginBottom: 24}}>
                Support
              </Label>

              <Label variant="P1_bold" color={palette.text[1000]}>
                Contact Us
              </Label>

              <ListGroup className="mb-5">
                <SupportListGroupItem
                  icon={
                    <MailIcon
                      width={26}
                      height={26}
                      color={'#32AFC9'}
                      rest={undefined}
                    />
                  }
                  primaryLabel={'Email'}
                  secondaryLabel={supportEmail}
                  href={`mailto:${supportEmail}`}
                />
                <SupportListGroupItem
                  icon={
                    <PhoneIcon
                      width={26}
                      height={26}
                      color={'#32AFC9'}
                      rest={undefined}
                    />
                  }
                  primaryLabel={'Phone'}
                  secondaryLabel={supportPhone}
                  href={`tel:${supportPhone}`}
                />
              </ListGroup>

              <NavigationBar
                backTo={
                  location && location.state && location.state.previousPathName
                    ? location.state.previousPathName
                    : '/'
                }
                className={undefined}
                backText={undefined}
                onBack={undefined}
                proceedText={undefined}
                proceedTo={undefined}
                onProceed={undefined}
                isHidden={undefined}
                isDisabled={undefined}
                dontuseNavigate={undefined}
                additionalLoadingState={undefined}
              />
            </Container>
          </ScrollView>
        </SafeAreaView>

        <Footer />
      </View>
    </Fragment>
  );
}

const createStyles = ({palette}: ResponsiveStyle) =>
  StyleSheet.create({
    screen: {
      flexGrow: 1,
      flexShrink: 1,
      backgroundColor: palette.surface[50],
    },
    headerDivider: {
      borderBottomWidth: 1,
      borderBottomColor: palette.surface[200],
    },
    bodyContainer: {
      flexGrow: 1,
      flexShrink: 1,
    },
  });

export default observer(Support);
