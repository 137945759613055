import {useSearchParams} from '../../utils/routing';

export function useCode() {
  const [searchParams, setSearchParams] = useSearchParams();

  function getCode() {
    return searchParams.get('code');
  }

  function extractCodeFromSearchParams() {
    const code = searchParams.get('code');

    if (code) {
      const newSearchParams = searchParams;
      newSearchParams.delete('code');
      setSearchParams(newSearchParams);
    }

    return code;
  }

  return {extractCodeFromSearchParams, getCode};
}
