import React from "react";
import { OverlayTrigger, Tooltip, Form } from "react-bootstrap";

const ManualInputComponent = ({ serialNumber, setSerialNumber, name, tooltipText, showReminder }) => {
  const renderManualInputTooltip = (props) => <Tooltip {...props}>{tooltipText}</Tooltip>;

  return (
    <>
      <div className="my-2 d-flex justify-content-center">
        OR MANUALLY INPUT {name}
        <OverlayTrigger placement="top" overlay={renderManualInputTooltip}>
          <div className="manual-input-tooltip-trigger ml-1">?</div>
        </OverlayTrigger>
      </div>

      <Form.Control
        value={serialNumber}
        onChange={(e) => {
          setSerialNumber(e.target.value);
        }}
        placeholder="ex. 7801237689"
      />

      {showReminder && <p className="text-muted mb-2">Have you scanned all sensors before proceeding to sensor verification?</p>}
    </>
  );
};

export default ManualInputComponent;
