import React, {useEffect, useState, useRef} from 'react';
import {observer} from 'mobx-react-lite';
import {ListGroup, InputGroup, Button} from 'react-bootstrap';
import AssetStore from '../../stores/AssetStore';
import useClickOutside from '../../hooks/useClickOutside';
import useDebounce from '../../hooks/useDebounce';
import {useStore} from '../../shared/stores/useStore';
import LabelInput from '../../shared/components/common/LabelInput';
import Label from '../../shared/components/common/Label';
import {usePalette} from '../../shared/theme/palette';
import Spinner from '../../shared/components/common/Spinner';

const AssetSearch = ({
  filter,
  setFilter,
  nothingFound,
  setNothingFound,
  disabled,
  fetchVin,
}: {
  filter: any;
  setFilter: any;
  nothingFound: any;
  setNothingFound: any;
  disabled: any;
  fetchVin: any;
}) => {
  const {apiClient} = useStore();

  const palette = usePalette();

  const [rivataAssets, setRivataAssets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const debouncedFilter = useDebounce(filter.value, 500);
  const searchRef = useRef();

  useClickOutside(searchRef, () => {
    setDropdownVisible(false);
  });

  const fetchAssets = () => {
    if (disabled || filter.withoutUpdate) {
      return;
    }

    if (!debouncedFilter.trim().length) {
      setRivataAssets([]);
      setNothingFound(false);
      return;
    }

    setNothingFound(false);
    setIsLoading(true);

    apiClient.asset
      .search({filter: debouncedFilter.trim()})
      .then(data => {
        if (data && data.length) {
          setDropdownVisible(true);
          setNothingFound(false);
          setRivataAssets(data);
        } else {
          setNothingFound(true);
          setRivataAssets([]);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchAssets();
  }, [debouncedFilter]);

  const onAssetSelect = async (asset: any) => {
    fetchVin(asset);
    setFilter({value: asset.vin, withoutUpdate: false});
    setDropdownVisible(false);
  };

  return (
    <div className="d-flex flex-column" ref={searchRef as any}>
      <InputGroup>
        <LabelInput
          value={filter.value}
          placeholder="Enter asset name or vin"
          isDisabled={disabled}
          onChangeText={text => setFilter({value: text, withoutUpdate: false})}
          onFocus={() => {
            setDropdownVisible(true);
          }}
          containerStyle={{flexGrow: 1, flexShrink: 1}}
        />

        {disabled ? null : (
          <Button
            id="search-button"
            variant="light"
            onClick={() => {
              setNothingFound(false);
              setRivataAssets([]);
              setFilter({value: '', withoutUpdate: false});
              AssetStore.clear();
            }}
            style={{backgroundColor: palette.surface[0]}}>
            {isLoading ? (
              <Spinner size={24} fill={palette.surface[1000]} />
            ) : (
              <Label variant="B1" color={palette.surface[1000]}>
                x
              </Label>
            )}
          </Button>
        )}
      </InputGroup>

      {nothingFound && (
        <div className="invalid-feedback" style={{display: 'block'}}>
          Nothing found
        </div>
      )}

      <div className="asset-search-wrapper">
        {dropdownVisible && rivataAssets && rivataAssets.length ? (
          <div className="asset-search" id="styled-scroll">
            <ListGroup as="ol" variant="flush">
              {rivataAssets.map(asset => {
                return (
                  <ListGroup.Item
                    key={(asset as any).id}
                    as="li"
                    action
                    className="d-flex"
                    onClick={async () => {
                      await onAssetSelect(asset);
                    }}
                    style={{backgroundColor: palette.surface[0]}}>
                    <div className="d-flex flex-column text-left">
                      <span
                        className="font-weight-bold"
                        style={{wordBreak: 'break-all'}}>
                        <Label variant="L1" color={palette.text[1000]}>
                          {(asset as any).name}
                        </Label>
                      </span>
                      <span style={{wordBreak: 'break-all'}}>
                        <Label variant="L2" color={palette.text[900]}>
                          {(asset as any).vin}
                        </Label>
                      </span>
                    </div>
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default observer(AssetSearch);
