import React from "react";
import { Modal, Button } from "react-bootstrap";

const InstallationCancelModal = ({ showModal, onClose, onProceed }) => (
  <Modal show={showModal} onHide={onClose} backdrop="static">
    <Modal.Header closeButton>
      <Modal.Title>Warning</Modal.Title>
    </Modal.Header>
    <Modal.Body>Are you sure you want to cancel this installation?</Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={onClose} style={{ width: "80px" }}>
        No
      </Button>
      <Button variant="primary" onClick={onProceed} style={{ width: "80px" }}>
        Yes
      </Button>
    </Modal.Footer>
  </Modal>
);

export default InstallationCancelModal;
