import { useStore } from "../shared/stores/useStore";
import AssetStore from "../stores/AssetStore";
import SensorsStore from "../stores/SensorsStore";
import InstallationStore from "../stores/InstallationStore";
import { assetType, atiInstalled } from "../utils/Constants";

function useNavigationState() {
  const {userStore} = useStore();

  function getScreen(action) {
    switch (action) {
      case "assetCreate.startInstallation":
        if (AssetStore.type === assetType.trailer && userStore.isRivataUser) return "/asset-update";

        if (!userStore.isRivataUser) return "/installation-case-number";

        return "/hardware-type";

      case "assetUpdate.proceed":
        if (!userStore.isRivataUser) return "/installation-case-number";

        return "/hardware-type";

      case "assetUpdate.goBack":
        if (AssetStore.isNew) return `/installations/${InstallationStore.installation.id}/asset-info`;

        if (!SensorsStore.sensors.length) return "/asset-type";

        return `/installations/${InstallationStore.installation.id}/asset-info`;

      case "assetInfo.proceed":
        if (AssetStore.isNew && AssetStore.type === assetType.trailer && userStore.isRivataUser) return "/asset-update";

        if (AssetStore.isNew) return "/hardware-type";

        if (!SensorsStore.sensors.length) return "/asset-type";

        if (
          AssetStore.asset.atiInstalled === atiInstalled.noInfoProvided ||
          AssetStore.asset.atiInstalled === atiInstalled.notSure ||
          (AssetStore.type === assetType.trailer && userStore.isRivataUser)
        )
          return "/asset-update";

        if (!userStore.isRivataUser) return "/installation-case-number";

        return "/hardware-type";

      case "assetType.select":
        if (
          AssetStore.asset.atiInstalled === atiInstalled.noInfoProvided ||
          AssetStore.asset.atiInstalled === atiInstalled.notSure ||
          (AssetStore.type === assetType.trailer && userStore.isRivataUser)
        )
          return "/asset-update";

        if (!userStore.isRivataUser) return "/installation-case-number";

        return "/hardware-type";

      case "installationCaseNumber.goBack":
        if (
          AssetStore.asset.atiInstalled === atiInstalled.noInfoProvided ||
          AssetStore.asset.atiInstalled === atiInstalled.notSure ||
          (AssetStore.type === assetType.trailer && userStore.isRivataUser)
        )
          return "/asset-update";

        if (!SensorsStore.sensors.length) return "/asset-type";

        return `/installations/${InstallationStore.installation.id}/asset-info`;

      case "hardwareType.goBack":
        if (userStore.isRivataUser === false) return "/installation-case-number";

        if (AssetStore.isNew && AssetStore.type === assetType.trailer && userStore.isRivataUser) return "/asset-update";

        if (AssetStore.isNew) return `/installations/${InstallationStore.installation.id}/asset-info`;

        if (
          AssetStore.asset.atiInstalled === atiInstalled.noInfoProvided ||
          AssetStore.asset.atiInstalled === atiInstalled.notSure ||
          (AssetStore.type === assetType.trailer && userStore.isRivataUser)
        )
          return "/asset-update";

        if (!SensorsStore.sensors.length) return "/asset-type";

        return `/installations/${InstallationStore.installation.id}/asset-info`;

      default:
        return "";
    }
  }

  return { getScreen };
}

export default useNavigationState;
