import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";

import QrScanner from "qr-scanner";
QrScanner.WORKER_PATH = "qr-scanner/qr-scanner-worker.min.js";

// We need torch functionality in some other places, the best way is to use QrScanner one

const Flash = () => {
  const [flash, setFlash] = useState(null);
  const [hasFlash, setHasFlash] = useState(false);
  const ref = useRef(null);

  const toggleFlash = async () => {
    flash.toggleFlash();
  };

  useEffect(() => {
    if (!ref) return;

    setFlash(new QrScanner(ref.current, () => {}, undefined, undefined, "environment"));
  }, [ref]);

  useEffect(() => {
    if (!flash) return;

    const initializeFlash = async () => {
      await flash.start();
      setHasFlash(await flash.hasFlash());
    };

    const destroyFlash = async () => {
      if (flash.isFlashOn()) await flash.turnFlashOff();
      flash.destroy();
      setFlash(null);
    };

    initializeFlash();

    return () => destroyFlash();
  }, [flash]);

  return (
    <div>
      <video style={{ display: "none" }} ref={ref}></video>

      {hasFlash && (
        <Button className="mt-3" block onClick={toggleFlash}>
          <i className="fas fa-bolt"></i>
        </Button>
      )}
    </div>
  );
};

export default Flash;
