import React, {
  Fragment,
  ReactNode,
  RefObject,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  LayoutChangeEvent,
  StyleSheet,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from 'react-native';
import OverlayModal from './OverlayModal';
import {OverlayModalKey} from '../../types/enums';
import {usePalette} from '../../theme/palette';

export type Rect = {x: number; y: number; width: number; height: number};

type Vector2 = {x: number; y: number};

export function calcAbsoluteRect(
  width: number,
  height: number,
  elementRectForAnchor: Rect,
  anchor: Vector2,
  anchorOffset: Vector2,
  pivot: Vector2,
): Rect {
  return {
    x:
      elementRectForAnchor.x +
      anchor.x * elementRectForAnchor.width +
      anchorOffset.x -
      pivot.x * width,
    y:
      elementRectForAnchor.y +
      anchor.y * elementRectForAnchor.height +
      anchorOffset.y -
      pivot.y * height,
    width: width,
    height: height,
  };
}

const nullableRect: Rect = {x: 0, y: 0, width: 0, height: 0};

type Props = {
  visible?: boolean;
  calcPopoverRect: (
    windowDimensions: Rect,
    absoluteComponentRect: Rect,
  ) => Rect;
  onBackdrop: () => void;
  renderComponent: (
    ref: RefObject<any | null>,
    onLayout: (event: LayoutChangeEvent) => void,
  ) => ReactNode;
  renderPopover: () => ReactNode;
};

const PopoverBase: React.FC<Props> = props => {
  const {visible, calcPopoverRect, onBackdrop, renderComponent, renderPopover} =
    props;

  const componentRef = useRef<View | null>(null);

  const fakeComponentRef = useRef<View | null>(null);

  const windowDimensions = useWindowDimensions();

  const [componentRect, setComponentRect] = useState(nullableRect);

  const [modalRect, setModalRect] = useState(nullableRect);

  const palette = usePalette();

  const popoverRect: Rect = useMemo(() => {
    if (!visible) return nullableRect;

    return calcPopoverRect(modalRect, componentRect);
  }, [visible, componentRect, calcPopoverRect, modalRect]);

  useLayoutEffect(() => {
    handleComponentPosition();
  }, [windowDimensions, visible]);

  const handleComponentPositionCallback = useCallback(() => {
    handleComponentPosition();
  }, [windowDimensions, visible]);

  const handleModalRect: (event: LayoutChangeEvent) => void = useCallback(
    event => {
      setModalRect(event.nativeEvent.layout);
    },
    [],
  );

  function handleComponentPosition() {
    if (!visible) return;

    if (!componentRef?.current) return;

    // TODO: add throttle for window resize

    componentRef.current.measureInWindow((x, y, width, height) => {
      setComponentRect(prev => {
        const valuesChanged =
          prev.x !== x ||
          prev.y !== y ||
          prev.width !== width ||
          prev.height !== height;

        if (valuesChanged) return {x, y, width, height};

        return prev;
      });
    });
  }

  return (
    <Fragment>
      {renderComponent(componentRef, handleComponentPositionCallback)}

      <OverlayModal
        modalKey={OverlayModalKey.POPOVER}
        zIndex={9999}
        visible={visible}>
        <View
          onLayout={handleModalRect}
          style={{
            flexGrow: 1,
            flexShrink: 1,
            flexDirection: 'column',
            position: 'relative',
          }}>
          <TouchableOpacity
            onPress={() => onBackdrop()}
            style={[
              StyleSheet.absoluteFill,
              {
                backgroundColor:
                  palette.mode === 'light' ? '#00000011' : '#ffffff11',
              },
            ]}
          />

          <View
            style={{
              position: 'absolute',
              zIndex: 0,
              left: popoverRect.x,
              top: popoverRect.y,
              width: popoverRect.width,
              height: popoverRect.height,
            }}>
            {renderPopover()}
          </View>

          <View
            style={{
              position: 'absolute',
              zIndex: 0,
              left: componentRect.x,
              top: componentRect.y,
              width: componentRect.width,
              height: componentRect.height,
            }}>
            {renderComponent(fakeComponentRef, () => {})}
          </View>
        </View>
      </OverlayModal>
    </Fragment>
  );
};

export default PopoverBase;
