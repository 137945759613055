const locales = {
  ok: 'Ok',
  startInstallation: 'Start installation',
  showLegend: 'Show legend',
  hideLegend: 'Hide legend',
  getLastData: 'Get last data',
  goBack: 'Go back',
  proceed: 'Proceed',
  submit: 'Submit',
  submitAndProceed: 'Submit and proceed',
  submitAndAddMore: 'Submit and add more',
  confirm: 'Confirm',
  createAsset: 'Create asset',
  skip: 'Skip',
  axleLoad: 'Axle load',
  linePressure: 'Line pressure',
  addSecondGateway: 'Add second gateway',
  validateAssetInstallation: 'Validate asset installation',
  signOut: 'Sign out',
  odometerOffset: 'Odometer Offset',
  optional: 'Optional',
  enterCurrentOdometerValue: 'Enter Current Odometer Value',
  automaticTireInflation: 'Automatic Tire Inflation',
  hasAssetAti: 'Does this asset have an Automatic Tire Inflation (ATI) system?',
  onlyNumbersAllowed: 'Only numbers are allowed',
  atiRequired: 'ATI is required',
  gatewayReminderWarning:
    'Gateway needs to be powered in order to validate installation – please power gateway on during installation',
  biggerVinCode:
    'Barcode contained more information than expected for a 17 character VIN.',
  invalidVinCode:
    'Please make sure VIN is valid and try again. Valid VIN consists of 17 characters.',
  retake: 'Retake',
  sensorErrorOfGateway:
    'Please make sure ID is valid and try again. Valid ID is 10 or 12 character ESN found on the label',
  back: 'Back',
  welcomeTo: 'Welcome To',
  loginAsFleet: 'Login as Fleet Installer',
  loginAsContractor: 'Login as ConMet Contractor',
  selectInstallation: 'Select Installation',
  showFinished: 'Show finished',
  results: 'Results',
  loading: 'Loading...',
  selectAsset: 'Select Asset',
  nhtsaAlerts: {
    timeout:
      'NHTSA timeout occurred, would you like to proceed without data from NHTSA?',
    vinNotRecognized:
      'VIN not recognized in NHTSA database, please check the VIN before proceeding',
    vinPartiallyRecognized:
      'VIN is not fully recognized in NHTSA database, please check the asset data before proceeding',
  },
  selectEnv: 'Select environment',
  dev: 'Dev',
  staging: 'Staging',
  sessionExpired: 'Session Expired',
  newVersionAvailable: 'New version available',
  newVersionAvailableLong:
    'An updated version of the app is now available. Please update to the latest version to experience all the improvements.',
  updateApp: 'Update',
  notNow: 'Not now',
  openStoreAndUpdate: 'Please open {{store}} and update the app',
  appStore: 'App Store',
  googleStore: 'Google Play Store',
  somethingWentWrong: 'Something went wrong',
  start: 'Start',
  cancel: 'Cancel',
  support: 'Support',
  warning: 'Warning',
  cancelInstallationWarning:
    'Are you sure you want to start a new installation? All progress will be lost',
  appearance: 'Appearance',
  light: 'Light',
  dark: 'Dark',
  system: 'System',
};

export default locales;
