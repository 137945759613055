import React from 'react';
import {useScale} from '../../theme/scale';
import {SignoutSvg} from '../iconsExporter';
import {usePalette} from '../../theme/palette';

type Props = {
  size?: number;
  color?: string;
};

const SignoutIcon: React.FC<Props> = props => {
  const palette = usePalette();

  const {size = 24, color = palette.surface[1000]} = props;

  const {moderateScale} = useScale();

  return (
    <SignoutSvg
      width={moderateScale(size)}
      height={moderateScale(size)}
      fill={color}
    />
  );
};

export default SignoutIcon;
