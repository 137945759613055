import {observer} from 'mobx-react-lite';
import React, {Fragment, useState} from 'react';
import {useController, useForm} from 'react-hook-form';
import AssetAtiSelection from '../../components/AssetAtiSelection';
import ButtonWithSpinner from '../../components/ButtonWithSpinner';
import VINLabel from '../../components/VINLabel';
import OdometerInput from '../../components/inputs/OdometerInput';
import AssetStore from '../../stores/AssetStore';
import {assetType, atiInstalled, regExps, units} from '../../utils/Constants';
import useNavigationState from '../../hooks/useNavigationState';
import {useStore} from '../../shared/stores/useStore';
import {ScrollView, StyleSheet, View} from 'react-native';
import InstallationTopAppBar from '../../components/InstallationTopAppBar';
import {SafeAreaView} from 'react-native-safe-area-context';
import Container from '../../shared/components/common/Container';
import Footer from '../../shared/components/navigation/Footer';
import {ResponsiveStyle, useStyles} from '../../shared/theme/styles';
import {useScale} from '../../shared/theme/scale';
import Alert from '../../shared/components/common/Alert';
import Label from '../../shared/components/common/Label';
import {usePalette} from '../../shared/theme/palette';
import locales from '../../shared/locales/en';
import {useNavigate} from '../../shared/utils/routing';

const AssetUpdateScreen = () => {
  const {apiClient, userStore} = useStore();

  const [error, setError] = useState('');

  const palette = usePalette();

  const navigate = useNavigate();

  const styles = useStyles(createStyles);

  const {moderateScale} = useScale();

  const {getScreen} = useNavigationState();

  const showAti =
    (AssetStore.asset.atiInstalled === atiInstalled.noInfoProvided ||
      AssetStore.asset.atiInstalled === atiInstalled.notSure) &&
    !AssetStore.isNew;

  const showOdometer =
    AssetStore.type === assetType.trailer && userStore.isRivataUser;

  const form = useForm({
    defaultValues: {
      atiInstalled: AssetStore.asset.atiInstalled,
      odometer:
        AssetStore.asset.odometer === null ||
        AssetStore.asset.odometer === undefined
          ? ''
          : AssetStore.asset.odometer,
      distanceUnit: AssetStore.asset.isOdometerInMiles
        ? units.distance.miles
        : units.distance.kilometers,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
  });

  const atiController = useController({
    control: form.control,
    name: 'atiInstalled',
    rules: {
      validate: {
        isNotNull: value =>
          value !== atiInstalled.noInfoProvided ? true : locales.atiRequired,
      },
    },
  });

  const odometerController = useController({
    control: form.control,
    name: 'odometer',
    rules: {
      validate: {
        hasNumbersOnly: value => {
          if (value.length === 0) return true;

          if (regExps.onlyNumbers.test(value)) return true;

          return locales.onlyNumbersAllowed;
        },
      },
    },
  });

  const distanceUnitController = useController({
    control: form.control,
    name: 'distanceUnit',
  });

  async function proceed(formValues: any) {
    try {
      const {atiInstalled, odometer, distanceUnit} = formValues;

      if (showAti) await AssetStore.updateAti(atiInstalled, apiClient.asset);

      if (showOdometer) await updateOdometer(odometer, distanceUnit);

      navigate(getScreen('assetUpdate.proceed'));
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message);
      }
    }
  }

  async function updateOdometer(odometer: any, odometerUnit: any) {
    if (!AssetStore.asset.id) return;

    let newOdometer;

    if (odometer.length === 0) newOdometer = null;
    else {
      newOdometer = parseInt(odometer, 10);

      if (isNaN(newOdometer)) return;
    }

    const data = {
      odometer: newOdometer,
      isOdometerInMiles: odometerUnit === units.distance.miles,
    };

    if (AssetStore.areOdometersEqual(newOdometer, data.isOdometerInMiles)) {
      return;
    }

    await apiClient.asset.setOdometer(AssetStore.asset.id, data);

    AssetStore.asset.odometer = (
      data.odometer === null ? null : data.odometer.toString()
    ) as any;
    AssetStore.asset.isOdometerInMiles = data.isOdometerInMiles;
  }

  return (
    <Fragment>
      <View style={styles.screen}>
        <InstallationTopAppBar />

        <SafeAreaView
          mode="padding"
          edges={['left', 'right']}
          style={styles.bodyContainer}>
          <ScrollView
            keyboardShouldPersistTaps="handled"
            contentContainerStyle={{
              flexGrow: 1,
              padding: moderateScale(16),
            }}>
            <Container
              style={{
                flexGrow: 1,
                flexShrink: 1,
              }}>
              <VINLabel />

              <div className="mb-3" />

              {showOdometer && (
                <View style={{alignItems: 'center'}}>
                  <Label variant="P1" color={palette.text[900]}>
                    {`${locales.odometerOffset} (${locales.optional})`}
                  </Label>

                  <Label variant="P2" color={palette.text[900]}>
                    {locales.enterCurrentOdometerValue}
                  </Label>

                  <OdometerInput
                    odometerValue={odometerController.field.value}
                    distanceUnit={distanceUnitController.field.value}
                    isDisabled={form.formState.isSubmitting}
                    error={form.formState.errors?.odometer?.message}
                    onChangeOdometerValue={odometerController.field.onChange}
                    onChangeDistanceUnit={distanceUnitController.field.onChange}
                    className="mb-4"
                  />
                </View>
              )}

              {showAti && (
                <View style={{alignItems: 'center'}}>
                  <Label variant="P1" color={palette.text[900]}>
                    {locales.automaticTireInflation}
                  </Label>

                  <Label variant="P2" color={palette.text[900]}>
                    {locales.hasAssetAti}
                  </Label>

                  <div className="mb-4">
                    <AssetAtiSelection
                      value={atiController.field.value}
                      onChange={atiController.field.onChange}
                      isDisabled={form.formState.isSubmitting}
                    />
                  </div>
                </View>
              )}

              {error ? (
                <Alert
                  variant="centerAligned"
                  styleVariant="error"
                  label={error}
                  style={{marginHorizontal: moderateScale(16)}}
                />
              ) : null}

              <div className={'d-flex mt-5'}>
                <ButtonWithSpinner
                  className="flex-fill"
                  onClick={() => navigate(getScreen('assetUpdate.goBack'))}
                  isLoading={form.formState.isSubmitting}
                  disabled={form.formState.isSubmitting}
                  variant={undefined}
                  type={undefined}
                  block={undefined}
                  style={undefined}>
                  <i className="fas fa-arrow-circle-left mr-2" />
                  {locales.goBack}
                </ButtonWithSpinner>

                <ButtonWithSpinner
                  className="flex-fill ml-3"
                  onClick={() => form.handleSubmit(proceed)()}
                  isLoading={form.formState.isSubmitting}
                  disabled={
                    !form.formState.isValid || form.formState.isSubmitting
                  }
                  variant={undefined}
                  type={undefined}
                  block={undefined}
                  style={undefined}>
                  {locales.proceed}
                  <i className="fas fa-arrow-circle-right ml-2" />
                </ButtonWithSpinner>
              </div>
            </Container>
          </ScrollView>
        </SafeAreaView>

        <Footer />
      </View>
    </Fragment>
  );
};

const createStyles = ({palette}: ResponsiveStyle) =>
  StyleSheet.create({
    screen: {
      flexGrow: 1,
      flexShrink: 1,
      backgroundColor: palette.surface[50],
    },
    headerDivider: {
      borderBottomWidth: 1,
      borderBottomColor: palette.surface[200],
    },
    bodyContainer: {
      flexGrow: 1,
      flexShrink: 1,
    },
  });

export default observer(AssetUpdateScreen);
