// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Camera, {FACING_MODES} from 'react-html5-camera-photo';

import {observer} from 'mobx-react-lite';
import React, {Fragment, useState} from 'react';
import {Alert, Button, Image} from 'react-bootstrap';
import Flash from '../../components/Flash';
import NavigationBar from '../../components/navigation/NavigationBar';
import InstallationPhotoStore from '../../stores/InstallationPhotoStore';
import {isIos} from '../../utils/Utils';
import {ResponsiveStyle, useStyles} from '../../shared/theme/styles';
import {useScale} from '../../shared/theme/scale';
import {ScrollView, StyleSheet, View} from 'react-native';
import InstallationTopAppBar from '../../components/InstallationTopAppBar';
import {SafeAreaView} from 'react-native-safe-area-context';
import Container from '../../shared/components/common/Container';
import Footer from '../../shared/components/navigation/Footer';

function InstallationPhotoCamera() {
  const styles = useStyles(createStyles);

  const {moderateScale} = useScale();

  const [preview, setPreview] = useState(null);
  const [error, setError] = useState('');

  function handleTakePhoto(dataUri: any) {
    setPreview(dataUri);
  }

  function handleRetakePhoto() {
    setPreview(null);
  }

  function handleProceed() {
    InstallationPhotoStore.photo.file = preview;
  }

  return (
    <Fragment>
      <View style={styles.screen}>
        <InstallationTopAppBar />

        <SafeAreaView
          mode="padding"
          edges={['left', 'right']}
          style={styles.bodyContainer}>
          <ScrollView
            keyboardShouldPersistTaps="handled"
            contentContainerStyle={{
              flexGrow: 1,
              padding: moderateScale(16),
            }}>
            <Container
              style={{
                flexGrow: 1,
                flexShrink: 1,
              }}>
              <div className="h4 my-4">Take A Photo</div>

              {preview && <Image src={preview} fluid />}

              {!preview && (
                <View
                  style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'hidden',
                  }}>
                  <Camera
                    onTakePhotoAnimationDone={handleTakePhoto}
                    isImageMirror={false}
                    idealFacingMode={FACING_MODES.ENVIRONMENT}
                    isDisplayStartCameraError={false}
                    onCameraError={() => setError('Camera not available!')}
                  />
                  {!isIos && <Flash />}
                </View>
              )}

              {error && <Alert variant="danger">{error}</Alert>}

              {preview && (
                <Button className="w-100 mt-3" onClick={handleRetakePhoto}>
                  RETAKE
                </Button>
              )}

              <NavigationBar
                backTo="/installation-photos"
                proceedTo={preview && '/installation-photos'}
                onProceed={handleProceed}
                className={undefined}
                backText={undefined}
                onBack={undefined}
                proceedText={undefined}
                isHidden={undefined}
                isDisabled={undefined}
                dontuseNavigate={undefined}
                additionalLoadingState={undefined}
              />
            </Container>
          </ScrollView>
        </SafeAreaView>

        <Footer />
      </View>
    </Fragment>
  );
}

const createStyles = ({palette}: ResponsiveStyle) =>
  StyleSheet.create({
    screen: {
      flexGrow: 1,
      flexShrink: 1,
      backgroundColor: palette.surface[50],
    },
    headerDivider: {
      borderBottomWidth: 1,
      borderBottomColor: palette.surface[200],
    },
    bodyContainer: {
      flexGrow: 1,
      flexShrink: 1,
    },
  });

export default observer(InstallationPhotoCamera);
