import React, {Fragment, useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Alert} from 'react-bootstrap';

import AssetStore from '../../stores/AssetStore';
import SensorsStore from '../../stores/SensorsStore';

import NavigationBar from '../../components/navigation/NavigationBar';
import VINLabel from '../../components/VINLabel';

import {assetType, sensorType} from '../../utils/Constants';

import AxlesNumberSelector from './sensors-data/AxlesNumberSelector';
import SmartHubGrid from './sensors-data/SmartHubGrid';
import TPMSGrid from './sensors-data/TPMSGrid';
import SensorScanner from './sensors-data/SensorScanner';
import {ResponsiveStyle, useStyles} from '../../shared/theme/styles';
import {useScale} from '../../shared/theme/scale';
import {ScrollView, StyleSheet, View} from 'react-native';
import InstallationTopAppBar from '../../components/InstallationTopAppBar';
import {SafeAreaView} from 'react-native-safe-area-context';
import Container from '../../shared/components/common/Container';
import Footer from '../../shared/components/navigation/Footer';
import Label from '../../shared/components/common/Label';
import {usePalette} from '../../shared/theme/palette';

function SensorsData() {
  const [selectedSensorCode, setSelectedSensorCode] = useState(null);

  const styles = useStyles(createStyles);

  const {moderateScale} = useScale();

  const palette = usePalette();

  const stopLoadingAll = () => {
    SensorsStore.sensors = SensorsStore.sensors.map(s => ({
      ...s,
      isLoading: false,
    }));
  };

  const predictNumberOfAxles = () => {
    if (AssetStore.type === assetType.trailer) predictTrailerNumberOfAxles();

    if (AssetStore.type === assetType.tractor) predictTractorNumberOfAxles();
  };

  const predictTrailerNumberOfAxles = () => {
    for (let i = 1; i < 6; ++i) {
      if (AssetStore.asset.numberOfAxles >= i) continue;

      if (
        SensorsStore.sensors.filter(s => s.code.includes(`_${i}`)).length > 0
      ) {
        AssetStore.asset.numberOfAxles = i;
      }
    }
  };

  const predictTractorNumberOfAxles = () => {
    for (let i = 2; i < 7; ++i) {
      if (AssetStore.asset.numberOfAxles >= i) continue;

      if (
        SensorsStore.sensors.filter(s => s.code.includes(`_${i - 1}`)).length >
        0
      ) {
        AssetStore.asset.numberOfAxles = i;
      }
    }
  };

  useEffect(() => {
    stopLoadingAll();
    predictNumberOfAxles();
  }, []);

  return (
    <Fragment>
      <View style={styles.screen}>
        <InstallationTopAppBar />

        <SafeAreaView
          mode="padding"
          edges={['left', 'right']}
          style={styles.bodyContainer}>
          <ScrollView
            keyboardShouldPersistTaps="handled"
            contentContainerStyle={{
              flexGrow: 1,
              padding: moderateScale(16),
            }}>
            <Container
              style={{
                flexGrow: 1,
                flexShrink: 1,
              }}>
              <VINLabel />

              <Label
                variant="H3"
                color={palette.text[1000]}
                style={{textAlign: 'center', marginBottom: 24}}>
                Scan {AssetStore.assetTypeName}{' '}
                {SensorsStore.sensorsType === sensorType.smarthub
                  ? 'Node'
                  : 'Sensor'}{' '}
                QR Codes
              </Label>

              <AxlesNumberSelector />

              {SensorsStore.sensorsType === sensorType.smarthub && (
                <SmartHubGrid
                  selectedSensorCode={selectedSensorCode}
                  setSelectedSensorCode={setSelectedSensorCode}
                />
              )}

              {SensorsStore.sensorsType === sensorType.tpms && (
                <TPMSGrid
                  selectedSensorCode={selectedSensorCode}
                  setSelectedSensorCode={setSelectedSensorCode}
                />
              )}

              {selectedSensorCode && (
                <SensorScanner
                  selectedSensorCode={selectedSensorCode}
                  setSelectedSensorCode={setSelectedSensorCode}
                  sensorTypes={[SensorsStore.sensorsType]}
                />
              )}

              {!selectedSensorCode &&
                SensorsStore.getWarningSensors([SensorsStore.sensorsType]).map(
                  sensor => {
                    return (
                      <Alert
                        key={sensor.esn}
                        className="mt-2 mb-2"
                        variant="warning">
                        <h6 className="alert-heading mb-1 text-left font-weight-bold">{`${sensor.code
                          .toString()
                          .replace(/\_/g, ' ')} (${sensor.esn})`}</h6>
                        <p className="mb-0 text-left">{sensor.warning}</p>
                      </Alert>
                    );
                  },
                )}

              {!selectedSensorCode && (
                <>
                  <Label
                    variant="P2"
                    color={palette.text[1000]}
                    style={{textAlign: 'center', marginBottom: 16}}>
                    Have you scanned all sensors before proceeding to sensor
                    verification?
                  </Label>

                  <NavigationBar
                    backTo="/hardware-type"
                    proceedTo="/additional-hardware"
                    isDisabled={
                      SensorsStore.anyLoading() || !!selectedSensorCode
                    }
                    className={undefined}
                    backText={undefined}
                    onBack={undefined}
                    proceedText={undefined}
                    onProceed={undefined}
                    isHidden={undefined}
                    dontuseNavigate={undefined}
                    additionalLoadingState={undefined}
                  />
                </>
              )}
            </Container>
          </ScrollView>
        </SafeAreaView>

        <Footer />
      </View>
    </Fragment>
  );
}

const createStyles = ({palette}: ResponsiveStyle) =>
  StyleSheet.create({
    screen: {
      flexGrow: 1,
      flexShrink: 1,
      backgroundColor: palette.surface[50],
    },
    headerDivider: {
      borderBottomWidth: 1,
      borderBottomColor: palette.surface[200],
    },
    bodyContainer: {
      flexGrow: 1,
      flexShrink: 1,
    },
  });

export default observer(SensorsData);
