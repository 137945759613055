import {action, computed, makeObservable, observable, runInAction} from 'mobx';
import ApiClient from '../../services/apiClient';
import Logging from '../../services/logging';
import {getGatewayEsn} from '../../utils';
import {ValidationResult} from './GatewayStore';

const gatewayDefaults = {
  id: 0,
  esn: '',
  s3key: null,
  powers3key: null,
  installationId: 0,
};

export default class GatewayModel {
  id?: number;
  esn: string;
  s3key: string | null;
  powers3key: string | null;
  installationId: number;
  isPrimary: boolean;
  isSelected: boolean = false;

  validationResult: ValidationResult | null = null;

  isLoading: boolean = false;

  constructor(
    data: GatewayDto,
    isPrimary: boolean,
    private readonly apiClient: ApiClient,
  ) {
    this.id = data.id;
    this.esn = data.esn;
    this.s3key = data.s3key;
    this.powers3key = data.powers3key;
    this.installationId = data.installationId;
    this.isPrimary = isPrimary;

    makeObservable(this, {
      id: observable,
      esn: observable,
      s3key: observable,
      powers3key: observable,
      isPrimary: observable,
      isSelected: observable,
      isLoading: observable,
      validationResult: observable,

      update: action,
      setSelected: action,
      setIsLoading: action,
      setS3Key: action,
      setPowers3key: action,
      delete: action,
      setValidationResult: action,

      label: computed,
    });
  }

  update(data: GatewayDto) {
    runInAction(() => {
      this.id = data.id;
      this.esn = data.esn;
      this.s3key = data.s3key;
      this.powers3key = data.powers3key;
    });
  }

  setSelected(isSelected: boolean) {
    this.isSelected = isSelected;
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setValidationResult(validationResult: ValidationResult | null) {
    this.validationResult = validationResult;
  }

  async setS3Key(s3key: string | null) {
    await this.apiClient.fetchStore
      .put(`gateway/${this.id}/s3key`, {s3key})
      .then(() => {
        runInAction(() => {
          this.s3key = s3key;
        });
      })
      .catch((e: unknown) => {
        const error = Logging.parseUnknownError(e);
        Logging.recordError(error);
      });
  }

  async setPowers3key(s3key: string | null) {
    await this.apiClient.fetchStore
      .put(`gateway/${this.id}/powers3key`, {s3key})
      .then(() => {
        runInAction(() => {
          this.powers3key = s3key;
        });
      })
      .catch((e: unknown) => {
        const error = Logging.parseUnknownError(e);
        Logging.recordError(error);
      });
  }

  async delete(installationId: number, vin: string) {
    this.setIsLoading(true);

    try {
      const result = await this.apiClient.fetchStore.delete(
        `gateway/${this.id}`,
        {
          installationId,
          vin,
        },
      );

      this.update({...gatewayDefaults});

      return result;
    } catch (e: unknown) {
      const error = Logging.parseUnknownError(e);
      Logging.recordError(error);
    } finally {
      this.setIsLoading(false);
    }
  }

  get label() {
    return getGatewayEsn(this.esn);
  }
}
