import React, {ReactNode} from 'react';
import {useBreakpoints} from './breakpoints';
import {ScaleProvider} from './scale';

const AdaptiveScaleProvider = ({children}: {children: ReactNode}) => {
  const currentBreakpointKey = useBreakpoints();

  return (
    <ScaleProvider currentBreakpointKey={currentBreakpointKey}>
      {children}
    </ScaleProvider>
  );
};

export default AdaptiveScaleProvider;
