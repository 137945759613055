import { autorun, toJS } from "mobx";

export const autoSave = (store, save) => {
  let firstRun = true;

  autorun(() => {
    const json = JSON.stringify(toJS(store));

    if (!firstRun) save(json);

    firstRun = false;
  });
};
