import React, {Fragment, useState} from 'react';
import {Button, Modal} from 'react-bootstrap';
import {observer} from 'mobx-react-lite';
import AssetStore from '../../stores/AssetStore';
import SensorsStore from '../../stores/SensorsStore';
import InstallationStore from '../../stores/InstallationStore';
import NavigationBar from '../../components/navigation/NavigationBar';
import VINLabel from '../../components/VINLabel';
import ButtonWithSpinner from '../../components/ButtonWithSpinner';
import {assetType} from '../../utils/Constants';
import useNavigationState from '../../hooks/useNavigationState';
import {useStore} from '../../shared/stores/useStore';
import Logging from '../../shared/services/logging';
import {ScrollView, View} from 'react-native';
import InstallationTopAppBar from '../../components/InstallationTopAppBar';
import {SafeAreaView} from 'react-native-safe-area-context';
import Container from '../../shared/components/common/Container';
import {ResponsiveStyle, useStyles} from '../../shared/theme/styles';
import {useScale} from '../../shared/theme/scale';
import {StyleSheet} from 'react-native';
import Footer from '../../shared/components/navigation/Footer';
import Label from '../../shared/components/common/Label';
import {usePalette} from '../../shared/theme/palette';
import {useNavigate} from '../../shared/utils/routing';

const AssetTypeChangeModal = ({
  showModal,
  closeModal,
  onSuccess,
}: {
  showModal: any;
  closeModal: any;
  onSuccess: any;
}) => {
  const {apiClient} = useStore();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const deprovision = async () => {
    setIsLoading(true);

    try {
      const result = await apiClient.installation.deprovision(
        InstallationStore.installation.id,
      );

      if (result && result.status === 'success') {
        SensorsStore.clear();
        closeModal();
        onSuccess();
      }
    } catch (e: unknown) {
      const _error = Logging.parseUnknownError(e);
      setError(_error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal show={showModal} onHide={closeModal} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Warning</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        There are sensors already provisioned to this asset, if you proceed with
        changing the vehicle type, all device assignments will be cleared.
        {error && (
          <div className="invalid-feedback" style={{display: 'block'}}>
            {error}
          </div>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={closeModal}
          style={{width: '80px'}}
          disabled={isLoading}>
          Cancel
        </Button>

        <ButtonWithSpinner
          variant="primary"
          type="submit"
          isLoading={isLoading}
          onClick={deprovision}
          style={{minWidth: '80px'}}
          disabled={undefined}
          block={undefined}
          className={undefined}>
          Proceed
        </ButtonWithSpinner>
      </Modal.Footer>
    </Modal>
  );
};

function AssetType() {
  const {apiClient} = useStore();

  const styles = useStyles(createStyles);

  const {moderateScale, moderateScaleV} = useScale();

  const palette = usePalette();

  const [showTypeChangeModal, setShowTypeChangeModal] = useState(false);
  const [selectedType, setSelectedType] = useState(AssetStore.type);

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const {getScreen} = useNavigationState();

  const selectType = async (type: any) => {
    setIsLoading(true);

    setSelectedType(type);

    if (AssetStore.type === type) {
      navigate(getScreen('assetType.select'));
      return;
    }

    if (SensorsStore.sensors.length) {
      setShowTypeChangeModal(true);
    } else {
      await updateAssetType(type);
      await resetOdometerOnTractorSelect(type);
      navigate(getScreen('assetType.select'));
    }

    setIsLoading(false);
  };

  async function onSuccessTypeChange() {
    setIsLoading(true);

    await updateAssetType(selectedType);
    await resetOdometerOnTractorSelect(selectedType);
    navigate(getScreen('assetType.select'));

    setIsLoading(false);
  }

  async function updateAssetType(selectedAssetType: any) {
    try {
      await apiClient.asset.setType(AssetStore.asset.id, selectedAssetType);
      AssetStore.type = selectedAssetType;
    } catch (e) {
      console.error(e);
    }
  }

  async function resetOdometerOnTractorSelect(selectedAssetType: any) {
    try {
      if (selectedAssetType === assetType.tractor) {
        await AssetStore.resetOdometer(apiClient.asset);
      }
    } catch (e) {
      console.error(e);
    }
  }
  return (
    <Fragment>
      <View style={styles.screen}>
        <InstallationTopAppBar />

        <SafeAreaView
          mode="padding"
          edges={['left', 'right']}
          style={styles.bodyContainer}>
          <ScrollView
            keyboardShouldPersistTaps="handled"
            contentContainerStyle={{
              flexGrow: 1,
              padding: moderateScale(16),
            }}>
            <Container
              style={{
                flexGrow: 1,
                flexShrink: 1,
              }}>
              <VINLabel />

              <AssetTypeChangeModal
                showModal={showTypeChangeModal}
                onSuccess={onSuccessTypeChange}
                closeModal={() => {
                  setShowTypeChangeModal(false);
                }}
              />

              <Label
                variant="H1"
                color={palette.text[1000]}
                style={{
                  textAlign: 'center',
                  marginVertical: moderateScaleV(12),
                }}>
                Choose Asset Type
              </Label>

              <AssetTypeSelector
                type={assetType.tractor}
                name="TRACTOR"
                isDisabled={isLoading}
                onSelect={selectType}
              />

              <Label
                variant="L3"
                color={palette.text[900]}
                style={{
                  textAlign: 'center',
                  marginVertical: moderateScaleV(24),
                }}>
                OR
              </Label>

              <AssetTypeSelector
                type={assetType.trailer}
                name="TRAILER"
                isDisabled={isLoading}
                onSelect={selectType}
              />
              <NavigationBar
                className="mt-5"
                backTo={`/installations/${InstallationStore.installation.id}/asset-info`}
                isDisabled={isLoading}
                backText={undefined}
                onBack={undefined}
                proceedText={undefined}
                proceedTo={undefined}
                onProceed={undefined}
                isHidden={undefined}
                dontuseNavigate={undefined}
                additionalLoadingState={undefined}
              />
            </Container>
          </ScrollView>
        </SafeAreaView>

        <Footer />
      </View>
    </Fragment>
  );
}

const AssetTypeSelector = observer(
  ({
    type,
    name,
    onSelect,
    isDisabled,
  }: {
    type: any;
    name: any;
    onSelect: any;
    isDisabled: any;
  }) => {
    return (
      <Button
        block
        size="lg"
        variant={AssetStore.type === type ? 'success' : 'primary'}
        disabled={isDisabled}
        onClick={() => onSelect(type)}>
        {name}
      </Button>
    );
  },
);

const createStyles = ({palette}: ResponsiveStyle) =>
  StyleSheet.create({
    screen: {
      flexGrow: 1,
      flexShrink: 1,
      backgroundColor: palette.surface[50],
    },
    headerDivider: {
      borderBottomWidth: 1,
      borderBottomColor: palette.surface[200],
    },
    bodyContainer: {
      flexGrow: 1,
      flexShrink: 1,
    },
  });

export default observer(AssetType);
