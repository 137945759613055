import FetchStore from '../stores/fetch/FetchStore';

export default class SensorApi {
  constructor(private readonly fetchStore: FetchStore) {}

  async upload(data: any) {
    return await this.fetchStore.post(`sensor`, data);
  }

  async update(data: any) {
    return await this.fetchStore.put(`sensor`, data);
  }

  async setS3key(id: any, key: any) {
    return await this.fetchStore.put(`sensor/${id}/s3key`, {s3key: key});
  }

  async delete(id: any) {
    return await this.fetchStore.delete(`sensor/${id}`);
  }
}
