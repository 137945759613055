import FetchStore from '../stores/fetch/FetchStore';

export default class PhotoApi {
  constructor(private readonly fetchStore: FetchStore) {}

  async getSignedUrl(data: any) {
    return await this.fetchStore.post(`photo/getSignedUrl`, data);
  }

  async delete(data: any) {
    return await this.fetchStore.post(`photo/delete`, data);
  }
}
