import React from 'react';
import {CaretUp, CaretDown} from '../iconsExporter';
import {useScale} from '../../theme/scale';

type Props = {
  variant: 'up' | 'down';
  size?: number;
  color?: string;
};

const CaretIcon: React.FC<Props> = props => {
  const {variant, size = 24, color = 'red'} = props;

  const {moderateScale} = useScale();

  if (variant === 'up') {
    return (
      <CaretUp
        width={moderateScale(size)}
        height={moderateScale(size)}
        stroke={color}
      />
    );
  }

  return (
    <CaretDown
      width={moderateScale(size)}
      height={moderateScale(size)}
      stroke={color}
    />
  );
};

export default CaretIcon;
