import {makeObservable} from 'mobx';
import AssetAPI from '../api/AssetAPI';
import InstallationAPI from '../api/InstallationAPI';
import AuthApi from '../api/auth';
import DocumentApi from '../api/document';
import UserApi from '../api/user';
import FetchStore from '../stores/fetch/FetchStore';
import GatewayApi from '../api/GatewayApi';
import SensorApi from '../api/SensorApi';
import PhotoApi from '../api/PhotoApi';

export default class ApiClient {
  user: UserApi;
  document: DocumentApi;
  auth: AuthApi;
  installation: InstallationAPI;
  asset: AssetAPI;
  gateway: GatewayApi;
  sensor: SensorApi;
  photo: PhotoApi;

  constructor(public readonly fetchStore: FetchStore) {
    this.user = new UserApi(this.fetchStore);
    this.document = new DocumentApi(this.fetchStore);
    this.auth = new AuthApi(this.fetchStore);
    this.installation = new InstallationAPI(this.fetchStore);
    this.asset = new AssetAPI(this.fetchStore);
    this.gateway = new GatewayApi(this.fetchStore);
    this.sensor = new SensorApi(this.fetchStore);
    this.photo = new PhotoApi(this.fetchStore);

    makeObservable(this, {});
  }
}
