import {observer} from 'mobx-react-lite';
import React from 'react';
import InstallerTopAppBar from '../shared/components/navigation/InstallerTopAppBar';
import {useStore} from '../shared/stores/useStore';
import InstallationStore from '../stores/InstallationStore';

const InstallationTopAppBar: React.FC = () => {
  const rootStore = useStore();

  const {userStore, authStore} = rootStore;

  return (
    <InstallerTopAppBar
      onStartInstallation={async () => {
        InstallationStore.clear();
      }}
      onValidateAssetInstallation={async () => {
        InstallationStore.clear();
      }}
      onLogOut={async () => {
        InstallationStore.clear();
        authStore.dispose();
        userStore.dispose();
        rootStore.dispose();
      }}
    />
  );
};

export default observer(InstallationTopAppBar);
