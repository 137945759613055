import {observer} from 'mobx-react-lite';
import React, {Fragment, ReactNode, useState} from 'react';
import {StyleSheet, TouchableOpacity, View} from 'react-native';
import locales from '../../locales/en';
import routePaths from '../../routePaths';
import {useStore} from '../../stores/useStore';
import {usePalette} from '../../theme/palette';
import {useScale} from '../../theme/scale';
import {ResponsiveStyle, useStyles} from '../../theme/styles';
import {OverlayModalKey} from '../../types/enums';
import {useLocation, useNavigate} from '../../utils/routing';
import Button from '../common/Button';
import DialogView from '../common/DialogView';
import Label from '../common/Label';
import OverlayModal from '../common/OverlayModal';
import CircleInfoIcon from '../icons/CircleInfoIcon';
import CirclePlusIcon from '../icons/CirclePlusIcon';
import ConMetLogo from '../icons/ConMetLogo';
import GearIcon from '../icons/GearIcon';
import SignoutIcon from '../icons/SignoutIcon';
import HamburgerButton from './HamburgerButton';
import TopAppBar from './TopAppBar';
import {SafeAreaView} from 'react-native-safe-area-context';
import shadow from '../../theme/shadow';

type Props = {
  onStartInstallation: () => Promise<void>;
  onValidateAssetInstallation: () => Promise<void>;
  onLogOut: () => Promise<void>;
};

const InstallerTopAppBar: React.FC<Props> = props => {
  const {onStartInstallation, onLogOut, onValidateAssetInstallation} = props;

  const navigate = useNavigate();

  const location = useLocation();

  const rootStore = useStore();

  const {userStore, authStore} = rootStore;

  const [drawerOpen, setDrawerOpen] = useState(false);

  const [modalVisible, setModalVisible] = useState(false);

  const palette = usePalette();

  const styles = useStyles(createStyles);

  const {moderateScale, moderateScaleV} = useScale();

  const isNotRootPathLocation = location.pathname !== routePaths.root.path;

  function handleLogoPress() {
    if (isNotRootPathLocation) {
      setModalVisible(true);
    }
  }

  function handleCloseModal() {
    setModalVisible(false);
  }

  async function handleStartInstallation() {
    await onStartInstallation();

    handleCloseModal();
    setDrawerOpen(false);
    navigate(routePaths.root.path);
  }

  function handleOpenSideBar() {
    setDrawerOpen(true);
  }

  async function handleValidateAssetInstallation() {
    await onValidateAssetInstallation();

    setDrawerOpen(false);
    navigate(routePaths.assetInstallationValidation.path);
  }

  async function handleLogOut() {
    await onLogOut();

    setDrawerOpen(false);
    navigate(routePaths.authentication.login.path);
  }

  function handleSupportPress() {
    setDrawerOpen(false);

    if (location.pathname !== routePaths.support.path) {
      navigate(routePaths.support.path, {
        state: {previousPathName: location.pathname},
      });
    }
  }

  function handleAppearanceChange() {
    switch (palette.appMode) {
      case 'system':
        palette.setAppMode('dark');
        return;
      case 'dark':
        palette.setAppMode('light');
        return;
      case 'light':
        palette.setAppMode('system');
        return;
    }
  }

  const appearanceText =
    palette.appMode === 'system'
      ? locales.system
      : palette.appMode === 'light'
      ? locales.light
      : locales.dark;

  return (
    <Fragment>
      <OverlayModal modalKey={OverlayModalKey.SIDEBAR} visible={drawerOpen}>
        <SideBarView onBackdropPress={() => setDrawerOpen(false)}>
          <View style={{paddingHorizontal: moderateScale(11)}}>
            <View
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                paddingVertical: moderateScaleV(24),
              }}>
              <ConMetLogo
                variant={
                  palette.mode === 'light' ? 'primaryBlack' : 'primaryWhite'
                }
                size="medium"
              />
            </View>

            {userStore.username ? (
              <Label
                variant="H6"
                color={palette.text[900]}
                style={{
                  marginBottom: moderateScaleV(16),
                }}>
                {userStore.username}
              </Label>
            ) : null}

            {authStore.isAuthenticated && userStore.isRivataUser && (
              <Button
                variant="text"
                text={locales.validateAssetInstallation}
                renderIcon={({color}) => <GearIcon color={color} />}
                onPress={handleValidateAssetInstallation}
                buttonStyle={{justifyContent: 'flex-start'}}
                style={{marginVertical: moderateScaleV(4)}}
              />
            )}

            {authStore.isAuthenticated && isNotRootPathLocation && (
              <Button
                variant="text"
                text={locales.startInstallation}
                renderIcon={({color}) => <CirclePlusIcon color={color} />}
                onPress={handleLogoPress}
                buttonStyle={{justifyContent: 'flex-start'}}
                style={{marginVertical: moderateScaleV(4)}}
              />
            )}

            <Button
              variant="text"
              text={locales.support}
              renderIcon={({color}) => <CircleInfoIcon color={color} />}
              onPress={handleSupportPress}
              buttonStyle={{justifyContent: 'flex-start'}}
              style={{marginVertical: moderateScaleV(4)}}
            />

            <Button
              variant="text"
              text={`${locales.appearance}: ${appearanceText}`}
              renderIcon={() => <View style={{width: moderateScale(24)}} />}
              onPress={handleAppearanceChange}
              buttonStyle={{justifyContent: 'flex-start'}}
              style={{marginVertical: moderateScaleV(4)}}
            />

            <View
              style={{
                borderBottomWidth: 1,
                marginVertical: moderateScaleV(8),
                borderColor: palette.surface[300],
              }}
            />

            {authStore.isAuthenticated && (
              <Button
                variant="signout"
                text={locales.signOut}
                renderIcon={({color}) => <SignoutIcon color={color} />}
                onPress={handleLogOut}
                buttonStyle={{justifyContent: 'flex-start'}}
                style={{marginVertical: moderateScaleV(4)}}
              />
            )}
          </View>
        </SideBarView>
      </OverlayModal>

      <OverlayModal
        visible={modalVisible}
        modalKey={OverlayModalKey.START_INSTALLATION_LOGO}>
        <DialogView fullWidth onBackdropPress={handleCloseModal}>
          <Label
            variant="H2"
            color={palette.text[1000]}
            style={{
              marginTop: moderateScaleV(24),
              marginHorizontal: moderateScale(24),
              marginBottom: moderateScaleV(12),
            }}>
            {locales.warning}
          </Label>

          <View style={{height: 1, backgroundColor: palette.surface[200]}} />

          <Label
            variant="P1"
            color={palette.text[900]}
            style={{
              marginVertical: moderateScaleV(20),
              marginHorizontal: moderateScale(24),
            }}>
            {locales.cancelInstallationWarning}
          </Label>

          <View style={{height: 1, backgroundColor: palette.surface[200]}} />

          <View style={styles.footer}>
            <Button
              variant="outlined"
              text={locales.cancel}
              onPress={handleCloseModal}
            />

            <View style={{marginLeft: moderateScale(24)}} />

            <Button
              variant="filled"
              text={locales.start}
              onPress={handleStartInstallation}
            />
          </View>
        </DialogView>
      </OverlayModal>

      <TopAppBar
        variant="centerAligned"
        leftNode={
          <TouchableOpacity onPress={handleLogoPress}>
            <ConMetLogo
              variant={
                palette.mode === 'light' ? 'primaryBlack' : 'primaryWhite'
              }
              size="small"
            />
          </TouchableOpacity>
        }
        rightNode={<HamburgerButton onPress={handleOpenSideBar} />}
      />
    </Fragment>
  );
};

const createStyles = ({
  scale: {moderateScale, moderateScaleV},
}: ResponsiveStyle) =>
  StyleSheet.create({
    footer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: moderateScale(20),
    },
    leftButton: {
      flex: 0.5,
      marginRight: moderateScale(10),
    },
    rightButton: {
      flex: 0.5,
      marginLeft: moderateScale(10),
    },
    listItem: {
      flexDirection: 'row',
      alignItems: 'center',
      paddingHorizontal: moderateScale(8),
      paddingVertical: moderateScaleV(12),
    },
  });

type SidebarProps = {
  onBackdropPress?: () => void;
  children?: ReactNode;
};

const SideBarView: React.FC<SidebarProps> = props => {
  const {children, onBackdropPress} = props;

  const styles = useStyles(createSideBarStyles);

  const fullWidth = false;

  return (
    <SafeAreaView
      mode="padding"
      pointerEvents="box-none"
      style={[
        styles.screen,
        {
          alignItems: 'flex-end',
        },
      ]}>
      <TouchableOpacity
        disabled={!onBackdropPress}
        onPress={onBackdropPress}
        style={styles.backdrop}
      />

      <View
        style={{
          width: fullWidth ? '100%' : undefined,
          minWidth: 260,
          maxWidth: 560,
          height: '100%',
          maxHeight: '100%',
        }}>
        <View style={[styles.dialog, shadow.xl]}>{children}</View>
      </View>
    </SafeAreaView>
  );
};

const createSideBarStyles = ({palette}: ResponsiveStyle) =>
  StyleSheet.create({
    screen: {
      flexGrow: 1,
      flexShrink: 1,
      position: 'relative',
    },
    backdrop: {
      ...StyleSheet.absoluteFillObject,

      backgroundColor: palette.modalOverlay,
      opacity: 0.5,
      position: 'absolute',
    },
    dialog: {
      flexGrow: 1,
      flexShrink: 1,
      backgroundColor: palette.surface[50],
      borderColor: palette.surface[300],
      borderLeftWidth: 1,
      overflow: 'hidden',
      maxWidth: 560,
      minWidth: 260,
    },
  });

export default observer(InstallerTopAppBar);
