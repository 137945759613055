import React from 'react';

import ButtonWithSpinner from '../ButtonWithSpinner';

import GatewaysStore from '../../stores/GatewaysStore';
import locales from '../../shared/locales/en';
import {useNavigate} from '../../shared/utils/routing';

function SensorSubmitBar({
  backTo,
  proceedTo,
  sensor,
  handleScan,
  hideMiddleButton,
  isGatewayScanner,
}) {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(backTo);
  };

  const handleSubmitAndProceed = async () => {
    await handleScan().then(() => {
      if (isGatewayScanner && GatewaysStore.isPrimarryMissing()) return;

      if (isGatewayScanner && GatewaysStore.validationResults.length) return;

      if (!sensor.error && !sensor.warning) navigate(proceedTo);
    });
  };

  const handleSubmitAndAddMore = async () => {
    await handleScan();
  };

  return (
    <div className="d-flex mt-3">
      <ButtonWithSpinner className="flex-fill" onClick={handleBack}>
        <i className="fas fa-arrow-circle-left mr-2" />
        {locales.goBack}
      </ButtonWithSpinner>

      {!hideMiddleButton && (
        <ButtonWithSpinner
          className="flex-fill ml-3"
          onClick={handleSubmitAndAddMore}>
          {locales.submitAndAddMore}
        </ButtonWithSpinner>
      )}

      <ButtonWithSpinner
        className="flex-fill ml-3"
        onClick={handleSubmitAndProceed}>
        {locales.submitAndProceed}
        <i className="fas fa-arrow-circle-right ml-2" />
      </ButtonWithSpinner>
    </div>
  );
}

export default SensorSubmitBar;
