import React, {Fragment} from 'react';
import {Button} from 'react-bootstrap';
import {observer} from 'mobx-react-lite';
import SensorsStore from '../../stores/SensorsStore';
import AssetStore from '../../stores/AssetStore';
import NavigationBar from '../../components/navigation/NavigationBar';
import VINLabel from '../../components/VINLabel';
import {assetType, sensorType} from '../../utils/Constants';
import useNavigationState from '../../hooks/useNavigationState';
import {ScrollView, StyleSheet, View} from 'react-native';
import InstallationTopAppBar from '../../components/InstallationTopAppBar';
import {SafeAreaView} from 'react-native-safe-area-context';
import Container from '../../shared/components/common/Container';
import Footer from '../../shared/components/navigation/Footer';
import {ResponsiveStyle, useStyles} from '../../shared/theme/styles';
import {useScale} from '../../shared/theme/scale';
import Label from '../../shared/components/common/Label';
import {usePalette} from '../../shared/theme/palette';
import locales from '../../shared/locales/en';
import {useNavigate} from '../../shared/utils/routing';

function HardwareType() {
  const navigate = useNavigate();

  const styles = useStyles(createStyles);

  const {moderateScale} = useScale();

  const palette = usePalette();

  const {getScreen} = useNavigationState();

  const selectSensorsType = async (type: any) => {
    SensorsStore.sensorsType = type;

    navigate('/sensors-data');
  };

  return (
    <Fragment>
      <View style={styles.screen}>
        <InstallationTopAppBar />

        <SafeAreaView
          mode="padding"
          edges={['left', 'right']}
          style={styles.bodyContainer}>
          <ScrollView
            keyboardShouldPersistTaps="handled"
            contentContainerStyle={{
              flexGrow: 1,
              padding: moderateScale(16),
            }}>
            <Container
              style={{
                flexGrow: 1,
                flexShrink: 1,
              }}>
              <VINLabel />

              <Label
                variant="H3"
                color={palette.text[1000]}
                style={{textAlign: 'center', marginBottom: 24}}>
                Choose Hardware Type
              </Label>

              <Selector
                name="Gateway"
                onSelect={() => navigate('/gateway-data')}
              />

              <Label
                variant="P1"
                color={palette.text[1000]}
                style={{textAlign: 'center', marginVertical: 24}}>
                OR
              </Label>

              <SensorTypeSelector
                type={sensorType.smarthub}
                name="SmartHub"
                onSelect={selectSensorsType}
              />

              <Label
                variant="P1"
                color={palette.text[1000]}
                style={{textAlign: 'center', marginVertical: 24}}>
                OR
              </Label>

              <SensorTypeSelector
                type={sensorType.tpms}
                name="TPMS"
                onSelect={selectSensorsType}
              />

              <Label
                variant="P1"
                color={palette.text[1000]}
                style={{textAlign: 'center', marginVertical: 24}}>
                OR
              </Label>

              <Selector
                name={
                  AssetStore.type === assetType.trailer
                    ? `${locales.axleLoad} / ${locales.linePressure}`
                    : locales.axleLoad
                }
                onSelect={() => navigate('/pneumatics-data')}
              />

              <NavigationBar
                className="mt-5"
                backTo={getScreen('hardwareType.goBack')}
                proceedTo="/installation-photos"
                proceedText={locales.skip}
                backText={undefined}
                onBack={undefined}
                onProceed={undefined}
                isHidden={undefined}
                isDisabled={undefined}
                dontuseNavigate={undefined}
                additionalLoadingState={undefined}
              />
            </Container>
          </ScrollView>
        </SafeAreaView>

        <Footer />
      </View>
    </Fragment>
  );
}

const Selector = ({name, onSelect}: {name: any; onSelect: any}) => {
  return (
    <Button block size="lg" onClick={onSelect}>
      {name}
    </Button>
  );
};

const SensorTypeSelector = observer(
  ({type, name, onSelect}: {type: any; name: any; onSelect: any}) => {
    return (
      <Button block size="lg" onClick={() => onSelect(type)}>
        {name}
      </Button>
    );
  },
);

const createStyles = ({palette}: ResponsiveStyle) =>
  StyleSheet.create({
    screen: {
      flexGrow: 1,
      flexShrink: 1,
      backgroundColor: palette.surface[50],
    },
    headerDivider: {
      borderBottomWidth: 1,
      borderBottomColor: palette.surface[200],
    },
    bodyContainer: {
      flexGrow: 1,
      flexShrink: 1,
    },
  });

export default observer(HardwareType);
