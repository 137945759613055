const routePaths = {
  root: {
    path: '/',
  },
  authentication: {
    login: {
      path: '/authentication/login',
    },
  },
  installationValidation: {
    path: '/installation-validation',
  },
  assetInstallationValidation: {
    path: '/asset-installation-validation',
  },
  assetInfo: {
    path: '/asset-info',
  },
  installations: {
    assetInfo: {
      path: '/installations/:installationId/asset-info',
      parse: (installationId: number) =>
        `/installations/${installationId}/asset-info`,
    },
  },
  assetType: {
    path: '/asset-type',
  },
  assetUpdate: {
    path: '/asset-update',
  },
  installationCaseNumber: {
    path: '/installation-case-number',
  },
  hardwareType: {
    path: '/hardware-type',
  },
  gatewayData: {
    path: '/gateway-data',
  },
  sensorsData: {
    path: '/sensors-data',
  },
  pneumaticsData: {
    path: '/pneumatics-data',
  },
  additionalHardware: {
    path: '/additional-hardware',
  },
  installationPhotos: {
    path: '/installation-photos',
  },
  installationPhotoCamera: {
    path: '/installation-photo-camera',
  },
  installationWorkOrder: {
    path: '/installation-work-order',
  },
  support: {
    path: '/support',
  },
};

export default routePaths;
