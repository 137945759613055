import React, {ReactNode, useState} from 'react';
import {Pressable, StyleProp, View, ViewStyle} from 'react-native';
import {usePalette} from '../../theme/palette';
import {useScale} from '../../theme/scale';
import Label from './Label';

type Props = {
  variant: 'filled' | 'outlined' | 'text' | 'signout';
  children?: undefined;
} & VariantProps;

type VariantProps = {
  text: string;
  isDisabled?: boolean;
  onPress: () => void;
  renderIcon?: (props: {state: ButtonState; color: string}) => ReactNode;
  style?: StyleProp<ViewStyle>;
  buttonStyle?: StyleProp<ViewStyle>;
};

type ButtonState = 'default' | 'pressed' | 'disabled';

type StateStyles = Record<
  ButtonState,
  {
    labelColor: string;
    bgColor: string;
    outlineWidth: number;
    outlineRadius: number;
    outlineColor: string;
    borderWidth: number;
    borderColor: string;
  }
>;

const Button: React.FC<Props> = props => {
  const {variant, isDisabled, text, onPress, renderIcon, style, buttonStyle} =
    props;

  const palette = usePalette();

  const [pressed, setPressed] = useState(false);

  const {moderateScale, moderateScaleV} = useScale();

  const borderRadius = 8;

  const variantStateStyles: Record<Props['variant'], StateStyles> = {
    filled: {
      default: {
        labelColor: palette.text[0],
        bgColor: palette.brandBlue[600],
        outlineColor: palette.transparent,
        outlineWidth: 0,
        outlineRadius: 0,
        borderColor: palette.transparent,
        borderWidth: 0,
      },
      pressed: {
        labelColor: palette.text[0],
        bgColor: palette.brandBlue[900],
        outlineColor: palette.blue[300],
        outlineWidth: 2,
        outlineRadius: borderRadius + 2,
        borderColor: palette.transparent,
        borderWidth: 0,
      },
      disabled: {
        labelColor: palette.text[300],
        bgColor: palette.surface[100],
        outlineColor: palette.transparent,
        outlineWidth: 0,
        outlineRadius: 0,
        borderColor: palette.surface[300],
        borderWidth: 1,
      },
    },
    outlined: {
      default: {
        labelColor: palette.brandBlue[600],
        bgColor: palette.surface[0],
        outlineColor: palette.transparent,
        outlineWidth: 0,
        outlineRadius: 0,
        borderColor: palette.surface[500],
        borderWidth: 1,
      },
      pressed: {
        labelColor: palette.brandBlue[600],
        bgColor: palette.brandBlue[50],
        outlineColor: palette.blue[300],
        outlineWidth: 2,
        outlineRadius: borderRadius + 2,
        borderColor: palette.transparent,
        borderWidth: 0,
      },
      disabled: {
        labelColor: palette.text[300],
        bgColor: palette.surface[0],
        outlineColor: palette.transparent,
        outlineWidth: 0,
        outlineRadius: 0,
        borderColor: palette.surface[300],
        borderWidth: 1,
      },
    },
    text: {
      default: {
        labelColor: palette.brandBlue[600],
        bgColor: palette.transparent,
        outlineColor: palette.transparent,
        outlineWidth: 0,
        outlineRadius: 0,
        borderColor: palette.transparent,
        borderWidth: 0,
      },
      pressed: {
        labelColor: palette.brandBlue[600],
        bgColor: palette.brandBlue[50],
        outlineColor: palette.blue[300],
        outlineWidth: 2,
        outlineRadius: borderRadius + 2,
        borderColor: palette.transparent,
        borderWidth: 0,
      },
      disabled: {
        labelColor: palette.text[300],
        bgColor: palette.transparent,
        outlineColor: palette.transparent,
        outlineWidth: 0,
        outlineRadius: 0,
        borderColor: palette.transparent,
        borderWidth: 0,
      },
    },
    signout: {
      default: {
        labelColor: palette.error[300],
        bgColor: palette.transparent,
        outlineColor: palette.transparent,
        outlineWidth: 0,
        outlineRadius: 0,
        borderColor: palette.transparent,
        borderWidth: 0,
      },
      pressed: {
        labelColor: palette.error[300],
        bgColor: palette.error[100],
        outlineColor: palette.error[300],
        outlineWidth: 2,
        outlineRadius: borderRadius + 2,
        borderColor: palette.transparent,
        borderWidth: 0,
      },
      disabled: {
        labelColor: palette.text[300],
        bgColor: palette.transparent,
        outlineColor: palette.transparent,
        outlineWidth: 0,
        outlineRadius: 0,
        borderColor: palette.transparent,
        borderWidth: 0,
      },
    },
  };

  const buttonState: ButtonState = isDisabled
    ? 'disabled'
    : pressed
    ? 'pressed'
    : 'default';

  const stateStyle = variantStateStyles[variant][buttonState];

  return (
    <View style={[style, {position: 'relative'}]}>
      <View
        pointerEvents="box-none"
        style={{
          position: 'absolute',
          top: -stateStyle.outlineWidth,
          bottom: -stateStyle.outlineWidth,
          left: -stateStyle.outlineWidth,
          right: -stateStyle.outlineWidth,
          borderRadius: stateStyle.outlineRadius,
          backgroundColor: stateStyle.outlineColor,
          zIndex: -1,
        }}
      />

      <Pressable
        disabled={isDisabled}
        onPressIn={() => setPressed(true)}
        onPressOut={() => setPressed(false)}
        onPress={onPress}
        style={[
          {
            flexDirection: 'row',
            height: moderateScaleV(40),
            borderRadius: borderRadius,
            backgroundColor: stateStyle.bgColor,
            borderWidth: stateStyle.borderWidth,
            borderColor: stateStyle.borderColor,
            zIndex: 0,
            justifyContent: 'center',
            alignItems: 'center',
          },
          buttonStyle,
        ]}>
        <View style={{marginLeft: moderateScale(renderIcon ? 16 : 24)}} />

        {renderIcon
          ? renderIcon({state: buttonState, color: stateStyle.labelColor})
          : null}

        {renderIcon ? <View style={{marginLeft: moderateScale(8)}} /> : null}

        <Label
          variant="B2"
          color={stateStyle.labelColor}
          style={{textAlign: 'center'}}>
          {text}
        </Label>

        <View style={{marginRight: moderateScale(24)}} />
      </Pressable>
    </View>
  );
};

export default Button;
