import React, { useState } from "react";
import { Form } from "react-bootstrap";

import { Formik } from "formik";
import * as Yup from "yup";

import ButtonWithSpinner from "./ButtonWithSpinner";

function FormWrapper({ value, validations, onSubmit, form, buttonText, innerRef, withoutButton, blockButton, onLoading, isDisabled }) {
  const [isLoading, setLoading] = useState(false);

  return (
    <Formik
      initialValues={value}
      onSubmit={async (values) => {
        setLoading(true);
        onLoading(true);
        try {
          await onSubmit(values);
        } catch (e) {
          console.log(e);
        } finally {
          setLoading(false);
          onLoading(false);
        }
      }}
      validationSchema={Yup.object().shape(validations)}
      innerRef={innerRef}
    >
      {(props) => {
        const { isSubmitting, handleSubmit } = props;

        return (
          <Form onSubmit={handleSubmit}>
            {form}
            <div className="d-flex justify-content-center">
              {!withoutButton && (
                <ButtonWithSpinner type="submit" disabled={isSubmitting || isLoading || isDisabled} isLoading={isLoading} block={blockButton}>
                  {buttonText ? buttonText : "Save"}
                </ButtonWithSpinner>
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default FormWrapper;
