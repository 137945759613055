import React from 'react';
import {Button} from 'react-bootstrap';
import {observer} from 'mobx-react-lite';

import AssetStore from '../../../stores/AssetStore';
import SensorsStore from '../../../stores/SensorsStore';
import {sensorType} from '../../../utils/Constants';
import {TouchableWithoutFeedback, View, StyleSheet} from 'react-native';
import XCircleIcon from '../../../shared/components/icons/XCircleIcon';
import {ResponsiveStyle, useStyles} from '../../../shared/theme/styles';
import {useStore} from '../../../shared/stores/useStore';

function SmartHubGrid({selectedSensorCode, setSelectedSensorCode}: any) {
  return (
    <div className="row">
      {AssetStore.getSensorsPositionsList(sensorType.smarthub).map(p => (
        <SensorButton
          key={p.code}
          code={p.code}
          name={p.name}
          selected={selectedSensorCode}
          setSelected={setSelectedSensorCode}
        />
      ))}
    </div>
  );
}

const SensorButton = observer(({code, name, selected, setSelected}: any) => {
  const {apiClient} = useStore();

  const styles = useStyles(createStyles);

  const sensor = SensorsStore.sensors.find(
    s => s.code === code && s.type === SensorsStore.sensorsType,
  );

  const del = async (id: any) => {
    SensorsStore.sensors = SensorsStore.sensors.filter(s => s.id !== id);
    apiClient.sensor.delete(id);
  };

  const getVariant = () => {
    if (selected === code) return 'primary';

    if (!sensor?.isUploaded) return 'outline-primary';

    if (sensor?.warning) return 'outline-warning';

    return 'outline-success';
  };

  return (
    <div className="col-6 mb-2 px-2">
      <Button
        className="sensor-button"
        variant={getVariant()}
        onClick={() =>
          setSelected((prev: any) => (code === prev ? null : code))
        }>
        {name}

        <br />

        {sensor?.esn && `ID: ${sensor?.esn}`}

        {sensor?.isUploaded && (
          <TouchableWithoutFeedback onPress={() => del(sensor.id)}>
            <View style={styles.removeIcon}>
              <XCircleIcon />
            </View>
          </TouchableWithoutFeedback>
        )}
      </Button>
    </div>
  );
});

export default observer(SmartHubGrid);

const createStyles = ({scale: {moderateScale}}: ResponsiveStyle) =>
  StyleSheet.create({
    removeIcon: {
      position: 'absolute',
      top: -moderateScale(6),
      right: -moderateScale(6),
    },
  });
