import {action, makeObservable, observable} from 'mobx';

export default class ErrorStore {
  error: string = '';

  constructor() {
    makeObservable(this, {
      error: observable,
      setError: action,
      clearError: action,
    });
  }

  setError(error: string) {
    this.error = error;
  }

  clearError() {
    this.error = '';
  }
}
