import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useMemo,
  Fragment,
  useEffect,
  useCallback,
} from 'react';
import {Modal, View, StyleSheet, StyleProp, ViewStyle} from 'react-native';

/**
 * Reason of usage:
 * In React Native, displaying multiple modals simultaneously is not supported,
 * and there are also issues related to input focus in nested modals.
 *
 * How it works:
 * We have a single instance of the Modal component. This is at the provider level.
 * The ReactNodes are displayed within the Modal as a full-screen overlay View.
 *
 * How to use:
 * Just use OverlayModal like original Modal.
 */

type ModalContextValue = {
  renderModal: (key: string, node: ReactNode) => void;
  deleteModal: (key: string) => void;
};

const OverlayModalContext = createContext<ModalContextValue>({
  renderModal: () => null,
  deleteModal: () => null,
});

export const OverlayModalProvider: React.FC = ({children}) => {
  const [modalItems, setModalItems] = useState(new Map<string, ReactNode>());

  const isModalVisible = useMemo(() => {
    return [...modalItems.values()].some(content => !!content) || false;
  }, [modalItems]);

  const renderModal: ModalContextValue['renderModal'] = useCallback(
    (key: string, node: ReactNode) => {
      setModalItems(prev => new Map(prev).set(key, node));
    },
    [],
  );

  const deleteModal: ModalContextValue['deleteModal'] = useCallback(
    (key: string) =>
      setModalItems(prev => {
        if (!prev.has(key)) return prev;

        const newMap = new Map(prev);
        newMap.delete(key);

        return newMap;
      }),
    [modalItems],
  );

  const value = useMemo(
    () => ({
      renderModal,
      deleteModal,
    }),
    [modalItems],
  );

  useEffect(() => {
    return () => {
      setModalItems(new Map());
    };
  }, []);

  return (
    <OverlayModalContext.Provider value={value}>
      {children}

      <Modal
        visible={isModalVisible}
        transparent
        supportedOrientations={['landscape', 'portrait']}
        onRequestClose={() => {}}>
        {[...modalItems.keys()].map(key => (
          <Fragment key={key}>{modalItems.get(key)}</Fragment>
        ))}
      </Modal>
    </OverlayModalContext.Provider>
  );
};

const useOverlayModal = () => {
  const context = useContext(OverlayModalContext);

  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }

  return context;
};

type ModalLayerProps = {
  style?: StyleProp<Pick<ViewStyle, 'zIndex'>>;
};

const ModalLayer: React.FC<ModalLayerProps> = props => {
  const {style, children} = props;

  return (
    <View
      style={[style, StyleSheet.absoluteFill, {flexGrow: 1, flexShrink: 1}]}>
      {children}
    </View>
  );
};

type Props = {
  modalKey: string;
  visible?: boolean;
  zIndex?: number;
};

const OverlayModal: React.FC<Props> = props => {
  const {modalKey, visible = false, zIndex = 0, children} = props;

  const {renderModal, deleteModal} = useOverlayModal();

  const modalLayer = useMemo(
    () => <ModalLayer style={{zIndex}}>{children}</ModalLayer>,
    [children, zIndex],
  );

  useEffect(() => {
    if (visible) {
      renderModal(modalKey, modalLayer);
    } else {
      deleteModal(modalKey);
    }

    return () => {
      deleteModal(modalKey);
    };
  }, [modalKey, modalLayer, visible]);

  return null;
};

export default OverlayModal;
