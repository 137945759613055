export const assetType = {
  trailer: 1,
  tractor: 2,
};

export const installationStatus = {
  inProgress: 1,
  finished: 2,
  canceled: 3,
};

export const sensorType = {
  smarthub: 1,
  tpms: 2,
  axleLoad: 3,
  linePressure: 4,
};

export const hardwareType = {
  gateway: 1,
  smartHubSensor: 2,
  tpmsSensor: 3,
  axleLoad: 4,
  linePressure: 5,
};

export const provisionSource = {
  web: 1,
  native: 2,
};

export const packetType = {
  gatewayGpsData: "gps_data",
  smartHubTemperature: "sh_temperature",
  smartHubVibration: "sh_vibration",
  tpmsPressure: "tpms_pressure",
  axleLoad: "axle_load",
  linePressure: "line_pressure",
};

export const provisionStatus = {
  inProgress: null,
  success: 1,
  failed: 2,
  unknown: 3,
};

export const hex = "0123456789ABCDEF";

export const supportEmail = "digitalcustomerservice@conmet.com";
export const supportPhone = "(940) 299-9143";

export const sensorErrors = {
  default: `Full 12 character Sensor ID is required. Please scan QR code to read full MAC. Please contact your ConMet Technical Account Manager or ${supportEmail} for support`,
  gateway: "Please make sure ID is valid and try again. Valid ID is 10 or 12 character ESN found on the label",
};

export const whitelistingStatusType = {
  sent: "SENT",
  failed: "FAILED",
  partiallyProcessed: "PARTIALLY_PROCESSED",
  success: "SUCCESS",
  pending: "PENDING",
};

export const permissions = {
  installerAppLogin: "INSTALLER_APP_LOGIN",
  installerAppCreateAsset: "INSTALLER_APP_CREATE_ASSET",
};

export const nhtsaError = {
  timeout: "NHTSA timeout occurred, would you like to proceed without data from NHTSA?",
  invalidVin: "VIN not recognized in NHTSA database, please check the VIN before proceeding",
};

export const atiInstalled = {
  noInfoProvided: null,
  no: 0,
  yes: 1,
  notSure: 2,
};

export const units = {
  distance: {
    kilometers: "kilometers",
    miles: "miles",
  },
};

export const regExps = {
  onlyNumbers: /^[0-9]+$/,
  eachNonInt: /[^0-9]+/g,
};
