import React, {Fragment, useEffect, useState} from 'react';
import {Alert, Button} from 'react-bootstrap';
import {observer} from 'mobx-react-lite';

import AssetStore from '../../stores/AssetStore';
import SensorsStore from '../../stores/SensorsStore';

import NavigationBar from '../../components/navigation/NavigationBar';
import VINLabel from '../../components/VINLabel';
import {assetType, sensorType} from '../../utils/Constants';

import SensorScanner from '../sensors/sensors-data/SensorScanner';
import {ResponsiveStyle, useStyles} from '../../shared/theme/styles';
import {
  TouchableWithoutFeedback,
  View,
  StyleSheet,
  ScrollView,
} from 'react-native';
import XCircleIcon from '../../shared/components/icons/XCircleIcon';
import {useStore} from '../../shared/stores/useStore';
import {useScale} from '../../shared/theme/scale';
import InstallationTopAppBar from '../../components/InstallationTopAppBar';
import {SafeAreaView} from 'react-native-safe-area-context';
import Container from '../../shared/components/common/Container';
import Footer from '../../shared/components/navigation/Footer';
import Label from '../../shared/components/common/Label';
import {usePalette} from '../../shared/theme/palette';

function PneumaticsData() {
  const [selectedSensorCode, setSelectedSensorCode] = useState(null);

  const styles = useStyles(createStyles);

  const palette = usePalette();

  const {moderateScale} = useScale();

  const isTrailer = AssetStore.type === assetType.trailer;

  const stopLoadingAll = () => {
    SensorsStore.sensors = SensorsStore.sensors.map(s => ({
      ...s,
      isLoading: false,
    }));
  };

  useEffect(() => {
    stopLoadingAll();
  }, []);

  return (
    <Fragment>
      <View style={styles.screen}>
        <InstallationTopAppBar />

        <SafeAreaView
          mode="padding"
          edges={['left', 'right']}
          style={styles.bodyContainer}>
          <ScrollView
            keyboardShouldPersistTaps="handled"
            contentContainerStyle={{
              flexGrow: 1,
              padding: moderateScale(16),
            }}>
            <Container
              style={{
                flexGrow: 1,
                flexShrink: 1,
              }}>
              <VINLabel />

              <Label
                variant="H3"
                color={palette.text[1000]}
                style={{textAlign: 'center', marginBottom: 24}}>
                {isTrailer
                  ? 'Install Axle Load / Line Pressure'
                  : 'Install Axle Load'}
              </Label>

              <PneumaticsButton
                code={'AXLE_LOAD'}
                name={'AXLE LOAD'}
                type={sensorType.axleLoad}
                selected={selectedSensorCode}
                setSelected={setSelectedSensorCode}
              />

              {isTrailer && (
                <div className="mt-3">
                  <PneumaticsButton
                    code={'LINE_PRESSURE'}
                    name={'LINE PRESSURE'}
                    type={sensorType.linePressure}
                    selected={selectedSensorCode}
                    setSelected={setSelectedSensorCode}
                  />
                </div>
              )}

              {!selectedSensorCode &&
                SensorsStore.getWarningSensors([
                  sensorType.axleLoad,
                  sensorType.linePressure,
                ]).map(sensor => {
                  return (
                    <Alert
                      key={sensor.esn}
                      className="mt-2 mb-2"
                      variant="warning">
                      <h6 className="alert-heading mb-1 text-left font-weight-bold">{`${sensor.code
                        .toString()
                        .replace(/\_/g, ' ')} (${sensor.esn})`}</h6>
                      <p className="mb-0 text-left">{sensor.warning}</p>
                    </Alert>
                  );
                })}

              {selectedSensorCode && (
                <SensorScanner
                  selectedSensorCode={selectedSensorCode}
                  setSelectedSensorCode={setSelectedSensorCode}
                  showReminder={false}
                  sensorTypes={[sensorType.axleLoad, sensorType.linePressure]}
                />
              )}

              {!selectedSensorCode && (
                <NavigationBar
                  backTo="/hardware-type"
                  proceedTo="/additional-hardware"
                  isDisabled={SensorsStore.anyLoading() || !!selectedSensorCode}
                  className={undefined}
                  backText={undefined}
                  onBack={undefined}
                  proceedText={undefined}
                  onProceed={undefined}
                  isHidden={undefined}
                  dontuseNavigate={undefined}
                  additionalLoadingState={undefined}
                />
              )}
            </Container>
          </ScrollView>
        </SafeAreaView>

        <Footer />
      </View>
    </Fragment>
  );
}

const PneumaticsButton = observer(
  ({code, name, type, selected, setSelected}: any) => {
    const {apiClient} = useStore();

    const styles = useStyles(createStyles);

    const sensor = SensorsStore.sensors.find(
      s => s.code === code && s.type === type,
    );

    const del = async (id: any) => {
      SensorsStore.sensors = SensorsStore.sensors.filter(s => s.id !== id);
      apiClient.sensor.delete(id);
    };

    const getVariant = () => {
      if (selected === code) return 'primary';

      if (!sensor?.isUploaded) return 'outline-primary';

      if (sensor?.warning) return 'outline-warning';

      return 'outline-success';
    };

    return (
      <Button
        className="sensor-button"
        variant={getVariant()}
        onClick={() => {
          SensorsStore.sensorsType = type;
          setSelected((prev: any) => (code === prev ? null : code));
        }}>
        {name}
        <br />
        {sensor?.esn && `ID: ${sensor?.esn}`}
        {sensor?.isUploaded && (
          <TouchableWithoutFeedback onPress={() => del(sensor.id)}>
            <View style={styles.removeIcon}>
              <XCircleIcon />
            </View>
          </TouchableWithoutFeedback>
        )}
      </Button>
    );
  },
);

export default observer(PneumaticsData);

const createStyles = ({scale: {moderateScale}, palette}: ResponsiveStyle) =>
  StyleSheet.create({
    screen: {
      flexGrow: 1,
      flexShrink: 1,
      backgroundColor: palette.surface[50],
    },
    headerDivider: {
      borderBottomWidth: 1,
      borderBottomColor: palette.surface[200],
    },
    bodyContainer: {
      flexGrow: 1,
      flexShrink: 1,
    },

    removeIcon: {
      position: 'absolute',
      top: -moderateScale(6),
      right: -moderateScale(6),
    },
  });
