import React from 'react';
import Animated, {useAnimatedStyle} from 'react-native-reanimated';
import {useLogoAnimation} from '../utils/useLogoAnimation';

const AnimatedLogoWrapper: React.FC = props => {
  const {children} = props;

  const {scale, opacity} = useLogoAnimation();

  const animationStyles = useAnimatedStyle(() => {
    return {
      transform: [{scale: scale.value}],
      opacity: opacity.value,
    };
  }, [scale, opacity]);

  return <Animated.View style={animationStyles}>{children}</Animated.View>;
};

export default AnimatedLogoWrapper;
