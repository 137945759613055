import React from 'react';
import {TouchableOpacity, TouchableOpacityProps, View} from 'react-native';
import {useScale} from '../../theme/scale';

export type AppBarButtonProps = Pick<
  TouchableOpacityProps,
  'disabled' | 'onPress' | 'style'
>;

const AppBarButton: React.FC<AppBarButtonProps> = props => {
  const {disabled, onPress, children, style} = props;

  const {moderateScale} = useScale();

  return (
    <TouchableOpacity
      disabled={disabled}
      onPress={onPress}
      style={[
        {
          margin: moderateScale(-12),
          borderWidth: moderateScale(12),
          borderColor: 'transparent',
        },
      ]}>
      <View style={style}>{children}</View>
    </TouchableOpacity>
  );
};

export default AppBarButton;
