import { makeAutoObservable, extendObservable } from "mobx";

import { autoSave } from "../utils/Mobx";

const defaultPhoto = {
  file: null,
  fileName: "",
};

class InstallationPhoto {
  constructor() {
    this.photo = defaultPhoto;

    this.load();
    autoSave(this, this.save.bind(this));
  }

  load() {
    let json = localStorage.getItem("installation-photo");

    if (json) extendObservable(this, JSON.parse(json), {}, { deep: true });
    else makeAutoObservable(this, {}, { deep: true });
  }

  save(json) {
    localStorage.setItem("installation-photo", json);
  }

  clear() {
    this.photo = defaultPhoto;
  }
}

export default new InstallationPhoto();
