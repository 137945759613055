import {observer} from 'mobx-react-lite';
import React, {useState} from 'react';
import {Alert, Button, Col, Row} from 'react-bootstrap';
import locales from '../shared/locales/en';

const ValidationActions = ({
  isValidationValid,
  lastDataButtonProps: {disabled, onPress},
}: any) => {
  const [isShowLegend, setIsShowLegend] = useState(false);

  return (
    <Row>
      <Col>
        <Row>
          <Col>
            <Button
              className="mb-2"
              block
              variant="outline-primary"
              onClick={() => setIsShowLegend(prev => !prev)}>
              {isShowLegend ? locales.hideLegend : locales.showLegend}
            </Button>
          </Col>

          <Col>
            {!isValidationValid && (
              <Button
                className="mb-2"
                disabled={disabled}
                block
                variant="primary"
                onClick={onPress}>
                {locales.getLastData}
              </Button>
            )}
          </Col>
        </Row>

        {isShowLegend && <Legend />}
      </Col>
    </Row>
  );
};

const Legend = () => {
  return (
    <div className="text-left mt-2">
      <Alert variant="success">Reported within the last 4 hours</Alert>
      <Alert variant="warning">Communicated between 4 and 24 hours ago</Alert>
      <Alert variant="dark">
        Not communicated in the past 24 hours or longer
      </Alert>
      <Alert className="mt-3" variant="danger">
        Has not communicated yet
      </Alert>
    </div>
  );
};

export default observer(ValidationActions);
