import React, {Fragment, useEffect} from 'react';
import {
  Control,
  Controller,
  FieldValues,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import Label from '../../components/common/Label';
import LabelInput from '../../components/common/LabelInput';
import {usePalette} from '../../theme/palette';
import {useScale} from '../../theme/scale';
import {AssetType} from '../../types/enums';

type Props = {
  control: Control<FieldValues> | undefined;
  disabled: boolean;
  assetType: AssetType;
  watch: UseFormWatch<FieldValues>;
  trigger: UseFormTrigger<FieldValues>;
};

const axleCountLimits = {
  [AssetType.tractor]: {
    min: 2,
    max: 5,
  },
  [AssetType.trailer]: {
    min: 1,
    max: 5,
  },
};

const assetTypeText = {
  [AssetType.tractor]: 'tractor',
  [AssetType.trailer]: 'trailer',
};

const AssetAxleCountController: React.FC<Props> = props => {
  const {control, disabled, watch, trigger} = props;

  const {moderateScale} = useScale();

  const palette = usePalette();

  const watchAssetType = watch('type') as AssetType | undefined;

  useEffect(() => {
    if (!watchAssetType) return;

    trigger('numberOfAxles');
  }, [watchAssetType]);

  return (
    <Controller
      name="numberOfAxles"
      control={control}
      defaultValue={null}
      rules={{
        required: 'This field is required',
        min: watchAssetType
          ? {
              value: axleCountLimits[watchAssetType].min,
              message: `The minimum number of axles for the ${assetTypeText[watchAssetType]} must be at least ${axleCountLimits[watchAssetType].min}`,
            }
          : undefined,
        max: watchAssetType
          ? {
              value: axleCountLimits[watchAssetType].max,
              message: `The maximum number of axles for the ${assetTypeText[watchAssetType]} cannot exceed ${axleCountLimits[watchAssetType].max}`,
            }
          : undefined,
        pattern: {
          value: /^[0-9]*$/g,
          message: 'Please provide whole numbers only',
        },
      }}
      render={({field: {onChange, value}, fieldState: {error}}) => (
        <Fragment>
          <Label
            variant="L1"
            color={palette.text[900]}
            style={{marginBottom: moderateScale(4)}}>
            Number Of Axles
          </Label>

          <LabelInput
            isDisabled={disabled}
            keyboardType="numeric"
            value={value}
            onChangeText={onChange}
          />

          {!!error && (
            <Label
              variant="L4"
              color={palette.error[300]}
              style={{marginTop: moderateScale(4)}}>
              {error?.message?.toString()}
            </Label>
          )}
        </Fragment>
      )}
    />
  );
};

export default AssetAxleCountController;
