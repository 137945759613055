import AsyncStorage from '@react-native-async-storage/async-storage';
import {action, computed, makeObservable, observable, toJS} from 'mobx';
import {listToTree} from '../../utils';
import ErrorStore from '../error/ErrorStore';
import ApiClient from '../../services/apiClient';
import {AsyncStorageKey} from '../../types/enums';

export default class UserStore {
  id: UserDto['id'] = undefined;

  isRivataUser: UserDto['isRivataUser'] = false;

  username: UserDto['username'] = '';

  roles: UserDto['roles'] = [];

  customers: UserDto['customers'] = [];

  permissions: UserDto['permissions'] = [];

  errorStore: ErrorStore;

  constructor(private readonly apiClient: ApiClient) {
    this.errorStore = new ErrorStore();

    makeObservable(this, {
      id: observable,
      isRivataUser: observable,
      username: observable,
      roles: observable,
      customers: observable,
      permissions: observable,
      errorStore: observable,

      customerNames: computed,
      isSuperAdmin: computed,
      customerTree: computed,

      setIsRivataUser: action,
      setUsername: action,
      setRoles: action,
      setCustomers: action,
      setPermissions: action,
      getUser: action,
      dispose: action,
      setId: action,
    });

    this.getCachedIsRivataUserValue();
  }

  get customerNames() {
    if (this.customers === null) return [];
    return this.customers.map(customer => customer.name);
  }

  get isSuperAdmin() {
    return (
      this.roles.includes('ADMIN') &&
      this.customers.find(customer => customer.id === 130)
    );
  }

  get customerTree() {
    return listToTree(toJS(this.customers));
  }

  async setIsRivataUser(isRivataUser: UserDto['isRivataUser']) {
    this.isRivataUser = isRivataUser;

    await AsyncStorage.setItem(
      AsyncStorageKey.isRivataUser,
      isRivataUser.toString(),
    );
  }

  async getCachedIsRivataUserValue() {
    const isRivataUser = await AsyncStorage.getItem(
      AsyncStorageKey.isRivataUser,
    );

    if (isRivataUser && isRivataUser === 'true') this.setIsRivataUser(true);
  }

  async getUser() {
    const user: UserDto | null = await this.apiClient.user.getUser();

    if (!user) return;

    this.setUsername(user.username);

    if (user.roles) this.setRoles(user.roles);

    if (user.customers) this.setCustomers(user.customers);

    if (user.permissions) this.setPermissions(user.permissions);

    this.setId(user.id);
  }

  setUsername(username: UserDto['username']) {
    this.username = username;
  }

  setRoles(roles: UserDto['roles']) {
    this.roles = roles;
  }

  setCustomers(customers: UserDto['customers']) {
    this.customers = customers;
  }

  setPermissions(permissions: UserDto['permissions']) {
    this.permissions = permissions;
  }

  setId(id: UserDto['id']) {
    this.id = id;
  }

  async setupCachedIsRivataUser() {
    try {
      const isRivataUser = await AsyncStorage.getItem(
        AsyncStorageKey.isRivataUser,
      );

      if (isRivataUser && isRivataUser === 'true') this.setIsRivataUser(true);
    } catch (e: unknown) {}
  }

  dispose() {
    this.setRoles([]);
    this.setCustomers([]);
    this.setPermissions([]);
    this.setIsRivataUser(false);
    this.setUsername('');
  }
}
