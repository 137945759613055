import moment from 'moment';
import React, {Fragment} from 'react';
import {Control, Controller, FieldValues} from 'react-hook-form';
import Label from '../../components/common/Label';
import LabelInput from '../../components/common/LabelInput';
import {usePalette} from '../../theme/palette';
import {useScale} from '../../theme/scale';

type Props = {
  control: Control<FieldValues> | undefined;
  disabled: boolean;
};

const maxYear = moment().year() + 2;

const AssetYearController: React.FC<Props> = props => {
  const {control, disabled} = props;

  const {moderateScale} = useScale();

  const palette = usePalette();

  return (
    <Controller
      name="modelyear"
      control={control}
      rules={{
        min: {
          value: 1900,
          message: 'Year must be greater than or equal to 1900',
        },
        max: {
          value: maxYear,
          message: `Year must be smaller than or equal to ${maxYear}`,
        },
      }}
      defaultValue={undefined}
      render={({field: {onChange, value}, fieldState: {error}}) => (
        <Fragment>
          <Label
            variant="L1"
            color={palette.text[900]}
            style={{marginBottom: moderateScale(4)}}>
            Year
          </Label>

          <LabelInput
            isDisabled={disabled}
            value={value}
            keyboardType="numeric"
            onChangeText={text => {
              if (+text > 0) {
                onChange(text);
              } else {
                onChange('');
              }
            }}
          />

          {!!error && (
            <Label
              variant="L4"
              color={palette.error[300]}
              style={{marginTop: moderateScale(4)}}>
              {error?.message?.toString()}
            </Label>
          )}
        </Fragment>
      )}
    />
  );
};

export default AssetYearController;
