import React from 'react';
import {usePalette} from '../../theme/palette';
import HamburgerMenuIcon from '../icons/HamburgerMenuIcon';
import AppBarButton, {AppBarButtonProps} from './AppBarButton';

const HamburgerButton: React.FC<AppBarButtonProps> = props => {
  const {disabled, onPress} = props;

  const palette = usePalette();

  return (
    <AppBarButton
      disabled={disabled}
      onPress={onPress}
      style={{flexDirection: 'row', alignItems: 'center'}}>
      <HamburgerMenuIcon
        size={24}
        stroke={disabled ? palette.surface[300] : palette.surface[900]}
      />
    </AppBarButton>
  );
};

export default HamburgerButton;
