import {extendObservable, makeAutoObservable} from 'mobx';
import GatewayApi from '../shared/api/GatewayApi';
import {sensorErrors, sensorType} from '../utils/Constants';
import {autoSave} from '../utils/Mobx';
import {
  parseSerial,
  prepareManuallyEnteredSerial,
  validateGatewayEsn,
} from '../utils/Utils';
import AssetStore from './AssetStore';
import InstallationStore from './InstallationStore';
import SensorsStore from './SensorsStore';

export type ValidationResult = {
  status: 'warning' | 'error';
  message: string;
  gatewayMacs: string[];
};

class GatewaysStore {
  gateways: any[];

  validationResults: ValidationResult[] = [];

  constructor() {
    this.gateways = [];

    this.load();
    autoSave(this, this.save.bind(this));
  }

  load() {
    const json = localStorage.getItem('gateways');

    if (json) extendObservable(this, JSON.parse(json), {}, {deep: true});
    else makeAutoObservable(this, {}, {deep: true});
  }

  save(json: any) {
    localStorage.setItem('gateways', json);
  }

  anyLoading() {
    return this.gateways.some(s => s.isLoading);
  }

  get(code: any) {
    const gateway = this.gateways.find(s => s.code === code);

    if (gateway) return gateway;

    this.gateways.push({
      esn: '',
      code: code,
      isUploaded: false,
    });

    return this.gateways.find(s => s.code === code);
  }

  set(gateways: any) {
    this.gateways = gateways.map((g: any, i: number) => ({
      ...g,
      code: i,
      isUploaded: true,
    }));
  }

  clear() {
    this.gateways = [];
  }

  clearUnsubmitted() {
    this.gateways = this.gateways.filter(g => g.id && g.esn && g.isUploaded);
  }

  isPrimarryMissing() {
    return (
      this.gateways.some(g => g.code === 1 && g.id) &&
      !this.gateways.some(g => g.code === 0 && g.id)
    );
  }

  setValidationResults(validationResults: ValidationResult[]) {
    this.validationResults = validationResults;

    const primaryGateway = this.gateways.find(
      gateway => gateway.code === 0 && gateway.id,
    );

    const secondaryGateway = this.gateways.find(
      gateway => gateway.code === 1 && gateway.id,
    );

    this.gateways.forEach(gateway => (gateway.validationResult = null));

    this.validationResults.forEach(r => {
      if (primaryGateway && r.gatewayMacs.includes(primaryGateway.esn)) {
        primaryGateway.validationResult = r;
      }

      if (secondaryGateway && r.gatewayMacs.includes(secondaryGateway.esn)) {
        secondaryGateway.validationResult = r;
      }
    });
  }

  async validate(vin: string, gatewayApi: GatewayApi) {
    const gatewayMacs = Array.from(this.gateways.values()).map(
      gateway => gateway.esn,
    );

    const validationResults = await gatewayApi.validate(gatewayMacs, vin);

    this.setValidationResults(validationResults);
  }

  async handleScan({
    serial,
    manuallyEntered,
    gateway,
    selectedCode,
    setSelectedCode,
    gatewayApi,
  }: {
    serial: string;
    manuallyEntered?: boolean;
    gateway: any;
    selectedCode: any;
    setSelectedCode: (code: any) => void;
    gatewayApi: GatewayApi;
  }) {
    gateway.error = '';
    serial = serial.toUpperCase();

    if (manuallyEntered) {
      serial = prepareManuallyEnteredSerial(serial);
    }

    const isValid = validateGatewayEsn(serial);

    if (!isValid) {
      gateway.error = sensorErrors.gateway;
      return;
    }

    if (serial.length === 10) serial = `00${serial}`;
    serial = parseSerial(serial);

    const existingEsn = this.gateways.some(g => g.esn === serial);

    if (existingEsn) {
      gateway.error = 'ESN is already in use';
    } else {
      await this.addGateway({
        serial,
        gateway,
        selectedCode,
        setSelectedCode,
        gatewayApi,
      });
    }
  }

  async addGateway({
    serial,
    gateway,
    selectedCode,
    setSelectedCode,
    gatewayApi,
  }: {
    serial: string;
    gateway: any;
    selectedCode: any;
    setSelectedCode: (code: any) => void;
    gatewayApi: GatewayApi;
  }) {
    gateway.isLoading = true;
    gateway.error = '';

    const data = {
      esn: serial,
      assetId: AssetStore.asset.id,
      installationId: InstallationStore.installation.id,
    };

    let existingEsn = null;
    const vin = AssetStore.asset.vin;

    if (selectedCode !== null && selectedCode !== undefined) {
      const existingGateway = this.gateways.find(g => g.code === selectedCode);

      if (existingGateway) {
        existingEsn = existingGateway.esn;
      }
    }

    try {
      const result = await gatewayApi.create(data, existingEsn, vin);

      if (!result.id) {
        gateway.error = 'Data not uploaded, uncaught error!';
        return;
      }

      gateway.id = result.id;
      gateway.esn = serial;
      gateway.isUploaded = true;

      this.setValidationResults(result.validationResults);

      setSelectedCode(null);
    } catch (e) {
      if (e instanceof Error) {
        gateway.error = e.message || 'Something went wrong';
      }
    } finally {
      gateway.isLoading = false;
    }
  }

  get validationErrorResults() {
    return this.validationResults.filter(r => r.status === 'error');
  }

  get hasValidationError() {
    return !!this.validationResults.filter(r => r.status === 'error').length;
  }

  get sensorCountLimitExceededAcrossGatewaysMessage(): string | undefined {
    const gatewaysCount = this.gateways.filter(g => g.id).length;
    const pressureSensorsCount = SensorsStore.sensors.filter(
      s => s.type !== sensorType.smarthub,
    ).length;
    const sensorCount = SensorsStore.sensors.length;
    const sensorsLimitByInstalledGateways = gatewaysCount * 10;

    if (sensorCount > 0 && gatewaysCount === 0) {
      return `Installed sensors (${sensorCount}) require gateway installation`;
    }

    if (
      sensorCount > 0 &&
      gatewaysCount !== 0 &&
      pressureSensorsCount > sensorsLimitByInstalledGateways
    ) {
      return `Installed TPMS sensor count ${pressureSensorsCount} exceeds total gateway sensor limit ${sensorsLimitByInstalledGateways}. Please either remove ${
        pressureSensorsCount - 10
      } TPMS sensors, or provision an additional gateway.`;
    }

    return undefined;
  }
}

export default new GatewaysStore();
