import React, {Fragment, useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Alert} from 'react-bootstrap';
import InstallationStore from '../../stores/InstallationStore';
import HardwareStore from '../../stores/HardwareStore';
import HardwareModel from '../../stores/HardwareModel';
import NavigationBar from '../../components/navigation/NavigationBar';
import HardwareGroups from '../../components/HardwareGroups';
import OverlaySpinner from '../../components/OverlaySpinner';
import ProvisionStatusAlert from '../../components/ProvisionStatusAlert';
import useInstallationProvisionStatus from '../../hooks/useInstallationProvisionStatus';
import {WhitelistingStatusAlerts} from '../../components/WhitelistingStatusAlert';
import {useStore} from '../../shared/stores/useStore';
import {ResponsiveStyle, useStyles} from '../../shared/theme/styles';
import {useScale} from '../../shared/theme/scale';
import {ScrollView, StyleSheet, View} from 'react-native';
import InstallationTopAppBar from '../../components/InstallationTopAppBar';
import {SafeAreaView} from 'react-native-safe-area-context';
import Container from '../../shared/components/common/Container';
import Footer from '../../shared/components/navigation/Footer';
import Label from '../../shared/components/common/Label';
import {usePalette} from '../../shared/theme/palette';
import ValidationActions from '../../components/ValidationActions';

export default observer(InstallationValidation);
function InstallationValidation() {
  const styles = useStyles(createStyles);

  const {moderateScale} = useScale();

  const {apiClient} = useStore();

  const palette = usePalette();

  const [validationAsset, setValidationAsset] = useState(null);
  const [isValidationValid, setIsValidationValid] = useState(false);
  const [hardwareStore, setHardwareStore] = useState(new HardwareStore());

  const {provisionStatusInfo, isProvisionInProgress} =
    useInstallationProvisionStatus(InstallationStore.installationToValidate);
  const [whitelistingStatuses, setWhitelistingStatuses] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  async function validateHardwareAndGetLatestData(installationId: any) {
    const validationResponse = await apiClient.installation.validate(
      installationId,
    );
    if (!validationResponse)
      throw new Error(`Failed to validate asset installation. Try again.`);

    setValidationAsset(
      validationResponse.asset ? validationResponse.asset : null,
    );
    setIsValidationValid(
      validationResponse.isValid ? validationResponse.isValid : false,
    );

    const newHardwareStore = new HardwareStore();
    const hardwareUnits = validationResponse.hardware
      ? validationResponse.hardware.map((r: any) => new HardwareModel(r))
      : [];
    newHardwareStore.setHardwareUnits(hardwareUnits);

    await newHardwareStore.setupLatestPackets(apiClient.installation);

    setHardwareStore(newHardwareStore);
  }

  async function getWhitelistingStatus() {
    if (!InstallationStore.installationToValidate) return;

    const data = await apiClient.installation.getProvisionByInstallationId(
      InstallationStore.installationToValidate,
    );
    if (!data) return;

    const {whitelisting_statuses} =
      await apiClient.installation.getProvisionWhitelistingStatus(
        data.provisioning_id,
      );
    setWhitelistingStatuses(whitelisting_statuses);
  }

  async function validate() {
    if (!InstallationStore.installationToValidate) return;

    setIsLoading(true);
    setError('');

    try {
      await Promise.all([
        getWhitelistingStatus(),
        validateHardwareAndGetLatestData(
          InstallationStore.installationToValidate,
        ),
      ]);
    } catch (e) {
      setError((e as any).message);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (provisionStatusInfo && (provisionStatusInfo as any).status !== null) {
      validate();
    }
  }, [(provisionStatusInfo as any)?.status]);

  if (!InstallationStore.installationToValidate) return null;

  return (
    <Fragment>
      <View style={styles.screen}>
        <InstallationTopAppBar />

        <SafeAreaView
          mode="padding"
          edges={['left', 'right']}
          style={styles.bodyContainer}>
          <ScrollView
            keyboardShouldPersistTaps="handled"
            contentContainerStyle={{
              flexGrow: 1,
              padding: moderateScale(16),
            }}>
            <Container
              style={{
                flexGrow: 1,
                flexShrink: 1,
              }}>
              {(isLoading || isProvisionInProgress) && <OverlaySpinner />}

              {validationAsset && (
                <View style={{alignItems: 'center'}}>
                  <Label variant="P1" color={palette.text[1000]}>
                    {'VIN: ' + (validationAsset as any).vin}
                  </Label>

                  <Label variant="P1" color={palette.text[1000]}>
                    {'Asset Name: ' + (validationAsset as any).name}
                  </Label>
                </View>
              )}

              <Label
                variant="H3"
                color={palette.text[1000]}
                style={{textAlign: 'center', marginBottom: 12}}>
                Validate Installation
              </Label>

              {!!error && <Alert variant="danger">{error}</Alert>}

              {validationAsset && (
                <>
                  {provisionStatusInfo && (
                    <ProvisionStatusAlert
                      provisionStatusInfo={provisionStatusInfo}
                    />
                  )}

                  <WhitelistingStatusAlerts
                    whitelistingStatuses={whitelistingStatuses}
                  />

                  {!isProvisionInProgress && (
                    <>
                      <ValidationActions
                        isValidationValid={isValidationValid}
                        lastDataButtonProps={{
                          disabled:
                            isLoading ||
                            !InstallationStore.installationToValidate,
                          onPress: () => validate(),
                        }}
                      />

                      <HardwareGroups
                        assetType={(validationAsset as any).type}
                        hardwareStore={hardwareStore}
                      />
                    </>
                  )}
                </>
              )}

              <NavigationBar
                backTo="/"
                onBack={() => (InstallationStore.installationToValidate = null)}
                isDisabled={isLoading}
                className={undefined}
                backText={undefined}
                proceedText={undefined}
                proceedTo={undefined}
                onProceed={undefined}
                isHidden={undefined}
                dontuseNavigate={undefined}
                additionalLoadingState={undefined}
              />
            </Container>
          </ScrollView>
        </SafeAreaView>

        <Footer />
      </View>
    </Fragment>
  );
}

const createStyles = ({palette}: ResponsiveStyle) =>
  StyleSheet.create({
    screen: {
      flexGrow: 1,
      flexShrink: 1,
      backgroundColor: palette.surface[50],
    },
    headerDivider: {
      borderBottomWidth: 1,
      borderBottomColor: palette.surface[200],
    },
    bodyContainer: {
      flexGrow: 1,
      flexShrink: 1,
    },
  });
