import AsyncStorage from '@react-native-async-storage/async-storage';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {Appearance, ColorSchemeName, useColorScheme} from 'react-native';
import Logging from '../services/logging';
import {AsyncStorageKey} from '../types/enums';

type AppearanceMode = 'light' | 'dark';

type AppAppearanceMode = AppearanceMode | 'system';

export type Palette = PaletteColors & {
  mode: AppearanceMode;
  appMode: AppAppearanceMode;
  systemMode: ColorSchemeName;
  setAppMode: (mode: AppAppearanceMode) => void;
};

export type PaletteColors = {
  transparent: string;
  surface: Record<
    | '0'
    | '50'
    | '100'
    | '200'
    | '300'
    | '400'
    | '500'
    | '600'
    | '700'
    | '800'
    | '900'
    | '1000',
    string
  >;
  text: Record<
    | '0'
    | '50'
    | '100'
    | '200'
    | '300'
    | '400'
    | '500'
    | '600'
    | '700'
    | '800'
    | '900'
    | '1000',
    string
  >;
  blue: Record<
    | '50'
    | '100'
    | '200'
    | '300'
    | '400'
    | '500'
    | '600'
    | '700'
    | '800'
    | '900',
    string
  >;
  brandBlue: Record<
    | '50'
    | '100'
    | '200'
    | '300'
    | '400'
    | '500'
    | '600'
    | '700'
    | '800'
    | '900',
    string
  >;
  error: Record<'100' | '200' | '300', string>;
  success: Record<'100' | '200' | '300', string>;
  amber: Record<
    | '50'
    | '100'
    | '200'
    | '300'
    | '400'
    | '500'
    | '600'
    | '700'
    | '800'
    | '900',
    string
  >;
  link: string;
  modalOverlay: string;
  threshold: Record<'critical' | 'warning' | 'normal', string>;
  white: string;
};

export const lightPalette: PaletteColors = {
  transparent: 'transparent',
  surface: {
    '0': '#FDFDFD',
    '50': '#FAFCFC',
    '100': '#F5F7F7',
    '200': '#E3E5E5',
    '300': '#CCCECF',
    '400': '#B6B8B8',
    '500': '#9FA1A1',
    '600': '#888A8A',
    '700': '#727373',
    '800': '#5B5C5C',
    '900': '#444545',
    '1000': '#1A1A1A',
  },
  text: {
    '0': '#FDFDFD',
    '50': '#FAFCFC',
    '100': '#F5F7F7',
    '200': '#E3E5E5',
    '300': '#CCCECF',
    '400': '#B6B8B8',
    '500': '#9FA1A1',
    '600': '#888A8A',
    '700': '#727373',
    '800': '#5B5C5C',
    '900': '#444545',
    '1000': '#1A1A1A',
  },
  blue: {
    '50': '#e3f7fc',
    '100': '#b7ebf7',
    '200': '#8bdef1',
    '300': '#66d1e9',
    '400': '#54c7e1',
    '500': '#52bdd9',
    '600': '#27b1c7',
    '700': '#4598ab',
    '800': '#408492',
    '900': '#376167',
  },
  brandBlue: {
    '50': '#e2f1f3',
    '100': '#b7dde1', // light
    '200': '#92C8D2',
    '300': '#6EB3C2',
    '400': '#499EB3',
    '500': '#2589A3',
    '600': '#007494', // medium
    '700': '#005E7B',
    '800': '#004961',
    '900': '#003348', // dark
  },
  amber: {
    '50': '#fff4e4',
    '100': '#ffe4bb',
    '200': '#ffd28f',
    '300': '#ffc061',
    '400': '#ffb140',
    '500': '#ffa42b',
    '600': '#fe9828',
    '700': '#f88824',
    '800': '#f17921',
    '900': '#e8601b',
  },
  error: {
    100: '#FA6B6B',
    200: '#EB4242',
    300: '#BF4141',
  },
  success: {
    100: '#8cea76',
    200: '#68c056',
    300: '#4c8d3e',
  },
  link: '#2D7AE1',
  modalOverlay: '#00000088',
  threshold: {
    critical: '#C85300',
    warning: '#FF9D0A',
    normal: '#6CC24A',
  },
  white: '#FDFDFD',
};

export const darkPalette: PaletteColors = {
  transparent: 'transparent',

  surface: {
    '0': '#161A1D',
    '50': '#1D2125',
    '100': '#22272B',
    '200': '#2C333A',
    '300': '#454F59',
    '400': '#596773',
    '500': '#738496',
    '600': '#8C9BAB',
    '700': '#9FADBC',
    '800': '#B6C2CF',
    '900': '#C7D1DB',
    '1000': '#DEE4EA',
  },
  text: {
    '0': '#161A1D',
    '50': '#1D2125',
    '100': '#22272B',
    '200': '#2C333A',
    '300': '#454F59',
    '400': '#596773',
    '500': '#738496',
    '600': '#8C9BAB',
    '700': '#9FADBC',
    '800': '#B6C2CF',
    '900': '#C7D1DB',
    '1000': '#DEE4EA',
  },
  blue: {
    '50': '#376167',
    '100': '#408492',
    '200': '#4598ab',
    '300': '#27b1c7', // Brand
    '400': '#52bdd9',
    '500': '#54c7e1',
    '600': '#66d1e9',
    '700': '#8bdef1',
    '800': '#b7ebf7',
    '900': '#e3f7fc',
  },
  brandBlue: {
    '50': '#003348',
    '100': '#004961',
    '200': '#005E7B',
    '300': '#007494',
    '400': '#2589A3',
    '500': '#499EB3',
    '600': '#6EB3C2',
    '700': '#92C8D2',
    '800': '#b7dde1',
    '900': '#e2f1f3',
  },
  error: {
    100: '#c34442',
    200: '#f04643',
    300: '#ff6d6c',
  },
  success: {
    100: '#4c8d3e',
    200: '#68c056',
    300: '#8cea76',
  },
  amber: {
    '900': '#fff4e4',
    '800': '#ffe4bb',
    '700': '#ffd28f',
    '400': '#ffc061',
    '600': '#ffb140',
    '500': '#ffa42b',
    '300': '#fe9828',
    '200': '#f88824',
    '100': '#f17921',
    '50': '#e8601b',
  },
  link: '#91cfff',
  modalOverlay: '#88888888',
  threshold: {
    critical: '#cc5411',
    warning: '#ff9c28',
    normal: '#68c056',
  },
  white: '#FDFDFD',
};

const Context = createContext<Palette>({
  mode: 'light',
  appMode: 'light',
  systemMode: Appearance.getColorScheme(),
  setAppMode: () => {},
  ...lightPalette,
});

const defaultAppMode: Palette['appMode'] = 'system';

export const PaletteProvider: React.FC = ({children}) => {
  const systemMode = useColorScheme();

  const [appMode, _setAppMode] = useState<Palette['appMode']>(defaultAppMode);

  const mode = useMemo((): Palette['mode'] => {
    if (appMode === 'dark') {
      return 'dark';
    }

    if (appMode === 'light') {
      return 'light';
    }

    if (systemMode === 'dark') {
      return 'dark';
    }

    return 'light';
  }, [systemMode, appMode]);

  useEffect(() => {
    (async () => {
      const appAppearanceMode = await readAppMode();
      setAppMode(appAppearanceMode || defaultAppMode);
    })();
  }, []);

  function setAppMode(appAppearanceMode: AppAppearanceMode) {
    _setAppMode(appAppearanceMode);
    writeAppMode(appAppearanceMode);
  }

  async function writeAppMode(appAppearanceMode: AppAppearanceMode) {
    try {
      await AsyncStorage.setItem(
        AsyncStorageKey.appAppearanceMode,
        appAppearanceMode,
      );
    } catch (e: unknown) {
      const error = Logging.parseUnknownError(e);
      Logging.recordError(error);
    }
  }

  async function readAppMode(): Promise<AppAppearanceMode | null> {
    try {
      return (await AsyncStorage.getItem(
        AsyncStorageKey.appAppearanceMode,
      )) as AppAppearanceMode | null;
    } catch (e: unknown) {
      const error = Logging.parseUnknownError(e);
      Logging.recordError(error);
      return null;
    }
  }

  return (
    <Context.Provider
      value={{
        mode,
        appMode,
        systemMode,
        setAppMode,
        ...(mode === 'light' ? lightPalette : darkPalette),
      }}>
      {children}
    </Context.Provider>
  );
};

export function usePalette() {
  return useContext(Context);
}
