import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Label from '../shared/components/common/Label';
import AuthWrapper from '../shared/components/routing/AuthWrapper';
import routePaths from '../shared/routePaths';
import {useStore} from '../shared/stores/useStore';
import {usePalette} from '../shared/theme/palette';
import {Navigate} from '../shared/utils/routing';
import {
  LogoEndAnimation,
  LogoStartAnimation,
} from '../shared/utils/useLogoAnimation';
import Support from '../views/Support';
import AssetInfoFleet from '../views/asset/AssetInfoFleet';
import AssetInfoMagentrix from '../views/asset/AssetInfoMagentrix';
import AssetType from '../views/asset/AssetType';
import SignIn from '../views/auth/SignIn';
import GatewaysData from '../views/gateway/GatewaysData';
import AdditionalHardware from '../views/installation/AdditionalHardware';
import AssetInstallationValidationScreen from '../views/installation/AssetInstallationValidationScreen';
import AssetUpdateScreen from '../views/installation/AssetUpdateScreen';
import HardwareType from '../views/installation/HardwareType';
import InstallationCaseNumber from '../views/installation/InstallationCaseNumber';
import InstallationPhotoCamera from '../views/installation/InstallationPhotoCamera';
import InstallationPhotos from '../views/installation/InstallationPhotos';
import InstallationValidation from '../views/installation/InstallationValidation';
import InstallationWorkOrder from '../views/installation/InstallationWorkOrder';
import InstallationsList from '../views/installation/InstallationsList';
import PneumaticsData from '../views/pneumatics/PneumaticsData';
import SensorsData from '../views/sensors/SensorsData';

type Props = {
  children?: undefined;
};

const AppRouter: React.FC<Props> = () => {
  const {userStore} = useStore();

  const palette = usePalette();

  const resetPaths = {
    onNotAuthenticated: routePaths.authentication.login.path,
    onAuthenticationLoss: routePaths.authentication.login.path,
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={routePaths.root.path}
          element={
            <LogoStartAnimation key={routePaths.root.path}>
              <AuthWrapper extractCode redirectPaths={resetPaths}>
                <LogoEndAnimation>
                  <InstallationsList />
                </LogoEndAnimation>
              </AuthWrapper>
            </LogoStartAnimation>
          }
        />

        <Route
          path={routePaths.authentication.login.path}
          element={
            <LogoStartAnimation key={routePaths.authentication.login.path}>
              <AuthWrapper
                redirectPaths={{
                  onAuthenticated: routePaths.root.path,
                }}>
                <LogoEndAnimation>
                  <SignIn />
                </LogoEndAnimation>
              </AuthWrapper>
            </LogoStartAnimation>
          }
        />

        <Route
          path={routePaths.installationValidation.path}
          element={
            <LogoStartAnimation key={routePaths.installationValidation.path}>
              <AuthWrapper redirectPaths={resetPaths}>
                <LogoEndAnimation>
                  <InstallationValidation />
                </LogoEndAnimation>
              </AuthWrapper>
            </LogoStartAnimation>
          }
        />

        <Route
          path={routePaths.assetInstallationValidation.path}
          element={
            <LogoStartAnimation
              key={routePaths.assetInstallationValidation.path}>
              <AuthWrapper redirectPaths={resetPaths}>
                <LogoEndAnimation>
                  {userStore.isRivataUser ? (
                    <AssetInstallationValidationScreen />
                  ) : (
                    <Navigate to={routePaths.root.path} />
                  )}
                </LogoEndAnimation>
              </AuthWrapper>
            </LogoStartAnimation>
          }
        />

        <Route
          path={routePaths.assetInfo.path}
          element={
            <LogoStartAnimation key={routePaths.assetInfo.path}>
              <AuthWrapper redirectPaths={resetPaths}>
                <LogoEndAnimation>
                  {userStore.isRivataUser ? (
                    <AssetInfoFleet />
                  ) : (
                    <AssetInfoMagentrix />
                  )}
                </LogoEndAnimation>
              </AuthWrapper>
            </LogoStartAnimation>
          }
        />

        <Route
          path={routePaths.installations.assetInfo.path}
          element={
            <LogoStartAnimation key={routePaths.installations.assetInfo.path}>
              <AuthWrapper redirectPaths={resetPaths}>
                <LogoEndAnimation>
                  {userStore.isRivataUser ? (
                    <AssetInfoFleet />
                  ) : (
                    <AssetInfoMagentrix />
                  )}
                </LogoEndAnimation>
              </AuthWrapper>
            </LogoStartAnimation>
          }
        />

        <Route
          path={routePaths.assetType.path}
          element={
            <LogoStartAnimation key={routePaths.assetType.path}>
              <AuthWrapper redirectPaths={resetPaths}>
                <LogoEndAnimation>
                  <AssetType />
                </LogoEndAnimation>
              </AuthWrapper>
            </LogoStartAnimation>
          }
        />

        <Route
          path={routePaths.assetUpdate.path}
          element={
            <LogoStartAnimation key={routePaths.assetUpdate.path}>
              <AuthWrapper redirectPaths={resetPaths}>
                <LogoEndAnimation>
                  <AssetUpdateScreen />
                </LogoEndAnimation>
              </AuthWrapper>
            </LogoStartAnimation>
          }
        />

        <Route
          path={routePaths.installationCaseNumber.path}
          element={
            <LogoStartAnimation key={routePaths.installationCaseNumber.path}>
              <AuthWrapper redirectPaths={resetPaths}>
                <LogoEndAnimation>
                  {!userStore.isRivataUser ? (
                    <InstallationCaseNumber />
                  ) : (
                    <Navigate to={routePaths.hardwareType.path} />
                  )}
                </LogoEndAnimation>
              </AuthWrapper>
            </LogoStartAnimation>
          }
        />

        <Route
          path={routePaths.hardwareType.path}
          element={
            <LogoStartAnimation key={routePaths.hardwareType.path}>
              <AuthWrapper redirectPaths={resetPaths}>
                <LogoEndAnimation>
                  <HardwareType />
                </LogoEndAnimation>
              </AuthWrapper>
            </LogoStartAnimation>
          }
        />

        <Route
          path={routePaths.gatewayData.path}
          element={
            <LogoStartAnimation key={routePaths.gatewayData.path}>
              <AuthWrapper redirectPaths={resetPaths}>
                <LogoEndAnimation>
                  <GatewaysData />
                </LogoEndAnimation>
              </AuthWrapper>
            </LogoStartAnimation>
          }
        />

        <Route
          path={routePaths.sensorsData.path}
          element={
            <LogoStartAnimation key={routePaths.sensorsData.path}>
              <AuthWrapper redirectPaths={resetPaths}>
                <LogoEndAnimation>
                  <SensorsData />
                </LogoEndAnimation>
              </AuthWrapper>
            </LogoStartAnimation>
          }
        />

        <Route
          path={routePaths.pneumaticsData.path}
          element={
            <LogoStartAnimation key={routePaths.pneumaticsData.path}>
              <AuthWrapper redirectPaths={resetPaths}>
                <LogoEndAnimation>
                  <PneumaticsData />
                </LogoEndAnimation>
              </AuthWrapper>
            </LogoStartAnimation>
          }
        />

        <Route
          path={routePaths.additionalHardware.path}
          element={
            <LogoStartAnimation key={routePaths.additionalHardware.path}>
              <AuthWrapper redirectPaths={resetPaths}>
                <LogoEndAnimation>
                  <AdditionalHardware />
                </LogoEndAnimation>
              </AuthWrapper>
            </LogoStartAnimation>
          }
        />

        <Route
          path={routePaths.installationPhotos.path}
          element={
            <LogoStartAnimation key={routePaths.installationPhotos.path}>
              <AuthWrapper redirectPaths={resetPaths}>
                <LogoEndAnimation>
                  <InstallationPhotos />
                </LogoEndAnimation>
              </AuthWrapper>
            </LogoStartAnimation>
          }
        />

        <Route
          path={routePaths.installationPhotoCamera.path}
          element={
            <LogoStartAnimation key={routePaths.installationPhotoCamera.path}>
              <AuthWrapper redirectPaths={resetPaths}>
                <LogoEndAnimation>
                  <InstallationPhotoCamera />
                </LogoEndAnimation>
              </AuthWrapper>
            </LogoStartAnimation>
          }
        />

        <Route
          path={routePaths.installationWorkOrder.path}
          element={
            <LogoStartAnimation key={routePaths.installationWorkOrder.path}>
              <AuthWrapper redirectPaths={resetPaths}>
                <LogoEndAnimation>
                  <InstallationWorkOrder />
                </LogoEndAnimation>
              </AuthWrapper>
            </LogoStartAnimation>
          }
        />

        <Route path={routePaths.support.path} element={<Support />} />

        <Route
          path="*"
          element={
            <View
              style={{
                flexGrow: 1,
                flexShrink: 1,
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: palette.surface[0],
              }}>
              <Label variant="H3" color={palette.text[1000]}>
                Page not found
              </Label>
            </View>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default observer(AppRouter);
