class BackgroundTimer {
  static interval: NodeJS.Timer | null = null;

  static setInterval(callback: () => void, delay: number) {
    this.clearInterval();
    this.interval = setInterval(callback, delay);
  }

  static clearInterval() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
}

export default BackgroundTimer;
