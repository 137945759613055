import React from "react";
import { ListGroup } from "react-bootstrap";
import "./SupportListGroupItem.scss";

function SupportListGroupItem({ icon, primaryLabel, secondaryLabel, href }) {
  return (
    <ListGroup.Item className="support-list-group-item" action href={href}>
      <div className="icon-container">{icon}</div>

      <div className="labels-container">
        <div className="label primary">{primaryLabel}</div>
        <div className="label secondary">{secondaryLabel}</div>
      </div>
    </ListGroup.Item>
  );
}

export default SupportListGroupItem;
