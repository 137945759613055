import React from 'react';
import {StyleProp, View, ViewStyle} from 'react-native';
import {breakpoints, useBreakpoints} from '../../theme/breakpoints';

type Props = {
  maxWidth?: number;
  style?: StyleProp<ViewStyle>;
};

const Container: React.FC<Props> = props => {
  const {maxWidth, style, children} = props;

  const {up} = useBreakpoints();

  return (
    <View
      style={[
        {
          justifyContent: 'center',
          alignItems: 'center',
        },
      ]}>
      <View
        style={[
          {
            width: '100%',

            ...(up('sm') && {
              maxWidth: breakpoints.sm,
            }),

            ...(up('md') && {
              maxWidth: breakpoints.md,
            }),

            ...(maxWidth !== undefined && {
              maxWidth: maxWidth,
            }),
          },
          style,
        ]}>
        {children}
      </View>
    </View>
  );
};

export default Container;
