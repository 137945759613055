import React, {ReactNode, createContext, useContext} from 'react';

type BreakpointsProviderProps = {
  children: ReactNode;
};

export type ColorsProviderValue = typeof colors;

const colors = {
  transparent: 'transparent',
  grayscale: {
    richBlack: '#1A1A1A',
    900: '#444545',
    800: '#727373',
    700: '#727373',
    600: '#888A8A',
    500: '#9FA1A1',
    400: '#B6B8B8',
    300: '#CCCECF',
    200: '#E3E5E5',
    100: '#F5F7F7',
    50: '#FAFCFC',
    white: '#FDFDFD',
  },
  blue: {
    link: '#2D7AE1',
    dark: '#003349',
    medium: '#007396',
    700: '#4D98AC',
    600: '#3CB1C8',
    500: '#5DBDDA',
    400: '#60C7E2',
    300: '#70D1EA',
    200: '#92DEF2',
    100: '#BBEBF8',
  },
  error: {
    300: '#BF4141',
    200: '#EB4242',
    100: '#FA6B6B',
  },
};

const ColorsContext = createContext<ColorsProviderValue>(colors);

export const ColorsProvider = ({children}: BreakpointsProviderProps) => {
  return (
    <ColorsContext.Provider value={colors}>{children}</ColorsContext.Provider>
  );
};

export function useColors() {
  return useContext(ColorsContext);
}

export const COLORS = {
  white: '#FFFFFF',
  black: '#212529',
  blue: '#71C7D9',
  navbarBlue: '#F8F9FA',
  navbarBlueLabel: '#71C7D9',
  gray: '#6C757D',
  lightGray: '#E8E4E4',
  green: '#28A745',
  red: '#D31145',
  darkGreen: '#155724',
  yellow: '#FFC107',
  blueCardLabel: '#3B6771',
  blueCardBorder: '#D7EFF4',
  blueCard: '#E3F4F7',
  greenCardLabel: '#155724',
  greenCardBorder: '#C3E6CB',
  greenCard: '#D4EDDA',
  yellowCardLabel: '#856404',
  yellowCardBorder: '#FFEEBA',
  yellowCard: '#FFF3CD',
  redCardLabel: '#6E0924',
  redCardBorder: '#F3BCCB',
  redCard: '#F6CFDA',
  grayCardLabel: '#495057',
  grayCardBorder: '#CED4DA',
  grayCard: '#E9ECEF',
};
