import {useFormikContext} from 'formik';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useState, useMemo, Fragment} from 'react';
import {Alert, Spinner} from 'react-bootstrap';
import * as Yup from 'yup';
import InputField from '../../common/formik/InputField';
import FormWrapper from '../../components/FormWrapper';
import AssetStore from '../../stores/AssetStore';
import InstallationStore from '../../stores/InstallationStore';
import InstallationProceedWarningModal from './InstallationProceedWarningModal';
import InstallationWarningModal from './InstallationWarningModal';
import {nhtsaError} from '../../utils/Constants';
import useNavigationState from '../../hooks/useNavigationState';
import ButtonWithSpinner from '../../components/ButtonWithSpinner';
import GatewaysStore from '../../stores/GatewaysStore';
import {useStore} from '../../shared/stores/useStore';
import Logging from '../../shared/services/logging';
import Label from '../../shared/components/common/Label';
import Container from '../../shared/components/common/Container';
import {SafeAreaView} from 'react-native-safe-area-context';
import {ScrollView, StyleSheet, View} from 'react-native';
import InstallationTopAppBar from '../../components/InstallationTopAppBar';
import {useScale} from '../../shared/theme/scale';
import {usePalette} from '../../shared/theme/palette';
import {ResponsiveStyle, useStyles} from '../../shared/theme/styles';
import Footer from '../../shared/components/navigation/Footer';
import locales from '../../shared/locales/en';
import {useNavigate, useParams} from '../../shared/utils/routing';

function AssetInfo() {
  const {apiClient} = useStore();

  const [error, setError] = useState<string | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  const [isProceeding, setIsProceeding] = useState(false);

  const navigate = useNavigate();

  const {getScreen} = useNavigationState();

  const urlParams = useParams();

  const styles = useStyles(createStyles);

  const {moderateScale, moderateScaleV} = useScale();

  const palette = usePalette();

  const [renderPage, setRenderPage] = useState(false);

  const [renderPageError, setRenderPageError] = useState<string | null>(null);

  useEffect(() => {
    AssetStore.asset.isOdometerInMiles = true;
  }, []);

  useEffect(() => {
    onPageOpened();
  }, [urlParams]);

  async function onPageOpened() {
    if (!urlParams?.installationId) {
      setRenderPage(true);

      return;
    }

    const installationId = parseInt(urlParams?.installationId);

    if (isNaN(installationId)) {
      setRenderPageError('Failed to continue installation');
      setRenderPage(true);

      return;
    }

    if (
      installationId &&
      InstallationStore.installation.id !== installationId
    ) {
      try {
        const installation = await apiClient.installation.get(installationId);

        if (!installation) throw new Error('Installation not exist');

        InstallationStore.apply(installation);
        InstallationStore.installationContinuation = true;
      } catch (e) {
        const error = Logging.parseUnknownError(e);
        Logging.recordError(error);

        setRenderPageError(error.message);
      }

      setRenderPage(true);

      return;
    }

    setRenderPage(true);
  }

  const validations = {
    vin: Yup.string().required('Required'),
    odometer: Yup.number().typeError('Odometer field must be a number'),
  };

  const fetchVin = async (values: any) => {
    try {
      setIsLoading(true);
      setError(null);

      InstallationStore.clear();

      const vin = values.vin.trim().toUpperCase();
      const asset = await apiClient.asset.getAsset(vin, true);

      AssetStore.set(asset);
    } catch (e: unknown) {
      const _error = Logging.parseUnknownError(e);
      Logging.recordError(_error);
      setError(_error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const updateOdometer = async () => {
    if (!AssetStore.asset.newOdometer || isNaN(AssetStore.asset.newOdometer))
      return;

    const data = {
      odometer: (AssetStore.asset.newOdometer as any).trim(),
      isOdometerInMiles: AssetStore.asset.isOdometerInMiles,
    };

    AssetStore.asset.odometer = AssetStore.asset.newOdometer;

    await apiClient.asset.setOdometer(AssetStore.asset.id, data);
  };

  const proceedInstallation = async (getForcedNew?: boolean) => {
    await updateOdometer();

    try {
      const i = await apiClient.asset.getInstallation(
        AssetStore.asset.id,
        getForcedNew,
      );
      InstallationStore.apply(i);
      await GatewaysStore.validate(i.asset.vin, apiClient.gateway);
    } catch (e: unknown) {
      const _error = Logging.parseUnknownError(e);
      Logging.recordError(_error);
      setError(_error.message);
      return;
    }

    navigate(getScreen('assetInfo.proceed'));
  };

  const [showModal, setShowModal] = useState(false);
  const [showProceedWarning, setShowProceedWarning] = useState(false);
  const [startedAt, setStartedAt] = useState(false);

  const proceedText = useMemo(() => {
    if (AssetStore.isInvalid && AssetStore.nhtsaRequestTimeout)
      return 'Proceed';

    return AssetStore.isInvalid ? 'Proceed without valid VIN' : locales.submit;
  }, [AssetStore.isInvalid, AssetStore.nhtsaRequestTimeout]);

  async function checkInstallationState() {
    setIsLoading(true);
    setIsProceeding(true);

    const activeInstallationStartedByAnotherUser =
      await InstallationStore.findActiveInstallationStartedByAnotherUser(
        AssetStore.asset.vin,
        apiClient.installation,
      );

    if (activeInstallationStartedByAnotherUser) {
      setStartedAt(activeInstallationStartedByAnotherUser.startedAt);
      setShowModal(true);
      setIsLoading(false);
      setIsProceeding(false);
      return;
    }

    if (InstallationStore.installation.id) {
      await proceedInstallation();
      setIsLoading(false);
      setIsProceeding(false);
      return;
    }

    const activeInstallationStartedByCurrentUser =
      await InstallationStore.findActiveInstallationStartedByCurrentUser(
        AssetStore.asset.vin,
        apiClient.installation,
      );

    if (activeInstallationStartedByCurrentUser) {
      setShowProceedWarning(true);
      setIsLoading(false);
      setIsProceeding(false);
      return;
    }

    await proceedInstallation(true);

    setIsLoading(false);
    setIsProceeding(false);
  }

  if (!renderPage)
    return (
      <View style={styles.screen}>
        <InstallationTopAppBar />
        <SafeAreaView
          mode="padding"
          edges={['left', 'right']}
          style={styles.bodyContainer}>
          <ScrollView
            keyboardShouldPersistTaps="handled"
            contentContainerStyle={{
              flexGrow: 1,
              padding: moderateScale(16),
            }}>
            <Container
              style={{
                flexGrow: 1,
                flexShrink: 1,
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Spinner animation="border" />
            </Container>
          </ScrollView>
        </SafeAreaView>

        <Footer />
      </View>
    );

  if (renderPageError)
    return (
      <View style={styles.screen}>
        <InstallationTopAppBar />
        <SafeAreaView
          mode="padding"
          edges={['left', 'right']}
          style={styles.bodyContainer}>
          <ScrollView
            keyboardShouldPersistTaps="handled"
            contentContainerStyle={{
              flexGrow: 1,
              padding: moderateScale(16),
            }}>
            <Container
              style={{
                flexGrow: 1,
                flexShrink: 1,
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Label variant="L1">{renderPageError}</Label>

              <div className={'d-flex mt-3'}>
                <ButtonWithSpinner
                  disabled={isLoading}
                  onClick={() => {
                    InstallationStore.clear();
                    navigate('/');
                  }}
                  className="flex-fill"
                  variant={undefined}
                  type={undefined}
                  isLoading={undefined}
                  block={undefined}
                  style={undefined}>
                  <i className="fas fa-arrow-circle-left mr-2" />
                  {locales.goBack}
                </ButtonWithSpinner>
              </div>
            </Container>
          </ScrollView>
        </SafeAreaView>

        <Footer />
      </View>
    );

  return (
    <Fragment>
      <InstallationWarningModal
        showModal={showModal}
        onClose={() => setShowModal(false)}
        startedAt={startedAt}
        onProceed={async () => {
          setIsLoading(true);
          setIsProceeding(true);

          setShowModal(false);
          await proceedInstallation();

          setIsLoading(false);
          setIsProceeding(false);
        }}
      />

      <InstallationProceedWarningModal
        showModal={showProceedWarning}
        onClose={() => {
          setShowProceedWarning(false);
        }}
        onStartOver={async () => {
          setIsLoading(true);
          setIsProceeding(true);

          setShowProceedWarning(false);
          await proceedInstallation(true);

          setIsLoading(false);
          setIsProceeding(false);
        }}
        onContinue={async () => {
          setIsLoading(true);
          setIsProceeding(true);

          setShowProceedWarning(false);
          await proceedInstallation();

          setIsLoading(false);
          setIsProceeding(false);
        }}
      />

      <View style={styles.screen}>
        <InstallationTopAppBar />

        <SafeAreaView
          mode="padding"
          edges={['left', 'right']}
          style={styles.bodyContainer}>
          <ScrollView
            keyboardShouldPersistTaps="handled"
            contentContainerStyle={{
              flexGrow: 1,
              padding: moderateScale(16),
            }}>
            <Container
              style={{
                flexGrow: 1,
                flexShrink: 1,
              }}>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                  marginTop: moderateScaleV(12),
                }}>
                <Label
                  variant="H1"
                  color={palette.text[1000]}
                  style={{
                    marginBottom: moderateScaleV(24),
                  }}>
                  Select Asset
                </Label>
              </View>

              <FormWrapper
                validations={validations}
                value={{
                  vin: AssetStore.asset.vin || '',
                  assetName: AssetStore.asset.name || '',
                  odometer: '',
                  isOdometerInMiles: true,
                }}
                onSubmit={fetchVin}
                form={<Form isDisabled={isLoading} />}
                buttonText="Lookup VIN"
                onLoading={setIsLoading}
                isDisabled={isLoading}
                withoutButton={InstallationStore.installation.id}
                innerRef={undefined}
                blockButton={undefined}
              />

              {error && (
                <Alert className="mt-3" variant="danger">
                  {error}
                </Alert>
              )}

              {AssetStore.isVinFound && (
                <View style={{marginTop: moderateScaleV(24)}}>
                  {!AssetStore.isInvalid && (
                    <Fragment>
                      <Label variant="L3" color={palette.text[900]}>
                        {`${AssetStore.assetTypeName} Model Year`}
                        {': '}
                        <Label variant="P1_bold" color={palette.text[900]}>
                          {AssetStore.asset.modelyear}
                        </Label>
                      </Label>

                      <Label
                        variant="L3"
                        color={palette.text[900]}
                        style={{marginTop: moderateScaleV(8)}}>
                        {`${AssetStore.assetTypeName} Make`}
                        {': '}
                        <Label variant="P1_bold" color={palette.text[900]}>
                          {AssetStore.asset.make}
                        </Label>
                      </Label>

                      <Label
                        variant="L3"
                        color={palette.text[900]}
                        style={{marginTop: moderateScaleV(8)}}>
                        {`${AssetStore.assetTypeName} Model`}
                        {': '}
                        <Label variant="P1_bold" color={palette.text[900]}>
                          {AssetStore.asset.model}
                        </Label>
                      </Label>
                    </Fragment>
                  )}

                  <Label
                    variant="L3"
                    color={palette.text[900]}
                    style={{marginTop: moderateScaleV(8)}}>
                    Asset Name
                    {': '}
                    <Label variant="P1_bold" color={palette.text[900]}>
                      {AssetStore.asset.name}
                    </Label>
                  </Label>

                  <Alert className="mt-3" variant="warning">
                    {AssetStore.nhtsaRequestTimeout
                      ? nhtsaError.timeout
                      : nhtsaError.invalidVin}
                  </Alert>
                </View>
              )}

              {AssetStore.isVinFound && (
                <Alert className="mt-3" variant="warning">
                  {locales.gatewayReminderWarning}
                </Alert>
              )}

              <div className={'d-flex mt-3'}>
                <ButtonWithSpinner
                  disabled={isLoading}
                  onClick={() => {
                    InstallationStore.clear();
                    navigate('/');
                  }}
                  className="flex-fill"
                  variant={undefined}
                  type={undefined}
                  isLoading={undefined}
                  block={undefined}
                  style={undefined}>
                  <i className="fas fa-arrow-circle-left mr-2" />
                  {locales.goBack}
                </ButtonWithSpinner>

                {AssetStore.isVinFound && (
                  <ButtonWithSpinner
                    disabled={isLoading}
                    isLoading={isProceeding}
                    onClick={checkInstallationState}
                    className="flex-fill ml-3"
                    variant={undefined}
                    type={undefined}
                    block={undefined}
                    style={undefined}>
                    {proceedText}
                    <i className="fas fa-arrow-circle-right ml-2" />
                  </ButtonWithSpinner>
                )}
              </div>
            </Container>
          </ScrollView>
        </SafeAreaView>

        <Footer />
      </View>
    </Fragment>
  );
}

const Form = observer(({isDisabled}: {isDisabled: boolean}) => {
  const {values} = useFormikContext();

  return (
    <>
      <InputField
        label="VIN"
        value={(values as any)?.vin}
        size="lg"
        name="vin"
        placeholder="ex. 1H4D02929GF114703"
        disabled={InstallationStore.installation.id || isDisabled}
        className={undefined}
        type={undefined}
        min={undefined}
        max={undefined}
      />
    </>
  );
});

const createStyles = ({palette}: ResponsiveStyle) =>
  StyleSheet.create({
    screen: {
      flexGrow: 1,
      flexShrink: 1,
      backgroundColor: palette.surface[50],
    },
    headerDivider: {
      borderBottomWidth: 1,
      borderBottomColor: palette.surface[200],
    },
    bodyContainer: {
      flexGrow: 1,
      flexShrink: 1,
    },
  });

export default observer(AssetInfo);
