import React, {ReactNode, createContext, useContext, useMemo} from 'react';
import {Dimensions, useWindowDimensions} from 'react-native';

export type BreakpointKey = keyof typeof breakpoints;

export type BreakpointsProviderValue = {
  currentBreakpointKey: BreakpointKey | null;
  up: (breakpoint: BreakpointKey) => boolean;
  down: (breakpoint: BreakpointKey) => boolean;
  between: (
    startBreakpoint: BreakpointKey,
    endBreakpoint: BreakpointKey,
  ) => boolean;
};

type BreakpointsProviderProps = {
  children: ReactNode;
};

function createBreakpoints<T extends Record<string, number>>(
  breakpoints: T,
): T {
  return breakpoints;
}

// https://m2.material.io/design/layout/responsive-layout-grid.html#breakpoints
export const breakpoints = createBreakpoints({
  xs: 0,
  sm: 600,
  md: 905,
  lg: 1240,
  xl: 1440,
});

// [905; 1240) - md

function getCurrentBreakpoint(windowWidth: number) {
  const keys = Object.keys(breakpoints) as BreakpointKey[];

  let closestKey: BreakpointKey | null = null;

  keys.forEach(key => {
    const breakpointValue = breakpoints[key];

    if (closestKey !== null) {
      if (
        breakpointValue <= windowWidth &&
        breakpointValue > breakpoints[closestKey]
      ) {
        closestKey = key;
      }
    } else {
      closestKey = key;
    }
  });

  return closestKey as BreakpointsProviderValue['currentBreakpointKey'];
}

function createDefaultBreakpoints(
  windowWidth: number,
): BreakpointsProviderValue {
  const currentBreakpointKey = getCurrentBreakpoint(windowWidth);

  function up(breakpoint: BreakpointKey) {
    return windowWidth >= breakpoints[breakpoint];
  }

  function down(breakpoint: BreakpointKey) {
    return windowWidth < breakpoints[breakpoint];
  }

  function between(
    startBreakpoint: BreakpointKey,
    endBreakpoint: BreakpointKey,
  ) {
    return (
      windowWidth >= breakpoints[startBreakpoint] &&
      windowWidth < breakpoints[endBreakpoint]
    );
  }

  return {
    currentBreakpointKey,
    up,
    down,
    between,
  };
}

const BreakpointsContext = createContext<BreakpointsProviderValue>(
  createDefaultBreakpoints(Dimensions.get('window').width),
);

export const BreakpointsProvider = ({children}: BreakpointsProviderProps) => {
  const {width: windowWidth} = useWindowDimensions();

  const currentBreakpointKey = useMemo(
    () => getCurrentBreakpoint(windowWidth),
    [windowWidth],
  );

  function up(breakpoint: BreakpointKey) {
    return windowWidth >= breakpoints[breakpoint];
  }

  function down(breakpoint: BreakpointKey) {
    return windowWidth < breakpoints[breakpoint];
  }

  function between(
    startBreakpoint: BreakpointKey,
    endBreakpoint: BreakpointKey,
  ) {
    return (
      windowWidth >= breakpoints[startBreakpoint] &&
      windowWidth < breakpoints[endBreakpoint]
    );
  }

  const value: BreakpointsProviderValue = useMemo(() => {
    return {
      currentBreakpointKey,
      up,
      down,
      between,
    };
  }, [currentBreakpointKey]);

  return (
    <BreakpointsContext.Provider value={value}>
      {children}
    </BreakpointsContext.Provider>
  );
};

export function useBreakpoints() {
  return useContext(BreakpointsContext);
}
