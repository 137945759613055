import React from 'react';
import {useScale} from '../../theme/scale';
import {XCircle} from '../iconsExporter';

interface Props {
  width?: number;
  height?: number;
}

const XCircleIcon = ({width = 24, height = 24}: Props) => {
  const {moderateScale} = useScale();

  return (
    <XCircle width={moderateScale(width)} height={moderateScale(height)} />
  );
};

export default XCircleIcon;
