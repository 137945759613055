export enum ProvisionSource {
  web = 1,
  native = 2,
}

export enum AssetType {
  trailer = 1,
  tractor = 2,
}

export enum AtiInstalled {
  no = 0,
  yes = 1,
  notSure = 2,
}

export enum AssetSubtypeTrailer {
  DOLLY = 'dolly',
  DRY_VAN_TRAILER = 'dry_van_trailer',
  REFRIGERATED_TRAILER = 'refrigerated_trailer',
  FLATBED_TRAILER = 'flatbed_trailer',
  LOWBODY_TRAILER = 'lowboy_trailer',
  DROP_DECK_TRAILER = 'drop_deck_trailer',
  TANKER_TRAILER = 'tanker_trailer',
  CAR_CARRIER_TRAILER = 'car_carrier_trailer',
  BULK_TRAILER = 'bulk_trailer',
  CURTAIN_SIDE_TRAILER = 'curtain_side_trailer',
  LIVESTOCK_TRAILER = 'livestock_trailer',
  INTERMODAL_CONTAINTER_TRAILER = 'intermodal_container_trailer',
  EXTENDABLE_DOUBLE_DROP_TRAILER = 'extendable_double_drop_trailer',
  POLE_TRAILER = 'pole_trailer',
  LOGGING_TRAILER = 'logging_trailer',
  HOPPER_BOTTOM_TRAILER = 'hopper_bottom_trailer',
  SPECIALIZED_HEAVY_HAUL_TRAILER = 'specialized_heavy_haul_trailer',
  OTHER_TRAILER = 'other_trailer',
}

export enum AssetSubtypeTractor {
  SLEEPER_CAB = 'sleeper_cab',
  DAY_CAB = 'day_cab',
  OTHER_TRACTOR = 'other_tractor',
}

const HyundaiTransleadVinInitialsDefaults = {
  validTypes: ['C', 'V', 'J', 'F', 'P', 'A'],
  mapping: {
    C: AssetSubtypeTrailer.INTERMODAL_CONTAINTER_TRAILER,
    V: AssetSubtypeTrailer.DRY_VAN_TRAILER,
    J: AssetSubtypeTrailer.DOLLY,
    F: AssetSubtypeTrailer.FLATBED_TRAILER,
    P: AssetSubtypeTrailer.OTHER_TRAILER,
    A: AssetSubtypeTrailer.CAR_CARRIER_TRAILER,
  },
  indexOfTrailerType: 3,
};

export const ManufacturerTrailerTypeMapping = {
  knownManufacturers: [
    '1GD', //Great Dane Trailers,
    '1UY', //Utility Trailer Manufacturer ,
    '3H3', //Hyundai Translead
    '3H4', //Hyundai Translead
    '3H5', //Hyundai Translead
    '3H6', //Hyundai Translead
    '3H7', //Hyundai Translead
    '3H8', //Hyundai Translead
    '1JJ', //Wabash National,
    '1DW', //Stoughton
  ],
  manufacturersData: {
    '1GD': {
      validTypes: ['1', '3', '4', '5', '6', 'A', 'D', 'E', 'L', 'C'],
      mapping: {
        1: AssetSubtypeTrailer.DRY_VAN_TRAILER,
        3: AssetSubtypeTrailer.INTERMODAL_CONTAINTER_TRAILER,
        4: AssetSubtypeTrailer.FLATBED_TRAILER,
        5: AssetSubtypeTrailer.DOLLY,
        6: AssetSubtypeTrailer.LOWBODY_TRAILER,
        A: AssetSubtypeTrailer.DRY_VAN_TRAILER,
        D: AssetSubtypeTrailer.FLATBED_TRAILER,
        E: AssetSubtypeTrailer.DOLLY,
        L: AssetSubtypeTrailer.LOWBODY_TRAILER,
        C: AssetSubtypeTrailer.INTERMODAL_CONTAINTER_TRAILER,
      },
      indexOfTrailerType: 3,
    },
    '1UY': {
      validTypes: ['V', 'F', 'T'],
      mapping: {
        V: AssetSubtypeTrailer.DRY_VAN_TRAILER,
        F: AssetSubtypeTrailer.FLATBED_TRAILER,
        T: AssetSubtypeTrailer.CURTAIN_SIDE_TRAILER,
      },
      indexOfTrailerType: 3,
    },
    '1JJ': {
      validTypes: ['V', 'F', 'C', 'U', 'D', 'T', 'E', 'G', 'D'],
      mapping: {
        V: AssetSubtypeTrailer.DRY_VAN_TRAILER,
        F: AssetSubtypeTrailer.FLATBED_TRAILER,
        C: AssetSubtypeTrailer.INTERMODAL_CONTAINTER_TRAILER,
        U: AssetSubtypeTrailer.OTHER_TRAILER,
        T: AssetSubtypeTrailer.TANKER_TRAILER,
        E: AssetSubtypeTrailer.OTHER_TRAILER,
        G: AssetSubtypeTrailer.FLATBED_TRAILER,
        D: AssetSubtypeTrailer.OTHER_TRAILER,
      },
      indexOfTrailerType: 3,
    },
    '1DW': {
      validTypes: ['A', 'B', 'C', 'U'],
      mapping: {
        A: AssetSubtypeTrailer.DRY_VAN_TRAILER,
        B: AssetSubtypeTrailer.FLATBED_TRAILER,
        C: AssetSubtypeTrailer.DOLLY,
        U: AssetSubtypeTrailer.INTERMODAL_CONTAINTER_TRAILER,
      },
      indexOfTrailerType: 4,
    },
    '3H3': HyundaiTransleadVinInitialsDefaults,
    '3H4': HyundaiTransleadVinInitialsDefaults,
    '3H5': HyundaiTransleadVinInitialsDefaults,
    '3H6': HyundaiTransleadVinInitialsDefaults,
    '3H7': HyundaiTransleadVinInitialsDefaults,
    '3H8': HyundaiTransleadVinInitialsDefaults,
  },
};

export enum NthsaTrailerSubtypeMap {
  'Van Trailer' = AssetSubtypeTrailer.DRY_VAN_TRAILER,
  'Refrigerated Trailer' = AssetSubtypeTrailer.REFRIGERATED_TRAILER,
  'Flatbed - Double Drop' = AssetSubtypeTrailer.FLATBED_TRAILER,
  'Flatbed - Single Drop' = AssetSubtypeTrailer.FLATBED_TRAILER,
  'Flatbed - Extendable' = AssetSubtypeTrailer.FLATBED_TRAILER,
  'Flatbed - A Train' = AssetSubtypeTrailer.FLATBED_TRAILER,
  'Flatbed - B Train' = AssetSubtypeTrailer.FLATBED_TRAILER,
  'Flatbed - Flip Axle' = AssetSubtypeTrailer.FLATBED_TRAILER,
  'Flatbed - Tilt' = AssetSubtypeTrailer.FLATBED_TRAILER,
  'Lowboy/Low bed Trailer' = AssetSubtypeTrailer.LOWBODY_TRAILER,
  'Drop Deck Trailer' = AssetSubtypeTrailer.DROP_DECK_TRAILER,
  'Tank Trailer' = AssetSubtypeTrailer.TANKER_TRAILER,
  'Car Hauler Trailer' = AssetSubtypeTrailer.CAR_CARRIER_TRAILER,
  'Bulk' = AssetSubtypeTrailer.BULK_TRAILER,
  'Dry Bulk Trailer' = AssetSubtypeTrailer.BULK_TRAILER,
  'Curtain Side Trailer' = AssetSubtypeTrailer.CURTAIN_SIDE_TRAILER,
  'Livestock Trailer' = AssetSubtypeTrailer.LIVESTOCK_TRAILER,
  'Livestock - Cattle Trailer' = AssetSubtypeTrailer.LIVESTOCK_TRAILER,
  'Livestock - Horse Trailer' = AssetSubtypeTrailer.LIVESTOCK_TRAILER,
  'Container Chassis/Container Trailer' = AssetSubtypeTrailer.INTERMODAL_CONTAINTER_TRAILER,
  'Pole Trailer' = AssetSubtypeTrailer.POLE_TRAILER,
  'Logging Trailer' = AssetSubtypeTrailer.LOGGING_TRAILER,
  'Hopper' = AssetSubtypeTrailer.HOPPER_BOTTOM_TRAILER,
  'Other' = AssetSubtypeTrailer.OTHER_TRAILER,
}

export enum OverlayModalKey {
  CUSTOMER_SELECT = 'CUSTOMER_SELECT',
  POPOVER = 'POPOVER',
  ASSET_CREATE = 'ASSET_CREATE',
  ASSET_CREATED = 'ASSET_CREATED',
  START_INSTALLATION_LOGO = 'START_INSTALLATION_LOGO',
  INSTALLATION_STARTED_BY_ANOTHER_USER = 'INSTALLATION_STARTED_BY_ANOTHER_USER',
  INSTALLATION_IN_PROGRESS = 'INSTALLATION_IN_PROGRESS',
  SIDEBAR = 'SIDEBAR',
  APP_UPDATE = 'APP_UPDATE',
}

export enum Permissions {
  installerAppLogin = 'INSTALLER_APP_LOGIN',
  installerAppCreateAsset = 'INSTALLER_APP_CREATE_ASSET',
}

export enum AsyncStorageKey {
  environment = 'environment',
  refreshToken = 'refreshToken',
  isRivataUser = 'isRivataUser',
  privacyPocilyModifiedTimestamp = 'privacyPocilyModifiedTimestamp',
  appAppearanceMode = 'appAppearanceMode',
}
