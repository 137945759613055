import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";

import QrScanner from "qr-scanner";
QrScanner.WORKER_PATH = "qr-scanner/qr-scanner-worker.min.js";

let lastScanned = null;

const SerialScanner = ({ onScan }) => {
  const [qrScanner, setQrScanner] = useState(null);
  const [hasFlash, setHasFlash] = useState(false);
  const videoElementRef = useRef(null);

  const handleScan = (data, manuallyEntered) => {
    if (data === lastScanned) return;

    lastScanned = data;
    onScan(data, manuallyEntered);
  };

  const toggleFlash = async () => {
    qrScanner.toggleFlash();
  };

  useEffect(() => {
    if (!videoElementRef) return;

    setQrScanner(new QrScanner(videoElementRef.current, handleScan, undefined, undefined, "environment"));
  }, [videoElementRef]);

  useEffect(() => {
    if (!qrScanner) return;

    const startCamera = async () => {
      qrScanner.setInversionMode("both");

      await qrScanner.start();

      setHasFlash(await qrScanner.hasFlash());

      lastScanned = null;
    };

    const stopCamera = async () => {
      if (qrScanner.isFlashOn()) await qrScanner.turnFlashOff();

      qrScanner.destroy();

      setQrScanner(null);
    };

    startCamera();

    return () => stopCamera();
  }, [qrScanner]);

  return (
    <div className="text-center mt-3">
      <div className="mb-2">SCAN QR CODE</div>

      <div className="d-flex justify-content-center">
        <video style={{ width: "100%" }} ref={videoElementRef}></video>
      </div>

      {hasFlash && (
        <Button className="mt-3" block onClick={toggleFlash}>
          <i className="fas fa-bolt"></i>
        </Button>
      )}
    </div>
  );
};

export default SerialScanner;
