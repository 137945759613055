import React from "react";
import { Dropdown, DropdownButton, FormControl, FormGroup, InputGroup } from "react-bootstrap";
import { units } from "../../utils/Constants";

const OdometerInput = ({
  placeholder = "ex. 100000",
  odometerValue = "",
  distanceUnit = units.distance.miles,
  isDisabled = false,
  error = "",
  onChangeOdometerValue = () => {},
  onChangeDistanceUnit = () => {},
  className = "",
}) => {
  const hasError = !!error;

  return (
    <FormGroup className={className}>
      <InputGroup>
        <FormControl
          size="lg"
          disabled={isDisabled}
          placeholder={placeholder}
          value={odometerValue}
          isInvalid={hasError}
          onChange={onChangeOdometerValue}
        />

        <DropdownButton as={InputGroup.Append} title={distanceUnit === units.distance.miles ? "MI" : "KM"} disabled={isDisabled}>
          <Dropdown.Item onSelect={() => onChangeDistanceUnit(units.distance.miles)}>MI</Dropdown.Item>
          <Dropdown.Item onSelect={() => onChangeDistanceUnit(units.distance.kilometers)}>KM</Dropdown.Item>
        </DropdownButton>

        <FormControl.Feedback type={hasError ? "invalid" : undefined}>{error}</FormControl.Feedback>
      </InputGroup>
    </FormGroup>
  );
};

export default OdometerInput;
