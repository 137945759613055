import React from 'react';

import AssetStore from '../stores/AssetStore';
import Label from '../shared/components/common/Label';
import {usePalette} from '../shared/theme/palette';

function VINLabel() {
  const palette = usePalette();

  return AssetStore && AssetStore.asset ? (
    <Label
      variant="H3"
      color={palette.text[1000]}
      style={{textAlign: 'center'}}>
      VIN: {AssetStore.asset?.vin}
    </Label>
  ) : null;
}

export default VINLabel;
