import React from 'react';
import {useScale} from '../../theme/scale';
import {Back, Forward} from '../iconsExporter';

interface Props {
  width?: number;
  height?: number;
  direction: 'back' | 'forward';
}

const NavigationIcon = ({width = 18, height = 18, direction}: Props) => {
  const {moderateScale} = useScale();

  if (direction === 'forward')
    return (
      <Forward width={moderateScale(width)} height={moderateScale(height)} />
    );

  return <Back width={moderateScale(width)} height={moderateScale(height)} />;
};

export default NavigationIcon;
