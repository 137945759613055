import {observer} from 'mobx-react-lite';
import React, {useEffect, useState, useMemo, Fragment} from 'react';
import AssetStore from '../../stores/AssetStore';
import InstallationStore from '../../stores/InstallationStore';
import InstallationProceedWarningModal from './InstallationProceedWarningModal';
import InstallationWarningModal from './InstallationWarningModal';
import CreateAssetModal from './CreateAssetModal';
import AssetSearch from './AssetSearch';
import {nhtsaError} from '../../utils/Constants';
import useNavigationState from '../../hooks/useNavigationState';
import ButtonWithSpinner from '../../components/ButtonWithSpinner';
import GatewaysStore from '../../stores/GatewaysStore';
import {useStore} from '../../shared/stores/useStore';
import Label from '../../shared/components/common/Label';
import Logging from '../../shared/services/logging';
import InstallationTopAppBar from '../../components/InstallationTopAppBar';
import {Platform, ScrollView, StyleSheet, View} from 'react-native';
import {ResponsiveStyle, useStyles} from '../../shared/theme/styles';
import {usePalette} from '../../shared/theme/palette';
import {useScale} from '../../shared/theme/scale';
import Footer from '../../shared/components/navigation/Footer';
import Container from '../../shared/components/common/Container';
import {Permissions} from '../../shared/types/enums';
import locales from '../../shared/locales/en';
import {SafeAreaView} from 'react-native-safe-area-context';
import Button from '../../shared/components/common/Button';
import Spinner from '../../shared/components/common/Spinner';
import Alert from '../../shared/components/common/Alert';
import {useNavigate, useParams} from '../../shared/utils/routing';

function AssetInfo() {
  const {userStore, apiClient} = useStore();

  const [error, setError] = useState<string | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  const [isProceeding, setIsProceeding] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [showProceedWarning, setShowProceedWarning] = useState(false);
  const [createAssetModalVisible, setCreateAssetModalVisible] = useState(false);
  const [startedAt, setStartedAt] = useState(false);
  const [filter, setFilter] = useState({
    value: AssetStore.asset.vin,
    withoutUpdate: true,
  });
  const [nothingFound, setNothingFound] = useState(false);

  const navigate = useNavigate();

  const urlParams = useParams();

  const {getScreen} = useNavigationState();

  const [renderPage, setRenderPage] = useState(false);

  const [renderPageError, setRenderPageError] = useState<string | null>(null);

  const styles = useStyles(createStyles);

  const {moderateScale, moderateScaleV} = useScale();

  const palette = usePalette();

  useEffect(() => {
    AssetStore.asset.isOdometerInMiles = true;
  }, []);

  useEffect(() => {
    onPageOpened();
  }, [urlParams]);

  async function onPageOpened() {
    if (!urlParams?.installationId) {
      setRenderPage(true);

      return;
    }

    const installationId = parseInt(urlParams?.installationId);

    if (isNaN(installationId)) {
      setRenderPageError('Failed to continue installation');
      setRenderPage(true);

      return;
    }

    if (
      installationId &&
      InstallationStore.installation.id !== installationId
    ) {
      try {
        const installation = await apiClient.installation.get(installationId);

        if (!installation) throw new Error('Installation not exist');

        InstallationStore.apply(installation);
        InstallationStore.installationContinuation = true;
        setFilter({value: AssetStore.asset.vin, withoutUpdate: true});
      } catch (e: unknown) {
        const error = Logging.parseUnknownError(e);
        Logging.recordError(error);

        setRenderPageError(error.message);
      }

      setRenderPage(true);

      return;
    }

    setRenderPage(true);
  }

  const fetchVin = async (values: any) => {
    try {
      setIsLoading(true);
      setError(null);

      InstallationStore.clear();

      const vin = values.vin.trim().toUpperCase();
      const asset = await apiClient.asset.getAsset(vin, true);

      AssetStore.set(asset);
    } catch (e: unknown) {
      const error = Logging.parseUnknownError(e);
      Logging.recordError(error);

      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const updateOdometer = async () => {
    if (!AssetStore.asset?.newOdometer || isNaN(AssetStore.asset?.newOdometer))
      return;

    const data = {
      odometer: (AssetStore.asset.newOdometer as string).trim(),
      isOdometerInMiles: AssetStore.asset.isOdometerInMiles,
    };

    AssetStore.asset.odometer = AssetStore.asset.newOdometer;

    await apiClient.asset.setOdometer(AssetStore.asset.id, data);
  };

  const proceedInstallation = async (getForcedNew?: any) => {
    await updateOdometer();

    try {
      const i = await apiClient.asset.getInstallation(
        AssetStore.asset.id,
        getForcedNew,
      );
      InstallationStore.apply(i);
      await GatewaysStore.validate(i.asset.vin, apiClient.gateway);
    } catch (e: unknown) {
      const error = Logging.parseUnknownError(e);
      Logging.recordError(error);

      setError(error.message);
      return;
    }

    navigate(getScreen('assetInfo.proceed'));
  };

  const showAssetCreateButton = useMemo(
    () => (nothingFound || !filter.value) && !InstallationStore.installation.id,
    [nothingFound, filter.value, InstallationStore.installation.id],
  );

  const proceedText = useMemo(() => {
    if (AssetStore.isInvalid && AssetStore.nhtsaRequestTimeout)
      return 'Proceed';

    return AssetStore.isInvalid ? 'Proceed without valid VIN' : locales.submit;
  }, [AssetStore.isInvalid, AssetStore.nhtsaRequestTimeout]);

  async function checkInstallationState() {
    setIsLoading(true);
    setIsProceeding(true);

    const activeInstallationStartedByAnotherUser =
      await InstallationStore.findActiveInstallationStartedByAnotherUser(
        AssetStore.asset.vin,
        apiClient.installation,
      );

    if (activeInstallationStartedByAnotherUser) {
      setStartedAt(activeInstallationStartedByAnotherUser.startedAt);
      setShowModal(true);
      setIsLoading(false);
      setIsProceeding(false);
      return;
    }

    if (InstallationStore.installation.id) {
      await proceedInstallation();
      setIsLoading(false);
      setIsProceeding(false);
      return;
    }

    const activeInstallationStartedByCurrentUser =
      await InstallationStore.findActiveInstallationStartedByCurrentUser(
        AssetStore.asset.vin,
        apiClient.installation,
      );

    if (activeInstallationStartedByCurrentUser) {
      setShowProceedWarning(true);
      setIsLoading(false);
      setIsProceeding(false);
      return;
    }

    await proceedInstallation(true);

    setIsProceeding(false);
    setIsLoading(false);
  }

  if (!renderPage)
    return (
      <View style={styles.screen}>
        <InstallationTopAppBar />
        <SafeAreaView
          mode="padding"
          edges={['left', 'right']}
          style={styles.bodyContainer}>
          <ScrollView
            keyboardShouldPersistTaps="handled"
            contentContainerStyle={{
              flexGrow: 1,
              padding: moderateScale(16),
            }}>
            <Container
              style={{
                flexGrow: 1,
                flexShrink: 1,
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Spinner fill={palette.surface[1000]} />
            </Container>
          </ScrollView>
        </SafeAreaView>

        <Footer />
      </View>
    );

  if (renderPageError)
    return (
      <View style={styles.screen}>
        <InstallationTopAppBar />
        <SafeAreaView
          mode="padding"
          edges={['left', 'right']}
          style={styles.bodyContainer}>
          <ScrollView
            keyboardShouldPersistTaps="handled"
            contentContainerStyle={{
              flexGrow: 1,
              padding: moderateScale(16),
            }}>
            <Container
              style={{
                flexGrow: 1,
                flexShrink: 1,
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Label variant="L1">{renderPageError}</Label>

              <div className={'d-flex mt-3'}>
                <ButtonWithSpinner
                  disabled={isLoading}
                  onClick={() => {
                    InstallationStore.clear();
                    navigate('/');
                  }}
                  className="flex-fill"
                  variant={undefined}
                  type={undefined}
                  isLoading={undefined}
                  block={undefined}
                  style={undefined}>
                  <i className="fas fa-arrow-circle-left mr-2" />
                  {locales.goBack}
                </ButtonWithSpinner>
              </div>
            </Container>
          </ScrollView>
        </SafeAreaView>

        <Footer />
      </View>
    );

  return (
    <Fragment>
      <InstallationWarningModal
        showModal={showModal}
        onClose={() => setShowModal(false)}
        startedAt={startedAt}
        onProceed={async () => {
          setIsLoading(true);
          setIsProceeding(true);

          setShowModal(false);
          await proceedInstallation(true);

          setIsProceeding(false);
          setIsLoading(false);
        }}
      />

      <InstallationProceedWarningModal
        showModal={showProceedWarning}
        onClose={() => {
          setShowProceedWarning(false);
        }}
        onStartOver={async () => {
          setIsLoading(true);
          setIsProceeding(true);

          setShowProceedWarning(false);
          await proceedInstallation(true);

          setIsProceeding(false);
          setIsLoading(false);
        }}
        onContinue={async () => {
          setIsLoading(true);
          setIsProceeding(true);

          setShowProceedWarning(false);
          await proceedInstallation();

          setIsProceeding(false);
          setIsLoading(false);
        }}
      />

      <CreateAssetModal
        showModal={createAssetModalVisible}
        closeModal={() => setCreateAssetModalVisible(false)}
        setFilter={setFilter}
        setNothingFound={setNothingFound}
      />

      <View style={styles.screen}>
        <InstallationTopAppBar />

        <SafeAreaView
          mode="padding"
          edges={
            Platform.OS === 'web'
              ? ['left', 'right']
              : ['left', 'right', 'bottom']
          }
          style={[
            styles.bodyContainer,
            {
              backgroundColor: palette.surface[50],
            },
          ]}>
          <ScrollView
            keyboardShouldPersistTaps="handled"
            contentContainerStyle={{
              flexGrow: 1,
              padding: moderateScale(16),
            }}>
            <Container>
              <Label
                variant="H1"
                color={palette.text[1000]}
                style={{
                  marginTop: moderateScaleV(12),
                }}>
                {locales.selectAsset}
              </Label>

              {userStore.permissions.includes(
                Permissions.installerAppCreateAsset,
              ) &&
                showAssetCreateButton && (
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      marginTop: moderateScaleV(24),
                    }}>
                    <View />

                    <Button
                      variant="filled"
                      isDisabled={isLoading}
                      text={locales.createAsset}
                      onPress={() => setCreateAssetModalVisible(prev => !prev)}
                    />
                  </View>
                )}

              <View
                style={{
                  marginTop: moderateScaleV(24),
                }}>
                <AssetSearch
                  filter={filter}
                  setFilter={setFilter}
                  nothingFound={nothingFound}
                  setNothingFound={setNothingFound}
                  disabled={
                    !!InstallationStore.installation.id ||
                    AssetStore.isNew ||
                    isLoading
                  }
                  fetchVin={fetchVin}
                />
              </View>

              {!AssetStore.asset.id && isLoading ? (
                <Spinner fill={palette.surface[1000]} />
              ) : null}

              {error && (
                <Alert
                  variant="centerAligned"
                  label={error}
                  styleVariant="error"
                  style={{marginTop: moderateScaleV(24)}}
                />
              )}

              {AssetStore.isVinFound && (
                <View style={{marginTop: moderateScaleV(24)}}>
                  {!AssetStore.isInvalid && (
                    <Fragment>
                      <Label variant="L3" color={palette.text[900]}>
                        {`${AssetStore.assetTypeName} Model Year`}
                        {': '}
                        <Label variant="P1_bold" color={palette.text[900]}>
                          {AssetStore.asset.modelyear}
                        </Label>
                      </Label>

                      <Label
                        variant="L3"
                        color={palette.text[900]}
                        style={{marginTop: moderateScaleV(8)}}>
                        {`${AssetStore.assetTypeName} Make`}
                        {': '}
                        <Label variant="P1_bold" color={palette.text[900]}>
                          {AssetStore.asset.make}
                        </Label>
                      </Label>

                      <Label
                        variant="L3"
                        color={palette.text[900]}
                        style={{marginTop: moderateScaleV(8)}}>
                        {`${AssetStore.assetTypeName} Model`}
                        {': '}
                        <Label variant="P1_bold" color={palette.text[900]}>
                          {AssetStore.asset.model}
                        </Label>
                      </Label>
                    </Fragment>
                  )}

                  <Label
                    variant="L3"
                    color={palette.text[900]}
                    style={{marginTop: moderateScaleV(8)}}>
                    Asset Name
                    {': '}
                    <Label variant="P1_bold" color={palette.text[900]}>
                      {AssetStore.asset.name}
                    </Label>
                  </Label>
                </View>
              )}

              {AssetStore.isVinFound && AssetStore.isInvalid && (
                <Alert
                  variant="centerAligned"
                  label={
                    AssetStore.nhtsaRequestTimeout
                      ? nhtsaError.timeout
                      : nhtsaError.invalidVin
                  }
                  styleVariant="warning"
                  style={{marginTop: moderateScaleV(24)}}
                />
              )}

              {AssetStore.isVinFound && (
                <Alert
                  variant="centerAligned"
                  label={locales.gatewayReminderWarning}
                  styleVariant="warning"
                  style={{marginTop: moderateScaleV(24)}}
                />
              )}

              <div className={'d-flex mt-3'}>
                <ButtonWithSpinner
                  disabled={isLoading}
                  onClick={() => {
                    InstallationStore.clear();
                    navigate('/');
                  }}
                  className="flex-fill"
                  variant={undefined}
                  type={undefined}
                  isLoading={undefined}
                  block={undefined}
                  style={undefined}>
                  <i className="fas fa-arrow-circle-left mr-2" />
                  {locales.goBack}
                </ButtonWithSpinner>

                {AssetStore.isVinFound && (
                  <ButtonWithSpinner
                    disabled={isLoading}
                    isLoading={isProceeding}
                    onClick={checkInstallationState}
                    className="flex-fill ml-3"
                    variant={undefined}
                    type={undefined}
                    block={undefined}
                    style={undefined}>
                    {proceedText}
                    <i className="fas fa-arrow-circle-right ml-2" />
                  </ButtonWithSpinner>
                )}
              </div>
            </Container>
          </ScrollView>
        </SafeAreaView>

        <Footer />
      </View>
    </Fragment>
  );
}

const createStyles = ({scale: {moderateScale}, palette}: ResponsiveStyle) =>
  StyleSheet.create({
    screen: {
      flexGrow: 1,
      flexShrink: 1,
      backgroundColor: palette.surface[50],
    },
    headerDivider: {
      borderBottomWidth: 1,
      borderBottomColor: palette.surface[200],
    },
    bodyContainer: {
      flexGrow: 1,
      flexShrink: 1,
    },
  });

export default observer(AssetInfo);
