import React, {useMemo, useState, useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {Alert, Spinner} from 'react-bootstrap';

import GatewaysStore from '../../../stores/GatewaysStore';
import SensorsStore from '../../../stores/SensorsStore';
import AssetStore from '../../../stores/AssetStore';
import InstallationStore from '../../../stores/InstallationStore';
import SerialScanner from '../../../components/uploaders/SerialScanner';
import ManualInputComponent from '../../../components/uploaders/ManualInputComponent';
import SensorSubmitBar from '../../../components/navigation/SensorSubmitBar';

import {
  parseSerial,
  prepareManuallyEnteredSerial,
  validateSerial,
  hardwareBelongsToCurrentAsset,
  findShortMacDuplicate,
  checkTpmsSensorsCount,
} from '../../../utils/Utils';
import {sensorType, sensorErrors} from '../../../utils/Constants';
import {useStore} from '../../../shared/stores/useStore';

function ErrorText({error}) {
  const emailDelimiter = error
    .toString()
    .match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi);

  if (emailDelimiter && emailDelimiter[0]) {
    const email = emailDelimiter[0];
    const emailRegExp = new RegExp(`(${email})`);

    const stringArr = error.toString().split(emailRegExp);

    return (
      <>
        {stringArr.map((str, i) => {
          if (str === email) {
            return (
              <Alert.Link key={i} href={`mailto: ${str}`}>
                {str}
              </Alert.Link>
            );
          }

          return <span key={i}>{str}</span>;
        })}
      </>
    );
  }

  return <span>{error}</span>;
}

function SensorScanner({
  selectedSensorCode,
  setSelectedSensorCode,
  showReminder = true,
  sensorTypes,
}) {
  const {apiClient} = useStore();

  const [serialNumber, setSerialNumber] = useState('');

  const sensor = useMemo(() => {
    if (!selectedSensorCode) return null;

    SensorsStore.clearUnsubmitted();

    return SensorsStore.get(selectedSensorCode);
  }, [selectedSensorCode]);

  const handleScan = async (serial, manuallyEntered) => {
    sensor.error = '';
    serial = serial.toUpperCase();

    if (manuallyEntered) {
      serial = prepareManuallyEnteredSerial(serial);
    }

    serial = parseSerial(serial);

    const isValid = validateSerial(serial);

    if (!isValid) {
      sensor.error = sensorErrors.default;
      return;
    }

    const existingEsn = SensorsStore.sensors.some(s => s.esn === serial);

    if (existingEsn) {
      sensor.error = 'ID is already in use';
      return;
    }

    if (sensor.type !== sensorType.smarthub) {
      let shortMacError = findShortMacDuplicate(
        serial,
        selectedSensorCode,
        SensorsStore.sensors.filter(s => s.type !== sensorType.smarthub),
        [
          ...AssetStore.getSensorsPositionsList(sensorType.tpms),
          ...AssetStore.getSensorsPositionsList(sensorType.axleLoad),
          ...AssetStore.getSensorsPositionsList(sensorType.linePressure),
        ],
      );

      if (shortMacError.value) {
        sensor.error = shortMacError.text;
        return;
      }
    }

    if (!sensorTypes.includes(sensorType.smarthub)) {
      const sensorsCountWarning = checkTpmsSensorsCount(
        GatewaysStore.gateways.filter(g => g.id).length,
        SensorsStore.sensors.filter(s => s.type !== sensorType.smarthub).length,
      );

      if (sensorsCountWarning) {
        sensor.error = sensorsCountWarning;
        return;
      }
    }

    await addSensor(serial);
  };

  const addSensor = async serial => {
    sensor.isLoading = true;
    sensor.warning = '';
    sensor.error = '';

    const data = {
      esn: serial,
      code: selectedSensorCode,
      type: SensorsStore.sensorsType,
      assetId: AssetStore.asset.id,
      installationId: InstallationStore.installation.id,
    };

    try {
      const result = await apiClient.sensor.upload(data);

      if (!result.id) {
        sensor.error = 'Data not uploaded, uncaught error!';
        return;
      }

      if (result.warning) {
        if (
          !hardwareBelongsToCurrentAsset(result.warning, AssetStore.asset.vin)
        ) {
          sensor.warning = result.warning;
        }
      }

      sensor.id = result.id;
      sensor.esn = serial;
      sensor.isUploaded = true;
      setSelectedSensorCode(null);
    } catch (e) {
      sensor.error = e?.error ?? 'Something went wrong';
    } finally {
      sensor.isLoading = false;
    }
  };

  useEffect(() => {
    return () => {
      SensorsStore.clearUnsubmitted();
    };
  }, []);

  return (
    <div>
      {!sensor.isLoading && (
        <>
          <SerialScanner name="SENSOR ID" onScan={handleScan} />
          <ManualInputComponent
            name="SENSOR ID"
            tooltipText="Sensor ESN and QR code can be found on product label"
            serialNumber={serialNumber}
            setSerialNumber={setSerialNumber}
            showReminder={showReminder}
          />
        </>
      )}

      {sensor.isLoading && (
        <div className="my-3">
          <Spinner animation="border" size="lg" />
        </div>
      )}

      {SensorsStore.getWarningSensors(sensorTypes).map(sensor => {
        return (
          <Alert key={sensor.esn} className="mt-2 mb-2" variant="warning">
            <h6 className="alert-heading mb-1 text-left font-weight-bold">{`${sensor.code.replace(
              /\_/g,
              ' ',
            )} (${sensor.esn})`}</h6>
            <p className="mb-0 text-left">{sensor.warning}</p>
          </Alert>
        );
      })}

      {sensor.error && (
        <Alert className="mt-3 mb-2" variant="danger">
          <ErrorText error={sensor.error} />
        </Alert>
      )}

      {!sensor.isLoading && (
        <SensorSubmitBar
          backTo="/hardware-type"
          proceedTo="/additional-hardware"
          handleScan={async () => {
            await handleScan(serialNumber, true);
          }}
          sensor={sensor}
        />
      )}
    </div>
  );
}

export default observer(SensorScanner);
