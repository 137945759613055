import {observer} from 'mobx-react-lite';
import React from 'react';
import {Alert} from 'react-bootstrap';
import {provisionStatus} from '../utils/Constants';

export default observer(ProvisionStatusAlert);
function ProvisionStatusAlert({provisionStatusInfo}) {
  if (provisionStatusInfo.status === provisionStatus.unknown) return null;

  let variant;
  if (provisionStatusInfo.status === provisionStatus.inProgress)
    variant = 'dark';
  else if (provisionStatusInfo.status === provisionStatus.success)
    variant = 'success';
  else variant = 'danger';

  return <Alert variant={variant}>{provisionStatusInfo.message}</Alert>;
}
