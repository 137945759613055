import React, {
  ForwardRefRenderFunction,
  ReactNode,
  forwardRef,
  useMemo,
  useState,
} from 'react';
import {
  Platform,
  StyleProp,
  StyleSheet,
  TextInput,
  TextInputProps,
  TouchableOpacity,
  TouchableOpacityProps,
  View,
  ViewStyle,
} from 'react-native';
import {usePalette} from '../../theme/palette';
import {useScale} from '../../theme/scale';
import shadow from '../../theme/shadow';
import {useTypographyVariant} from '../../theme/typography';

type InputAsButton = Pick<TouchableOpacityProps, 'onPress'> & {
  /** Manual setting of input focus. Useful to use with the onPress event. */
  isFocusedManually?: boolean;
};

type InputInheritProps = Pick<
  TextInputProps,
  | 'value'
  | 'placeholder'
  | 'textContentType'
  | 'keyboardType'
  | 'autoCapitalize'
  | 'onChangeText'
  | 'onBlur'
  | 'onFocus'
  | 'onSubmitEditing'
  | 'style'
>;

type InputProps = InputInheritProps &
  InputAsButton & {
    variant?: 'text' | 'password';
    isDisabled?: boolean;
    renderRightNode?: (isFocused: boolean) => ReactNode;
    renderLeftNode?: (isFocused: boolean) => ReactNode;
    containerStyle?: StyleProp<ViewStyle>;
    rightNodeStyle?: StyleProp<ViewStyle>;
  };

const LabelInput: ForwardRefRenderFunction<TextInput, InputProps> = (
  props,
  ref,
) => {
  const {
    variant,
    isDisabled,
    value: _value,
    placeholder,
    textContentType,
    autoCapitalize,
    keyboardType,
    isFocusedManually,
    onChangeText,
    onBlur,
    onFocus,
    onSubmitEditing,
    renderRightNode,
    renderLeftNode,
    onPress,
    containerStyle,
    rightNodeStyle,
    style,
  } = props;

  const value = _value?.toString() || '';

  const showPassword = false;

  const [isFocused, setIsFocused] = useState(false);

  const {moderateScale, moderateScaleV} = useScale();

  const palette = usePalette();

  const typographyStyle = useTypographyVariant(
    'Noto Sans',
    isDisabled ? 'P1_italic' : 'P1',
  );

  const borderWidth = 1;

  const borderRadius = 4;

  const outlineWidth = Math.ceil(1);

  const outlineRadius = outlineWidth + borderRadius;

  const isFocusedFinally = useMemo(
    () => isFocused || isFocusedManually || false,
    [isFocused, isFocusedManually],
  );

  const rightNode = useMemo(
    () => (renderRightNode ? renderRightNode(isFocusedFinally) : null),
    [renderRightNode, isFocusedFinally],
  );

  const leftNode = useMemo(
    () => (renderLeftNode ? renderLeftNode(isFocusedFinally) : null),
    [renderLeftNode, isFocusedFinally],
  );

  const inputColors: {
    borderColor: string;
    outlineColor: string;
    labelColor: string;
  } = useMemo(() => {
    if (isDisabled) {
      return {
        borderColor: palette.surface[300],
        outlineColor: palette.transparent,
        labelColor: palette.text[500],
      };
    }

    if (isFocusedFinally || value?.length) {
      return {
        borderColor: palette.surface[1000],
        outlineColor: isFocusedFinally ? palette.blue[300] : 'transparent',
        labelColor: palette.text[1000],
      };
    }

    return {
      borderColor: palette.surface[400],
      outlineColor: 'transparent',
      labelColor: palette.text[400],
    };
  }, [isDisabled, isFocusedFinally, value]);

  const renderTextInput = () => {
    return (
      <TextInput
        ref={ref}
        value={value}
        placeholder={placeholder}
        editable={!isDisabled}
        secureTextEntry={
          variant === 'password' && (!showPassword || isDisabled)
        }
        textContentType={textContentType}
        autoCapitalize={autoCapitalize}
        keyboardType={keyboardType}
        allowFontScaling={false}
        disableFullscreenUI
        onChangeText={onChangeText}
        onBlur={event => {
          setIsFocused(false);

          if (onBlur) onBlur(event);
        }}
        onFocus={event => {
          setIsFocused(true);

          if (onFocus) onFocus(event);
        }}
        onSubmitEditing={onSubmitEditing}
        placeholderTextColor={
          isDisabled ? palette.text[500] : palette.text[400]
        }
        selectionColor={palette.blue[300]}
        style={[
          typographyStyle,
          {
            // Prevents different input height with only value and only placeholder
            lineHeight: undefined,
          },
          {
            color: inputColors.labelColor,
            paddingTop: moderateScaleV(12),
            paddingBottom: moderateScaleV(12),
            paddingRight: moderateScale(12),
            textDecorationLine: 'none',
            flexGrow: 1,
            flexShrink: 1,
          },
          Platform.select({
            web: {
              outlineStyle: 'none',
              outlineColor: 'transparent',
              outlineWidth: 0,
              outlineOffset: 0,
            },
          }),
          style,
        ]}
      />
    );
  };

  return (
    <View style={[containerStyle, {position: 'relative', zIndex: 0}]}>
      <View
        // pointerEvents - required to fix bug, when contextual menu (with copy, paste...) not opens and zIndex not works
        pointerEvents="box-none"
        style={[
          StyleSheet.absoluteFill,
          isDisabled ? undefined : isFocused ? shadow.md : shadow.sm,
          {
            // Native shadow requires "backgroundColor",
            backgroundColor: '#00000001',
            borderRadius: borderRadius,
            zIndex: 0,
          },
        ]}
      />

      <View
        // pointerEvents - required to fix bug, when contextual menu (with copy, paste...) not opens and zIndex not works
        pointerEvents="box-none"
        style={{
          position: 'absolute',
          top: -outlineWidth,
          bottom: -outlineWidth,
          left: -outlineWidth,
          right: -outlineWidth,
          borderRadius: outlineRadius,
          backgroundColor: inputColors.outlineColor,
          zIndex: 0,
        }}
      />

      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          borderWidth: borderWidth,
          borderColor: inputColors.borderColor,
          borderRadius: borderRadius,
          backgroundColor: isDisabled
            ? palette.surface[100]
            : palette.surface[0],
          zIndex: 1,
        }}>
        {leftNode ? (
          <View style={{marginRight: moderateScale(4)}}>{leftNode}</View>
        ) : (
          <View style={{marginRight: moderateScale(12)}} />
        )}

        {onPress ? (
          <TouchableOpacity
            disabled={isDisabled}
            onPress={onPress}
            style={{flexGrow: 1, flexShrink: 1}}>
            <View pointerEvents="none" style={{flexGrow: 1, flexShrink: 1}}>
              {renderTextInput()}
            </View>
          </TouchableOpacity>
        ) : (
          renderTextInput()
        )}

        {rightNode && (
          <View
            style={[
              {
                marginLeft: moderateScale(4),
              },
              rightNodeStyle,
            ]}>
            {rightNode}
          </View>
        )}
      </View>
    </View>
  );
};

export default forwardRef(LabelInput);
