import {action, makeObservable} from 'mobx';
import ApiClient from '../services/apiClient';
import AppStore from './app/AppStore';
import DocumentStore from './document/DocumentStore';
import FetchConfig from './fetch/FetchConfig';
import FetchStore from './fetch/FetchStore';
import UserStore from './user/UserStore';
import AuthStore from './auth/AuthStore';
import GatewayStore from './gateway/GatewayStore';

export default class RootStore {
  apiClient: ApiClient;
  fetchStore: FetchStore;
  documentStore: DocumentStore;
  appStore: AppStore;
  userStore: UserStore;
  authStore: AuthStore;
  gatewayStore: GatewayStore;

  constructor() {
    this.fetchStore = new FetchStore(new FetchConfig(this));
    this.apiClient = new ApiClient(this.fetchStore);
    this.appStore = new AppStore();
    this.documentStore = new DocumentStore(this.apiClient);
    this.userStore = new UserStore(this.apiClient);
    this.authStore = new AuthStore(this.apiClient, this.userStore);
    this.gatewayStore = new GatewayStore(this.apiClient);

    makeObservable(this, {
      dispose: action,
    });
  }

  dispose() {}
}
