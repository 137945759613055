import React, {Fragment, useEffect, useMemo, useRef, useState} from 'react';
import {FlatList, StyleSheet, TouchableOpacity, View} from 'react-native';
import {usePalette} from '../../../theme/palette';
import {useScale} from '../../../theme/scale';
import shadow from '../../../theme/shadow';
import {ResponsiveStyle, useStyles} from '../../../theme/styles';
import {
  defaultLineHeightMultiplier,
  typographyVariants,
} from '../../../theme/typography';
import CaretIcon from '../../icons/CaretIcon';
import Label from '../Label';
import LabelInput from '../LabelInput';
import SafeAreaPopoverForSelect from './SafeAreaPopoverForSelect';

type Props<T> = {
  hideNone?: boolean;
  disabled?: boolean;
  data: T[];
  selectedKey?: string | null;
  placeholder?: string;
  autoScroll?: boolean;
  keyExtractor: (item: T) => string;
  labelExtractor: (item: T) => string;
  onSelectedKeyChange?: (key: string | null) => void;
  onSelected?: (item: T | null) => void;
};

/** Pass any data to `data` prop. Provide key in `keyExtractor`, and label in `labelExtractor` */
const Select = <T,>(props: Props<T>) => {
  const {
    disabled,
    hideNone,
    data,
    placeholder,
    selectedKey = null,
    autoScroll,
    keyExtractor,
    labelExtractor,
    onSelectedKeyChange,
    onSelected,
  } = props;

  const [visible, setVisible] = useState(false);

  const styles = useStyles(createStyles);

  const palette = usePalette();

  const {moderateScale, moderateScaleV} = useScale();

  const flatListRef = useRef<FlatList | null>(null);

  const selectedItemIndex = useMemo(() => {
    if (!data) return undefined;

    if (!selectedKey) return undefined;

    const index = data.findIndex(item => keyExtractor(item) === selectedKey);

    return index < 0 ? undefined : index;
  }, [data, selectedKey, keyExtractor]);

  useEffect(() => {
    if (!visible) return;

    if (!autoScroll) return;

    if (!flatListRef?.current) return;

    if (selectedItemIndex === undefined) return;

    (async () =>
      await new Promise<void>(resolve =>
        setTimeout(() => {
          if (!autoScroll) return;

          if (!flatListRef?.current) return;

          if (selectedItemIndex === undefined) return;

          flatListRef.current.scrollToIndex({
            index: selectedItemIndex,
            animated: true,
          });

          resolve();
        }, 200),
      ))();
  }, [visible]);

  const oneItemApproximateHeight = useMemo(() => {
    const padding = moderateScale(16) * 2;

    const text = moderateScaleV(
      typographyVariants.P1.fontSize * defaultLineHeightMultiplier,
    );

    const divider = 1;

    return padding + text + divider;
  }, [moderateScale, moderateScaleV]);

  const selectMinMaxHeight = useMemo(() => {
    let itemsCount = data?.length || 0;

    if (!hideNone) itemsCount += 1;

    return {
      min: oneItemApproximateHeight * Math.min(3, itemsCount) + 3,
      max: oneItemApproximateHeight * Math.min(5, itemsCount) + 3,
    };
  }, [oneItemApproximateHeight, data, hideNone]);

  return (
    <SafeAreaPopoverForSelect
      minHeight={selectMinMaxHeight.min}
      maxHeight={selectMinMaxHeight.max}
      visible={visible}
      onBackdrop={() => setVisible(false)}
      renderComponent={(ref, onLayout) => (
        <View ref={ref} onLayout={onLayout}>
          <LabelInput
            isDisabled={disabled}
            placeholder={placeholder}
            value={
              selectedItemIndex !== undefined
                ? labelExtractor(data[selectedItemIndex])
                : undefined
            }
            onPress={() => setVisible(prev => !prev)}
            isFocusedManually={visible}
            renderRightNode={isFocused => (
              <TouchableOpacity
                disabled={disabled}
                onPress={() => setVisible(true)}
                style={{paddingRight: moderateScale(12)}}>
                <CaretIcon
                  variant={isFocused ? 'up' : 'down'}
                  color={
                    disabled
                      ? palette.surface[500]
                      : isFocused
                      ? palette.surface[900]
                      : palette.surface[1000]
                  }
                />
              </TouchableOpacity>
            )}
          />
        </View>
      )}
      renderPopover={() => (
        <View style={[styles.popover, shadow.xl]}>
          <FlatList
            ref={flatListRef}
            data={data}
            keyExtractor={keyExtractor}
            renderItem={({item}) =>
              keyExtractor(item) === selectedKey ? (
                <TouchableOpacity
                  onPress={() => {
                    setVisible(false);
                  }}
                  style={styles.popoverSelectedItem}>
                  <Label
                    variant="P1"
                    color={palette.text[1000]}
                    numberOfLines={1}>
                    {labelExtractor(item)}
                  </Label>
                </TouchableOpacity>
              ) : (
                <TouchableOpacity
                  onPress={() => {
                    if (onSelected) {
                      onSelected(item);
                    }

                    if (onSelectedKeyChange) {
                      onSelectedKeyChange(keyExtractor(item));
                    }

                    setVisible(false);
                  }}
                  style={styles.popoverItem}>
                  <Label
                    variant="P1"
                    color={palette.text[900]}
                    numberOfLines={1}>
                    {labelExtractor(item)}
                  </Label>
                </TouchableOpacity>
              )
            }
            ListHeaderComponent={
              hideNone
                ? undefined
                : () => (
                    <Fragment>
                      <TouchableOpacity
                        onPress={() => {
                          if (onSelected) {
                            onSelected(null);
                          }

                          if (onSelectedKeyChange) {
                            onSelectedKeyChange(null);
                          }

                          setVisible(false);
                        }}
                        style={styles.popoverItem}>
                        <Label
                          variant="P1"
                          color={palette.text[900]}
                          numberOfLines={1}>
                          None
                        </Label>
                      </TouchableOpacity>

                      <View style={styles.divider} />
                    </Fragment>
                  )
            }
            ItemSeparatorComponent={() => <View style={styles.divider} />}
            onScrollToIndexFailed={() => null}
          />
        </View>
      )}
    />
  );
};

const createStyles = ({palette, scale: {moderateScale}}: ResponsiveStyle) =>
  StyleSheet.create({
    popover: {
      flexGrow: 1,
      flexShrink: 1,
      backgroundColor: palette.surface[0],
      borderWidth: 0.5,
      borderColor: palette.surface[300],
      borderRadius: 4,
      overflow: 'hidden',
    },
    popoverItem: {
      paddingHorizontal: moderateScale(12),
      paddingVertical: moderateScale(16),
      backgroundColor: palette.surface[0],
    },
    popoverSelectedItem: {
      paddingHorizontal: moderateScale(12),
      paddingVertical: moderateScale(16),
      backgroundColor: palette.surface[50],
      borderRadius: 2,
      borderWidth: 1.5,
      borderColor: palette.blue[300],
    },
    divider: {
      height: 1,
      backgroundColor: palette.surface[100],
    },
  });

export default Select;
