import {Platform} from 'react-native';
import FetchStore from '../stores/fetch/FetchStore';
import {ProvisionSource} from '../types/enums';

export default class InstallationAPI {
  constructor(private readonly fetchStore: FetchStore) {}

  async getAll() {
    return await this.fetchStore.get('installation');
  }

  async get(id: any) {
    return await this.fetchStore.get(`installation/${id}`);
  }

  async getByVinInProgress(vin: string, forCurrentUser: any) {
    let url = `installation/${vin}/inProgress`;

    if (forCurrentUser) {
      url += `?forCurrentUser=${forCurrentUser}`;
    }

    return await this.fetchStore.get(url);
  }

  async getAssetLastProvisionByVin(vin: string) {
    return await this.fetchStore.get(
      `installation/assetProvisionValidation/${vin}`,
    );
  }

  async validate(id: any) {
    return await this.fetchStore.get(`installation/${id}/validate`);
  }

  async acknowledge(id: any) {
    return await this.fetchStore.get(`installation/${id}/acknowledge`);
  }

  async cancel(id: any) {
    return await this.fetchStore.get(`installation/${id}/cancel`);
  }

  async setCaseNumber(id: any, caseNumber: any) {
    return await this.fetchStore.put(`installation/${id}/caseNumber`, {
      caseNumber: caseNumber,
    });
  }

  async setComment(id: any, comment: any) {
    return await this.fetchStore.put(`installation/${id}/comment`, {
      comment: comment,
      source:
        Platform.OS === 'web' ? ProvisionSource.web : ProvisionSource.native,
    });
  }

  async setAssetVins3key(id: any, assetVins3key: any) {
    return await this.fetchStore.put(`installation/${id}/assetVins3key`, {
      assetVins3key: assetVins3key,
    });
  }

  async getInstallationHardwareLatestPackets(installationId: number) {
    return await this.fetchStore.get(
      `installation/${installationId}/sensorsLatestData`,
    );
  }

  async getLatestPackets(hardwareUnits: any) {
    return await this.fetchStore.post(`installation/latestPackets`, {
      hardwareUnits,
    });
  }

  async getStatus(id: any) {
    return await this.fetchStore.get(`installation/${id}/status`);
  }

  async setStatus(installationId: number, provisionStatus: any) {
    return await this.fetchStore.post(`installation/${installationId}/status`, {
      provisionStatus,
    });
  }

  async deprovision(id: any) {
    return await this.fetchStore.post(`installation/${id}/deprovision`);
  }

  async getProvisionByInstallationId(installationId: number) {
    return await this.fetchStore.get(
      `installation/provision/info?installation_id=${installationId}`,
    );
  }

  async getProvisionByVin(vin: string) {
    return await this.fetchStore.get(`installation/provision/info?vin=${vin}`);
  }

  async getProvisionWhitelistingStatus(provisioning_id: any) {
    return await this.fetchStore.get(
      `installation/provision/whitelisting_status?provisioning_id=${provisioning_id}`,
    );
  }
}
