import {IAppConfig} from './types';

export default class AppConfig implements IAppConfig {
  private static _instance: AppConfig | null = null;
  private static ENV_PREFIX = 'REACT_APP_';

  private constructor() {}

  public static get instance() {
    if (!this._instance) {
      this._instance = new AppConfig();
    }

    return this._instance;
  }

  public get ENVIRONMENT() {
    return process.env[AppConfig.ENV_PREFIX + 'ENVIRONMENT'] as
      | Environment
      | undefined;
  }

  public get API_URL() {
    return process.env[AppConfig.ENV_PREFIX + 'API_URL'];
  }

  public get CONMET_URL() {
    return process.env[AppConfig.ENV_PREFIX + 'CONMET_URL'];
  }

  public get VERSION() {
    return process.env[AppConfig.ENV_PREFIX + 'VERSION'];
  }
}
