import React, {ReactNode} from 'react';
import {
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';
import shadow from '../../theme/shadow';
import {ResponsiveStyle, useStyles} from '../../theme/styles';
import {useScale} from '../../theme/scale';

type Props = {
  fullWidth?: boolean;
  onBackdropPress?: () => void;
  children?: ReactNode;
  style?: StyleProp<ViewStyle>;
};

const DialogView: React.FC<Props> = props => {
  const {fullWidth, children, onBackdropPress} = props;

  const styles = useStyles(createStyles);

  const {moderateScale} = useScale();

  return (
    <SafeAreaView
      mode="padding"
      pointerEvents="box-none"
      style={[
        styles.screen,
        {
          alignItems: 'center',
          justifyContent: 'center',
          padding: moderateScale(11),
        },
      ]}>
      <TouchableOpacity
        disabled={!onBackdropPress}
        onPress={onBackdropPress}
        style={styles.backdrop}
      />

      <View
        style={{
          width: fullWidth ? '100%' : undefined,
          minWidth: 260,
          maxWidth: 560,
          maxHeight: '100%',
        }}>
        <View style={[styles.dialog, shadow.xl]}>{children}</View>
      </View>
    </SafeAreaView>
  );
};

const createStyles = ({palette}: ResponsiveStyle) =>
  StyleSheet.create({
    screen: {
      flexGrow: 1,
      flexShrink: 1,
      position: 'relative',
    },
    backdrop: {
      ...StyleSheet.absoluteFillObject,

      backgroundColor: palette.modalOverlay,
      opacity: 0.5,
      position: 'absolute',
    },
    dialog: {
      flexGrow: 0,
      flexShrink: 1,
      backgroundColor: palette.surface[0],
      borderColor: palette.surface[300],
      borderWidth: 1,
      borderRadius: 8,
      overflow: 'hidden',
      maxWidth: 560,
      minWidth: 260,
    },
  });

export default DialogView;
