import {action, makeObservable, observable} from 'mobx';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Logging from '../../services/logging';
import ApiClient from '../../services/apiClient';
import {AsyncStorageKey} from '../../types/enums';

export default class DocumentStore {
  privacyPolicy: DocumentDto | null = null;

  isNewPrivacyPolicyAvailable: boolean = false;

  constructor(private readonly apiClient: ApiClient) {
    makeObservable(this, {
      privacyPolicy: observable,
      isNewPrivacyPolicyAvailable: observable,

      setupPrivacyPolicy: action,
      setPrivacyPolicy: action,
      checkPrivacyPolicyUpdate: action,
      acceptPrivacyPolicy: action,
      setIsNewPrivacyPolicyAvailable: action,
    });
  }

  async setupPrivacyPolicy() {
    const result = await this.apiClient.document.getPublicDocument(
      'privacy_policy',
    );
    this.setPrivacyPolicy(result?.document || null);
  }

  setPrivacyPolicy(privacyPolicy: DocumentDto | null) {
    this.privacyPolicy = privacyPolicy;
  }

  async checkPrivacyPolicyUpdate(userId: UserDto['id']) {
    try {
      const result = await Promise.all([
        this.setupPrivacyPolicy(),
        this.readPrivacyPolicyModifiedTime(userId),
      ]);

      const lastModifiedTime = result[1];

      if (!this.privacyPolicy) return;

      if (!lastModifiedTime || lastModifiedTime < this.privacyPolicy.modified) {
        this.setIsNewPrivacyPolicyAvailable(true);
      }
    } catch (e: unknown) {
      const error = Logging.parseUnknownError(e);
      Logging.recordError(error);
    }
  }

  async acceptPrivacyPolicy(userId: UserDto['id']) {
    try {
      if (!this.privacyPolicy) return;

      await this.writePrivacyPolicyModifiedTime(
        this.privacyPolicy.modified,
        userId,
      );

      this.setIsNewPrivacyPolicyAvailable(false);
    } catch (e: unknown) {
      const error = Logging.parseUnknownError(e);
      Logging.recordError(error);
    }
  }

  setIsNewPrivacyPolicyAvailable(value: boolean) {
    this.isNewPrivacyPolicyAvailable = value;
  }

  async readPrivacyPolicyModifiedTime(
    userId: UserDto['id'],
  ): Promise<number | null> {
    const lastModified = await AsyncStorage.getItem(
      AsyncStorageKey.privacyPocilyModifiedTimestamp + '_' + userId?.toString(),
    );

    return lastModified ? parseInt(lastModified, 10) : null;
  }

  async writePrivacyPolicyModifiedTime(
    timestamp: number,
    userId: UserDto['id'],
  ) {
    try {
      await AsyncStorage.setItem(
        AsyncStorageKey.privacyPocilyModifiedTimestamp +
          '_' +
          userId?.toString(),
        timestamp.toString(),
      );
    } catch (e) {
      console.error(e);
    }
  }
}
