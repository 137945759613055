import { makeAutoObservable, extendObservable } from "mobx";

import { autoSave } from "../utils/Mobx";
import { sensorType } from "../utils/Constants";

class SensorsStore {
  constructor() {
    this.sensorsType = sensorType.smarthub;
    this.sensors = [];

    this.load();
    autoSave(this, this.save.bind(this));
  }

  load() {
    let json = localStorage.getItem("sensors");

    if (json) extendObservable(this, JSON.parse(json), {}, { deep: true });
    else makeAutoObservable(this, {}, { deep: true });
  }

  save(json) {
    localStorage.setItem("sensors", json);
  }

  anyLoading() {
    return this.sensors.some((s) => s.isLoading);
  }

  get(code) {
    let sensor = this.sensors.find((s) => s.code === code && s.type === this.sensorsType);

    if (sensor) return sensor;

    this.sensors.push({
      id: null,
      esn: "",
      code: code,
      type: this.sensorsType,
    });

    return this.sensors.find((s) => s.code === code && s.type === this.sensorsType);
  }

  set(sensors) {
    this.sensors = sensors.map((s) => ({ ...s, isUploaded: true }));
  }

  clear() {
    this.sensors = [];
  }

  clearUnsubmitted() {
    this.sensors = this.sensors.filter((s) => s.id && s.esn && s.isUploaded);
  }

  getWarningSensors(types) {
    if (Array.isArray(types)) {
      return this.sensors.filter((s) => types.find((t) => t === s.type) && s.warning);
    } else {
      return [];
    }
  }
}

export default new SensorsStore();
