import React, {Fragment} from 'react';
import {Control, Controller, FieldValues} from 'react-hook-form';
import Label from '../../components/common/Label';
import Select from '../../components/common/Select';
import {usePalette} from '../../theme/palette';
import {useScale} from '../../theme/scale';
import {AtiInstalled} from '../../types/enums';

type Props = {
  control: Control<FieldValues> | undefined;
  disabled: boolean;
};

const AssetAtiController: React.FC<Props> = props => {
  const {control, disabled} = props;

  const {moderateScale} = useScale();

  const palette = usePalette();

  return (
    <Controller
      name="atiInstalled"
      control={control}
      defaultValue={undefined}
      rules={{
        required: 'This field is required',
      }}
      render={({field: {onChange, value}, fieldState: {error}}) => (
        <Fragment>
          <Label
            variant="L1"
            color={palette.text[900]}
            style={{marginBottom: moderateScale(4)}}>
            ATI Installed
          </Label>

          <Select
            hideNone
            disabled={disabled}
            selectedKey={typeof value === 'number' ? value.toString() : null}
            data={[
              {label: 'Yes', value: AtiInstalled.yes},
              {label: 'No', value: AtiInstalled.no},
              {
                label: 'Not Sure',
                value: AtiInstalled.notSure,
              },
            ]}
            keyExtractor={item => item.value.toString()}
            labelExtractor={item => item.label}
            onSelected={item => onChange(item?.value)}
          />

          {!!error && (
            <Label
              variant="L4"
              color={palette.error[300]}
              style={{marginTop: moderateScale(4)}}>
              {error?.message?.toString()}
            </Label>
          )}
        </Fragment>
      )}
    />
  );
};

export default AssetAtiController;
