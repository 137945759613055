import {action, computed, makeObservable, observable} from 'mobx';
import {Platform} from 'react-native';
import deviceInfoModule from 'react-native-device-info';
import AppPermissions from '../../services/AppPermissions/AppPermissions';
import remoteConfig from '../../services/firebase/remoteConfig';
import AppConfig from '../../utils/AppConfig/AppConfig';
import {APP_STORE_URL, GOOGLE_PLAY_URL} from '../../utils/AppConstants';

const apiUrls = {
  dev: 'https://instapp.dev.digital.conmet.com/api/private/',
  staging: 'https://instapp.stg.digital.conmet.com/api/private/',
  prod: 'https://install.digital.conmet.com/api/private/',
};

export default class AppStore {
  initialEnvironment: Environment;

  environment: Environment;

  environmentSelectAvailable: boolean = false;

  cameraEnabled: boolean = false;

  photoLibraryEnabled: boolean = false;

  configurationDone: boolean = false;

  constructor() {
    this.initialEnvironment = AppConfig.instance.ENVIRONMENT as Environment;

    this.environment = this.initialEnvironment;

    this.environmentSelectAvailable = this.initialEnvironment === 'dev';

    makeObservable(this, {
      environment: observable,
      environmentSelectAvailable: observable,
      cameraEnabled: observable,
      photoLibraryEnabled: observable,
      configurationDone: observable,

      configure: action,
      setEnvironment: action,
      setEnvironmentSelectAvailable: action,
      setCameraEnabled: action,
      setPhotoLibraryEnabled: action,
      setConfigurationDone: action,
      requestCameraPermission: action,
      checkCameraPermission: action,
      requestPhotoLibraryPermission: action,
      checkPhotoLibraryPermission: action,

      config: computed,
    });
  }

  async configure() {
    await this.checkCameraPermission();

    await this.checkPhotoLibraryPermission();

    this.setConfigurationDone();
  }

  setEnvironment(environment: Environment) {
    this.environment = environment;
  }

  setEnvironmentSelectAvailable(environmentSelectAvailable: boolean) {
    this.environmentSelectAvailable = environmentSelectAvailable;
  }

  setCameraEnabled(cameraEnabled: boolean) {
    this.cameraEnabled = cameraEnabled;
  }

  setPhotoLibraryEnabled(photoLibraryEnabled: boolean) {
    this.photoLibraryEnabled = photoLibraryEnabled;
  }

  setConfigurationDone() {
    this.configurationDone = true;
  }

  async checkCameraPermission() {
    this.setCameraEnabled(
      await AppPermissions.instance.checkCameraPermission(),
    );
  }

  async requestCameraPermission() {
    this.setCameraEnabled(
      await AppPermissions.instance.requestCameraPermission(),
    );
  }

  async checkPhotoLibraryPermission() {
    this.setPhotoLibraryEnabled(
      await AppPermissions.instance.checkPhotoLibraryPermission(),
    );
  }

  async requestPhotoLibraryPermission() {
    this.setPhotoLibraryEnabled(
      await AppPermissions.instance.requestPhotoLibraryPermission(),
    );
  }

  get config() {
    return {
      apiUrl:
        this.initialEnvironment === 'dev'
          ? apiUrls[this.environment]
          : AppConfig.instance.API_URL!,
      conmentUrl: AppConfig.instance.CONMET_URL!,
    };
  }

  public async checkAppUpdate() {
    if (Platform.OS === 'web') {
      return {
        latestBuildNumber: -1,
        forceUpdate: false,
        appUpdateUrl: '',
      };
    }

    const buildNumber = +deviceInfoModule.getBuildNumber();
    const ios = Platform.OS === 'ios';

    remoteConfig.setDefaults({
      android_build_number: buildNumber,
      ios_build_number: buildNumber,
      force_update_required_android: false,
      force_update_required_ios: false,
      google_play_url: GOOGLE_PLAY_URL,
      app_store_url: APP_STORE_URL,
    });

    await remoteConfig.fetch(60);

    await remoteConfig.activate();

    const latestBuildNumber = remoteConfig
      .getValue(ios ? 'ios_build_number' : 'android_build_number')
      .asNumber();

    const forceUpdate = remoteConfig
      .getValue(
        ios ? 'force_update_required_ios' : 'force_update_required_android',
      )
      .asBoolean();

    const appUpdateUrl = remoteConfig
      .getValue(ios ? 'app_store_url' : 'google_play_url')
      .asString();

    return {
      latestBuildNumber,
      forceUpdate,
      appUpdateUrl,
    };
  }
}
