import React from "react";
import { Form } from "react-bootstrap";

import { useField, useFormikContext } from "formik";

import { clamp } from "lodash";

function InputField({ label, className, type, min, max, size, ...props }) {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);

  function handleChange(target) {
    if (type === "number") target.value = clamp(target.value, min, max);

    setFieldValue(field.name, target.value);
  }

  return (
    <Form.Group className={className}>
      {label && <Form.Label htmlFor={label}>{label}</Form.Label>}
      <Form.Control
        {...field}
        {...props}
        id={label}
        type={type}
        value={field.value}
        size={size}
        onChange={(e) => handleChange(e.target)}
        className={" text-input text-center " + (meta.error && meta.touched ? "invalid" : "")}
      ></Form.Control>
      {meta.touched && meta.error && (
        <div className="invalid-feedback" style={{ display: "block" }}>
          {meta.error}
        </div>
      )}
    </Form.Group>
  );
}

export default InputField;
