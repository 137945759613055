import React, {ReactNode, createContext, useContext, useMemo} from 'react';
import {Platform, useWindowDimensions} from 'react-native';

export type ScaleProviderValue = {
  moderateScale: (size: number, scaleFactor?: number | undefined) => number;
  moderateScaleV: (size: number, scaleFactor?: number | undefined) => number;
};

type ScaleProviderProps = {
  currentBreakpointKey?: any;
  children: ReactNode;
};

function createDefault(): ScaleProviderValue {
  return {
    moderateScale: size => size,
    moderateScaleV: size => size,
  };
}

const ScaleContext = createContext<ScaleProviderValue>(createDefault());

export const ScaleProvider = ({
  currentBreakpointKey,
  children,
}: ScaleProviderProps) => {
  //Default guideline sizes are based on standard ~5" screen mobile device
  const guidelineBaseWidth = 350;
  const guidelineBaseHeight = 680;

  const defaultScaleFactor = 0.2;
  const linearScaleFactor = 1;

  const {width, height} = useWindowDimensions();

  const [shortDimension, longDimension] =
    width < height ? [width, height] : [height, width];

  function moderateScale(size: number, factor = defaultScaleFactor) {
    const linearSize = size * linearScaleFactor;

    if (Platform.OS === 'web') return linearSize;

    const tempScale = (shortDimension / guidelineBaseWidth) * linearSize;

    return linearSize + (tempScale - linearSize) * factor;
  }

  function moderateScaleV(size: number, factor = defaultScaleFactor) {
    const linearSize = size * linearScaleFactor;

    if (Platform.OS === 'web') return linearSize;

    const tempScale = (longDimension / guidelineBaseHeight) * linearSize;

    return linearSize + (tempScale - linearSize) * factor;
  }

  const value: ScaleProviderValue = useMemo(() => {
    return {moderateScale, moderateScaleV};
  }, [currentBreakpointKey]);

  return (
    <ScaleContext.Provider value={value}>{children}</ScaleContext.Provider>
  );
};

export function useScale() {
  return useContext(ScaleContext);
}
