import {observer} from 'mobx-react-lite';
import React from 'react';
import {Alert, Linking, Platform} from 'react-native';
import deviceInfoModule from 'react-native-device-info';
import {SafeAreaView} from 'react-native-safe-area-context';
import {useStore} from '../../stores/useStore';
import {usePalette} from '../../theme/palette';
import {useScale} from '../../theme/scale';
import AppConfig from '../../utils/AppConfig/AppConfig';
import Label from '../common/Label';

const appVersion = deviceInfoModule.getVersion();
const buildNumber = deviceInfoModule.getBuildNumber();

function Footer() {
  const palette = usePalette();

  const {appStore} = useStore();

  const {moderateScale} = useScale();

  async function openUrl(url: string) {
    const supported = await Linking.canOpenURL(url);

    if (supported) {
      await Linking.openURL(url);
    } else {
      Alert.alert('Failed to open url: ' + url);
    }
  }

  const versionLabel =
    Platform.OS === 'web'
      ? `(v${AppConfig.instance.VERSION})`
      : ` v${appVersion} - ${buildNumber} ${
          appStore.environment !== 'prod' ? `(${appStore.environment})` : ''
        }`;

  return (
    <SafeAreaView mode="padding" edges={['bottom', 'left', 'right']}>
      <Label
        variant="L3"
        color={palette.text[800]}
        style={{padding: moderateScale(12), textAlign: 'center'}}>
        © Copyright Consolidated Metco, Inc. All rights reserved. ConMet is a
        registered trademark of{' '}
        <Label
          variant="P2"
          color={palette.link}
          onPress={() => openUrl('https://conmet.com/')}
          style={{
            textDecorationLine: 'underline',
          }}>
          Consolidated Metco, Inc
        </Label>{' '}
        {versionLabel}
      </Label>
    </SafeAreaView>
  );
}

export default observer(Footer);
