import React, {Fragment} from 'react';
import {StyleSheet, View} from 'react-native';
import Label from '../../components/common/Label';
import FilledButton from '../../components/common/MediumButton/FilledButton';
import {ResponsiveStyle, useStyles} from '../../theme/styles';
import {usePalette} from '../../theme/palette';

type Props = {
  disabled: boolean;
  continueButtonText: string;
  resetAndClose: () => void;
  onContinue: () => void;
};

const AssetCreateView: React.FC<Props> = props => {
  const {disabled, continueButtonText, resetAndClose, onContinue, children} =
    props;

  const styles = useStyles(createStyles);

  const palette = usePalette();

  return (
    <Fragment>
      <View style={styles.header}>
        <Label variant="H3" color={palette.text[1000]}>
          {'Create Asset'}
        </Label>
      </View>

      <View style={{height: 1, backgroundColor: palette.surface[200]}} />

      <View style={styles.body}>{children}</View>

      <View style={{height: 1, backgroundColor: palette.surface[200]}} />

      <View style={styles.footer}>
        <View style={styles.leftButton}>
          <FilledButton
            text="Cancel"
            isDisabled={disabled}
            onPress={resetAndClose}
            style={{backgroundColor: palette.surface[400]}}
          />
        </View>

        <View style={styles.rightButton}>
          <FilledButton
            text={continueButtonText}
            isDisabled={disabled}
            onPress={onContinue}
          />
        </View>
      </View>
    </Fragment>
  );
};

const createStyles = ({scale: {moderateScale}}: ResponsiveStyle) =>
  StyleSheet.create({
    header: {
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'row',
      padding: moderateScale(20),
    },
    body: {
      flexDirection: 'column',
      padding: moderateScale(20),
    },
    footer: {
      flexDirection: 'row',
      padding: moderateScale(20),
    },
    leftButton: {
      flex: 0.5,
      marginRight: moderateScale(10),
    },
    rightButton: {
      flex: 0.5,
      marginLeft: moderateScale(10),
    },
  });

export default AssetCreateView;
