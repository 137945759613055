import crashlytics from './firebase/crashlytics';

const Logging = {
  parseUnknownError,
  recordError: crashlytics.recordError,
};

function parseUnknownError(error: unknown): Error {
  if (error instanceof Error) {
    return error;
  }
  try {
    return new Error(JSON.stringify(error));
  } catch {
    return new Error('Unknown error');
  }
}

export default Logging;
