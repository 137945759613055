import { makeAutoObservable, extendObservable } from "mobx";
import { autoSave } from "../utils/Mobx";

import AssetStore from "./AssetStore";
import GatewaysStore from "./GatewaysStore";
import SensorsStore from "./SensorsStore";

const defaultInstallation = {
  id: null,
  customerKey: "",
  caseNumber: "",
  comment: "",
  assetVins3key: null,
};

class InstallationStore {
  constructor() {
    this.installation = defaultInstallation;

    this.installationToValidate = null;

    this.installationContinuation = false;

    this.load();
    autoSave(this, this.save.bind(this));
  }

  load() {
    let json = localStorage.getItem("installation");

    if (json) extendObservable(this, JSON.parse(json), {}, { deep: true });
    else makeAutoObservable(this, {}, { deep: true });
  }

  save(json) {
    localStorage.setItem("installation", json);
  }

  async fetch(id) {
  }

  apply(installation) {
    try {
      const isNewAsset = AssetStore.isNew;

      this.clear();

      this.set(installation);

      AssetStore.set({ ...installation.asset, isNew: isNewAsset });
      GatewaysStore.set(installation.asset.gateways);
      SensorsStore.set(installation.asset.sensors);
    } catch {
      throw new Error("Something went wrong");
    }
  }

  set(installation) {
    try {
      this.installation.id = installation.id;
      this.installation.customerKey = installation.customerKey;
      this.installation.caseNumber = installation.caseNumber;
      this.installation.comment = installation.comment;
      this.installation.assetVins3key = installation.assetVins3key;
    } catch {
      throw new Error("Something went wrong");
    }
  }

  clear() {
    AssetStore.clear();
    this.installation = defaultInstallation;
    this.installationToValidate = null;
  }

  async setNotCompletedInstalation(installationId, installationAPI) {
    const installation = await installationAPI.get(installationId);
    this.apply(installation);
    this.installationContinuation = true;
  }

  async findActiveInstallationStartedByAnotherUser(assetVin, installationAPI) {
    return await installationAPI.getByVinInProgress(assetVin);
  }

  async findActiveInstallationStartedByCurrentUser(assetVin, installationAPI) {
    return await installationAPI.getByVinInProgress(assetVin, true);
  }
}

export default new InstallationStore();
