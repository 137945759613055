import React, {Fragment} from 'react';
import {Control, Controller, FieldValues} from 'react-hook-form';
import Label from '../../components/common/Label';
import Select from '../../components/common/Select';
import {usePalette} from '../../theme/palette';
import {useScale} from '../../theme/scale';
import {AssetType} from '../../types/enums';
import {inEnum} from '../../utils';

type Props = {
  control: Control<FieldValues> | undefined;
  disabled: boolean;
  setupSubTypeOptions: (assetType: AssetType | null) => void;
};

const AssetTypeController: React.FC<Props> = props => {
  const {control, disabled, setupSubTypeOptions} = props;

  const {moderateScale} = useScale();

  const palette = usePalette();

  return (
    <Controller
      name="type"
      control={control}
      defaultValue={null}
      rules={{
        required: 'This field is required',
      }}
      render={({field: {onChange, value}, fieldState: {error}}) => (
        <Fragment>
          <Label
            variant="L1"
            color={palette.text[900]}
            style={{marginBottom: moderateScale(4)}}>
            Asset Type
          </Label>

          <Select
            hideNone
            disabled={disabled}
            selectedKey={inEnum(value, AssetType) ? value.toString() : null}
            data={[
              {label: 'Tractor', value: AssetType.tractor},
              {label: 'Trailer', value: AssetType.trailer},
            ]}
            keyExtractor={item => item.value.toString()}
            labelExtractor={item => item.label}
            onSelected={item => {
              onChange(item?.value);
              setupSubTypeOptions(item?.value || null);
            }}
          />

          {!!error && (
            <Label
              variant="L4"
              color={palette.error[300]}
              style={{marginTop: moderateScale(4)}}>
              {error?.message?.toString()}
            </Label>
          )}
        </Fragment>
      )}
    />
  );
};

export default AssetTypeController;
