import React, {Fragment, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Button, Form} from 'react-bootstrap';
import InstallationStore from '../../stores/InstallationStore';
import NavigationBar from '../../components/navigation/NavigationBar';
import VINLabel from '../../components/VINLabel';
import useNavigationState from '../../hooks/useNavigationState';
import {useStore} from '../../shared/stores/useStore';
import {ScrollView, StyleSheet, View} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';
import Container from '../../shared/components/common/Container';
import InstallationTopAppBar from '../../components/InstallationTopAppBar';
import {ResponsiveStyle, useStyles} from '../../shared/theme/styles';
import {useScale} from '../../shared/theme/scale';
import Footer from '../../shared/components/navigation/Footer';
import Label from '../../shared/components/common/Label';
import {usePalette} from '../../shared/theme/palette';
import locales from '../../shared/locales/en';
import {useNavigate} from '../../shared/utils/routing';

function InstallationCaseNumber() {
  const {apiClient} = useStore();

  const styles = useStyles(createStyles);

  const palette = usePalette();

  const {moderateScale, moderateScaleV} = useScale();

  const [newCaseNumber, setNewCaseNumber] = useState(
    InstallationStore.installation.caseNumber || '',
  );

  const navigate = useNavigate();

  const {getScreen} = useNavigationState();

  return (
    <Fragment>
      <View style={styles.screen}>
        <InstallationTopAppBar />

        <SafeAreaView
          mode="padding"
          edges={['left', 'right']}
          style={styles.bodyContainer}>
          <ScrollView
            keyboardShouldPersistTaps="handled"
            contentContainerStyle={{
              flexGrow: 1,
              padding: moderateScale(16),
            }}>
            <Container
              style={{
                flexGrow: 1,
                flexShrink: 1,
              }}>
              <VINLabel />

              <Label
                variant="H1"
                color={palette.text[1000]}
                style={{
                  textAlign: 'center',
                  marginVertical: moderateScaleV(12),
                }}>
                Case Number
              </Label>

              <Form.Group style={{textAlign: 'center'}}>
                <Form.Label style={{color: palette.text[900]}}>
                  Case Number
                </Form.Label>
                <Form.Control
                  className="text-input text-center"
                  value={newCaseNumber}
                  placeholder="ex. 84287543"
                  onChange={e => {
                    setNewCaseNumber(e.target.value);
                  }}></Form.Control>
              </Form.Group>

              <NavigationBar
                backTo={getScreen('installationCaseNumber.goBack')}
                proceedTo="/hardware-type"
                proceedText={locales.submit}
                onProceed={() => {
                  InstallationStore.installation.caseNumber = newCaseNumber;
                  apiClient.installation.setCaseNumber(
                    InstallationStore.installation.id,
                    newCaseNumber,
                  );
                }}
                className={undefined}
                backText={undefined}
                onBack={undefined}
                isHidden={undefined}
                isDisabled={undefined}
                dontuseNavigate={undefined}
                additionalLoadingState={undefined}
              />

              <Button
                className="mt-3"
                block
                onClick={() => navigate('/hardware-type')}>
                {locales.skip}
              </Button>
            </Container>
          </ScrollView>
        </SafeAreaView>

        <Footer />
      </View>
    </Fragment>
  );
}

const createStyles = ({palette}: ResponsiveStyle) =>
  StyleSheet.create({
    screen: {
      flexGrow: 1,
      flexShrink: 1,
      backgroundColor: palette.surface[50],
    },
    headerDivider: {
      borderBottomWidth: 1,
      borderBottomColor: palette.surface[200],
    },
    bodyContainer: {
      flexGrow: 1,
      flexShrink: 1,
    },
  });

export default observer(InstallationCaseNumber);
