import { hardwareType, hex, assetType, regExps } from "./Constants";

export const dataURItoFile = (dataURI, fileName) => {
  const arr = dataURI.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);

  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) u8arr[n] = bstr.charCodeAt(n);

  return new File([u8arr], fileName, { type: mime });
};

export const parseSerial = (rawSerial) => {
  const dividers = ["$S", "@S"];

  dividers.forEach((d) => {
    const indexOfDivider = rawSerial.indexOf(d);

    if (indexOfDivider !== -1) {
      rawSerial = rawSerial.substring(indexOfDivider + d.length);
    }
  });

  let serial = rawSerial;

  if (rawSerial.indexOf("-") === -1) {
    serial = rawSerial.replace(/(.{2})/g, "$1-");
    serial = serial.replace(/-$/, "");
  }

  return serial;
};

export const containsOnlyNumbers = (string) => /^\d+$/.test(string);

export const removeSpecialCharacters = (string) => string.replace(/[^a-zA-Z0-9]/g, "");

export const prepareManuallyEnteredSerial = (serial) => removeSpecialCharacters(serial);

export const getHexCount = (string) =>
  string.split("").reduce((total, symbol) => {
    if (symbol !== "-" && hex.includes(symbol)) total++;

    return total;
  }, 0);

export const validateSerial = (serial) => getHexCount(serial) === 12;

export const validateGatewayEsn = (serial) => {
  const hexCount = getHexCount(serial);
  return hexCount === 10 || hexCount === 12;
};

export function getEsn(esn) {
  if (!esn?.length) return "";

  esn = esn.startsWith("00") ? esn.substring(2) : esn;
  esn = removeSpecialCharacters(esn);
  esn = insertSeparatorBetweenChars(esn, "-", 2);

  return esn;
}

export const hardwareBelongsToCurrentAsset = (warning, vin) => warning.includes(`Hardware already belongs to asset ${vin}`);

const getShortMac = (sensor) => {
  if (sensor.esn) return sensor.esn.substring(12);

  if (sensor.psmId) return sensor.psmId.substring(12);
};

export const findShortMacDuplicate = (serial, code, sensors, sensorPositions) => {
  const shortMacError = {
    value: false,
    text: "",
  };

  const shortMac = serial.substring(12);

  if (shortMac) {
    const sensorWithSameShortMac = sensors.find((s) => getShortMac(s) === shortMac && s.code !== code);

    if (sensorWithSameShortMac) {
      shortMacError.value = true;

      const existingSensorPosition = sensorPositions.find((p) => p.code === sensorWithSameShortMac.code);

      const position = existingSensorPosition ? `${existingSensorPosition.name}` : "Axle Load";

      shortMacError.text = `Please select a different sensor for installation - 
      this sensor has the same short MAC with position "${position}"`;
    }
  }

  return shortMacError;
};

export const findShortMacDuplicates = (sensors, sensorPositions) => {
  const shortMacError = {
    value: false,
    text: "",
  };

  for (let i = 0; i < sensors.length; i++) {
    const currentSensor = sensors[i];
    const currentShortMac = getShortMac(currentSensor);

    if (currentShortMac?.length) {
      const sensorWithSameShortMac = sensors.find((s, index) => getShortMac(s) === currentShortMac && index !== i);

      if (sensorWithSameShortMac) {
        const existingSensorPosition = sensorPositions.find((p) => p.code === sensorWithSameShortMac.code);
        const currentSensorPosition = sensorPositions.find((p) => p.code === currentSensor.code);

        shortMacError.value = true;
        shortMacError.text = `You installed two TPMS sensors with the same short MAC on positions "${existingSensorPosition.code}" 
        and "${currentSensorPosition.code}", please use different sensor`;

        break;
      }
    }
  }

  return shortMacError;
};

export const isIos = navigator.platform.match(/iPhone|iPod|iPad/);

export const checkGatewayCount = (gatewaysCount, sensorsCount) => {
  if (!sensorsCount) return;

  if (!gatewaysCount) return "Please go back and provision a gateway first";
};

export const checkTpmsSensorsCount = (gatewaysCount, sensorsCount) => {
  if (!sensorsCount) return;

  const maxSensorCountPerGateway = 10;

  const diff = sensorsCount - maxSensorCountPerGateway;

  if (!gatewaysCount) return "Please go back and provision a gateway first";

  if (gatewaysCount === 1 && sensorsCount > maxSensorCountPerGateway) {
    return `Only 10 TPMS sensors can be provisioned per gateway, please either remove ${diff} sensors, 
    or provision an additional Gateway first and then add the additional TPMS sensors`;
  }

  if (gatewaysCount === 2 && sensorsCount > maxSensorCountPerGateway * 2) {
    return `The maximum number of TPMS sensors that are supported in this configuration is 20. 
    Please limit provisioning to 20 or fewer TPMS sensor`;
  }
};

export function hardwareToSensorType(hardwareType) {
  return hardwareType - 1;
}

export function sensorToHardwareType(sensorType) {
  return sensorType + 1;
}

export function isHardwareTypeSensor(type) {
  return type === hardwareType.smartHubSensor || type === hardwareType.tpmsSensor || type === hardwareType.axleLoad || type === hardwareType.linePressure;
}

export function parseRivataAssetType(rivataType) {
  switch (rivataType) {
    case "trailer":
      return assetType.trailer;
    case "tractor":
    case "truck":
      return assetType.tractor;
    default:
      return assetType.trailer;
  }
}

export function getRivataAssetType(type) {
  switch (type) {
    case assetType.trailer:
      return "trailer";
    case assetType.tractor:
      return "tractor";
    default:
      return "";
  }
}

export function areValuesEqual(valueOne, valueTwo) {
  if (!valueOne || !valueTwo) return false;

  return valueOne.toString().trim().toLowerCase() === valueTwo.toString().trim().toLowerCase();
}

export function listToTree(list) {
  const map = {};

  const roots = [];

  for (let i = 0; i < list.length; i += 1) {
    map[list[i].id] = i;
    list[i].children = [];
  }

  for (let i = 0; i < list.length; i += 1) {
    let node = list[i];

    if (node.parent_id && map[node.parent_id]) {
      list[map[node.parent_id]].children.push(node);
    } else {
      roots.push(node);
    }
  }

  roots.forEach((root) => (root.children = root.children.sort((a, b) => a.name.localeCompare(b.name))));

  return roots.sort((a, b) => a.name.localeCompare(b.name));
}

export function insertSeparatorBetweenChars(text, separator, charGroupLength) {
  const regex = new RegExp(`(.{${charGroupLength}})`, "g");
  const separetedText = text.replace(regex, "$1" + separator);

  return text.length % charGroupLength === 0 ? separetedText.slice(0, -separator.length) : separetedText;
}

export function validateYearToInt(year, orValue) {
  let value = String(year);
  value = value.replace(regExps.eachNonInt, "");

  if (value.length === 0) return orValue;

  value = Number(value);

  if (isNaN(value) || isFinite(value) === false) return orValue;

  return value;
}
