import {action, computed, makeObservable, observable} from 'mobx';
import locales from '../../locales/en';
import ApiClient from '../../services/apiClient';
import Logging from '../../services/logging';
import {
  parseSerial,
  removeSpecialCharacters,
  validateGatewayEsn,
} from '../../utils';
import ErrorStore from '../error/ErrorStore';
import GatewayModel from './GatewayModel';

const gatewayDefaults = {
  id: 0,
  esn: '',
  s3key: null,
  powers3key: null,
  installationId: 0,
};

type GatewayMapKey = 'primary' | 'secondary';

export type ValidationResult = {
  status: 'warning' | 'error';
  message: string;
  gatewayMacs: string[];
};

export default class GatewayStore {
  gateways = new Map<GatewayMapKey, GatewayModel>();

  validationResults: ValidationResult[] = [];

  isLoading: boolean = false;

  errorStore: ErrorStore;

  constructor(private readonly apiClient: ApiClient) {
    this.errorStore = new ErrorStore();

    makeObservable(this, {
      gateways: observable,
      isLoading: observable,
      validationResults: observable,

      set: action,
      setIsLoading: action,
      selectPrimaryGateway: action,
      selectSecondaryGateway: action,
      addGateway: action,
      uploadGateway: action,
      setValidationResults: action,
      validate: action,

      primaryGateway: computed,
      secondaryGateway: computed,
      selectedGateway: computed,
      gatewaysArray: computed,
      filteredGateways: computed,
      anyLoading: computed,
      validationErrorResults: computed,
      hasValidationError: computed,
    });
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  selectPrimaryGateway() {
    this.errorStore.clearError();

    if (!this.primaryGateway) {
      this.gateways.set(
        'primary',
        new GatewayModel({...gatewayDefaults}, true, this.apiClient),
      );
    }

    if (this.primaryGateway) {
      this.primaryGateway.isSelected
        ? this.primaryGateway.setSelected(false)
        : this.primaryGateway.setSelected(true);
    }

    if (this.secondaryGateway) {
      this.secondaryGateway.setSelected(false);
    }
  }

  selectSecondaryGateway() {
    this.errorStore.clearError();

    if (!this.secondaryGateway) {
      this.gateways.set(
        'secondary',
        new GatewayModel({...gatewayDefaults}, false, this.apiClient),
      );
    }

    if (this.secondaryGateway) {
      this.secondaryGateway.isSelected
        ? this.secondaryGateway.setSelected(false)
        : this.secondaryGateway.setSelected(true);
    }

    if (this.primaryGateway) {
      this.primaryGateway.setSelected(false);
    }
  }

  prepareEsn(esn: string, manuallyEntered?: boolean) {
    if (manuallyEntered) {
      esn = removeSpecialCharacters(esn);
    }

    esn = esn.toUpperCase();

    esn = parseSerial(esn);

    if (esn.length === 14) {
      esn = `00-${esn}`;
    }

    return esn;
  }

  async addGateway({
    esn,
    manuallyEntered,
    assetId,
    assetVin,
    installationId,
  }: {
    esn: string;
    manuallyEntered?: boolean;
    assetId: number;
    assetVin: string;
    installationId: number;
  }) {
    if (!this.selectedGateway) {
      return;
    }

    this.errorStore.clearError();

    esn = this.prepareEsn(esn, manuallyEntered);

    const isValid = validateGatewayEsn(esn);

    if (!isValid) {
      this.errorStore.setError(locales.sensorErrorOfGateway);
      return;
    }

    const existingEsn = [...this.gateways.values()].find(g => g.esn === esn);

    if (existingEsn) {
      this.errorStore.setError('ESN is already in use');
      return;
    }

    return await this.uploadGateway(esn, assetId, assetVin, installationId);
  }

  async uploadGateway(
    esn: string,
    assetId: number,
    assetVin: string,
    installationId: number,
  ) {
    if (!this.selectedGateway) {
      return;
    }

    this.setIsLoading(true);

    try {
      const data = await this.apiClient.fetchStore.post('gateway', {
        model: {
          esn,
          assetId,
          installationId,
        },
        existingEsn: this.selectedGateway.esn || null,
        vin: assetVin,
      });

      if (!data.id) {
        this.errorStore.setError('Data not uploaded, uncaught error!');
        return;
      }

      this.selectedGateway.update(data);

      this.setValidationResults(data.validationResults);

      this.selectedGateway.setSelected(false);

      return {
        added: true,
        isValid: !this.validationResults.length,
      };
    } catch (e: unknown) {
      const error = Logging.parseUnknownError(e);
      Logging.recordError(error);

      this.errorStore.setError(error.message);
    } finally {
      this.setIsLoading(false);
    }
  }

  setValidationResults(validationResults: ValidationResult[]) {
    this.validationResults = validationResults;

    this.primaryGateway?.setValidationResult(null);
    this.secondaryGateway?.setValidationResult(null);

    this.validationResults.forEach(r => {
      if (
        this.primaryGateway &&
        r.gatewayMacs.includes(this.primaryGateway.esn)
      ) {
        this.primaryGateway.setValidationResult(r);
      }

      if (
        this.secondaryGateway &&
        r.gatewayMacs.includes(this.secondaryGateway.esn)
      ) {
        this.secondaryGateway.setValidationResult(r);
      }
    });
  }

  set(gateways: Array<GatewayDto>) {
    gateways.forEach((gateway, index) => {
      const isPrimary = index === 0;

      this.gateways.set(
        isPrimary ? 'primary' : 'secondary',
        new GatewayModel(gateway, isPrimary, this.apiClient),
      );
    });
  }

  async validate(vin: string) {
    const gatewayMacs = Array.from(this.gateways.values()).map(
      gateway => gateway.esn,
    );

    const validationResults = await this.apiClient.fetchStore.post(
      'gateway/validate',
      {
        gatewayMacs,
        vin,
      },
    );

    this.setValidationResults(validationResults);
  }

  get primaryGateway() {
    return this.gateways.get('primary');
  }

  get secondaryGateway() {
    return this.gateways.get('secondary');
  }

  get gatewaysArray() {
    return [this.primaryGateway, this.secondaryGateway];
  }

  get filteredGateways() {
    return this.gatewaysArray.filter(g => g && g.esn);
  }

  get selectedGateway() {
    return this.gatewaysArray.find(g => g?.isSelected);
  }

  get anyLoading() {
    return this.gatewaysArray.some(g => g?.isLoading);
  }

  get validationErrorResults() {
    return this.validationResults.filter(r => r.status === 'error');
  }

  get hasValidationError() {
    return !!this.validationResults.filter(r => r.status === 'error').length;
  }
}
