import React from 'react';
import {HamburgerMenuSvg} from '../iconsExporter';
import {useScale} from '../../theme/scale';
import {usePalette} from '../../theme/palette';

type Props = {
  size?: number;
  stroke?: string;
};

const HamburgerMenuIcon: React.FC<Props> = props => {
  const palette = usePalette();

  const {size = 24, stroke = palette.surface[1000]} = props;

  const {moderateScale} = useScale();

  return (
    <HamburgerMenuSvg
      width={moderateScale(size)}
      height={moderateScale(size)}
      stroke={stroke}
    />
  );
};

export default HamburgerMenuIcon;
