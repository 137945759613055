import {observer} from 'mobx-react-lite';
import React, {Fragment, useEffect, useState} from 'react';
import {Platform, View} from 'react-native';
import Logging from '../../services/logging';
import {useStore} from '../../stores/useStore';
import {usePalette} from '../../theme/palette';
import {useNavigate} from '../../utils/routing';
import AnimatedLogoWrapper from '../AnimatedLogoWrapper';
import ConMetLogo from '../icons/ConMetLogo';
import {useCode} from './useCode';

const NO_CACHED_REFRESH_TOKEN = 'NO_CACHED_REFRESH_TOKEN';

type Props = {
  extractCode?: boolean;
  redirectPaths?: {
    onAuthenticated?: string;
    onNotAuthenticated?: string;
    onAuthenticationLoss?: string;
  };
};

const AuthWrapper: React.FC<Props> = props => {
  const {extractCode, redirectPaths, children} = props;

  const {authStore, userStore, documentStore} = useStore();

  const [showChildren, setShowChildren] = useState(false);

  const {extractCodeFromSearchParams} = useCode();

  const navigate = useNavigate();

  const palette = usePalette();

  const [wasAuthorized, setWasAuthorized] = useState(false);

  useEffect(() => {
    process();
  }, []);

  useEffect(() => {
    if (authStore.isAuthenticated) {
      setWasAuthorized(true);
    }

    if (!authStore.isAuthenticated && wasAuthorized) {
      if (redirectPaths?.onAuthenticationLoss) {
        navigate(redirectPaths.onAuthenticationLoss);
      }

      setWasAuthorized(false);
      setShowChildren(false);
    }
  }, [wasAuthorized, authStore.isAuthenticated, navigate, redirectPaths]);

  async function process() {
    if (authStore.isAuthenticated) {
      if (redirectPaths?.onAuthenticated) {
        navigate(redirectPaths.onAuthenticated);
      }

      setShowChildren(true);

      return;
    }

    let code: string | null | undefined;

    if (extractCode) {
      code = extractCodeFromSearchParams();
    }

    await authenticateUser(code);

    if (!authStore.isAuthenticated) {
      if (redirectPaths?.onNotAuthenticated) {
        navigate(redirectPaths.onNotAuthenticated);
      }

      setShowChildren(true);

      return;
    }

    if (Platform.OS === 'android' || Platform.OS === 'ios') {
      try {
        documentStore.checkPrivacyPolicyUpdate(userStore.id);
      } catch (e) {}
    }

    if (redirectPaths?.onAuthenticated) {
      navigate(redirectPaths.onAuthenticated);
    }

    setShowChildren(true);
  }

  async function authenticateUser(code?: string | null) {
    try {
      await userStore.setupCachedIsRivataUser();

      if (code) {
        await authStore.exchangeCodeToTokens(code);
      } else {
        await authStore.setupCachedRefreshToken();

        if (!authStore.refreshToken) throw new Error(NO_CACHED_REFRESH_TOKEN);

        await authStore.refreshTokens();
      }

      authStore.setupTokensRefresh();

      await userStore.getUser();
    } catch (e: unknown) {
      const error = Logging.parseUnknownError(e);

      await authStore.dispose();
      userStore.dispose();

      if (error.message !== NO_CACHED_REFRESH_TOKEN) {
        Logging.recordError(error);

        authStore.errorStore.setError(error.message);
      }
    }
  }

  if (showChildren) {
    return <Fragment>{children}</Fragment>;
  }

  return (
    <View
      style={{
        flexGrow: 1,
        flexShrink: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: palette.surface[0],
      }}>
      <AnimatedLogoWrapper>
        <ConMetLogo
          variant={palette.mode === 'light' ? 'primaryBlack' : 'primaryWhite'}
          size="big"
        />
      </AnimatedLogoWrapper>
    </View>
  );
};

export default observer(AuthWrapper);
