import AsyncStorage from '@react-native-async-storage/async-storage';
import {action, computed, makeObservable, observable} from 'mobx';
import locales from '../../locales/en';
import ApiClient from '../../services/apiClient';
import Logging from '../../services/logging';
import {AsyncStorageKey} from '../../types/enums';
import BackgroundTimer from '../../utils/backgroundTimer/backgroundTimer';
import ErrorStore from '../error/ErrorStore';
import UserStore from '../user/UserStore';

export default class AuthStore {
  accessToken: string = '';

  refreshToken: string = '';

  idToken: string = '';

  tokenExpiresIn: number = 0;

  isLoading: boolean = false;

  errorStore: ErrorStore;

  constructor(
    private readonly apiClient: ApiClient,
    private readonly userStore: UserStore,
  ) {
    this.errorStore = new ErrorStore();

    makeObservable(this, {
      accessToken: observable,
      refreshToken: observable,
      idToken: observable,
      tokenExpiresIn: observable,
      isLoading: observable,

      isAuthenticated: computed,

      setAccessToken: action,
      setRefreshToken: action,
      setIdToken: action,
      setTokenExpiresIn: action,
      setIsLoading: action,
      exchangeCodeToTokens: action,
      dispose: action,
      setupTokensRefresh: action,
      setupCachedRefreshToken: action,
      refreshTokens: action,
    });
  }

  get isAuthenticated() {
    return !!this.accessToken;
  }

  setAccessToken(accessToken: string) {
    this.accessToken = accessToken;
  }

  async setRefreshToken(refreshToken: string) {
    this.refreshToken = refreshToken;

    await AsyncStorage.setItem(AsyncStorageKey.refreshToken, refreshToken);
  }

  setIdToken(idToken: string) {
    this.idToken = idToken;
  }

  setTokenExpiresIn(tokenExpiresIn: number) {
    this.tokenExpiresIn = tokenExpiresIn;
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  async getSignInUrl() {
    return await this.apiClient.auth.getAuthUrl(this.userStore.isRivataUser);
  }

  async exchangeCodeToTokens(code: string) {
    const tokens = await this.apiClient.auth.exchangeCodeToTokens(
      code,
      this.userStore.isRivataUser,
    );

    if (!tokens) throw new Error('NoAuth');

    this.setIdToken(tokens.idToken);
    this.setAccessToken(tokens.accessToken);
    this.setRefreshToken(tokens.refreshToken);
    this.setTokenExpiresIn(tokens.tokenExpiresIn * 1000);
  }

  async setupCachedRefreshToken() {
    try {
      const token = await AsyncStorage.getItem(AsyncStorageKey.refreshToken);
      this.setRefreshToken(token || '');
    } catch (e: unknown) {
      const error = Logging.parseUnknownError(e);
      Logging.recordError(error);
    }
  }

  async refreshTokens() {
    try {
      if (!this.refreshToken) throw new Error('NoAuth');

      const tokens = await this.apiClient.auth.refreshAccessToken(
        this.refreshToken,
        this.userStore.isRivataUser,
      );

      if (!tokens) throw new Error('NoAuth');

      this.setIdToken(tokens.idToken);
      this.setAccessToken(tokens.accessToken);
      this.setTokenExpiresIn(tokens.tokenExpiresIn * 1000);
    } catch (e: unknown) {
      const error = Logging.parseUnknownError(e);
      Logging.recordError(error);

      if (error.message.includes('invalid_grant') || !this.refreshToken) {
        BackgroundTimer.clearInterval();

        await this.dispose();
        this.userStore.dispose();

        this.errorStore.setError(locales.sessionExpired);
      } else {
        this.errorStore.setError(error.message);
      }
    }
  }

  setupTokensRefresh() {
    BackgroundTimer.clearInterval();

    BackgroundTimer.setInterval(async () => {
      await this.refreshTokens();
    }, this.tokenExpiresIn - 10000);
  }

  async dispose() {
    this.setIsLoading(false);
    this.setAccessToken('');
    this.setRefreshToken('');
    this.setIdToken('');
    this.setTokenExpiresIn(0);
    BackgroundTimer.clearInterval();
  }
}
