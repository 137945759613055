import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {Alert, Button as RbButton} from 'react-bootstrap';
import {observer} from 'mobx-react-lite';
import moment from 'moment';
import InstallationStore from '../../stores/InstallationStore';
import ButtonWithSpinner from '../../components/ButtonWithSpinner';
import InstallationCancelModal from './InstallationCancelModal';
import {installationStatus} from '../../utils/Constants';
import {useStore} from '../../shared/stores/useStore';
import Logging from '../../shared/services/logging';
import {
  FlatList,
  Platform,
  RefreshControl,
  TouchableOpacity,
  View,
} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';
import Container from '../../shared/components/common/Container';
import InstallationTopAppBar from '../../components/InstallationTopAppBar';
import Footer from '../../shared/components/navigation/Footer';
import {ResponsiveStyle, useStyles} from '../../shared/theme/styles';
import {usePalette} from '../../shared/theme/palette';
import {useScale} from '../../shared/theme/scale';
import {StyleSheet} from 'react-native';
import Label from '../../shared/components/common/Label';
import locales from '../../shared/locales/en';
import CheckBox from '../../shared/components/common/CheckBox';
import Spinner from '../../shared/components/common/Spinner';
import Button from '../../shared/components/common/Button';
import {useNavigate} from '../../shared/utils/routing';

function InstallationsList() {
  const {apiClient} = useStore();

  const styles = useStyles(createStyles);

  const palette = usePalette();

  const {moderateScale, moderateScaleV} = useScale();

  const [isLoading, setIsLoading] = useState(false);
  const [installations, setInstallations] = useState([]);
  const [isShowFinished, setIsShowFinished] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    getInstallations();
  }, []);

  useEffect(() => {
    InstallationStore.clear();
  }, []);

  const navigate = useNavigate();

  const getInstallations = async () => {
    setIsLoading(true);

    try {
      const result = await apiClient.installation.getAll();

      if (result) setInstallations(result);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const installationsFilter = (installation: any) => {
    return (
      isShowFinished || installation.status !== installationStatus.finished
    );
  };

  const filteredInstallations = useMemo(
    () => installations.filter(installationsFilter),
    [installations, installationsFilter],
  );

  const onRefresh = React.useCallback(async () => {
    getInstallations();
  }, []);

  const headerNode = (
    <Header
      disabled={isLoading}
      listLabel={
        isLoading
          ? locales.loading
          : `${locales.results} (${filteredInstallations.length})`
      }
      listError={error}
      isCheckedShowFinished={isShowFinished}
      onChangeShowFinished={checked => setIsShowFinished(checked)}
      onToggleShowFinished={() => setIsShowFinished(prev => !prev)}
      onStartInstallation={() => {
        InstallationStore.clear();
        InstallationStore.installationContinuation = false;
        navigate('/asset-info');
      }}
    />
  );

  return (
    <Fragment>
      <View style={styles.screen}>
        <InstallationTopAppBar />

        <FlatList
          ListHeaderComponent={headerNode}
          data={isLoading ? [] : filteredInstallations}
          keyExtractor={installation => (installation as any).id.toString()}
          renderItem={({item, index}) => (
            <SafeAreaView
              mode="padding"
              edges={
                Platform.OS === 'web'
                  ? ['left', 'right']
                  : ['left', 'right', 'bottom']
              }
              style={[
                styles.bodyContainer,
                {
                  paddingHorizontal: moderateScale(16),
                  paddingTop: moderateScaleV(index === 0 ? 16 : 0),
                },
              ]}>
              <Container
                style={{
                  flexGrow: 1,
                  flexShrink: 1,
                }}>
                <InstallationCard
                  key={(item as any).id}
                  installation={item}
                  installations={installations}
                  setInstallations={setInstallations}
                  setError={setError}
                />
              </Container>
            </SafeAreaView>
          )}
          refreshControl={
            <RefreshControl refreshing={isLoading} onRefresh={onRefresh} />
          }
          ListEmptyComponent={
            <View
              style={{
                flexGrow: 1,
                flexShrink: 1,
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Spinner fill={palette.surface[1000]} />
            </View>
          }
          contentContainerStyle={{
            flexGrow: 1,
            backgroundColor: palette.surface[100],
          }}
        />

        {Platform.OS === 'web' && <Footer />}
      </View>
    </Fragment>
  );
}

const InstallationCard = observer(
  ({
    installation,
    installations,
    setInstallations,
    setError,
  }: {
    installation: any;
    installations: any;
    setInstallations: any;
    setError: any;
  }) => {
    const {apiClient, userStore} = useStore();

    const [isLoading, setIsLoading] = useState(false);
    const [isCanceling, setIsCanceling] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const navigate = useNavigate();

    async function previewNotCompletedInstallation() {
      navigate(`/installations/${installation.id}/asset-info`);
    }

    const cancelInstallation = async (installationId: number) => {
      try {
        setIsCanceling(true);
        setError('');

        await apiClient.installation.cancel(installation.id).then(() => {
          const filteredInstallations = installations.filter(
            (installation: any) => installation.id !== installationId,
          );
          setInstallations(filteredInstallations);
        });
      } catch (e: unknown) {
        const _error = Logging.parseUnknownError(e);
        Logging.recordError(_error);
        setError(_error.message);
      } finally {
        setIsCanceling(false);
      }
    };

    const getVariant = (installation: any) => {
      switch (installation.status) {
        case installationStatus.finished:
          return 'success';
        case installationStatus.inProgress:
          return 'primary';
        default:
          return 'warning';
      }
    };

    const [acknowledgeProcessing, setAcknowledgeProcessing] = useState(false);

    const acknowledgeInstallation = async (installationId: number) => {
      try {
        setAcknowledgeProcessing(true);
        setError('');

        await apiClient.installation.acknowledge(installationId).then(() => {
          const filteredInstallations = installations.filter(
            (installation: any) => installation.id !== installationId,
          );
          setInstallations(filteredInstallations);
        });
      } catch (e: unknown) {
        const _error = Logging.parseUnknownError(e);
        Logging.recordError(_error);
        setError(_error.message);
      } finally {
        setAcknowledgeProcessing(false);
      }
    };

    return (
      <Alert variant={getVariant(installation)}>
        <div className="d-flex justify-content-between align-items-center">
          <div className="text-left">
            <div
              style={{
                wordBreak: 'break-all',
              }}>{`VIN: ${installation.vin}`}</div>

            {installation.status === installationStatus.finished && (
              <div
                style={{
                  wordBreak: 'break-all',
                }}>{`Asset Name: ${installation.assetName}`}</div>
            )}

            {userStore.isRivataUser === false && (
              <div>{`Case Number: ${
                installation.caseNumber || 'Not Provided'
              }`}</div>
            )}

            {installation.status === installationStatus.canceled && (
              <div>
                {installation.finishedAt
                  ? `Canceled at: ${moment
                      .utc(installation.finishedAt)
                      .local()
                      .format('L hh:mm A')}`
                  : `Canceled!`}
              </div>
            )}

            {installation.status === installationStatus.inProgress &&
              installation.startedAt && (
                <div>
                  Started at:{' '}
                  {moment
                    .utc(installation.startedAt)
                    .local()
                    .format('L hh:mm A')}
                </div>
              )}

            {installation.status === installationStatus.finished &&
              installation.finishedAt && (
                <div>
                  Finished at:{' '}
                  {moment
                    .utc(installation.finishedAt)
                    .local()
                    .format('L hh:mm A')}
                </div>
              )}
          </div>
          <div className="d-flex align-items-center">
            {installation.status === installationStatus.inProgress && (
              <div className="d-flex flex-column">
                <ButtonWithSpinner
                  disabled={isCanceling}
                  isLoading={isLoading}
                  onClick={previewNotCompletedInstallation}
                  variant={undefined}
                  type={undefined}
                  className={undefined}
                  block={undefined}
                  style={undefined}>
                  Continue
                </ButtonWithSpinner>

                <ButtonWithSpinner
                  onClick={() => {
                    setShowModal(true);
                  }}
                  className="mt-1"
                  isLoading={isCanceling}
                  disabled={isLoading}
                  variant={undefined}
                  type={undefined}
                  block={undefined}
                  style={undefined}>
                  Cancel
                </ButtonWithSpinner>
              </div>
            )}

            {installation.status === installationStatus.canceled ? (
              <ButtonWithSpinner
                isLoading={acknowledgeProcessing}
                variant="warning"
                onClick={() => acknowledgeInstallation(installation.id)}
                type={undefined}
                className={undefined}
                disabled={undefined}
                block={undefined}
                style={undefined}>
                Acknowledge
              </ButtonWithSpinner>
            ) : (
              <ValidationButton installation={installation} />
            )}

            <InstallationCancelModal
              showModal={showModal}
              onClose={() => setShowModal(false)}
              onProceed={async () => {
                setShowModal(false);
                await cancelInstallation(installation.id);
              }}
            />
          </div>
        </div>
      </Alert>
    );
  },
);

const ValidationButton = observer(({installation}: {installation: any}) => {
  const navigate = useNavigate();

  const proceedToValidation = () => {
    InstallationStore.installationToValidate = installation.id;
    navigate('/installation-validation');
  };

  if (installation.status === installationStatus.inProgress) return null;

  if (installation.isValidated)
    return <div className="mr-3 font-weight-bold">Validated!</div>;

  return <RbButton onClick={proceedToValidation}>Validate</RbButton>;
});

type HeaderProps = {
  disabled: boolean;
  listLabel: string;
  listError: string | null | undefined;
  isCheckedShowFinished: boolean;
  onToggleShowFinished: () => void;
  onChangeShowFinished: (checked: boolean) => void;
  onStartInstallation: () => void;
};

const Header: React.FC<HeaderProps> = props => {
  const {
    disabled,
    listLabel,
    listError,
    isCheckedShowFinished,
    onToggleShowFinished,
    onChangeShowFinished,
    onStartInstallation,
  } = props;

  const styles = useStyles(createStyles);

  const {moderateScale, moderateScaleV} = useScale();

  const palette = usePalette();

  return (
    <Fragment>
      <SafeAreaView
        mode="padding"
        edges={['left', 'right']}
        style={[
          styles.bodyContainer,
          {
            paddingHorizontal: moderateScale(16),
            backgroundColor: palette.surface[50],
          },
        ]}>
        <Container>
          <Label
            variant="H1"
            color={palette.text[1000]}
            style={{
              marginTop: moderateScaleV(12),
              marginBottom: moderateScaleV(24),
            }}>
            {locales.selectInstallation}
          </Label>

          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}>
            <TouchableOpacity
              disabled={disabled}
              onPress={onToggleShowFinished}
              style={{flexDirection: 'row', alignItems: 'center'}}>
              <CheckBox
                disabled={disabled}
                checked={isCheckedShowFinished}
                onChange={onChangeShowFinished}
              />

              <Label
                variant="B2"
                color={disabled ? palette.text[300] : palette.text[900]}
                style={{marginLeft: moderateScale(6)}}>
                {locales.showFinished}
              </Label>
            </TouchableOpacity>

            <Button
              variant="filled"
              isDisabled={disabled}
              text={locales.startInstallation}
              onPress={onStartInstallation}
            />
          </View>

          <Label
            variant="L1"
            color={listError ? palette.error[300] : palette.text[900]}
            style={{
              marginTop: moderateScaleV(24),
              marginBottom: moderateScaleV(12),
            }}>
            {listError ? listError : listLabel}
          </Label>
        </Container>
      </SafeAreaView>

      <View style={styles.headerDivider} />
    </Fragment>
  );
};

const createStyles = ({palette}: ResponsiveStyle) =>
  StyleSheet.create({
    screen: {
      flexGrow: 1,
      flexShrink: 1,
      backgroundColor: palette.surface[50],
    },
    headerDivider: {
      borderBottomWidth: 1,
      borderBottomColor: palette.surface[200],
    },
    bodyContainer: {
      flexGrow: 1,
      flexShrink: 1,
    },
  });

export default InstallationsList;
