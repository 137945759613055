import React from "react";

import { Button, Spinner } from "react-bootstrap";

function ButtonWithSpinner({ variant, type, className, disabled, isLoading, children, onClick, block, style }) {
  return (
    <Button
      variant={variant}
      type={type}
      className={"d-flex justify-content-center align-items-center " + className}
      disabled={isLoading || disabled}
      onClick={onClick}
      block={block}
      style={style}
    >
      {children} {isLoading && <Spinner className="ml-2" animation="border" size="sm" variant="light" />}
    </Button>
  );
}

export default ButtonWithSpinner;
