import {BreakpointsProviderValue, useBreakpoints} from './breakpoints';
import {ColorsProviderValue, useColors} from './colors';
import {Palette, usePalette} from './palette';
import {ScaleProviderValue, useScale} from './scale';

export type ResponsiveStyle = {
  scale: ScaleProviderValue;
  breakpoints: BreakpointsProviderValue;
  colors: ColorsProviderValue;
  palette: Palette;
};

export function useStyles<T>(styles: (responsiveStyle: ResponsiveStyle) => T) {
  const scale = useScale();

  const breakpoints = useBreakpoints();

  const colors = useColors();

  const palette = usePalette();

  return styles({
    scale,
    breakpoints,
    colors,
    palette,
  });
}
