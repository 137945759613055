import { observer } from "mobx-react-lite";
import React from "react";
import { hardwareType, packetType } from "../utils/Constants";
import { hardwareToSensorType, isHardwareTypeSensor } from "../utils/Utils";
import HardwareGroup from "./HardwareGroup";

const hardwareGroupList = [
  {
    name: "Gateway",
    type: hardwareType.gateway,
  },
  {
    name: "SmartHub",
    type: hardwareType.smartHubSensor,
  },
  {
    name: "TPMS",
    type: hardwareType.tpmsSensor,
  },
  {
    name: "Axle Load",
    type: hardwareType.axleLoad,
  },
  {
    name: "Line Pressure",
    type: hardwareType.linePressure,
  },
];

export default observer(HardwareGroups);
function HardwareGroups({ assetType, hardwareStore }) {
  return (
    <>
      {hardwareGroupList.map((hardwareGroup) => {
        const hardwareList = isHardwareTypeSensor(hardwareGroup.type)
          ? hardwareStore.getFilterSensorsByType(hardwareToSensorType(hardwareGroup.type), assetType)
          : hardwareStore.getHardwareByType(hardwareGroup.type);

        if (hardwareList.length === 0) return null;

        return (
          <HardwareGroup key={hardwareGroup.type} hardwareTypeName={hardwareGroup.name}>
            {hardwareList.map((hardware) => (
              <HardwareGroup.Item
                key={hardware.mac}
                reportColorVariant={hardware.reportColorVariant}
                hardwareCode={isHardwareTypeSensor(hardwareGroup.type) ? hardware.code : undefined}
                hardwareMac={hardware.mac}
                latestPacketUpdateDate={hardware.latestPacketUpdateDate}
                belongsToAnotherAssetText={hardware.belongsToAnotherAsset}
              >
                {hardware.latestPackets.map((packet) => {
                  if (packet.key === packetType.tpmsPressure || packet.key === packetType.axleLoad || packet.key === packetType.linePressure) {
                    return <HardwareGroup.Item.Packet key={packet.key} value={`Pressure: ${packet.data.value} PSI`} />;
                  }

                  return null;
                })}
              </HardwareGroup.Item>
            ))}
          </HardwareGroup>
        );
      })}
    </>
  );
}
