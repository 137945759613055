import {ConfigValue, FirebaseRemoteConfig} from './types';

const remoteConfig: FirebaseRemoteConfig = {
  setDefaults: async () => null,
  fetch: async () => {},
  activate: async () => true,
  getValue: () => nullableConfigValue,
};

export default remoteConfig;

const nullableConfigValue: ConfigValue = {
  asBoolean: () => false,
  asNumber: () => 0,
  asString: () => '',
  getSource: () => 'default',
};
