import {observer} from 'mobx-react-lite';
import React, {Fragment, useState} from 'react';
import {useEffect} from 'react';
import AssetSearch from '../asset/AssetSearch';
import HardwareStore from '../../stores/HardwareStore';
import HardwareModel from '../../stores/HardwareModel';
import {Alert} from 'react-bootstrap';
import HardwareGroups from '../../components/HardwareGroups';
import OverlaySpinner from '../../components/OverlaySpinner';
import ProvisionStatusAlert from '../../components/ProvisionStatusAlert';
import NavigationBar from '../../components/navigation/NavigationBar';
import {WhitelistingStatusAlerts} from '../../components/WhitelistingStatusAlert';
import {assetType} from '../../utils/Constants';
import moment from 'moment';
import {useStore} from '../../shared/stores/useStore';
import {ResponsiveStyle, useStyles} from '../../shared/theme/styles';
import {ScrollView, StyleSheet, View} from 'react-native';
import {useScale} from '../../shared/theme/scale';
import Footer from '../../shared/components/navigation/Footer';
import {SafeAreaView} from 'react-native-safe-area-context';
import Container from '../../shared/components/common/Container';
import InstallationTopAppBar from '../../components/InstallationTopAppBar';
import Label from '../../shared/components/common/Label';
import {usePalette} from '../../shared/theme/palette';
import ValidationActions from '../../components/ValidationActions';

export default observer(AssetInstallationValidationScreen);
function AssetInstallationValidationScreen() {
  const {apiClient} = useStore();

  const styles = useStyles(createStyles);

  const palette = usePalette();

  const {moderateScale} = useScale();

  const [isValidationValid, setIsValidationValid] = useState(false);
  const [hardwareStore, setHardwareStore] = useState(new HardwareStore());

  const [filter, setFilter] = useState({value: '', withoutUpdate: true});
  const [nothingFound, setNothingFound] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(null);

  const [provisionStatusInfo, setProvisionStatusInfo] = useState(null);
  const [whitelistingStatuses, setWhitelistingStatuses] = useState([]);

  const [provisionTimestamp, setProvisionTimestamp] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  async function validateHardwareAndGetLatestData(vin: any) {
    const assetLastProvision =
      await apiClient.installation.getAssetLastProvisionByVin(vin);

    if (!assetLastProvision)
      throw new Error(`Failed to validate asset provision. Try again.`);

    if ('message' in assetLastProvision) {
      setMessage(assetLastProvision.message);
      setHardwareStore(new HardwareStore());
      setIsValidationValid(false);
      setProvisionStatusInfo(null);
      setWhitelistingStatuses([]);
      setProvisionTimestamp(null);
      return;
    }
    setMessage('');

    setIsValidationValid(
      assetLastProvision.isValid ? assetLastProvision.isValid : false,
    );
    setProvisionStatusInfo(
      assetLastProvision.provisionStatus
        ? assetLastProvision.provisionStatus
        : null,
    );

    setProvisionTimestamp(assetLastProvision.timestamp);

    const newHardwareStore = new HardwareStore();

    const hardwareUnits = assetLastProvision.hardware
      ? assetLastProvision.hardware.map((r: any) => new HardwareModel(r))
      : [];
    newHardwareStore.setHardwareUnits(hardwareUnits);

    await newHardwareStore.setupLatestPackets(apiClient.installation);

    setHardwareStore(newHardwareStore);
  }

  async function getWhitelistingStatus(vin: any) {
    const data = await apiClient.installation.getProvisionByVin(vin);
    if (!data) return;

    const {whitelisting_statuses} =
      await apiClient.installation.getProvisionWhitelistingStatus(
        data.provisioning_id,
      );
    setWhitelistingStatuses(whitelisting_statuses);
  }

  async function validate() {
    if (!selectedAsset) return;

    setIsLoading(true);
    setError('');

    try {
      await Promise.all([
        getWhitelistingStatus((selectedAsset as any).vin),
        validateHardwareAndGetLatestData((selectedAsset as any).vin),
      ]);
    } catch (e) {
      setMessage('');
      setHardwareStore(new HardwareStore());
      setIsValidationValid(false);
      setProvisionStatusInfo(null);
      setWhitelistingStatuses([]);
      setProvisionTimestamp(null);

      setError((e as any)?.message || '');
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    validate();
  }, [selectedAsset]);

  return (
    <Fragment>
      <View style={styles.screen}>
        <InstallationTopAppBar />

        <SafeAreaView
          mode="padding"
          edges={['left', 'right']}
          style={styles.bodyContainer}>
          <ScrollView
            keyboardShouldPersistTaps="handled"
            contentContainerStyle={{
              flexGrow: 1,
              padding: moderateScale(16),
            }}>
            <Container
              style={{
                flexGrow: 1,
                flexShrink: 1,
              }}>
              {selectedAsset && (
                <View style={{alignItems: 'center'}}>
                  <Label variant="P1" color={palette.text[1000]}>
                    {'VIN: ' + (selectedAsset as any).vin}
                  </Label>

                  <Label variant="P1" color={palette.text[1000]}>
                    {'Asset Name: ' + (selectedAsset as any).name}
                  </Label>
                </View>
              )}

              <Label
                variant="H3"
                color={palette.text[1000]}
                style={{textAlign: 'center', marginBottom: 12}}>
                Validate Asset Installation
              </Label>

              {!!provisionTimestamp && (
                <Label
                  variant="P1"
                  color={palette.text[1000]}
                  style={{textAlign: 'center', marginBottom: 12}}>
                  {'Last provisioning' +
                    ': ' +
                    moment
                      .unix(provisionTimestamp)
                      .format('MM/DD/YYYY hh:mm A')}
                </Label>
              )}

              <div className="mb-3">
                <AssetSearch
                  filter={filter}
                  setFilter={setFilter}
                  nothingFound={nothingFound}
                  setNothingFound={setNothingFound}
                  fetchVin={(asset: any) => setSelectedAsset(asset)}
                  disabled={isLoading}
                />
              </div>

              {!!error && <Alert variant="danger">{error}</Alert>}

              {!!message && <Alert variant="dark">{message}</Alert>}

              {provisionStatusInfo && (
                <ProvisionStatusAlert
                  provisionStatusInfo={provisionStatusInfo}
                />
              )}

              <WhitelistingStatusAlerts
                whitelistingStatuses={whitelistingStatuses}
              />

              {selectedAsset && (
                <ValidationActions
                  isValidationValid={isValidationValid}
                  lastDataButtonProps={{
                    disabled: isLoading || !selectedAsset,
                    onPress: () => validate(),
                  }}
                />
              )}

              {isLoading && <OverlaySpinner />}

              {selectedAsset && (
                <HardwareGroups
                  assetType={
                    (selectedAsset as any).asset_type === 'tractor'
                      ? assetType.tractor
                      : assetType.trailer
                  }
                  hardwareStore={hardwareStore}
                />
              )}

              <NavigationBar
                backTo="/"
                isDisabled={isLoading}
                className={undefined}
                backText={undefined}
                onBack={undefined}
                proceedText={undefined}
                proceedTo={undefined}
                onProceed={undefined}
                isHidden={undefined}
                dontuseNavigate={undefined}
                additionalLoadingState={undefined}
              />
            </Container>
          </ScrollView>
        </SafeAreaView>

        <Footer />
      </View>
    </Fragment>
  );
}

const createStyles = ({palette}: ResponsiveStyle) =>
  StyleSheet.create({
    screen: {
      flexGrow: 1,
      flexShrink: 1,
      backgroundColor: palette.surface[50],
    },
    headerDivider: {
      borderBottomWidth: 1,
      borderBottomColor: palette.surface[200],
    },
    bodyContainer: {
      flexGrow: 1,
      flexShrink: 1,
    },
  });
