import React from 'react';
import {Button} from 'react-bootstrap';
import {observer} from 'mobx-react-lite';

import AssetStore from '../../../stores/AssetStore';
import SensorsStore from '../../../stores/SensorsStore';
import {assetType, sensorType} from '../../../utils/Constants';
import {TouchableWithoutFeedback, View, StyleSheet} from 'react-native';
import XCircleIcon from '../../../shared/components/icons/XCircleIcon';
import {ResponsiveStyle, useStyles} from '../../../shared/theme/styles';
import {useStore} from '../../../shared/stores/useStore';

function TPMSGrid({selectedSensorCode, setSelectedSensorCode}: any) {
  const sensorPositions = AssetStore.getSensorsPositionsList(sensorType.tpms);

  if (AssetStore.type === assetType.tractor)
    return (
      <TractorGrid
        positions={sensorPositions}
        selectedSensorCode={selectedSensorCode}
        setSelectedSensorCode={setSelectedSensorCode}
      />
    );

  return (
    <TrailerGrid
      positions={sensorPositions}
      selectedSensorCode={selectedSensorCode}
      setSelectedSensorCode={setSelectedSensorCode}
    />
  );
}

const TractorGrid = observer(
  ({positions, selectedSensorCode, setSelectedSensorCode}: any) => {
    return (
      <div className="row">
        <SensorButton
          code={positions[0].code}
          name={positions[0].name}
          selected={selectedSensorCode}
          setSelected={setSelectedSensorCode}
        />
        <SensorButton
          className="offset-6"
          code={positions[1].code}
          name={positions[1].name}
          selected={selectedSensorCode}
          setSelected={setSelectedSensorCode}
        />

        {positions.slice(2).map((p: any) => (
          <SensorButton
            key={p.code}
            code={p.code}
            name={p.name}
            selected={selectedSensorCode}
            setSelected={setSelectedSensorCode}
          />
        ))}
      </div>
    );
  },
);

const TrailerGrid = observer(
  ({positions, selectedSensorCode, setSelectedSensorCode}: any) => {
    return (
      <div className="row">
        {positions.map((p: any) => (
          <SensorButton
            key={p.code}
            code={p.code}
            name={p.name}
            selected={selectedSensorCode}
            setSelected={setSelectedSensorCode}
          />
        ))}
      </div>
    );
  },
);

const SensorButton = observer(
  ({className, code, name, selected, setSelected}: any) => {
    const {apiClient} = useStore();

    const styles = useStyles(createStyles);

    const sensor = SensorsStore.sensors.find(
      s => s.code === code && s.type === SensorsStore.sensorsType,
    );

    const del = async (id: any) => {
      SensorsStore.sensors = SensorsStore.sensors.filter(s => s.id !== id);
      apiClient.sensor.delete(id);
    };

    const getVariant = () => {
      if (selected === code) return 'primary';

      if (!sensor?.isUploaded) return 'outline-primary';

      if (sensor?.warning) return 'outline-warning';

      return 'outline-success';
    };

    const getEsn = (esn: any) => esn.slice(esn.length - 8);

    return (
      <div className={'col-3 mb-2 px-2 ' + (className || '')}>
        <Button
          className="sensor-button"
          variant={getVariant()}
          onClick={() =>
            setSelected((prev: any) => (code === prev ? null : code))
          }>
          {name}

          <br />

          {sensor?.esn && getEsn(sensor.esn)}

          {sensor?.isUploaded && (
            <TouchableWithoutFeedback onPress={() => del(sensor.id)}>
              <View style={styles.removeIcon}>
                <XCircleIcon />
              </View>
            </TouchableWithoutFeedback>
          )}
        </Button>
      </div>
    );
  },
);

export default observer(TPMSGrid);

const createStyles = ({scale: {moderateScale}}: ResponsiveStyle) =>
  StyleSheet.create({
    removeIcon: {
      position: 'absolute',
      top: -moderateScale(6),
      right: -moderateScale(6),
    },
  });
