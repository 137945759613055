import FetchStore from '../stores/fetch/FetchStore';

export default class AuthApi {
  constructor(private readonly fetchStore: FetchStore) {}

  async refreshAccessToken(
    refreshToken: string,
    isRivataUser: boolean,
  ): Promise<{accessToken: string; idToken: string; tokenExpiresIn: number}> {
    return await this.fetchStore.post('auth/refreshAccessToken', {
      refreshToken,
      isRivataUser,
    });
  }

  async getAuthUrl(isRivataUser: boolean): Promise<string> {
    return await this.fetchStore.get(`auth/url?isRivataUser=${isRivataUser}`);
  }

  async exchangeCodeToTokens(
    code: string,
    isRivataUser: boolean,
  ): Promise<{
    accessToken: string;
    refreshToken: string;
    idToken: string;
    tokenExpiresIn: number;
  }> {
    return await this.fetchStore.post('auth/exchange', {
      code,
      isRivataUser,
    });
  }
}
