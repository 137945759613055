import React, {ReactNode} from 'react';
import {
  Modal,
  ScrollView,
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';
import {ResponsiveStyle, useStyles} from '../../theme/styles';
import shadow from '../../theme/shadow';

type DialogProps = {
  isVisible: boolean;
  children?: ReactNode;
  onBackdropPress?: () => void;
  style?: StyleProp<ViewStyle>;
};

const Dialog = (props: DialogProps) => {
  const {isVisible, children, onBackdropPress, style} = props;

  const styles = useStyles(createStyles);

  return (
    <Modal transparent visible={isVisible} animationType="fade">
      <View style={styles.screen}>
        <TouchableOpacity onPress={onBackdropPress} style={styles.backdrop} />

        <SafeAreaView
          mode="padding"
          pointerEvents="box-none"
          style={styles.dialogWrapper}>
          <View style={[styles.dialog, shadow.xl]}>
            <ScrollView
              style={{flexGrow: 0, flexShrink: 1}}
              contentContainerStyle={style}>
              {children}
            </ScrollView>
          </View>
        </SafeAreaView>
      </View>
    </Modal>
  );
};

export default Dialog;

const createStyles = ({scale: {moderateScale}, palette}: ResponsiveStyle) =>
  StyleSheet.create({
    screen: {
      flexGrow: 1,
      flexShrink: 1,
      position: 'relative',
    },
    backdrop: {
      ...StyleSheet.absoluteFillObject,

      backgroundColor: palette.modalOverlay,
      opacity: 0.5,
      position: 'absolute',
    },
    dialogWrapper: {
      padding: moderateScale(11),
      flexGrow: 1,
      flexShrink: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    dialog: {
      flexGrow: 0,
      flexShrink: 1,
      backgroundColor: palette.surface[0],
      borderColor: palette.surface[300],
      borderWidth: 1,
      borderRadius: 8,
      overflow: 'hidden',
      maxWidth: moderateScale(560),
    },
  });
