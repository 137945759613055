import {observer} from 'mobx-react-lite';
import React, {Fragment} from 'react';
import {ListGroup, ListGroupItem} from 'react-bootstrap';
import {getFormattedDate} from '../utils/DateTime';
import {getEsn} from '../utils/Utils';
import Label from '../shared/components/common/Label';
import {usePalette} from '../shared/theme/palette';

const HardwareGroup = observer(({hardwareTypeName, children}) => {
  const palette = usePalette();

  return (
    <Fragment>
      {!!hardwareTypeName && (
        <Label
          variant="L1"
          color={palette.text[1000]}
          style={{
            textAlign: 'center',
            marginTop: 16,
            marginBottom: 4,
          }}>
          {hardwareTypeName}
        </Label>
      )}

      <ListGroup>{children}</ListGroup>
    </Fragment>
  );
});

const HardwareGroupItem = observer(
  ({
    reportColorVariant,
    hardwareCode,
    hardwareMac,
    latestPacketUpdateDate,
    belongsToAnotherAssetText,
    children,
  }) => {
    return (
      <ListGroupItem variant={reportColorVariant}>
        {!!hardwareCode && !!hardwareMac && (
          <div className="d-flex justify-content-between">
            <Label variant="P1">{hardwareCode.replace(/\_/g, ' ')}</Label>

            <Label variant="P1">{hardwareMac}</Label>
          </div>
        )}

        {!hardwareCode && !!hardwareMac && (
          <div className="d-flex justify-content-end">
            <Label variant="P1">{getEsn(hardwareMac)}</Label>
          </div>
        )}

        {children}

        {latestPacketUpdateDate && (
          <div className="d-flex">
            <div className="d-flex flex-column">
              <Label variant="P1">
                {'Last Update: ' + getFormattedDate(latestPacketUpdateDate)}
              </Label>
            </div>
          </div>
        )}

        {!!belongsToAnotherAssetText && (
          <div className="d-flex">
            <Label variant="P1">{belongsToAnotherAssetText}</Label>
          </div>
        )}
      </ListGroupItem>
    );
  },
);

const HardwareGroupItemPacket = observer(({value}) => {
  return (
    <div className="d-flex">
      <div className="d-flex flex-column">
        <Label variant="P1">{value}</Label>
      </div>
    </div>
  );
});

HardwareGroupItem.Packet = HardwareGroupItemPacket;
HardwareGroup.Item = HardwareGroupItem;

export default HardwareGroup;
