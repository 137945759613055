import React from 'react';
import Label from '../shared/components/common/Label';
import {usePalette} from '../shared/theme/palette';

function Counter({min, max, value, onChange}) {
  const palette = usePalette();

  const increase = () => {
    onChange(value >= max ? max : value + 1);
  };

  const decrease = () => {
    onChange(value <= min ? min : value - 1);
  };

  return (
    <div className="counter" style={{display: 'flex', alignItems: 'center'}}>
      <i className="fas fa-caret-left counter-arrow" onClick={decrease} />

      <Label
        variant="P1"
        color={palette.text[1000]}
        style={{marginHorizontal: 12}}>
        {value}
      </Label>

      <i className="fas fa-caret-right counter-arrow" onClick={increase} />
    </div>
  );
}

export default Counter;
