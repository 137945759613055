import React from 'react';
import {Search} from '../iconsExporter';
import {useScale} from '../../theme/scale';

type Props = {
  size?: number;
  color?: string;
};

const SearchIcon: React.FC<Props> = props => {
  const {size = 24, color = 'red'} = props;

  const {moderateScale} = useScale();

  return (
    <Search
      width={moderateScale(size)}
      height={moderateScale(size)}
      stroke={color}
    />
  );
};

export default SearchIcon;
