import React from "react";
import { Modal, Button } from "react-bootstrap";

const InstallationProceedWarningModal = ({ showModal, onClose, onContinue, onStartOver }) => (
  <Modal show={showModal} onHide={onClose} backdrop="static">
    <Modal.Header closeButton>
      <Modal.Title>Warning</Modal.Title>
    </Modal.Header>
    <Modal.Body>There is already an install in progress for this asset, would you like to continue where you left off, or start over?</Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={onStartOver} style={{ width: "80px" }}>
        Start over
      </Button>
      <Button variant="primary" onClick={onContinue} style={{ width: "80px" }}>
        Continue
      </Button>
    </Modal.Footer>
  </Modal>
);

export default InstallationProceedWarningModal;
