import React, {Fragment} from 'react';
import {Control, Controller, FieldValues} from 'react-hook-form';
import Label from '../../components/common/Label';
import LabelInput from '../../components/common/LabelInput';
import {usePalette} from '../../theme/palette';
import {useScale} from '../../theme/scale';

type Props = {
  control: Control<FieldValues> | undefined;
  disabled: boolean;
};

const AssetModelController: React.FC<Props> = props => {
  const {control, disabled} = props;

  const {moderateScale} = useScale();

  const palette = usePalette();

  return (
    <Controller
      name="model"
      control={control}
      defaultValue={undefined}
      rules={{
        maxLength: {
          value: 100,
          message: 'This field must be at most 100 characters',
        },
      }}
      render={({field: {onChange, value}, fieldState: {error}}) => (
        <Fragment>
          <Label
            variant="L1"
            color={palette.text[900]}
            style={{marginBottom: moderateScale(4)}}>
            Model
          </Label>

          <LabelInput
            isDisabled={disabled}
            value={value}
            onChangeText={onChange}
          />

          {!!error && (
            <Label
              variant="L4"
              color={palette.error[300]}
              style={{marginTop: moderateScale(4)}}>
              {error?.message?.toString()}
            </Label>
          )}
        </Fragment>
      )}
    />
  );
};

export default AssetModelController;
