import React from 'react';
import {useScale} from '../../theme/scale';
import {
  ConMetLogoBlackSvg,
  ConMetLogoPrimaryBlackSvg,
  ConMetLogoPrimaryWhiteSvg,
  ConMetLogoWhiteSvg,
} from '../iconsExporter';

const heightRatio = 64 / 132;

const sizes = {
  small: {
    width: 74,
    height: 74 * heightRatio,
  },
  medium: {
    width: 132,
    height: 64,
  },
  big: {
    width: 220,
    height: 220 * heightRatio,
  },
  extraBig: {
    width: 360,
    height: 360 * heightRatio,
  },
};

type Props = {
  variant: 'primaryBlack' | 'primaryWhite' | 'white' | 'black';
  size: keyof typeof sizes;
};

const ConMetLogo = (props: Props) => {
  const {size, variant} = props;

  const {moderateScale} = useScale();

  if (variant === 'primaryBlack') {
    return (
      <ConMetLogoPrimaryBlackSvg
        width={moderateScale(sizes[size].width)}
        height={moderateScale(sizes[size].height)}
      />
    );
  }

  if (variant === 'primaryWhite') {
    return (
      <ConMetLogoPrimaryWhiteSvg
        width={moderateScale(sizes[size].width)}
        height={moderateScale(sizes[size].height)}
      />
    );
  }

  if (variant === 'black') {
    return (
      <ConMetLogoBlackSvg
        width={moderateScale(sizes[size].width)}
        height={moderateScale(sizes[size].height)}
      />
    );
  }

  if (variant === 'white') {
    return (
      <ConMetLogoWhiteSvg
        width={moderateScale(sizes[size].width)}
        height={moderateScale(sizes[size].height)}
      />
    );
  }

  return null;
};

export default ConMetLogo;
