import {Fragment, createContext, useContext, useEffect} from 'react';
import {
  Easing,
  SharedValue,
  cancelAnimation,
  useSharedValue,
  withRepeat,
  withTiming,
} from 'react-native-reanimated';
import React from 'react';

type ContextValue = {
  scale: SharedValue<number>;
  opacity: SharedValue<number>;
  startAnimation: () => void;
  stopAnimation: () => void;
  resetAnimation: () => void;
};

const LogoAnimationContext = createContext<ContextValue>({
  scale: {value: 1},
  opacity: {value: 0},
  startAnimation: () => {},
  stopAnimation: () => {},
  resetAnimation: () => {},
});

export const LogoAnimationProvider: React.FC = ({children}) => {
  const scale = useSharedValue(1);

  const opacity = useSharedValue(0);

  function startAnimation() {
    scale.value = withRepeat(
      withTiming(1.3, {
        duration: 1600,
        easing: Easing.linear,
      }),
      -1,
      true,
    );

    opacity.value = withTiming(1, {
      duration: 800,
    });
  }

  function resetAnimation() {
    scale.value = 1;
    opacity.value = 0;
  }

  function stopAnimation() {
    cancelAnimation(scale);
    cancelAnimation(opacity);
  }

  return (
    <LogoAnimationContext.Provider
      value={{scale, opacity, startAnimation, stopAnimation, resetAnimation}}>
      {children}
    </LogoAnimationContext.Provider>
  );
};

export const useLogoAnimation = () => {
  return useContext(LogoAnimationContext);
};

export const LogoStartAnimation: React.FC = ({children}) => {
  return (
    <LogoAnimationProvider>
      <LogoAnimationStarter>{children}</LogoAnimationStarter>
    </LogoAnimationProvider>
  );
};

const LogoAnimationStarter: React.FC = ({children}) => {
  const {startAnimation, resetAnimation} = useLogoAnimation();

  useEffect(() => {
    resetAnimation();
    startAnimation();
  }, []);

  return <Fragment>{children}</Fragment>;
};

export const LogoEndAnimation: React.FC = ({children}) => {
  const {stopAnimation} = useLogoAnimation();

  useEffect(() => {
    stopAnimation();
  }, []);

  return <Fragment>{children}</Fragment>;
};
