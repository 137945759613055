import React from "react";
import { Spinner } from "react-bootstrap";

export default OverlaySpinner;
function OverlaySpinner() {
  return <Spinner animation="border" style={styles.spinner} />;
}

const styles = {
  spinner: {
    position: "fixed",
    top: "calc(50% - 1rem)",
    left: "calc(50% - 1rem)",
    zIndex: 1000,
  },
};
