import { action, makeObservable, observable } from "mobx";
import { packetType } from "../utils/Constants";
import { sensorToHardwareType } from "../utils/Utils";
import AssetStore from "./AssetStore";

export default class HardwareStore {
  hardwareUnits = [];

  constructor() {
    makeObservable(this, {
      hardwareUnits: observable,

      setHardwareUnits: action,
      _setupHardwareUnitsLatestPackets: action,
    });
  }

  setHardwareUnits(hadrwareUnits) {
    this.hardwareUnits = hadrwareUnits;
  }

  getHardwareByType(hardwareType) {
    return this.hardwareUnits.filter((h) => h.type === hardwareType);
  }

  getFilterSensorsByType(sensorType, assetType) {
    const sensors = this.getHardwareByType(sensorToHardwareType(sensorType));

    const filteredSensors = [];

    AssetStore.getSensorsPositionsList(sensorType, assetType, 6).forEach((p) => {
      const sensor = sensors.find((s) => s.code === p.code);
      if (sensor) filteredSensors.push(sensor);
    });

    return filteredSensors;
  }

  async setupLatestPackets(installationAPI) {
    if (this.hardwareUnits.length > 0) {
      const hardwareUnitsMacsAndTypes = this.hardwareUnits.map((unit) => ({
        mac: unit.mac,
        type: unit.type,
      }));

      const packets = await installationAPI.getLatestPackets(hardwareUnitsMacsAndTypes);
      if (!packets) throw new Error(`Failed to get hardware latest data. Try again.`);

      this._setupHardwareUnitsLatestPackets(this.hardwareUnits, packets);
    }
  }

  _setupHardwareUnitsLatestPackets(hardwareUnits, packets) {
    hardwareUnits.forEach((h) => h.clearLatestPackets());

    packets.forEach((packet) => {
      if (
        packet.key === packetType.gatewayGpsData ||
        packet.key === packetType.smartHubTemperature ||
        packet.key === packetType.smartHubVibration ||
        packet.key === packetType.tpmsPressure ||
        packet.key === packetType.axleLoad ||
        packet.key === packetType.linePressure
      ) {
        const foundHardwareUnit = hardwareUnits.find((hardwareUnit) => hardwareUnit.mac === packet.mac);

        if (foundHardwareUnit) {
          foundHardwareUnit.addLatestPacket(packet);
        }
      }
    });
  }
}
