import {observer} from 'mobx-react-lite';
import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {Alert, Button as RBButton, Spinner} from 'react-bootstrap';
import {
  ScrollView,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';
import Flash from '../../components/Flash';
import VINLabel from '../../components/VINLabel';
import ManualInputComponent from '../../components/uploaders/ManualInputComponent';
import Dialog from '../../shared/components/common/Dialog';
import Label from '../../shared/components/common/Label';
import FilledButton from '../../shared/components/common/MediumButton/FilledButton';
import TextButton from '../../shared/components/common/MediumButton/TextButton';
import NavigationIcon from '../../shared/components/icons/NavigationIcon';
import XCircleIcon from '../../shared/components/icons/XCircleIcon';
import {useStore} from '../../shared/stores/useStore';
import {useScale} from '../../shared/theme/scale';
import {ResponsiveStyle, useStyles} from '../../shared/theme/styles';
import AssetStore from '../../stores/AssetStore';
import GatewaysStore from '../../stores/GatewaysStore';
import InstallationStore from '../../stores/InstallationStore';
import {getEsn, isIos} from '../../utils/Utils';
import InstallationTopAppBar from '../../components/InstallationTopAppBar';
import {SafeAreaView} from 'react-native-safe-area-context';
import Container from '../../shared/components/common/Container';
import Footer from '../../shared/components/navigation/Footer';
import {usePalette} from '../../shared/theme/palette';
import locales from '../../shared/locales/en';
import {useNavigate} from '../../shared/utils/routing';

function GatewaysData() {
  const {apiClient} = useStore();

  const styles = useStyles(createStyles);

  const {moderateScale} = useScale();

  const palette = usePalette();

  const [selectedCode, setSelectedCode] = useState(null);

  const [showGatewaySensorLimitError, setShowGatewaySensorLimitError] =
    useState(false);

  const [serialNumber, setSerialNumber] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    removeEmpty();
    stopLoadingAll();
  }, []);

  const gateway = useMemo(() => {
    if (selectedCode === null) return null;

    GatewaysStore.clearUnsubmitted();

    return GatewaysStore.get(selectedCode);
  }, [selectedCode]);

  const removeEmpty = () => {
    GatewaysStore.gateways = GatewaysStore.gateways.filter(g => g.id);
  };

  const stopLoadingAll = () => {
    GatewaysStore.gateways = GatewaysStore.gateways.map(g => ({
      ...g,
      isLoading: false,
    }));
  };

  return (
    <Fragment>
      <View style={styles.screen}>
        <InstallationTopAppBar />

        <SafeAreaView
          mode="padding"
          edges={['left', 'right']}
          style={styles.bodyContainer}>
          <ScrollView
            keyboardShouldPersistTaps="handled"
            contentContainerStyle={{
              flexGrow: 1,
              padding: moderateScale(16),
            }}>
            <Container
              style={{
                flexGrow: 1,
                flexShrink: 1,
              }}>
              <VINLabel />

              <Label
                variant="H3"
                color={palette.text[1000]}
                style={{textAlign: 'center', marginBottom: 24}}>
                Install Gateways
              </Label>

              <GatewaysGrid
                selectedCode={selectedCode}
                setSelectedCode={setSelectedCode}
                onDeleted={() => {
                  setShowGatewaySensorLimitError(
                    !!GatewaysStore.sensorCountLimitExceededAcrossGatewaysMessage,
                  );
                }}
              />

              {selectedCode !== null && !gateway?.isLoading && (
                <Fragment>
                  <GatewayInputScanner
                    onScanned={async text => {
                      await GatewaysStore.handleScan({
                        serial: text,
                        gateway,
                        selectedCode,
                        setSelectedCode,
                        gatewayApi: apiClient.gateway,
                      });

                      setShowGatewaySensorLimitError(
                        !!GatewaysStore.sensorCountLimitExceededAcrossGatewaysMessage,
                      );
                    }}
                  />

                  <ManualInputComponent
                    name={'ESN'}
                    tooltipText={
                      'Gateway ESN can be found on top of the product label, it is a 10 or 12 character ESN found next to the ESN bar code.'
                    }
                    serialNumber={serialNumber}
                    setSerialNumber={setSerialNumber}
                    showReminder={undefined}
                  />
                </Fragment>
              )}

              {gateway?.isLoading && (
                <div className="my-3">
                  <Spinner animation="border" />
                </div>
              )}

              {gateway?.error && (
                <Alert className="mt-3 mb-2" variant="danger">
                  {gateway.error}
                </Alert>
              )}

              {showGatewaySensorLimitError && !gateway && (
                <Alert className="mt-3" variant="danger">
                  {GatewaysStore.sensorCountLimitExceededAcrossGatewaysMessage}
                </Alert>
              )}

              {!gateway?.isLoading &&
                GatewaysStore.validationResults.map(validationResult => (
                  <Alert
                    key={validationResult.message}
                    className="mt-2 mb-2"
                    variant={
                      validationResult.status === 'warning'
                        ? 'warning'
                        : 'danger'
                    }>
                    <h6 className="alert-heading mb-1 text-left font-weight-bold">
                      {validationResult.gatewayMacs.join(', ')}
                    </h6>

                    <p className="mb-0 text-left">{validationResult.message}</p>
                  </Alert>
                ))}

              <View style={{marginBottom: moderateScale(16)}} />

              {gateway?.code === 0 && (
                <Fragment>
                  <View
                    style={{
                      flexDirection: 'row',
                    }}>
                    <FilledButton
                      text={locales.submitAndAddMore}
                      isDisabled={GatewaysStore.anyLoading()}
                      onPress={async () => {
                        await GatewaysStore.handleScan({
                          serial: serialNumber,
                          manuallyEntered: true,
                          gateway,
                          selectedCode,
                          setSelectedCode,
                          gatewayApi: apiClient.gateway,
                        });

                        setSerialNumber('');

                        setShowGatewaySensorLimitError(
                          !!GatewaysStore.sensorCountLimitExceededAcrossGatewaysMessage,
                        );
                      }}
                      style={{
                        flexGrow: 1,
                        flexShrink: 1,
                      }}
                    />
                  </View>

                  <View style={{height: moderateScale(8)}} />
                </Fragment>
              )}

              <View
                style={{
                  flexDirection: 'row',
                }}>
                <FilledButton
                  text={locales.goBack}
                  isDisabled={GatewaysStore.anyLoading()}
                  onPress={() => {
                    navigate('/hardware-type');
                  }}
                  leftNode={() => <NavigationIcon direction="back" />}
                  style={{
                    flexGrow: 1,
                    flexShrink: 1,
                  }}
                />

                <View style={{flexBasis: moderateScale(8)}} />

                {selectedCode !== null ? (
                  <FilledButton
                    text={locales.submit}
                    isDisabled={GatewaysStore.anyLoading()}
                    onPress={async () => {
                      await GatewaysStore.handleScan({
                        serial: serialNumber,
                        manuallyEntered: true,
                        gateway,
                        selectedCode,
                        setSelectedCode,
                        gatewayApi: apiClient.gateway,
                      });

                      setSerialNumber('');

                      if (gateway.error) return;

                      if (gateway?.warning) return;

                      if (GatewaysStore.validationResults.length) return;

                      const errorMessage =
                        GatewaysStore.sensorCountLimitExceededAcrossGatewaysMessage;

                      if (errorMessage) {
                        setShowGatewaySensorLimitError(true);

                        return;
                      }

                      navigate('/additional-hardware');
                    }}
                    rightNode={() => <NavigationIcon direction="forward" />}
                    style={{
                      flexGrow: 1,
                      flexShrink: 1,
                    }}
                  />
                ) : (
                  <FilledButton
                    text={locales.submit}
                    isDisabled={
                      GatewaysStore.anyLoading() || selectedCode !== null
                    }
                    onPress={() => {
                      const errorMessage =
                        GatewaysStore.sensorCountLimitExceededAcrossGatewaysMessage;

                      if (errorMessage && !showGatewaySensorLimitError) {
                        setShowGatewaySensorLimitError(true);

                        return;
                      }

                      navigate('/additional-hardware');
                    }}
                    rightNode={() => <NavigationIcon direction="forward" />}
                    style={{
                      flexGrow: 1,
                      flexShrink: 1,
                    }}
                  />
                )}
              </View>
            </Container>
          </ScrollView>
        </SafeAreaView>

        <Footer />
      </View>
    </Fragment>
  );
}

const GatewaysGrid = observer(
  ({
    selectedCode,
    setSelectedCode,
    onDeleted,
  }: {
    selectedCode: any;
    setSelectedCode: any;
    onDeleted: () => void;
  }) => {
    const isSecondPresented = GatewaysStore.gateways.some(g => g.code === 1);

    return (
      <div>
        <GatewayButton
          code={0}
          selected={selectedCode}
          setSelected={setSelectedCode}
          gateway={GatewaysStore.gateways.find(g => g.code === 0)}
          onDeleted={onDeleted}
        />

        {isSecondPresented && (
          <div className="mt-3">
            <GatewayButton
              code={1}
              selected={selectedCode}
              setSelected={setSelectedCode}
              gateway={GatewaysStore.gateways.find(g => g.code === 1)}
              onDeleted={onDeleted}
            />
          </div>
        )}

        {!isSecondPresented && (
          <RBButton
            className="mt-3"
            disabled={GatewaysStore.anyLoading()}
            block
            variant="outline-primary"
            onClick={() => {
              GatewaysStore.gateways.push({
                code: 1,
                esn: '',
                isUploaded: false,
              });
            }}>
            {locales.addSecondGateway}
          </RBButton>
        )}
      </div>
    );
  },
);

const GatewayButton = observer(
  ({
    code,
    selected,
    setSelected,
    gateway,
    onDeleted,
  }: {
    code: any;
    selected: any;
    setSelected: any;
    gateway: any;
    onDeleted: () => void;
  }) => {
    const {apiClient} = useStore();

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const {moderateScale} = useScale();

    const palette = usePalette();

    const styles = useStyles(createStyles);

    const del = async (id: any) => {
      gateway.id = null;
      gateway.esn = '';
      gateway.s3key = null;
      gateway.powers3key = null;
      gateway.isLoading = false;
      gateway.isUploaded = false;
      gateway.error = '';

      try {
        if (InstallationStore.installation?.id) {
          return await apiClient.gateway.delete(
            id,
            InstallationStore.installation.id,
            AssetStore.asset.vin,
          );
        }
      } catch (e: unknown) {
        if (e instanceof Error) {
          gateway.error = e.message;
        }
      }
    };

    const getVariant = () => {
      if (gateway?.validationResult?.status === 'warning')
        return 'outline-warning';

      if (gateway?.validationResult?.status === 'error')
        return 'outline-danger';

      if (selected === code) return 'primary';

      if (!gateway?.isUploaded) return 'outline-primary';

      return 'outline-success';
    };

    return (
      <Fragment>
        <Dialog
          isVisible={isDialogOpen}
          onBackdropPress={() => setIsDialogOpen(false)}
          style={{
            paddingHorizontal: moderateScale(24),
          }}>
          <Label
            variant="H2"
            color={palette.text[1000]}
            style={{
              paddingTop: moderateScale(24),
              paddingBottom: moderateScale(12),
              borderBottomWidth: 0.5,
              borderBottomColor: palette.surface[300],
            }}>
            {`Are you sure you want to remove ${
              gateway?.esn ? gateway.esn : 'gateway'
            }?`}
          </Label>

          <View style={{height: moderateScale(8)}} />

          <Label
            variant="P1"
            color={palette.text[900]}
            style={{marginVertical: moderateScale(12)}}>
            <Label variant="P1_bold" color={palette.text[900]}>
              {'Warning: '}
            </Label>
            This will remove this gateway from your installation.
          </Label>

          <View style={{height: moderateScale(8)}} />

          <View style={{flexDirection: 'row', justifyContent: 'flex-end'}}>
            <TextButton text="Cancel" onPress={() => setIsDialogOpen(false)} />

            <View style={{marginLeft: moderateScale(8)}} />

            <FilledButton
              text="Remove"
              onPress={async () => {
                setIsDialogOpen(false);

                const result = await del(gateway.id);

                if (result && result.validationResults) {
                  GatewaysStore.setValidationResults(result.validationResults);
                }

                onDeleted();
              }}
            />
          </View>

          <View style={{height: moderateScale(24)}} />
        </Dialog>

        <RBButton
          className="sensor-button"
          variant={getVariant()}
          onClick={() =>
            setSelected((prev: any) => (code === prev ? null : code))
          }>
          GATEWAY
          <br />
          {gateway?.isUploaded && gateway?.esn && `ESN: ${getEsn(gateway.esn)}`}
          {gateway?.isUploaded && (
            <TouchableWithoutFeedback onPress={() => setIsDialogOpen(true)}>
              <View style={styles.removeIcon}>
                <XCircleIcon />
              </View>
            </TouchableWithoutFeedback>
          )}
        </RBButton>
      </Fragment>
    );
  },
);

const GatewayInputScanner = observer(
  ({onScanned}: {onScanned: (text: string) => void}) => {
    return (
      <div className="text-center mt-3">
        <div className="mb-2">SCAN ESN CODE</div>

        <BarcodeScannerComponent
          onUpdate={(err, result) => {
            if (!result) return;

            const text = result.getText();

            if (!text) return;

            onScanned(text);
          }}
        />

        {!isIos && <Flash />}
      </div>
    );
  },
);

const createStyles = ({scale: {moderateScale}, palette}: ResponsiveStyle) =>
  StyleSheet.create({
    screen: {
      flexGrow: 1,
      flexShrink: 1,
      backgroundColor: palette.surface[50],
    },
    headerDivider: {
      borderBottomWidth: 1,
      borderBottomColor: palette.surface[200],
    },
    bodyContainer: {
      flexGrow: 1,
      flexShrink: 1,
    },

    removeIcon: {
      position: 'absolute',
      top: -moderateScale(6),
      right: -moderateScale(6),
    },
  });

export default observer(GatewaysData);
