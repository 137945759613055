import React, {ReactNode} from 'react';
import {
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import {useScale} from '../../../theme/scale';
import {ResponsiveStyle, useStyles} from '../../../theme/styles';
import Label from '../Label';
import {usePalette} from '../../../theme/palette';

type Props = {
  text: string;
  isDisabled?: boolean;
  onPress: () => void;
  leftNode?: () => ReactNode;
  rightNode?: () => ReactNode;
  style?: StyleProp<ViewStyle>;
};

const TextButton = (props: Props) => {
  const {text, isDisabled, onPress, leftNode, rightNode, style} = props;

  const styles = useStyles(createStyles);

  const {moderateScale} = useScale();

  const palette = usePalette();

  return (
    <TouchableOpacity
      disabled={isDisabled}
      onPress={onPress}
      style={[
        styles.navigationButton,
        {opacity: isDisabled ? 0.38 : undefined},
        style,
      ]}>
      <View style={{width: moderateScale(leftNode ? 12 : 16)}} />

      {leftNode && leftNode()}

      <View style={{width: moderateScale(leftNode ? 8 : 0)}} />

      <Label variant="B2" color={palette.text[900]}>
        {text}
      </Label>

      <View style={{width: moderateScale(rightNode ? 8 : 0)}} />

      {rightNode && rightNode()}

      <View style={{width: moderateScale(rightNode ? 12 : 16)}} />
    </TouchableOpacity>
  );
};

export default TextButton;

const createStyles = ({scale: {moderateScaleV}}: ResponsiveStyle) =>
  StyleSheet.create({
    navigationButton: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      height: moderateScaleV(40),
      borderRadius: 4,
    },
  });
