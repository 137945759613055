import FetchStore from '../stores/fetch/FetchStore';

export default class GatewayApi {
  constructor(private readonly fetchStore: FetchStore) {}

  async create(data: any, existingEsn: any, vin: string) {
    return await this.fetchStore.post(`gateway`, {
      model: data,
      existingEsn,
      vin,
    });
  }

  async update(data: any) {
    return await this.fetchStore.put(`gateway`, data);
  }

  async setS3key(id: any, key: any) {
    return await this.fetchStore.put(`gateway/${id}/s3key`, {s3key: key});
  }

  async setPowerS3key(id: any, key: any) {
    return await this.fetchStore.put(`gateway/${id}/powers3key`, {s3key: key});
  }

  async delete(id: any, installationId: number, vin: string) {
    return await this.fetchStore.delete(`gateway/${id}`, {
      installationId,
      vin,
    });
  }

  async validate(
    gatewayMacs: string[],
    vin: string,
  ): Promise<ValidationResultDto[]> {
    return await this.fetchStore.post(`gateway/validate`, {gatewayMacs, vin});
  }
}
