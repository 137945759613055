import React, {Fragment, useRef} from 'react';
import {observer} from 'mobx-react-lite';

import moment from 'moment';

import AssetStore from '../../stores/AssetStore';
import GatewaysStore from '../../stores/GatewaysStore';
import SensorsStore from '../../stores/SensorsStore';
import InstallationStore from '../../stores/InstallationStore';
import SensorPhotoUploader from '../../components/uploaders/SensorPhotoUploader';
import NavigationBar from '../../components/navigation/NavigationBar';
import ConfirmationModal from '../../components/ConfirmationModal';
import VINLabel from '../../components/VINLabel';

import {sensorType} from '../../utils/Constants';
import {getEsn} from '../../utils/Utils';
import {useStore} from '../../shared/stores/useStore';
import {ScrollView, StyleSheet, View} from 'react-native';
import InstallationTopAppBar from '../../components/InstallationTopAppBar';
import {SafeAreaView} from 'react-native-safe-area-context';
import {ResponsiveStyle, useStyles} from '../../shared/theme/styles';
import {useScale} from '../../shared/theme/scale';
import Container from '../../shared/components/common/Container';
import Footer from '../../shared/components/navigation/Footer';
import Label from '../../shared/components/common/Label';
import {usePalette} from '../../shared/theme/palette';

function InstallationPhotos() {
  const confirmationModalRef = useRef({});

  const styles = useStyles(createStyles);

  const {moderateScale} = useScale();

  const palette = usePalette();

  const filesLocation = `${AssetStore.asset.vin}/INSTALLATION_${InstallationStore.installation.id}`;

  return (
    <Fragment>
      <View style={styles.screen}>
        <InstallationTopAppBar />

        <SafeAreaView
          mode="padding"
          edges={['left', 'right']}
          style={styles.bodyContainer}>
          <ScrollView
            keyboardShouldPersistTaps="handled"
            contentContainerStyle={{
              flexGrow: 1,
              padding: moderateScale(16),
            }}>
            <Container
              style={{
                flexGrow: 1,
                flexShrink: 1,
              }}>
              <VINLabel />

              <Label
                variant="H3"
                color={palette.text[1000]}
                style={{textAlign: 'center', marginBottom: 24}}>
                Upload {AssetStore.assetTypeName} Install Photos
              </Label>

              <VinPhotoUploader
                filesLocation={filesLocation}
                confirmationModalRef={confirmationModalRef}
              />

              <GatewaysPhotoUploader
                filesLocation={filesLocation}
                confirmationModalRef={confirmationModalRef}
              />

              <SensorsPhotoUploader
                type={sensorType.smarthub}
                typeName="SmartHub"
                filesLocation={filesLocation}
                confirmationModalRef={confirmationModalRef}
              />

              <SensorsPhotoUploader
                type={sensorType.tpms}
                typeName="TPMS"
                filesLocation={filesLocation}
                confirmationModalRef={confirmationModalRef}
              />

              <PneumaticsPhotoUploader
                filesLocation={filesLocation}
                confirmationModalRef={confirmationModalRef}
              />

              <NavigationBar
                backTo="/hardware-type"
                proceedTo="/installation-work-order"
                className={undefined}
                backText={undefined}
                onBack={undefined}
                proceedText={undefined}
                onProceed={undefined}
                isHidden={undefined}
                isDisabled={undefined}
                dontuseNavigate={undefined}
                additionalLoadingState={undefined}
              />

              <ConfirmationModal ref={confirmationModalRef} />
            </Container>
          </ScrollView>
        </SafeAreaView>

        <Footer />
      </View>
    </Fragment>
  );
}

const VinPhotoUploader = observer(
  ({
    filesLocation,
    confirmationModalRef,
  }: {
    filesLocation: any;
    confirmationModalRef: any;
  }) => {
    const {apiClient} = useStore();

    const palette = usePalette();

    const setS3Key = async (key: any) => {
      await apiClient.installation.setAssetVins3key(
        InstallationStore.installation.id,
        key,
      );

      InstallationStore.installation.assetVins3key = key;
    };

    return (
      <>
        <Label
          variant="H5"
          color={palette.text[1000]}
          style={{textAlign: 'center', marginTop: 24}}>
          Asset
        </Label>

        <SensorPhotoUploader
          className="mb-3"
          name="ASSET VIN"
          fileName={`${filesLocation}/${moment().format('MM-DD-YYYY')}-VIN`}
          s3key={InstallationStore.installation.assetVins3key}
          onSucessfullUpload={setS3Key}
          onSucessfullDelete={() => setS3Key(null)}
          confirmationModalRef={confirmationModalRef}
        />
      </>
    );
  },
);

const GatewaysPhotoUploader = observer(
  ({
    filesLocation,
    confirmationModalRef,
  }: {
    filesLocation: any;
    confirmationModalRef: any;
  }) => {
    const {apiClient} = useStore();

    const palette = usePalette();

    const gateways = GatewaysStore.gateways.filter(g => g.id);

    const setS3Key = async (gateway: any, key: any) => {
      await apiClient.gateway.setS3key(gateway.id, key);

      gateway.s3key = key;
    };

    const setPowerS3Key = async (gateway: any, key: any) => {
      await apiClient.gateway.setPowerS3key(gateway.id, key);

      gateway.powers3key = key;
    };

    if (gateways.length <= 0) return null;

    return (
      <>
        <Label
          variant="H5"
          color={palette.text[1000]}
          style={{textAlign: 'center', marginTop: 24}}>
          Gateway
        </Label>

        {gateways.map(g => (
          <div key={g.id}>
            {getEsn(g.esn)}

            <SensorPhotoUploader
              className="mb-3"
              name="GATEWAY LOCATION"
              fileName={`${filesLocation}/GATEWAYS/${g.esn}/${moment().format(
                'MM-DD-YYYY',
              )}-LOCATION`}
              s3key={g.s3key}
              onSucessfullUpload={async (key: any) => {
                setS3Key(g, key);
              }}
              onSucessfullDelete={() => setS3Key(g, null)}
              confirmationModalRef={confirmationModalRef}
            />

            <SensorPhotoUploader
              className="mb-3"
              name="GATEWAY POWER CONNECTION"
              fileName={`${filesLocation}/GATEWAYS/${g.esn}/${moment().format(
                'MM-DD-YYYY',
              )}-POWER-CONNECTION`}
              s3key={g.powers3key}
              onSucessfullUpload={async (key: any) => {
                setPowerS3Key(g, key);
              }}
              onSucessfullDelete={() => setPowerS3Key(g, null)}
              confirmationModalRef={confirmationModalRef}
            />
          </div>
        ))}
      </>
    );
  },
);

const SensorsPhotoUploader = observer(
  ({
    type,
    typeName,
    filesLocation,
    confirmationModalRef,
  }: {
    type: any;
    typeName: any;
    filesLocation: any;
    confirmationModalRef: any;
  }) => {
    const {apiClient} = useStore();

    const palette = usePalette();

    const sensors = SensorsStore.sensors.filter(s => s.type === type && s.id);

    const setS3Key = async (sensor: any, key: any) => {
      await apiClient.sensor.setS3key(sensor.id, key);

      sensor.s3key = key;
    };

    const getSensorTypeName = (type: any) => {
      switch (type) {
        case sensorType.smarthub:
          return 'SMARTHUB';
        case sensorType.tpms:
          return 'TPMS';
        default:
          return '';
      }
    };

    if (sensors.length <= 0) return null;

    return (
      <>
        <Label
          variant="H5"
          color={palette.text[1000]}
          style={{textAlign: 'center', marginTop: 24}}>
          {typeName}
        </Label>

        {AssetStore.getSensorsPositionsList(type).map(p => {
          const sensor = sensors.find(s => s.code === p.code);

          if (!sensor) return null;

          return (
            <SensorPhotoUploader
              key={sensor.code}
              className="mb-3"
              name={sensor.code.replaceAll('_', ' ')}
              fileName={`${filesLocation}/SENSORS/${getSensorTypeName(
                sensor.type,
              )}/${moment().format('MM-DD-YYYY')}-${sensor.code}`}
              s3key={sensor.s3key}
              onSucessfullUpload={async (key: any) => {
                setS3Key(sensor, key);
              }}
              onSucessfullDelete={() => setS3Key(sensor, null)}
              confirmationModalRef={confirmationModalRef}
            />
          );
        })}
      </>
    );
  },
);

const PneumaticsPhotoUploader = observer(
  ({
    filesLocation,
    confirmationModalRef,
  }: {
    filesLocation: any;
    confirmationModalRef: any;
  }) => {
    const {apiClient} = useStore();

    const palette = usePalette();

    const axleLoad = SensorsStore.sensors.find(
      s => s.type === sensorType.axleLoad,
    );
    const axleLoadName = 'AXLE LOAD';

    const linePressure = SensorsStore.sensors.find(
      s => s.type === sensorType.linePressure,
    );
    const linePressureName = 'LINE PRESSURE';

    const setS3Key = async (sensor: any, key: any) => {
      await apiClient.sensor.setS3key(sensor.id, key);

      sensor.s3key = key;
    };

    return (
      <>
        {axleLoad && (
          <>
            <Label
              variant="H5"
              color={palette.text[1000]}
              style={{textAlign: 'center', marginTop: 24}}>
              {axleLoadName}
            </Label>

            <SensorPhotoUploader
              key={axleLoad.id}
              name={axleLoadName}
              fileName={`${filesLocation}/SENSORS/AXLE_LOAD/${moment().format(
                'MM-DD-YYYY',
              )}-AXLE_LOAD`}
              s3key={axleLoad.s3key}
              onSucessfullUpload={async (key: any) => {
                setS3Key(axleLoad, key);
              }}
              onSucessfullDelete={() => setS3Key(axleLoad, null)}
              confirmationModalRef={confirmationModalRef}
              className={undefined}
            />
          </>
        )}

        {linePressure && (
          <>
            <Label
              variant="H5"
              color={palette.text[1000]}
              style={{textAlign: 'center', marginTop: 24}}>
              {linePressureName}
            </Label>

            <SensorPhotoUploader
              key={linePressure.id}
              name={linePressureName}
              fileName={`${filesLocation}/SENSORS/LINE_PRESSURE/${moment().format(
                'MM-DD-YYYY',
              )}-LINE_PRESSURE`}
              s3key={linePressure.s3key}
              onSucessfullUpload={async (key: any) => {
                setS3Key(linePressure, key);
              }}
              onSucessfullDelete={() => setS3Key(linePressure, null)}
              confirmationModalRef={confirmationModalRef}
              className={undefined}
            />
          </>
        )}
      </>
    );
  },
);

const createStyles = ({palette}: ResponsiveStyle) =>
  StyleSheet.create({
    screen: {
      flexGrow: 1,
      flexShrink: 1,
      backgroundColor: palette.surface[50],
    },
    headerDivider: {
      borderBottomWidth: 1,
      borderBottomColor: palette.surface[200],
    },
    bodyContainer: {
      flexGrow: 1,
      flexShrink: 1,
    },
  });

export default observer(InstallationPhotos);
