import {observer} from 'mobx-react-lite';
import React, {useCallback, useMemo, useState} from 'react';
import {
  FlatList,
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import CheckBox from '../../components/common/CheckBox';
import DialogView from '../../components/common/DialogView';
import Label from '../../components/common/Label';
import LabelInput from '../../components/common/LabelInput';
import FilledButton from '../../components/common/MediumButton/FilledButton';
import OverlayModal from '../../components/common/OverlayModal';
import SearchIcon from '../../components/icons/SearchIcon';
import {COLORS} from '../../theme/colors';
import {usePalette} from '../../theme/palette';
import {useScale} from '../../theme/scale';
import {ResponsiveStyle, useStyles} from '../../theme/styles';
import {OverlayModalKey} from '../../types/enums';
import {listToTree} from '../../utils';

interface Props {
  customers: UserDto['customers'];
  label: string;
  value: number | null;
  alignLabel?: 'center' | 'left';
  error?: string;
  disabled?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  onChange: (customerId: number) => void;
}

const CustomerSelect: React.FC<Props> = ({
  customers,
  label,
  value,
  error,
  disabled,
  containerStyle = {},
  onChange,
}) => {
  const [filter, setFilter] = useState('');

  const [showModal, setShowModal] = useState(false);

  const [selectedCustomerId, setSelectedCustomerId] = useState(value);

  const palette = usePalette();

  const {moderateScale, moderateScaleV} = useScale();

  const styles = useStyles(createStyles);

  const customerTree = useMemo(() => listToTree(customers), [customers]);

  const filteredCustomers = useMemo(() => {
    const searchFilter = filter.trim().toLowerCase();

    if (!searchFilter) return customerTree;

    return customerTree.filter(customer => {
      const parentMatchesFilter = customer.name
        .toLowerCase()
        .includes(searchFilter);

      const childMatchesFilter = customer.children
        ? customer.children.some(c =>
            c.name.toLowerCase().includes(searchFilter),
          )
        : false;

      return parentMatchesFilter || childMatchesFilter;
    });
  }, [customerTree, filter]);

  const customerName = useMemo(() => {
    if (!value) return '';

    return customers.find(c => c.id === value)?.name || '';
  }, [value, customers]);

  const renderItem = useCallback(
    ({item}: {item: CustomerDto & {children: Array<CustomerDto>}}) => (
      <View>
        <TouchableOpacity
          style={styles.parentCustomer}
          onPress={() => setSelectedCustomerId(item.id)}>
          <CheckBox
            checked={selectedCustomerId === item.id}
            onChange={() => setSelectedCustomerId(item.id)}
          />

          <Label
            variant="L1"
            color={palette.text[900]}
            style={{
              marginLeft: moderateScale(10),
            }}>
            {item.name}
          </Label>
        </TouchableOpacity>

        {item.children.map((c: CustomerDto) => (
          <TouchableOpacity
            key={c.id}
            style={styles.childCustomer}
            onPress={() => setSelectedCustomerId(c.id)}>
            <CheckBox
              checked={selectedCustomerId === c.id}
              onChange={() => setSelectedCustomerId(c.id)}
            />

            <Label
              variant="L1"
              color={palette.text[900]}
              style={{
                marginLeft: moderateScale(10),
              }}>
              {c.name}
            </Label>
          </TouchableOpacity>
        ))}
      </View>
    ),
    [selectedCustomerId],
  );

  return (
    <View style={[styles.container, containerStyle]}>
      <OverlayModal
        modalKey={OverlayModalKey.CUSTOMER_SELECT}
        visible={showModal}>
        <DialogView fullWidth>
          <View style={styles.header}>
            <Label variant="H3" color={palette.text[1000]}>
              {'Select Customer'}
            </Label>
          </View>

          <View style={{height: 1, backgroundColor: palette.surface[200]}} />

          <View
            style={{
              marginHorizontal: moderateScale(16),
              marginVertical: moderateScaleV(24),
            }}>
            <LabelInput
              placeholder="Search..."
              value={filter}
              onChangeText={text => setFilter(text)}
              renderLeftNode={isFocused => (
                <View style={{paddingLeft: moderateScale(12)}}>
                  <SearchIcon
                    color={
                      isFocused ? palette.surface[1000] : palette.surface[900]
                    }
                  />
                </View>
              )}
            />
          </View>

          <FlatList
            keyExtractor={customer => customer.id.toString()}
            data={filteredCustomers}
            renderItem={renderItem}
            contentContainerStyle={{paddingVertical: moderateScaleV(8)}}
          />

          <View style={{height: 1, backgroundColor: palette.surface[200]}} />

          <View style={styles.footer}>
            <View style={styles.leftButton}>
              <FilledButton
                text="Cancel"
                onPress={() => {
                  setFilter('');
                  setShowModal(false);
                  setSelectedCustomerId(value);
                }}
                style={{backgroundColor: palette.surface[400]}}
              />
            </View>

            <View style={styles.rightButton}>
              <FilledButton
                text="Ok"
                onPress={() => {
                  if (selectedCustomerId) onChange(selectedCustomerId);

                  setFilter('');
                  setShowModal(false);
                }}
              />
            </View>
          </View>
        </DialogView>
      </OverlayModal>

      <Label
        variant="L1"
        color={palette.text[900]}
        style={{marginBottom: moderateScaleV(4)}}>
        {label}
      </Label>

      <View style={styles.inputContainer}>
        <LabelInput
          placeholder="Select Customer"
          value={customerName}
          isDisabled={disabled}
          onPress={() => setShowModal(prev => !prev)}
          containerStyle={{
            flexGrow: 1,
            flexShrink: 1,
          }}
        />
      </View>

      {!!error && (
        <Label
          variant="L4"
          color={palette.error[300]}
          style={{marginTop: moderateScaleV(4)}}>
          {error?.toString()}
        </Label>
      )}
    </View>
  );
};

const createStyles = ({scale: {moderateScale}}: ResponsiveStyle) =>
  StyleSheet.create({
    container: {
      justifyContent: 'center',
    },
    inputContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    label: {
      marginBottom: moderateScale(5),
    },
    error: {
      color: COLORS.red,
      marginTop: moderateScale(10),
    },
    modalContainer: {
      flex: 1,
      backgroundColor: 'rgba(33, 37, 41, 0.3)',
      padding: moderateScale(10),
    },
    centeredView: {
      flex: 1,
      borderRadius: 4,
      backgroundColor: '#fff',
    },
    header: {
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'row',
      padding: moderateScale(20),
    },
    parentCustomer: {
      flexDirection: 'row',
      paddingHorizontal: moderateScale(16),
      paddingVertical: moderateScale(5),
    },
    childCustomer: {
      flexDirection: 'row',
      paddingHorizontal: moderateScale(16),
      paddingVertical: moderateScale(5),
      marginLeft: moderateScale(24 + 10),
    },
    footer: {
      flexDirection: 'row',
      padding: moderateScale(20),
    },
    leftButton: {
      flex: 0.5,
      marginRight: moderateScale(10),
    },
    rightButton: {
      flex: 0.5,
      marginLeft: moderateScale(10),
    },
  });

export default observer(CustomerSelect);
