import React, {useState} from 'react';

import ButtonWithSpinner from '../ButtonWithSpinner';
import locales from '../../shared/locales/en';
import {useNavigate} from '../../shared/utils/routing';

function NavigationBar({
  className,
  backText,
  backTo,
  onBack,
  proceedText,
  proceedTo,
  onProceed,
  isHidden,
  isDisabled,
  dontuseNavigate,
  additionalLoadingState,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleBack = async () => {
    try {
      if (onBack) {
        setIsLoading(true);

        await onBack();
      }
    } catch (e) {
      console.error(e.message);
    } finally {
      setIsLoading(false);
      navigate(backTo);
    }
  };

  const handleProceed = async () => {
    try {
      setIsLoading(true);

      if (onProceed) {
        await onProceed();
      }

      if (!dontuseNavigate) {
        navigate(proceedTo);
      }
    } catch (e) {
      console.error(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  if (isHidden) return null;

  return (
    <div className={`d-flex mt-3 ${className || ''}`}>
      {backTo && (
        <ButtonWithSpinner
          className="flex-fill"
          onClick={handleBack}
          isLoading={isLoading || additionalLoadingState}
          disabled={isDisabled}>
          <i className="fas fa-arrow-circle-left mr-2" />
          {backText || locales.goBack}
        </ButtonWithSpinner>
      )}

      {proceedTo && (
        <ButtonWithSpinner
          className="flex-fill ml-3"
          onClick={handleProceed}
          isLoading={isLoading || additionalLoadingState}
          disabled={isDisabled}>
          {proceedText || locales.proceed}
          <i className="fas fa-arrow-circle-right ml-2" />
        </ButtonWithSpinner>
      )}
    </div>
  );
}

export default NavigationBar;
