import React, {Fragment} from 'react';
import {Button} from 'react-bootstrap';
import VINLabel from '../../components/VINLabel';
import {ScrollView, StyleSheet, View} from 'react-native';
import InstallationTopAppBar from '../../components/InstallationTopAppBar';
import {SafeAreaView} from 'react-native-safe-area-context';
import Container from '../../shared/components/common/Container';
import {useScale} from '../../shared/theme/scale';
import {ResponsiveStyle, useStyles} from '../../shared/theme/styles';
import Footer from '../../shared/components/navigation/Footer';
import Label from '../../shared/components/common/Label';
import {usePalette} from '../../shared/theme/palette';
import {useNavigate} from '../../shared/utils/routing';

function AdditionalHardware() {
  const navigate = useNavigate();

  const styles = useStyles(createStyles);

  const {moderateScale} = useScale();

  const palette = usePalette();

  return (
    <Fragment>
      <View style={styles.screen}>
        <InstallationTopAppBar />

        <SafeAreaView
          mode="padding"
          edges={['left', 'right']}
          style={styles.bodyContainer}>
          <ScrollView
            keyboardShouldPersistTaps="handled"
            contentContainerStyle={{
              flexGrow: 1,
              padding: moderateScale(16),
            }}>
            <Container
              style={{
                flexGrow: 1,
                flexShrink: 1,
              }}>
              <VINLabel />

              <Label
                variant="H3"
                color={palette.text[1000]}
                style={{textAlign: 'center', marginBottom: 24}}>
                Add additional hardware?
              </Label>

              <Button
                block
                size="lg"
                onClick={() => {
                  navigate('/hardware-type');
                }}>
                Yes
              </Button>
              <Button
                block
                size="lg"
                onClick={() => {
                  navigate('/installation-photos');
                }}>
                No
              </Button>
            </Container>
          </ScrollView>
        </SafeAreaView>

        <Footer />
      </View>
    </Fragment>
  );
}

const createStyles = ({palette}: ResponsiveStyle) =>
  StyleSheet.create({
    screen: {
      flexGrow: 1,
      flexShrink: 1,
      backgroundColor: palette.surface[50],
    },
    headerDivider: {
      borderBottomWidth: 1,
      borderBottomColor: palette.surface[200],
    },
    bodyContainer: {
      flexGrow: 1,
      flexShrink: 1,
    },
  });

export default AdditionalHardware;
