import React from 'react';
import {observer} from 'mobx-react-lite';

import AssetStore from '../../../stores/AssetStore';

import Counter from '../../../components/Counter';

import {assetType} from '../../../utils/Constants';
import Label from '../../../shared/components/common/Label';
import {usePalette} from '../../../shared/theme/palette';

function AxlesNumberSelector() {
  const palette = usePalette();

  return (
    <div
      className="d-flex justify-content-center mb-3"
      style={{alignItems: 'center'}}>
      <Label variant="P1" color={palette.text[1000]}>
        # of Axles:
      </Label>

      <Counter
        min={AssetStore.type === assetType.trailer ? 1 : 2}
        max={5}
        value={AssetStore.asset.numberOfAxles}
        onChange={v => (AssetStore.asset.numberOfAxles = v)}
      />
    </div>
  );
}

export default observer(AxlesNumberSelector);
