import {configure} from 'mobx';
import {observer} from 'mobx-react-lite';
import React from 'react';
import 'react-html5-camera-photo/build/css/index.css';
import {GestureHandlerRootView} from 'react-native-gesture-handler';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import RootStore from '../shared/stores/RootStore';
import {StoreProvider} from '../shared/stores/useStore';
import AdaptiveScaleProvider from '../shared/theme/AdaptiveScaleProvider';
import {BreakpointsProvider} from '../shared/theme/breakpoints';
import {ColorsProvider} from '../shared/theme/colors';
import '../styles/app.scss';
import {OverlayModalProvider} from '../shared/components/common/OverlayModal';
import {PaletteProvider} from '../shared/theme/palette';
import AppRouter from '../components/AppRouter';

configure({
  enforceActions: 'never',
});

export const rootStore = new RootStore();

function App() {
  return (
    <React.StrictMode>
      <StoreProvider store={rootStore}>
        <BreakpointsProvider>
          <AdaptiveScaleProvider>
            <PaletteProvider>
              <ColorsProvider>
                <SafeAreaProvider>
                  <OverlayModalProvider>
                    <GestureHandlerRootView style={{flex: 1}}>
                      <AppRouter />
                    </GestureHandlerRootView>
                  </OverlayModalProvider>
                </SafeAreaProvider>
              </ColorsProvider>
            </PaletteProvider>
          </AdaptiveScaleProvider>
        </BreakpointsProvider>
      </StoreProvider>
    </React.StrictMode>
  );
}

export default observer(App);
