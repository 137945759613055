import {IAppPermissions} from './types';

export default class AppPermissions implements IAppPermissions {
  private static _instance: AppPermissions | null = null;

  private constructor() {}

  public static get instance() {
    if (!this._instance) {
      this._instance = new AppPermissions();
    }

    return this._instance;
  }

  public async checkCameraPermission() {
    return false;
  }

  public async requestCameraPermission() {
    return false;
  }

  public async checkPhotoLibraryPermission() {
    return false;
  }

  public async requestPhotoLibraryPermission() {
    return false;
  }
}
