import React, {createContext, useContext} from 'react';
import RootStore from './RootStore';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const StoreContext = createContext<RootStore>();

const StoreProvider: React.FC<{store: RootStore}> = ({store, children}) => {
  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};

const useStore = () => useContext(StoreContext);

export {RootStore, StoreProvider, useStore};
