import Logging from '../services/logging';
import FetchStore from '../stores/fetch/FetchStore';

export default class DocumentApi {
  constructor(private readonly fetchStore: FetchStore) {}

  async getPublicDocument(
    documentKey: DocumentKey,
  ): Promise<{document: DocumentDto} | null> {
    try {
      return await this.fetchStore.get(`document/public/${documentKey}`);
    } catch (e: unknown) {
      const error = Logging.parseUnknownError(e);
      Logging.recordError(error);

      return null;
    }
  }
}
