import React, {useCallback} from 'react';
import {LayoutChangeEvent} from 'react-native';
import Popover, {Rect, calcAbsoluteRect} from '../Popover';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {useScale} from '../../../theme/scale';

type Props = {
  visible?: boolean;
  minHeight: number;
  maxHeight: number;
  minWidth?: number;
  maxWidth?: number;
  onBackdrop: () => void;
  renderComponent: (
    ref: React.RefObject<any>,
    onLayout: (event: LayoutChangeEvent) => void,
  ) => React.ReactNode;
  renderPopover: () => React.ReactNode;
};

const SafeAreaPopoverForSelect: React.FC<Props> = props => {
  const {
    visible,
    minHeight,
    maxHeight,
    minWidth: _minWidth,
    maxWidth: _maxWidth,
    onBackdrop,
    renderComponent,
    renderPopover,
  } = props;

  const insets = useSafeAreaInsets();

  const {moderateScale} = useScale();

  const calcPopoverRect = useCallback(
    (windowDimensions: Rect, absoluteComponentRect: Rect): Rect => {
      const safeAreaRect: Rect = {
        x: insets.left + moderateScale(16),
        y: insets.top + moderateScale(16),
        width:
          windowDimensions.width -
          insets.left -
          insets.right -
          moderateScale(16 * 2),
        height:
          windowDimensions.height -
          insets.top -
          insets.bottom -
          moderateScale(16 * 2),
      };

      const minWidth = _minWidth || absoluteComponentRect.width;
      const maxWidth = _maxWidth || absoluteComponentRect.width;

      const componentBottomY =
        absoluteComponentRect.y + absoluteComponentRect.height;

      const componentRightX =
        absoluteComponentRect.x + absoluteComponentRect.width;

      const componentVerticallyInsideViewport =
        componentBottomY > 0 &&
        absoluteComponentRect.y < windowDimensions.height;

      const componentHorizontallyInsideViewport =
        componentRightX > 0 && absoluteComponentRect.x < windowDimensions.width;

      const spaces = {
        top: absoluteComponentRect.y - safeAreaRect.y,
        bottom:
          safeAreaRect.height -
          (absoluteComponentRect.y +
            absoluteComponentRect.height -
            safeAreaRect.y),
        left: absoluteComponentRect.x + absoluteComponentRect.width,
        right: windowDimensions.width - absoluteComponentRect.x,
      };

      if (
        !componentVerticallyInsideViewport ||
        !componentHorizontallyInsideViewport ||
        Math.max(spaces.bottom, spaces.top) < minHeight ||
        Math.max(spaces.left, spaces.right) < minWidth
      ) {
        return calcAbsoluteRect(
          Math.min(safeAreaRect.width, maxWidth),
          Math.min(safeAreaRect.height, maxHeight),
          windowDimensions,
          {x: 0.5, y: 0.5},
          {x: 0, y: 0},
          {x: 0.5, y: 0.5},
        );
      } else {
        const availableWidth =
          spaces.right > minWidth ? spaces.right : spaces.left;
        const availableHeight =
          spaces.bottom > minHeight ? spaces.bottom : spaces.top;

        const clampedMinWidth = Math.min(availableWidth, minWidth);
        const clampedMaxWidth = Math.min(availableWidth, maxWidth);
        const clampedMinHeight = Math.min(availableHeight, minHeight);
        const clampedMaxHeight = Math.min(availableHeight, maxHeight);

        return calcAbsoluteRect(
          Math.max(clampedMinWidth, clampedMaxWidth),
          Math.max(clampedMinHeight, clampedMaxHeight),
          absoluteComponentRect,
          {
            x: spaces.right > minWidth ? 0 : 1,
            y: spaces.bottom > minHeight ? 1 : 0,
          },
          {x: 0, y: 0},
          {
            x: spaces.right > minWidth ? 0 : 1,
            y: spaces.bottom > minHeight ? 0 : 1,
          },
        );
      }
    },
    [minHeight, _minWidth, maxHeight, _maxWidth, insets],
  );

  return (
    <Popover
      visible={visible}
      calcPopoverRect={calcPopoverRect}
      onBackdrop={onBackdrop}
      renderComponent={renderComponent}
      renderPopover={renderPopover}
    />
  );
};

export default SafeAreaPopoverForSelect;
