import React from 'react';
import {StyleProp, View, ViewStyle} from 'react-native';
import {usePalette} from '../../theme/palette';
import Label from './Label';
import {useScale} from '../../theme/scale';

type Props = {
  variant: 'centerAligned';
} & CenterAlignedProps;

type CenterAlignedProps = {
  styleVariant: 'gray' | 'success' | 'warning' | 'error';
  label: string;
  style?: StyleProp<ViewStyle>;
};

const Alert: React.FC<Props> = props => {
  const {variant, ...rest} = props;

  if (variant === 'centerAligned') {
    return <CenterAligned {...rest} />;
  }

  return null;
};

const CenterAligned: React.FC<CenterAlignedProps> = props => {
  const {styleVariant, label, style} = props;

  const palette = usePalette();

  const {moderateScale} = useScale();

  const stateStyles: Record<CenterAlignedProps['styleVariant'], string> = {
    gray: palette.surface[800],
    success: palette.success[300],
    warning: palette.amber[400],
    error: palette.error[300],
  };

  return (
    <View
      style={[
        {
          borderRadius: 8,
          borderWidth: 1,
          padding: moderateScale(12),
          borderColor: palette.surface[300],
          backgroundColor: stateStyles[styleVariant],
        },
        style,
      ]}>
      <Label variant="P2" color={palette.text[0]} style={{textAlign: 'center'}}>
        {label}
      </Label>
    </View>
  );
};

export default Alert;
