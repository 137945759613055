import { makeAutoObservable, extendObservable } from "mobx";

import { autoSave } from "../utils/Mobx";

import GatewaysStore from "./GatewaysStore";
import SensorsStore from "./SensorsStore";

import { assetType, atiInstalled, sensorType } from "../utils/Constants";

const defaultAsset = {
  id: null,

  vin: "",
  name: "",
  odometer: "",
  isOdometerInMiles: true,

  make: "",
  model: "",
  modelyear: "",
  numberOfAxles: 2,
  atiInstalled: null,
  newOdometer: undefined
};

class AssetStore {
  constructor() {
    this.asset = defaultAsset;
    this.type = assetType.tractor;
    this.isVinFound = false;
    this.isInvalid = false;
    this.isNew = false;
    this.nhtsaRequestTimeout = false;

    this.load();
    autoSave(this, this.save.bind(this));
  }

  load() {
    let json = localStorage.getItem("asset");

    if (json) extendObservable(this, JSON.parse(json), {}, { deep: true });
    else makeAutoObservable(this, {}, { deep: true });
  }

  save(json) {
    localStorage.setItem("asset", json);
  }

  getSensorsPositionsList(type, positionsAssetType, assetAxlesCount) {
    const assetTypeToFind = positionsAssetType || this.type;
    const numberOfAxles = assetAxlesCount || this.asset.numberOfAxles;
    const axles = [];

    if (type === sensorType.smarthub) {
      if (assetTypeToFind === assetType.trailer) {
        for (let i = 1; i <= numberOfAxles; i++) {
          axles.push({ name: `TRAILER LEFT ${i}`, code: `TRAILER_LEFT_${i}` }, { name: `TRAILER RIGHT ${i}`, code: `TRAILER_RIGHT_${i}` });
        }
      } else {
        axles.push({ name: "STEER LEFT", code: "STEER_LEFT" }, { name: "STEER RIGHT", code: "STEER_RIGHT" });

        for (let i = 2; i <= numberOfAxles; i++) {
          axles.push({ name: `DRIVE LEFT ${i - 1}`, code: `DRIVE_LEFT_${i - 1}` }, { name: `DRIVE RIGHT ${i - 1}`, code: `DRIVE_RIGHT_${i - 1}` });
        }
      }
    }

    if (type === sensorType.tpms) {
      if (assetTypeToFind === assetType.trailer) {
        for (let i = 1; i <= numberOfAxles; i++) {
          axles.push(
            { code: `TRAILER_LEFT_${i}_OUTER`, name: `T-${i} L-O` },
            { code: `TRAILER_LEFT_${i}_INNER`, name: `T-${i} L-I` },
            { code: `TRAILER_RIGHT_${i}_INNER`, name: `T-${i} R-I` },
            { code: `TRAILER_RIGHT_${i}_OUTER`, name: `T-${i} R-O` }
          );
        }
      } else {
        axles.push({ code: "STEER_LEFT", name: "SL" }, { code: "STEER_RIGHT", name: "SR" });

        for (let i = 2; i <= numberOfAxles; i++) {
          axles.push(
            { code: `DRIVE_LEFT_${i - 1}_OUTER`, name: `D-${i - 1} L-O` },
            { code: `DRIVE_LEFT_${i - 1}_INNER`, name: `D-${i - 1} L-I` },
            { code: `DRIVE_RIGHT_${i - 1}_INNER`, name: `D-${i - 1} R-I` },
            { code: `DRIVE_RIGHT_${i - 1}_OUTER`, name: `D-${i - 1} R-O` }
          );
        }
      }
    }

    if (type === sensorType.axleLoad) {
      axles.push({ code: "AXLE_LOAD", name: "AXLE LOAD" });
    }

    if (type === sensorType.linePressure) {
      axles.push({ code: "LINE_PRESSURE", name: "LINE PRESSURE" });
    }

    return axles;
  }

  get assetTypeName() {
    switch (this.type) {
      case assetType.trailer:
        return "Trailer";
      case assetType.tractor:
        return "Tractor";
      default:
        return "";
    }
  }

  set(asset) {
    try {
      this.asset.id = asset.id;
      this.asset.vin = asset.vin;
      this.asset.make = asset.make;
      this.asset.model = asset.model;
      this.asset.modelyear = asset.modelyear;
      this.asset.name = asset.name;
      this.asset.odometer = asset.odometer;
      this.asset.isOdometerInMiles = asset.isOdometerInMiles;
      this.asset.atiInstalled = asset.atiInstalled;
      this.type = asset.type;
      this.isVinFound = true;
      this.isInvalid = asset.isInvalid;
      this.isNew = asset.isNew || false;
      this.nhtsaRequestTimeout = asset.nhtsaRequestTimeout || false;
    } catch {
      throw new Error("Something went wrong");
    }
  }

  clear() {
    GatewaysStore.clear();
    SensorsStore.clear();

    this.asset = defaultAsset;
    this.isVinFound = false;
    this.isInvalid = false;
    this.isNew = false;
    this.nhtsaRequestTimeout = false;
  }

  async updateAti(ati, assetAPI) {
    if (this.asset.id === null) return;

    if ([atiInstalled.no, atiInstalled.yes, atiInstalled.notSure].includes(ati) === false) return;

    if (this.asset.atiInstalled === ati) return;

    await assetAPI.setAti(this.asset.id, ati);

    this.asset.atiInstalled = ati;
  }

  areOdometersEqual(odometer, isOdometerInMiles) {
    let isValueEqual = this.asset.odometer === null && this.asset.odometer === odometer;

    if (this.asset.odometer !== null && isValueEqual === false) {
      const currentOdometer = parseInt(this.asset.odometer, 10);

      if (isNaN(currentOdometer)) return false;

      isValueEqual = currentOdometer === odometer;
    }

    return isValueEqual && this.asset.isOdometerInMiles === isOdometerInMiles;
  }

  get hasOdometer() {
    if (typeof this.asset.odometer === "number" && isNaN(this.asset.odometer) === false) return true;

    if (typeof this.asset.odometer === "string") {
      const parsedOdometer = parseInt(this.asset.odometer, 10);

      return !isNaN(parsedOdometer);
    }

    return false;
  }

  async resetOdometer(assetAPI) {
    if (!this.asset.id) return;

    const data = {
      odometer: null,
      isOdometerInMiles: true,
    };

    await assetAPI.setOdometer(this.asset.id, data);

    this.asset.odometer = null;
    this.asset.isOdometerInMiles = true;
  }
}

export default new AssetStore();
