import React, {useEffect} from 'react';
import {View} from 'react-native';
import Animated, {
  cancelAnimation,
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withTiming,
} from 'react-native-reanimated';
import Svg, {Path} from 'react-native-svg';
import {usePalette} from '../../theme/palette';
import {useScale} from '../../theme/scale';

type SpinnerProps = {
  size?: number;
  fill?: string;
};
const beamCount = 8.0;
const degStep = 360.0 / beamCount;

const Spinner = (props: SpinnerProps) => {
  const palette = usePalette();

  const {moderateScale} = useScale();

  const {size = 40, fill = palette.surface[1000]} = props;

  const rotation = useSharedValue(0);

  const animatedStyle = useAnimatedStyle(() => ({
    transform: [{rotateZ: `${Math.floor(rotation.value / 1) * degStep}deg`}],
  }));

  useEffect(() => {
    rotation.value = 0;

    rotation.value = withRepeat(
      withTiming(beamCount, {duration: 1000, easing: Easing.linear}),
      -1,
    );

    return () => cancelAnimation(rotation);
  }, []);

  return (
    <View
      style={{
        width: moderateScale(size),
        height: moderateScale(size),
        position: 'relative',
      }}>
      <Animated.View
        style={[
          {
            position: 'absolute',
          },
          animatedStyle,
        ]}>
        <Svg
          width={moderateScale(size)}
          height={moderateScale(size)}
          viewBox="0 0 40 40"
          fill="none">
          <Path
            d="M20 5v5"
            stroke={fill}
            strokeOpacity={7 / beamCount}
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <Path
            d="m9.39 9.39 3.532 3.532"
            stroke={fill}
            strokeOpacity={6 / beamCount}
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <Path
            d="M5 20h5"
            stroke={fill}
            strokeOpacity={5 / beamCount}
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <Path
            d="m9.39 30.61 3.532-3.532"
            stroke={fill}
            strokeOpacity={4 / beamCount}
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <Path
            d="M20 35v-5"
            stroke={fill}
            strokeOpacity={3 / beamCount}
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <Path
            d="m30.61 30.61-3.532-3.532"
            stroke={fill}
            strokeOpacity={2 / beamCount}
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <Path
            d="M35 20h-5"
            stroke={fill}
            strokeOpacity={1 / beamCount}
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <Path
            d="M30.607 9.393 27.07 12.93"
            stroke={fill}
            strokeOpacity={1}
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </Svg>
      </Animated.View>
    </View>
  );
};

export default Spinner;
