import {observer} from 'mobx-react-lite';
import React from 'react';
import {Alert} from 'react-bootstrap';
import {whitelistingStatusType} from '../utils/Constants';
import {getEsn} from '../utils/Utils';

const WhitelistingStatusAlert = ({status, gateway_mac}) => {
  let variant;
  if (status === whitelistingStatusType.success) variant = 'success';
  else if (status === whitelistingStatusType.failed) variant = 'danger';
  else variant = 'dark';

  const gatewayMacString = gateway_mac ? `(${getEsn(gateway_mac)})` : '';

  return (
    <Alert
      variant={
        variant
      }>{`Gateway ${gatewayMacString} Provisioning Status: ${status
      .toLowerCase()
      .replace('_', ' ')}`}</Alert>
  );
};

export default observer(WhitelistingStatusAlert);

export const WhitelistingStatusAlerts = ({whitelistingStatuses}) => {
  return (
    <>
      {whitelistingStatuses.map(whitelistingStatus => {
        if (!whitelistingStatus.status) return null;
        return (
          <WhitelistingStatusAlert
            key={whitelistingStatus.gateway_mac}
            status={whitelistingStatus.status}
            gateway_mac={
              whitelistingStatuses.length > 1
                ? whitelistingStatus.gateway_mac
                : undefined
            }
          />
        );
      })}
    </>
  );
};
